import axios from "./axios";

export default {
    getActiveDataDictionaryRevisionData(brandURL, payload) {
        return axios.get(`/brands/${brandURL}/data-dictionary/revisions/active`, { params: payload });
    },
    setActiveDataDictionaryRevision(brandURL, payload) {
        return axios.post(`/brands/${brandURL}/data-dictionary/revisions/active`, payload);
    },
    saveDataDictionaryRevision(brandURL, payload) {
        return axios.post(`/brands/${brandURL}/data-dictionary/revisions`, payload);
    },
    getDataDictionaryRevisions(brandURL) {
        return axios.get(`/brands/${brandURL}/data-dictionary/revisions`);
    }
};
