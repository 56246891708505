<template>
    <div
        class="sidebar-header"
        :class="{ collapsed: isCollapsedSideBar }"
    >
        <button
            class="dashboard__sideBar--toggle"
            tabindex="0"
            @click="toggleSideBar"
        >
            <span class="cssLogo">
                <span class="hiddenText">Open full menu</span>
                <span class="one" />
                <span class="two" />
                <span class="three" />
            </span>
        </button>
        <div class="logo-wrapper">
            <img
                src="../../assets/asanto-white.svg"
                alt="Asanto logo"
                class="logo"
            >
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "SidebarHeader",
    computed: {
        ...mapState({
            brands: (state) => state.auth.brands,
            selectedBrand: (state) => state.auth.selectedBrand,
            provider: (state) => state.auth.provider,
            isCollapsedSideBar: (state) => state.application.isCollapsedSideBar
        })
    },
    methods: {
        toggleSideBar() {
            this.$store.dispatch("application/toggleSideBar", !this.isCollapsedSideBar);
        }
    }
};
</script>

<style lang="sass" scoped>
.sidebar-header
    display: grid
    grid-template-columns: auto 1fr 30px
    align-items: center
    gap: 10px
    padding: 10px
    border-bottom: 1px solid rgba(255, 255, 255, 0.3)
    .logo-wrapper
        position: relative
        display: flex
        width: min-content
    .logo
        width: 110px
    .logo-append
        position: absolute
        right: 0
        top: 100%
        color: $white
    &.collapsed
        .logo
            display: none
</style>
