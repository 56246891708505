import {isFormulaValid,isNotEmpty} from "./ExpressionValidators";
import Expression from "./Expression";

export default class FormulaExpression extends Expression {
    constructor(id){
        const properties = {
            data:{
                value: null,
                isValid:null,
                dirty:false,
                errors:[],
                validators:[
                    isNotEmpty(),
                    isFormulaValid()
                ]   
            },
            expressionType:{
                value: "formula",
                isValid:null,
                validators:[]   
            }
        };
        super(id,properties);
    }
    static cleanExpression(exp) {
        if(exp.returns.type==="array"){
            if (!exp.returns.items?.type) {
                exp.returns.items = {
                    type:"string"
                };
            }
        }
        return {
            id:exp.id,
            returns:exp.returns,
            data:exp.data,
            expressionType:exp.expressionType,
            description: exp.description,
            tags: exp.tags
        };
    }
}
