import * as permissionsService  from "../../../../services/provider/permissions";

export const types = {
    "SET_PROVIDER_PERMISSIONS": "SET_PROVIDER_PERMISSIONS",
    "SET_BRAND_PERMISSIONS": "SET_BRAND_PERMISSIONS"
};

const state = {
    providerPermissions: [],
    brandPermissions: []
};

const actions = {
    async getProviderPermissions({ commit }) {
        const { data } = await permissionsService.getProviderPermissions();
        commit(types.SET_PROVIDER_PERMISSIONS, data);
    },
    async getBrandPermissions({ commit }) {
        const { data } = await permissionsService.getBrandPermissions();
        commit(types.SET_BRAND_PERMISSIONS, data);
    }
};

const mutations = {
    [types.SET_PROVIDER_PERMISSIONS]: (state, permissions) => (state.providerPermissions = permissions),
    [types.SET_BRAND_PERMISSIONS]: (state, permissions) => (state.brandPermissions = permissions)
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
