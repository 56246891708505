export const CONDITION_TYPES = {
    IP_ADDRESS: 1,
    DATE_TIME_RANGE: 2,
    ACCESS: 3
};

export const DEFAULT_CONDITION = {
    name: "",
    "condition_type_id": CONDITION_TYPES.IP_ADDRESS,
    data: {
        values: []
    }
};
