<template>
    <div class="row dashboard__row">
        <div
            class="col-4 dashboard__data"
            :class="[{'up': quotesPercentage > 0, 'down': quotesPercentage < 0}]"
        >
            <b-overlay
                variant="transparent"
                :show="loadingQuotes"
            >
                <h3 class="dashboard__data--title">
                    Quotes
                </h3>
                <span class="dashboard__data--info">{{ quotes }}</span>
                <span class="dashboard__data--change">
                    <strong>{{ quotesPercentage }}%</strong>
                    on {{ displayRange }}
                </span>
            </b-overlay>
        </div>
        <div
            class="col-4 dashboard__data"
            :class="[{'up': errorsPercentage > 0, 'down': errorsPercentage < 0}]"
        >
            <b-overlay
                variant="transparent"
                :show="loadingErrors"
            >
                <h3 class="dashboard__data--title">
                    Errors
                </h3>
                <span class="dashboard__data--info">{{ errors }}</span>
                <span class="dashboard__data--change">
                    <strong>{{ errorsPercentage }}%</strong>
                    on {{ displayRange }}
                </span>
            </b-overlay>
        </div>
        <div
            class="col-4 dashboard__data"
            :class="[{'up': requestsPercentage > 0, 'down': requestsPercentage < 0}]"
        >
            <b-overlay
                variant="transparent"
                :show="loadingRequests"
            >
                <h3 class="dashboard__data--title">
                    Requests
                </h3>
                <span class="dashboard__data--info">{{ requests }}</span>
                <span class="dashboard__data--change">
                    <strong>{{ requestsPercentage }}%</strong>
                    on {{ displayRange }}
                </span>
            </b-overlay>
        </div>

        <div
            class="col-4 dashboard__data"
            :class="[{'up': rejectsPercentage > 0, 'down': rejectsPercentage < 0}]"
        >
            <b-overlay
                variant="transparent"
                :show="loadingRejects"
            >
                <h3 class="dashboard__data--title">
                    Rejects
                </h3>
                <span class="dashboard__data--info">{{ rejects }}</span>
                <span class="dashboard__data--change">
                    <strong>{{ rejectsPercentage }}%</strong>
                    on {{ displayRange }}
                </span>
            </b-overlay>
        </div>
        <div
            class="col-4 dashboard__data"
            :class="[{'up': cancellationsPercentage > 0, 'down': cancellationsPercentage < 0}]"
        >
            <b-overlay
                variant="transparent"
                :show="loadingCancellations"
            >
                <h3 class="dashboard__data--title">
                    Cancellations
                </h3>
                <span class="dashboard__data--info">{{ cancellations }}</span>
                <span class="dashboard__data--change">
                    <strong>{{ cancellationsPercentage }}%</strong>
                    on {{ displayRange }}
                </span>
            </b-overlay>
        </div>
        <div
            class="col-4 dashboard__data"
            :class="[{'up': conversionsPercentage > 0, 'down': conversionsPercentage < 0}]"
        >
            <b-overlay
                variant="transparent"
                :show="loadingConversions"
            >
                <h3 class="dashboard__data--title">
                    Conversions
                </h3>
                <span class="dashboard__data--info">{{ conversions }}</span>
                <span class="dashboard__data--change">
                    <strong>{{ conversionsPercentage }}%</strong>
                    on {{ displayRange }}
                </span>
            </b-overlay>
        </div>
        <div class="col-12 dashboard__income">
            <b-overlay
                variant="white"
                :show="loadingIncome"
            >
                <div
                    :class="['dashboard__income--inner', {
                        'up': incomePercentage > 0, 
                        'down': incomePercentage < 0
                    }]"
                >
                    <span class="dashboard__data--info">
                        {{ new Intl
                            .NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
                            .format(income) }}
                    </span>
                    <h3>Income</h3>
                    <span class="dashboard__data--change">{{ incomePercentage }}%</span>
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    props: {
        endpoint: {
            type: Object,
            required: true
        },
        range: {
            type: Number,
            required: true
        },
        selected: {
            type: Number,
            required: true
        },
        timePeriod: {
            type: String,
            required: true
        },
        quotes: {
            type: Number,
            required: true
        },
        errors: {
            type: Number,
            required: true
        },
        conversions: {
            type: Number,
            required: true
        },
        rejects: {
            type: Number,
            required: true
        },
        requests: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            metricdata: {
                endpointID: this.selected,
                unit: "day",
                from: this.$moment()
                    .subtract(this.range, "days")
                    .format("YYYY-MM-DD"),
                to: this.$moment().format("YYYY-MM-DD"),
                toTime: "23:59:59",
                fromTime: "00:00:00",
                range: this.range
            }
        };
    },
    computed: {
        ...mapState({
            endpoints: state => state.dashboardMetrics.endpointsData,
            customRange: state => state.dashboardMetrics.customRange,
            loadingQuotes: state => state.dashboardMetrics.loadingQuotes,
            loadingConversions: state => state.dashboardMetrics.loadingConversions,
            loadingRequests: state => state.dashboardMetrics.loadingRequests,
            loadingErrors: state => state.dashboardMetrics.loadingErrors,
            loadingRejects: state => state.dashboardMetrics.loadingRejects,
            loadingCancellations: state =>
                state.dashboardMetrics.loadingCancellations,
            loadingIncome: state => state.dashboardMetrics.loadingIncome
            // loadingPolicies: state => state.dashboardMetrics.loadingPolicies
        }),
        // policiesSold() {
        //     return this.$store.state.dashboardMetrics.current[this.endpoint.id] !== undefined ?
        //         this.$store.state.dashboardMetrics.current[this.endpoint.id].policiesSold : 0;
        // },
        displayRange() {
            const days = this.timePeriod === "custom" ? this.customRange : this.range;

            if (days === 0) {
                return "yesterday";
            }

            return `last ${days} ${days === 1 ? "day" : "days"}`;
        },
        currentDashBoardMetrics() {
            return this.$store.state.dashboardMetrics.current[this.endpoint.id];
        },
        quotesPercentageData() {
            return this.currentDashBoardMetrics.quotesPercentageData;
        },
        errorsPercentageData() {
            return this.currentDashBoardMetrics.errorsPercentageData;
        },
        incomePercentageData() {
            return this.currentDashBoardMetrics.incomePercentageData;
        },
        requestsPercentageData() {
            return this.currentDashBoardMetrics.requestsPercentageData;
        },
        rejectsPercentageData() {
            return this.currentDashBoardMetrics.rejectsPercentageData;
        },
        conversionsPercentageData() {
            return this.currentDashBoardMetrics.conversionsPercentageData;
        },
        cancellationsPercentageData() {
            return this.currentDashBoardMetrics.cancellationsPercentageData;
        },
        cancellations() {
            return this.currentDashBoardMetrics
                ? this.currentDashBoardMetrics.cancellations
                : 0;
        },
        income() {
            return this.currentDashBoardMetrics
                ? this.currentDashBoardMetrics.income
                : 0;
        },
        quotesPercentage() {
            return this.currentDashBoardMetrics &&
                this.quotes !== 0 &&
                this.quotesPercentageData !== 0
                ? this.calculatePercentage(this.quotes, this.quotesPercentageData)
                : 0;
        },
        errorsPercentage() {
            return this.currentDashBoardMetrics &&
                this.errors !== 0 &&
                this.errorsPercentageData !== 0
                ? this.calculatePercentage(this.errors, this.errorsPercentageData)
                : 0;
        },
        // policiesPercentage() {
        //     return this.currentDashBoardMetrics && 
        //         this.policiesSold !== 0 && 
        //         this.currentDashBoardMetrics.policiesPercentageData !== 0 
        //         ? Math.floor((this.policiesSold - this.currentDashBoardMetrics
        //             .policiesPercentageData)
        //             / this.currentDashBoardMetrics.policiesPercentageData * 100) 
        //         : 0;
        // },
        requestsPercentage() {
            return this.currentDashBoardMetrics &&
                this.requests !== 0 &&
                this.requestsPercentageData !== 0
                ? this.calculatePercentage(this.requests, this.requestsPercentageData)
                : 0;
        },
        rejectsPercentage() {
            return this.currentDashBoardMetrics &&
                this.rejects !== 0 &&
                this.rejectsPercentageData !== 0
                ? this.calculatePercentage(this.rejects, this.rejectsPercentageData) 
                : 0;
        },
        cancellationsPercentage() {
            return this.currentDashBoardMetrics &&
                this.cancellations !== 0 &&
                this.cancellationsPercentageData !== 0
                ? this.calculatePercentage(this.cancellations, this.cancellationsPercentageData) 
                : 0;
        },
        conversionsPercentage() {
            return this.currentDashBoardMetrics &&
                this.conversions !== 0 &&
                this.conversionsPercentageData !== 0
                ? this.calculatePercentage(this.conversions, this.conversionsPercentageData)
                : 0;
        },
        incomePercentage() {
            return !this.currentDashBoardMetrics && 
                this.income !== 0 && 
                this.incomePercentageData !== 0
                ? this.calculatePercentage(this.income, this.incomePercentageData)
                : 0;
        }
    },
    watch: {
        selected: async function(newSelected) {
            const data = {
                endpointID: newSelected,
                unit: "day",
                from: this.$moment()
                    .subtract(this.range, "days")
                    .format("YYYY-MM-DD"),
                to: this.$moment().format("YYYY-MM-DD"),
                toTime: "23:59:59",
                fromTime: "00:00:00",
                range: this.range
            };
            await this.runAll(data);
        }
    },
    methods: {
        ...mapActions("dashboardMetrics", ["runAll"]),
        calculatePercentage(category, percentageData) {
            return Math.floor(((category - percentageData) / percentageData) * 100);
        }
    }
};
</script>
