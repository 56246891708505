//TODO this is from the engine
//We need to package this as it is duplicated
import Expression from "./Expression";
import randomKey from "random-key";
import {
    isCellValid,
    isRowsValid,
    isDefaultValid,
    isNotEmpty
    // isExpressionInScope
} from "./ExpressionValidators";

export default class LookupExpression extends Expression {
    constructor(id){
        const properties = {
            returns:{  //this will be calculated based on the input - could this move into expression?
                value: null,
                isValid:null,
                validators:[
                    isDefaultValid()
                ],
                get type(){
                    return this.value.type?this.value.type:null;
                },  //getter to stop having to go into values object e.g returns{type:number}
                get default(){
                    return this.value.default?this.value.default:null;
                }  //getter to stop having to go into values object e.g returns{type:number}
            },
            expressionType:{
                value: "lookup",
                isValid:null,
                validators:[]
            },
            types:{
                value: null,
                isValid:null,
                validators:[]
            },
            columns:{
                value: null,
                isValid:null,
                validators:[
                    // isExpressionInScope()
                ]
            },
            rows:{
                value: null,
                isValid:null,
                validators:[
                    //This is very heavy on cpu - maybe swap?
                    isRowsValid()
                ]
            },
            nullMatches: {
                value: [],
                isValid:null,
                validators:[]
            },
            //we have provided an additional set and get
            //as if the aggregate is set we must update
            //the type. This should be automatted
            aggregate:{
                value: null,
                isValid:null,
                validators:[
                    isNotEmpty("Multiple Matches")
                ]
            }
        };
        super(id,properties);
        this.invalidCells = new Map();
        // Here we are overloading the getter/setter so we can trigger validation and functions on setting
        Object.defineProperty(this, "aggregate", {
            get() {
                return this.properties.aggregate.value;
            },
            set(val) {
                this.properties.aggregate.value = val;
                this.properties.aggregate.dirty = true;
                this.validateProperties();
                this.inferReturnType();
            }
        });
    }

    //simple static helpers to convert data to columns and rows
    //this also adds id's to help rendering
    static rowBuilder(data,types){
        if(!Array.isArray(data)){
            return [];
        }
        return data.map((row) => ({
            id: randomKey.generate(5),
            rowData: row.map((rowItem, i) => ({
                id: randomKey.generate(6),
                data: rowItem,
                dirty: false,
                isValid: isCellValid().validate(rowItem, types[i], i + 1 === row.length)
            }))}));
    }
    static columnBuilder(headings){
        if(!Array.isArray(headings)){
            return [];
        }

        let isReturn = [];

        //When importing a csv the Isreturn is set by the user and 
        //sent up as an object so we need to split the name and return out
        if(typeof headings[0]==="object"){
            let headingNames = [];
            headings.forEach(heading=>{
                headingNames.push(heading.name);
                isReturn.push(heading.return);
            });

            headings = headingNames;
        }
        return headings.map((col,i)=>({name: col, width:150, return:i+1===headings.length||isReturn[i]}));
    }
    static objectRowBuilder(data, types, returns, columns){
        if(!Array.isArray(data)){
            return [];
        }

        let returnColumnCount = columns.filter(column=>column.return).length;
        let returnColumnStartingIndex = types.length - returnColumnCount;

        let formattedRowData = data.map(row=>{
            let rowData = [];
            row.forEach((rowItem, i)=>{
                if(typeof rowItem === "object"){
                    let rowObjectValues = Object.values(rowItem);

                    rowObjectValues.forEach((objectValue,j)=>{
                        const typeIndex = j+i;
                        const isValid = isCellValid().validate(objectValue,types[typeIndex], true);
                        const id = randomKey.generate(6);

                        const columnId = columns[typeIndex].name;

                        rowData.push({id,data:objectValue,dirty:false,isValid,columnId});
                    });
                }else if(rowItem==="reject"){
                    for(let j = returnColumnStartingIndex; j<types.length;j++){
                        const isValid = isCellValid().validate("reject",types[j], true);
                        const id = randomKey.generate(6);
                        rowData.push({id,data:"reject",dirty:false,isValid});
                    }
                }else{
                    let id = randomKey.generate(6);
                    let isReturn = i+1===row.length;
                    let isValid = isCellValid().validate(rowItem, types[i],isReturn);
                    const cellData = {id,data:rowItem,dirty:false,isValid};
                    rowData.push(cellData);
                }
            });

            return {
                id:randomKey.generate(5),
                rowData
            };

        });

        return formattedRowData;
    }

    static objectColumnBuilder(headings, returns,data){
        let headingsLoop = returns.type === "object" ? returns.properties : returns.items.properties;
        let objectKeys = [];

        data.forEach((column,i)=>{
            if(typeof column[column.length-1]==="object"){
                objectKeys = Object.keys(data[i][data[i].length-1]);
            }
        });

        let typesFromObject = objectKeys.map(key=>{
            const property = headingsLoop.find(property=>{
                return property.id === key;
            });
            return {
                name: property.id, width:150, return:true, isObjectColumn:true
            };
        });


        let headingsExcludeObject = [...headings];

        headingsExcludeObject = headingsExcludeObject.map((col)=>({name: col, width:150, return:false}));
        headingsExcludeObject.pop();
        let combinedHeadings = headingsExcludeObject.concat(typesFromObject);
        return combinedHeadings;
    }

    static objectTypesBuilder(types, returns, data){
        const dynamicReturnsForArrays = returns.type === "object" ? returns.properties : returns.items.properties;

        let objectKeys = [];
        data.forEach((column,i)=>{
            if(typeof column[column.length-1]==="object"){
                objectKeys = Object.keys(data[i][data[i].length-1]);
            }
        });

        let typesFromObject = objectKeys.map(key=>{
            const {type} = dynamicReturnsForArrays.find(property=>{
                return property.id === key;
            });
            return type;
        });


        const typesWithoutObject = types.filter(type=>type!=="object");
        const combinedTypes = typesWithoutObject.concat(typesFromObject);


        return combinedTypes;
    }

    // for existing schemes nullMatches won't exsist so
    // set them to false on load if nullMatches is undefind
    static nullMatchesBuilder(headers, nullMatches) {
        if (!headers) {
            return [];
        }
        if (!nullMatches && Array.isArray(headers) && headers.length > 1) {
            return new Array(headers.length - 1).fill(false);
        }
        return nullMatches;
    }
    //if we just validate all cells every update - we waste time
    //we know which cells are invalid
    setCell(rowIndex,columnIndex,data){
        let row = {...this.rows[rowIndex]};
        let cell = {...row.rowData[columnIndex]};
        //is this a returns columns
        let isReturns = columnIndex + 1 === this.columns.length || this.columns[columnIndex].isObjectColumn;
        cell.data = data;
        cell.dirty = true;
        cell.isValid = isCellValid(`Cell ${rowIndex,columnIndex}`).validate(data,this.types[columnIndex],isReturns);
        if(!cell.isValid){
            this.invalidCells.set(cell.id,true);
            this.properties.rows.isValid = false;

        }else{
            this.invalidCells.delete(cell.id);
            if(this.invalidCells.size === 0){
                this.properties.rows.isValid = true;
            }
        }
        row.rowData.splice(columnIndex,1,cell);
    }

    /* Used by the import tools - wipes any data and inserts new
    */
    create(data,headings,types, nullMatches){
        //TODO - we need to vaidate the input against the input type
        this.properties.types.value = types;
        this.properties.rows.value = LookupExpression.rowBuilder(data,types);
        this.properties.columns.value = LookupExpression.columnBuilder(headings); 
        this.properties.rows.isValid = !this.properties.rows.value.some(row=>row.rowData.some(cell=>!cell.isValid));
        this.properties.nullMatches.value = nullMatches.filter( (nm, x) => {
            return headings[x].return === false;
        });
        this.inferReturnType();
        //update valid properties
    }

    createNewRow(){
        let row = {id:randomKey.generate(6),rowData:[]};
        for (let columnIndex = 0; columnIndex < this.columns.length; columnIndex++) {
            row.rowData.push({id:randomKey.generate(6),isValid:false, isDirty:false, data:undefined});
        }
        return row;
    }

    insertRow(index){
        this.rows = [...this.properties.rows.value.slice(0,index),
            this.createNewRow(),...this.properties.rows.value.slice(index)];
    }

    createNewCell(data=undefined){
        return ({id:randomKey.generate(6),data, isValid:false, isDirty:false});
    }

    insertColumn(index,identifier,type,isReturn,width,isObjectColumn, nullMatch){
        //TODO - add calculated returns..
        this.types = [
            ...this.properties.types.value.slice(0, index),
            type,
            ...this.properties.types.value.slice(index)
        ];

        this.columns = [
            ...this.properties.columns.value.slice(0,index),
            {
                name: identifier,
                width,
                return: isReturn,
                isObjectColumn
            },
            ...this.properties.columns.value.slice(index)
        ];

        this.rows = this.properties.rows.value.map((row)=>({
            id: row.id,
            rowData:[
                ...row.rowData.slice(0,index),
                this.createNewCell(),
                ...row.rowData.slice(index)
            ]
        }));

        this.nullMatches = [
            ...this.properties.nullMatches.value.slice(0,index),
            nullMatch,
            ...this.properties.nullMatches.value.slice(index)
        ];
        this.inferReturnType();
    }

    updateColumn(index,identifier,type,isReturn,width, isObjectColumn, nullMatch){
        this.types = [...this.properties.types.value.slice(0,index-1),type,...this.properties.types.value.slice(index)];
        this.columns = [...this.properties.columns.value.slice(0,index-1),
            {name:identifier,width, return:isReturn, isObjectColumn}
            ,...this.properties.columns.value.slice(index)];
        this.nullMatches = [...this.properties.nullMatches.value.slice(0,index-1),
            nullMatch,
            ...this.properties.nullMatches.value.slice(index)];
        this.inferReturnType();
    }

    /**
     * @function inferReturnType
     * @description Infers the return type of the table
     * @memberof LookupExpression
     * @instance
     * @returns {undefined} - no return value
     */
    inferReturnType(){
        if (this.columns === null) {
            return;
        }
        if (this.aggregate === "all" || this.aggregate === "all-no-duplicates" || this.aggregate === "null") {
            return this.updateReturns("array", this.returns.default);
        }
        const amountOfReturnColumn = this.columns.filter(column => column.return).length;

        if (amountOfReturnColumn > 1) {
            return this.updateReturns("object", this.returns.default);
        } else {
            const typeIndex = this.columns.findIndex(column => column.return);
            return this.updateReturns(this.types[typeIndex], this.returns.default);
        }
    }

    updateReturns(type, default_){
        if(type==="array"){
            this.returns = {
                type,
                default:default_,
                items: {
                    type: this.returns.type,
                    items: this.returns.properties
                }
            };

        } else if(type !== "object"){
            this.columns = this.columns.map((column) => {
                column.isObjectColumn = false;
                return column;
            });
            this.returns = {
                type,
                default: default_
            };
        }else{
            this.columns = this.columns.map((column) => {
                column.isObjectColumn=column.return;
                return column;
            });
            this.returns = {
                type,
                default: default_,
                properties: this.columns.reduce((acc, column, i) => {
                    if(column.return) {
                        acc.push({
                            id: column.name,
                            type: this.types[i]
                        });
                    }
                    return acc;
                }, [])
            };
        }
    }

    deleteRow(index){
        this.rows[index].rowData.forEach(cell=>this.invalidCells.delete(cell.id));
        this.rows = [...this.rows.slice(0,index),...this.rows.slice(index+1)];
    }

    deleteColumn(index){
        this.columns = [...this.properties.columns.value.slice(0,index),
            ...this.properties.columns.value.slice(index+1)];

        this.types = [
            ...this.properties.types.value.slice(0, index),
            ...this.properties.types.value.slice(index + 1)
        ];

        this.nullMatches = [
            ...this.properties.nullMatches.value.slice(0, index),
            ...this.properties.nullMatches.value.slice(index + 1)
        ];
        if(this.columns.length===0){
            this.rows = [];
        }else{
            this.rows = this.rows.map(row=>{
                this.invalidCells.delete(row.rowData[index].id);
                return {
                    id:row.id,
                    rowData:[ ...row.rowData.slice(0,index),
                        ...row.rowData.slice(index+1)]};

            });
        }

        this.inferReturnType();
    }

    moveRowByOne(index,direction){
        let from = index;
        let [rows] = this.moveByOne(this.rows,from || 0,direction);
        this.rows = rows;
    }

    moveColumnByOne(index,direction){
        let columnFromIndex = index || 0;
        let [columns] = this.moveByOne(this.columns,columnFromIndex,direction);
        let rows = this.rows.map(row=>
            ({id:row.id,rowData:this.moveByOne(row.rowData,columnFromIndex,direction)[0]}));
        let types = this.moveByOne([...this.types],columnFromIndex,direction)[0];
        let nullMatches = this.moveByOne([...this.nullMatches],columnFromIndex,direction)[0];
        this.columns = columns,
        this.rows = rows;
        this.types = types;
        this.nullMatches = nullMatches;
    }

    moveRowToIndex(from,to){
        this.rows = this.moveToIndex(this.rows,from,to);
    }

    moveColToIndex(from,to){
        this.columns = this.moveToIndex(this.columns,from,to);
    }

    //item = row or column  array
    moveToIndex(array,from,to){
        if(to===from || to >= array.length || to < 0){
            return array;
        }
        let newArray = [];
        let itemToBeMoved = array[from];
        array.forEach((item,i) => {
            if(to < from){
                if(i===to){
                    newArray.push(itemToBeMoved);
                }
                if(i!==from){
                    newArray.push(item);
                }
            }else{
                if(i!==from){
                    newArray.push(item);
                }
                if(i===to){
                    newArray.push(itemToBeMoved);
                }
            }
        });
        return newArray;
    }

    moveByOne(array, currentPosition,direction){
        //copy row that we are dragging/moving
        //when we get to index insert into new list
        let toPosition;
        if(direction==="d" || direction==="r"){
            toPosition = currentPosition +1;

        }else{
            toPosition = currentPosition -1;
        }

        if(toPosition >= array.length || toPosition < 0){
            return [array,currentPosition];
        }

        let newArray = this.moveToIndex(array,currentPosition,toPosition);
        return [newArray,toPosition];
    }

    static cleanObjectExpression(exp, isArrayObject){
        let properties =[];
        exp.columns.forEach((column,i)=>{
            if(column.return){
                properties.push({
                    id:column.name,
                    type: exp.types[i]
                });
            }
        });

        const objectLength = properties.length;
        const totalColumnsLength = exp.columns.length;

        let returns = {};
        if(isArrayObject){
            returns = {
                type: "array",
                items: {
                    type: "object",
                    properties
                },
                default: "reject"
            };
        }else{
            returns = {
                type: "object",
                default: "reject",
                properties
            };

        }

        const data = exp.rows.map(row=>{
            let rowReturn = [];
            let returnObject = {};
            let rejectFlag = false;
            row.rowData.forEach((rowItem,i)=>{
                rowItem.data = rowItem.data === "$null" ? null : rowItem.data;
                if(i+objectLength < totalColumnsLength){
                    rowReturn.push(rowItem.data);
                } else{
                    let objectKey = properties[i + objectLength - totalColumnsLength].id;
                    returnObject[objectKey] = rowItem.data;
                    rowItem.data==="reject"? rejectFlag = true: "";
                    if(i===totalColumnsLength-1){
                        returnObject = rejectFlag ? "reject" : returnObject;
                        rowReturn.push(returnObject);
                    }
                }
            });
            return rowReturn;
        });

        let types = [];
        for(let i = 0; i<totalColumnsLength-objectLength;i++){
            types.push(exp.types[i]);
        }

        types.push("object");

        let headings = exp.columns.filter(column=>!column.return).map(heading=>heading.name);

        const nullMatches = exp.nullMatches.filter((nm, i) => !exp.columns[i].return);

        headings.push("returns");
        return {
            id: exp.id,
            default: exp.default,
            expressionType: exp.expressionType,
            headings,
            aggregate: exp.aggregate || "first",
            types,
            returns,
            data,
            description: exp.description,
            tags: exp.tags,
            nullMatches: nullMatches
        };
    }

    static cleanArrayExpression(exp){
        const typeIndex = exp.columns.findIndex(column=>column.return);

        const nullMatches = exp.nullMatches.filter((nm, i) => !exp.columns[i].return);

        let returns = {
            type:"array",
            items: {
                type: exp.types[typeIndex]
            },
            default: "reject"

        };

        return {
            id: exp.id,
            default: exp.default,
            expressionType: exp.expressionType,
            headings: exp.columns.map(heading=>heading.name),
            aggregate: exp.aggregate || "first",
            types: exp.types,
            returns,
            data: exp.rows.map(row => row.rowData.map((rowItem) => rowItem.data === "$null" ? null : rowItem.data)),
            description: exp.description,
            tags: exp.tags,
            nullMatches: nullMatches
        };

    }

    static cleanExpression(exp) {
        let isArrayObject = exp.returns.type==="array";
        const amountOfReturnColumns = exp.columns.filter(column=>column.return).length;
        if(amountOfReturnColumns>1){
            return this.cleanObjectExpression(exp,isArrayObject);
        }
        if(exp.returns.type==="array"){
            return this.cleanArrayExpression(exp);
        }
        const nullMatches = exp.nullMatches.filter((nm, i) => !exp.columns[i].return);

        return {
            id: exp.id,
            default: exp.default,
            expressionType: exp.expressionType,
            headings: exp.columns.map(heading => heading.name),
            aggregate: exp.aggregate || "first",
            types: exp.types,
            returns: exp.returns,
            data: exp.rows.map(row => row.rowData.map((rowItem) => rowItem.data === "$null" ? null : rowItem.data)),
            description: exp.description,
            tags: exp.tags,
            nullMatches: nullMatches
        };
    }
    // function which triggers a "reload" of the state
    // this is necessary to allow instant validation feedback
    // for the isExpressionInScope validator.
    hasMoved() {
        // eslint-disable-next-line no-self-assign
        this.columns = this.columns;
    }
}
