import Vue from "vue";
import Vuex from "vuex";

import adjustments from "./modules/adjustments";
import admin from "./modules/admin";
import application  from "./modules/application";
import audit from "./modules/audit";
import auth from "./modules/auth";
import buyjourney from "./modules/buyjourney";
import components from "./modules/components";
import crm from "./modules/crm";
import customers from "./modules/customers";
import dashboardMetrics from "./modules/dashboard-metrics";
import data from "./modules/data";
import dataDictionary from "./modules/dataDictionary";
import documents from "./modules/documents";
import images from "./modules/images";
import editor from "./modules/editor";
import endpoint from "./modules/endpoint";
import endpoints from "./modules/endpoints";
import env from "./modules/env";
import forms from "./modules/forms";
import provider from "./modules/provider";
import payments from "./modules/payments";
import policies from "./modules/policies";
import reportRevisions from "./modules/reportRevisions";
import pages from "./modules/pages";
import reports from "./modules/reports";
import scheme from "./modules/scheme";
import schemes from "./modules/schemes";
import renewals from "./modules/renewals";
import templates from "./modules/templates";
import email from "./modules/email";
import vuexPersist from "./vuex-persist";
import testMode from "./modules/testMode";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        adjustments,
        admin,
        application,
        audit,
        auth,
        buyjourney,
        components,
        crm,
        customers,
        dashboardMetrics,
        data,
        dataDictionary,
        documents,
        images,
        editor,
        endpoint,
        endpoints,
        env,
        forms,
        provider,
        pages,
        payments,
        policies,
        renewals,
        reportRevisions,
        reports,
        scheme,
        schemes,
        templates,
        email,
        testMode
    },
    plugins: [vuexPersist.plugin]
});
