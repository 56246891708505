<template>
    <b-modal
        id="twoFactorSetup"
        title="Enable authenticator app"
        static
        @ok.prevent="enableTwoFactor"
        @show="showHandler"
    >
        <div class="two-factor-wrapper">
            <div>
                <h4>Scan QR code</h4>
                <div>
                    An authenticator application that supports TOTP
                    (like Google Authenticator or 1Password) can be used to
                    access your account securely using a token and secret key.
                    A new token is generated every 30 seconds.
                </div>
            </div>
            <div>
                <b-img-lazy
                    fluid-grow
                    :src="url"
                    :alt="secret"
                    :title="secret"
                />
            </div>
        </div>
        <div class="mt-1 mb-5">
            <b-link v-b-toggle.collapse-1-inner>
                Can’t scan the QR Code?
                <font-awesome-icon
                    class="ml-1"
                    :icon="isSecretKeyVisible ? ['fal', 'angle-up'] : ['fal', 'angle-down']"
                />
            </b-link>
            <b-collapse
                id="collapse-1-inner"
                v-model="isSecretKeyVisible"
                class="mt-1"
            >
                <div>
                    Enter this secret key into your authenticator app: <br>
                    <b>{{ secret }}</b>
                </div>
            </b-collapse>
        </div>
        <h4>
            Enter confirmation code
        </h4>
        <ValidationObserver
            ref="form"
            tag="form"
        >
            <ValidationProvider
                v-slot="{ errors }"
                rules="required|length:6|numeric"
                name="Code"
                mode="eager"
                slim
            >
                <b-form-group
                    label-for="code"
                    description="Six-digit code generated by your authenticator app"
                    :invalid-feedback="errors[0]"
                >
                    <template #label>
                        Code
                        <span
                            aria-hidden="true"
                            class="text-danger"
                        >*</span>
                    </template>
                    <b-form-input
                        id="code"
                        v-model="code"
                        placeholder="000000"
                        :state="!errors[0] ? null : false"
                    />
                </b-form-group>
            </ValidationProvider>
        </ValidationObserver>
        <b-alert
            v-if="errorMessage"
            variant="danger"
            show
        >
            {{ errorMessage }}
        </b-alert>
        <template #modal-footer="{ cancel, ok }">
            <b-button
                variant="outline-primary"
                @click="cancel()"
            >
                Cancel
            </b-button>
            <b-button
                variant="primary"
                :disabled="!!errorMessage"
                @click="ok()"
            >
                Turn on
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import authService from "../../../../services/auth";
import { qrToDataURL } from "../../../../store/modules/auth";

export default {
    name: "UserSecurityTwoFactorSetup",
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            code: "",
            url: "",
            secret: "",
            errorMessage: "",
            isSecretKeyVisible: false
        };
    },
    watch: {
        code() {
            this.errorMessage = "";
        }
    },
    methods: {
        async fetchTwoFactorSetupData() {
            try {
                const { data } = await authService.fetchTwoFactorSetupData();
                this.url = await qrToDataURL(data.url);
                this.secret = data.secret;
            } catch (err) {
                this.$bvToast.toast(err.message, {
                    title: "Error",
                    variant: "danger"
                });
            }
        },
        async enableTwoFactor() {
            try {
                this.errorMessage = "";
                const isValid = await this.$refs.form.validate();
                if (isValid) {
                    await authService.updateTwoFactor({
                        "two_factor_enabled": true,
                        code: this.code
                    });
                    this.$emit("changed");
                }
            } catch(err) {
                this.errorMessage = err.message;
            }
        },
        async showHandler() {
            this.url = "";
            this.secret = "";
            this.errorMessage = "";
            await this.fetchTwoFactorSetupData();
            await this.$refs.form.reset();
        }
    }
};
</script>

<style lang="sass" scoped>
.two-factor-wrapper
    display: grid
    grid-template-columns: 60% 40%
</style>
