import VueRouter from "vue-router";
import jwt from "jsonwebtoken";


import Page from "../src/components/layout/Page";
import Dashboard from "../src/views/Dashboard";
import Account from "../src/components/layout/Account.vue";
import Provider from "../src/components/layout/Provider.vue";

//authorisation & admin routes
import Auth from "../src/views/Auth";
import ResetPassword from "../src/components/auth/ResetPassword.vue";
import ForgotPassword from "../src/components/auth/ForgottenPassword.vue";
import TwoFactor from "../src/components/auth/TwoFactor.vue";
import TwoFactorSetup from "../src/components/auth/TwoFactorSetup.vue";
import Login from "../src/components/auth/Login.vue";
import Logout from "../src/components/auth/Logout.vue";

//error routes
import Notfound from "../src/components/buyjourney/Notfound";

//account
import UserProfile from "../src/components/account/UserProfile.vue";
import UserSecurity from "../src/components/account/security/UserSecurity.vue";

import abilityHelper from "../ability/abilityHelper";

import store from "../store/index";
import moment from "moment";
import userpilotService from "../services/userpilot-service";

//Add routes below
export const routes = [
    {
        path: "/",
        component: Page,
        meta: { requiresAuth: true },
        beforeEnter(_to, _from, next) {
            const { userpilotUser, error } = store.state.auth;
            try {
                if (userpilotUser && !error) {
                    const { id, brandId, brandName } = userpilotUser;
                    userpilotService.identify({ id, brandId, brandName });
                } 
            } catch (err) {
                return next(err);
            } finally {
                if (userpilotUser) {
                    store.dispatch("auth/clearUserpilot");
                }
            } 
            return next();  
        },
        redirect: { name: "dashboard" },
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: Dashboard,
                meta: { requiresAuth: true, title: "Dashboard" }
            },
            {
                path: "/audit",
                name: "audit",
                component: () => import("../src/views/Audit.vue"),
                meta: { requiresAuth: true, title: "Audit" }
            },
            {
                path: "/notfound",
                name: "notfoundauth",
                component: () => import("../src/views/Errors"),
                meta: { requiresAuth: true, title: "Not Found" }
            },
            {
                path: "/forms",
                name: "forms",
                component: () => import("../src/views/Forms"),
                props: true,
                meta: { requiresAuth: true, title: "Forms" },
                children: [
                    {
                        path: "/forms/:formId",
                        name: "form-revision",
                        component: () => import("../src/components/forms/Revisions"),
                        props:true,
                        meta: { requiresAuth: true, title: "Forms"}
                    }
                ]
            },
            {
                path: "/pages",
                name: "pages",
                component: () => import("../src/views/Forms"),
                props: true,
                meta: { requiresAuth: true, title: "Pages" },
                children: [
                    {
                        path: "/pages/:pageId",
                        name: "page-revision",
                        component: () => import("../src/components/pages/Revisions"),
                        props:true,
                        meta: { requiresAuth: true, title: "Pages"}
                    }
                ]
            },
            {
                path: "/forms/:formId/form-revision/:revisionId",
                name: "form",
                component: () => import("../src/views/Form"),
                props: true,
                meta: { requiresAuth: true, title: "Forms" }
            },
            {
                path: "/pages/:pageId/page-revision/:revisionId",
                name: "page",
                component: () => import("../src/views/Page"),
                props: true,
                meta: { requiresAuth: true, title: "Pages" }
            },
            {
                path: "/schemes",
                name: "schemes",
                component: () => import("../src/views/Schemes"),
                props: true,
                meta: { requiresAuth: true, title: "Schemes" },
                children: [
                    {
                        path: "/schemes/:schemeId",
                        name: "revisions",
                        component: () => import("../src/components/schemes/Revisions"),
                        props:true,
                        meta: { requiresAuth: true, title: "Schemes"}
                    }
                ]
            },
            {
                path: "/schemes/scheme/:schemeId/revision/:revisionId",
                name: "scheme",
                component: () => import("../src/views/Scheme"),
                props: true,
                meta: { requiresAuth: true, title: "Scheme" }
            },
            {
                path: "/endpoints",
                name: "endpoints",
                component: () => import("../src/views/Endpoints"),
                props: true,
                meta: { requiresAuth: true, title: "Endpoints", can: ["view", "endpoints"] },
                children: [
                    {
                        path: "/endpoints/:endpointId",
                        component: () => import("../src/views/Endpoint"),
                        props: true,
                        meta: { requiresAuth: true, title: "Endpoints" },
                        children: [
                            {
                                path: "",
                                name: "endpoint",
                                component: () => import("../src/components/endpoint/Summary"),
                                props: true,
                                meta: { requiresAuth: true, title: "Endpoints" }
                            },
                            {
                                path: "schemes",
                                component: () => import("../src/components/endpoint/schemes/SchemeTab"),
                                meta: { requiresAuth: true, title: "Endpoints" },
                                children: [
                                    {
                                        path: "",
                                        name: "endpointSchemes",
                                        component: () => import("../src/components/endpoint/schemes/Schemes"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Endpoints" }
                                    },
                                    {
                                        path: ":schemeId/schedules",
                                        name: "schemeSchedules",
                                        component: () => import("../src/components/endpoint/schemes/Schedules"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Endpoints" }
                                    }
                                ]

                            },
                            {
                                path: "/endpoints/:endpointId/templates",
                                name: "EndpointSelectTemplate",
                                component: () => import("../src/components/endpoint/Templates"),
                                props: true,
                                meta: { requiresAuth: true, title: "Endpoints" },
                                children: [
                                    {
                                        path: "/endpoints/:endpointId/template",
                                        name: "endpointTemplate",
                                        component: () => import("../src/components/endpoint/ResponseTemplate"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Endpoints" }
                                    },
                                    {
                                        path: "/endpoints/:endpointId/request-template",
                                        name: "endpointRequestTemplate",
                                        component: () => import("../src/components/endpoint/RequestTemplate"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Endpoints" }
                                    }
                                ]
                            },
                            {
                                path: "/endpoints/:endpointId/details",
                                name: "EndpointDetails",
                                component: () => import("../src/components/endpoint/Details"),
                                props: true,
                                meta: { requiresAuth: true, title: "Endpoints" },
                                children: [
                                    {
                                        path: "/endpoints/:endpointId/errors",
                                        name: "endpointErrors",
                                        component: () => import("../src/components/endpoint/Errors"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Endpoints" }
                                    },
                                    {
                                        path: "/endpoints/:endpointId/errors/:errorid",
                                        name: "endpointError",
                                        component: () => import("../src/components/endpoint/Error"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Endpoints" }
                                    },
                                    {
                                        path: "/endpoints/:endpointId/jobs",
                                        name: "endpointJobs",
                                        component: () => import("../src/components/endpoint/Jobs"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Endpoints" }
                                    },
                                    {
                                        path: "/endpoints/:endpointId/jobs/:jobid",
                                        name: "endpointJob",
                                        component: () => import("../src/components/endpoint/Job"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Endpoints" }
                                    },
                                    {
                                        path: "/endpoints/:endpointId/quotes",
                                        name: "endpointQuotes",
                                        component: () => import("../src/components/endpoint/Quotes"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Endpoints" }
                                    },
                                    {
                                        path: "/endpoints/:endpointId/quotes/:quoteid",
                                        name: "endpointQuote",
                                        component: () => import("../src/components/endpoint/Quote"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Endpoints" }
                                    },
                                    {
                                        path: "/endpoints/:endpointId/rejects",
                                        name: "endpointRejects",
                                        component: () => import("../src/components/endpoint/Rejects"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Rejects" }
                                    },
                                    {
                                        path: "/endpoints/:endpointId/rejects/:rejectid",
                                        name: "endpointReject",
                                        component: () => import("../src/components/endpoint/Reject"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Reject" }
                                    },
                                    {
                                        path: "/endpoints/:endpointId/service-errors",
                                        name: "serviceErrors",
                                        component: () => import("@/components/endpoint/ServiceErrorLogs"),
                                        props: true,
                                        meta: { requiresAuth: true, title: "Endpoints" }
                                    }
                                ]
                            },
                            {
                                path: "/endpoints/:endpointId/testing",
                                name: "endpointTesting",
                                component: () => import("../src/components/endpoint/testing/Testing"),
                                props: true,
                                meta: { requiresAuth: true, title: "Endpoints" }
                            },
                            {
                                path: "/endpoints/:endpointId/settings",
                                name: "endpointSettings",
                                component: () => import("@/components/endpoint/Settings"),
                                props: true,
                                meta: { requiresAuth: true, title: "Endpoints" }
                            },
                            {
                                path: "/endpoints/:endpointId/security-settings",
                                name: "endpointSecuritySettings",
                                component: () =>
                                    import("@/components/endpoint/securitySettings/SecuritySettings"),
                                props: true,
                                meta: { requiresAuth: true, title: "Endpoints" }
                            },
                            {
                                path: "/endpoints/:endpointId/journeys",
                                name: "endpointJourneys",
                                component: () => import("@/components/endpoint/Journeys/Journeys"),
                                props: true,
                                meta: { requiresAuth: true, title: "Endpoints" }
                            },
                            {
                                path: "/endpoints/:endpointId/health-check",
                                name: "endpointHealthCheck",
                                component: () => import("@/components/endpoint/HealthCheck"),
                                meta: { title: "Endpoints" }
                            }
                            // disable EL-4478
                            // {
                            //     path: "/endpoints/:endpointId/journeys/revisions/",
                            //     name: "endpointJourneyRevisions",
                            //     component: () => import("@/components/endpoint/Journeys/JourneyRevisions"),
                            //     props: true,
                            //     meta: { requiresAuth: true, title: "Endpoints" }
                            // }
                        ]
                    }
                ]
            },
            {
                path: "/customers",
                name: "customers",
                component: () => import("../src/views/Customers"),
                props: true,
                meta: { requiresAuth: true, title: "Customers" }
            },
            {
                path: "/customers/printandsend",
                name: "printandsend",
                component: () => import("../src/components/customers/PrintAndSend"),
                props: true,
                meta: { requiresAuth: true, title: "Print and Send" }
            },
            {
                path: "/crm",
                name: "crm",
                component: () => import("../src/views/CRM"),
                props: true,
                meta: { requiresAuth: true, title: "Customer Relationship Management" }
            },
            {
                path: "/customers/:customerID/policies/",
                name: "policies",
                component: () => import("../src/components/customers/Policies"),
                props: true,
                meta: { requiresAuth: true, title: "Customers" }
            },
            {
                path: "/customers/:customerID/policies/:policyID",
                name: "policy",
                component: () => import("../src/components/customers/Policy"),
                props: true,
                meta: { requiresAuth: true, title: "Customers" }
            },
            {
                path: "/customers/quotes/:quoteID",
                name: "singleQuote",
                component: () => import("../src/components/customers/Quotes.vue"),
                props: true,
                meta: { requiresAuth: true, title: "Customers" }
            },
            {
                path: "/customers/quotes",
                name: "customersQuotes",
                component: () => import("../src/components/customers/CustomerQuotes.vue"),
                props: true,
                meta: { requiresAuth: true, title: "Customers" }
            },
            {
                path: "/configuration",
                name: "admin",
                component: () => import("../src/views/Admin.vue"),
                props: true,
                meta: { requiresAuth: true, title: "Configuration" },
                children: [
                    {
                        path: "/configuration/retention",
                        name: "retention",
                        component: () => import("../src/components/admin/Retention.vue"),
                        props: true,
                        meta: { requiresAuth: true, title: "Configuration" }
                    },
                    {
                        path: "/configuration/email-templates",
                        name: "email-templates",
                        component: () => import("../src/components/templates/EmailTemplates.vue"),
                        props: true,
                        meta: { requiresAuth: true, title: "Configuration" }
                    },
                    {
                        path: "/configuration/policy-templates",
                        name: "policy-templates",
                        component: () => import("../src/components/templates/PolicyTemplates.vue"),
                        props: true,
                        meta: { requiresAuth: true, title: "Configuration" }
                    },
                    {
                        path: "/configuration/webhooks",
                        name: "webhooks",
                        component: () => import("../src/components/admin/webhooks/Webhooks.vue"),
                        redirect: { name: "webhooksConfiguration" },
                        props: true,
                        meta: { requiresAuth: true, title: "Configuration" },
                        children: [
                            {
                                path: "/configuration/webhooks/configuration",
                                name: "webhooksConfiguration",
                                component: () => import("../src/components/admin/webhooks/Configuration.vue"),
                                props: true,
                                meta: { requiresAuth: true, title: "Configuration" }
                            },
                            {
                                path: "/configuration/webhooks/service-errors",
                                name: "webhooksServiceErrors",
                                component: () => import("../src/components/admin/webhooks/ServiceErrors.vue"),
                                props: true,
                                meta: { requiresAuth: true, title: "Configuration" }
                            }
                        ]
                    },
                    {
                        path: "policy-numbers",
                        name: "policy-numbers",
                        component: () => import("../src/components/admin/policyNumbers/CustomPolicyNumbers.vue"),
                        redirect: {name: "sequential-numbers"},
                        props: true,
                        meta: { requiresAuth: true, title: "Configuration" },
                        children: [
                            {
                                path: "add-range",
                                name: "sequential-numbers",
                                component: () => import("../src/components/admin/policyNumbers/SequentialNumbers"),
                                meta: { requiresAuth: true, title: "Configuration" }
                            },
                            {
                                path: "add-upload",
                                name: "non-sequential-numbers",
                                component: () => import("../src/components/admin/policyNumbers/NonSequentialNumbers"),
                                meta: { requiresAuth: true, title: "Configuration" }
                            }
                        ]
                    },
                    {
                        path: "/data-dictionary",
                        name: "data-dictionary",
                        component: () => import("../src/components/admin/dataDictionary/DataDictionary"),
                        props: true,
                        meta: { title: "Configuration" }
                    }
                ]
            },
            {
                path: "/error",
                name: "error",
                props: true,
                component: () => import("../src/views/Errors"),
                meta: { requiresAuth: true }
            },
            {
                path: "/manage-users",
                name: "manage-users",
                component: () => import("../src/views/ManageUsers"),
                meta: { requiresAuth: true, title: "Manage Users" },
                children: [
                    {
                        path: "users",
                        name: "manage-users-users",
                        component: () => import("../src/components/manage-users/Users"),
                        meta: { title: "Users" }
                    },
                    {
                        path: "roles",
                        name: "manage-users-roles",
                        component: () => import("../src/components/manage-users/Roles"),
                        meta: { title: "Roles" }
                    }
                ]
            },
            {
                path: "/account/profile",
                name: "user-profile",
                component: () => import("../src/components/account/UserProfile.vue"),
                meta: { requiresAuth: true, title: "User Profile" }
            },
            {
                path: "/resources",
                name: "resources",
                component: () => import("../src/views/Resources"),
                meta: { requiresAuth: true, title: "Resources" },
                children: [
                    {
                        path: "documents",
                        name: "documents",
                        component: () => import("../src/components/resources/documents/Documents"),
                        meta: { requiresAuth: true, title: "Documents" }
                    },
                    {
                        path: "images",
                        name: "images",
                        component: () => import("../src/components/resources/images/Images"),
                        meta: { requiresAuth: true, title: "Images" }
                    }
                ]
            },
            {
                path: "/reports",
                prop: true,
                component: () => import("../src/views/Reports"),
                meta: { requiresAuth: true, title: "Reports" },
                children: [
                    {
                        path: "",
                        name: "report_templates",
                        props: true,
                        component: () => import("../src/components/report/ReportTemplates"),
                        meta: { requiresAuth: true, title: "Reports" },
                        children: [
                            {
                                path: ":reportId",
                                name: "report",
                                props: true,
                                component: () => import("../src/components/report/Report"),
                                meta: { requiresAuth: true, title: "Reports" }
                            }
                        ]
                    },
                    {
                        path: ":reportId/revision/:revisionId",
                        name: "report_revision",
                        props: true,
                        component: () => import("../src/components/report/Editor/createRevision"),
                        meta: { requiresAuth: true, title: "Reports" }
                    }
                ]
            },
            {
                path: "/templates",
                name: "template_editor",
                props: true,
                meta: { requiresAuth: true, title: "Templates" },
                component: () => import("../src/views/Templates"),
                children: [
                    {
                        path: "/templates/:templateID",
                        name: "template_revisions",
                        component: () => import("../src/components/templates/TemplateRevisions"),
                        meta: {
                            requiresAuth: true, title: "Template Revisions"
                        }
                    },
                    {
                        path: "/templates/:templateID/revision/:revisionID",
                        name: "TemplateRevisionsEdit",
                        component: () => import("../src/components/templates/TemplateEditor"),
                        meta: {
                            requiresAuth: true, title: "Template Editor"
                        }
                    }
                ]
            },
            {
                path: "/renewals",
                name: "renewals",
                component: () => import("../src/views/Renewals.vue"),
                props: true,
                meta: { requiresAuth: true, title: "Renewals" },
                children: [
                    {
                        path: "/renewals/:id",
                        name: "renewal",
                        component: () => import("../src/components/renewals/RenewalGroup.vue"),
                        props: true,
                        meta: { requiresAuth: true, title: "Renewal Settings" }

                    },
                    {
                        path: "/renewals/:id/testing",
                        name: "renewalRuleTesting",
                        component: () => import("../src/components/renewals/RenewalRuleTesting.vue"),
                        props: true,
                        meta: { requiresAuth: true, title: "Renewal Testing" }
                    }, {
                        path: "/renewals/:id/rules",
                        name: "renewalRules",
                        component: () => import("../src/components/renewals/RenewalRules.vue"),
                        props: true,
                        meta: { requiresAuth: true, title: "Renewal Rules" }
                    }
                ]
            },
            {
                path: "/test_mode",
                name: "test_mode",
                component: () => import("../src/views/TestMode.vue"),
                redirect: { name: "testModeJourneys" },
                meta: { requiresAuth: true, title: "Test Mode" },
                children: [
                    {
                        path: "/test_mode/journeys",
                        name: "testModeJourneys",
                        component: () => import("../src/components/testMode/TestJourneysD2C.vue"),
                        props: true,
                        meta: { requiresAuth: true, title: "Test Mode" }

                    }
                ]
            }
            /*{
                path: "/renewals/:id/renewal",
                name: "renewalNav",
                component: () => import("../src/components/renewals/Renewal.vue"),
                props: true,
                meta: { requiresAuth: true, title: "Renewal" },
                children: [
                    {
                        path: "/renewals/:id/renewal/testing",
                        name: "renewalRuleTesting",
                        component: () => import("../src/components/renewals/RenewalRuleTesting.vue"),
                        props: true,
                        meta: { requiresAuth: true, title: "Renewal Testing" }
                    }, {
                        path: "/renewals/:id/renewal/rules",
                        name: "renewalRules",
                        component: () => import("../src/components/renewals/RenewalRules.vue"),
                        props: true,
                        meta: { requiresAuth: true, title: "Renewal Rules" }
                    }
                ]
            }*/
        ]
    },
    {
        path: "/auth",
        name: "auth",
        props: true,
        component: Auth,
        meta: { requiresAuth: false },
        children: [
            {
                path: "/forgotpassword",
                name: "forgotPassword",
                component: ForgotPassword,
                meta: { requiresAuth: false }
            },
            {
                path: "/resetpassword/:jwt",
                name: "passwordReset",
                props: true,
                component: ResetPassword,
                meta: { requiresAuth: false }
            },
            {
                path: "/login",
                name: "login",
                props: true,
                component: Login,
                meta: { requiresAuth: false }
            },
            {
                path: "/login/twofactor",
                name: "twofactor",
                props: true,
                component: TwoFactor,
                meta: { requiresAuth: false }
            },
            {
                path: "/login/twofactorsetup",
                name: "twofactorsetup",
                props: true,
                component: TwoFactorSetup,
                meta: { requiresAuth: false }
            }
        ]
    },
    {
        path: "/account",
        component: Account,
        name: "account",
        meta: { requiresAuth: true },
        redirect: { name: "account-profile" },
        children: [
            {
                path: "profile",
                name: "account-profile",
                component: UserProfile,
                meta: { title: "Profile" }
            },
            {
                path: "security",
                name: "account-security",
                component: UserSecurity,
                meta: { title: "Security" }
            }
        ]
    },
    {
        path: "/administration",
        name: "provider",
        component: Provider,
        redirect: () => {
            const subpages = [ "users", "roles", "conditions", "settings" ];
            for (const page of subpages) {
                if (abilityHelper.canOnProvider("view", page, store)) {
                    return { name: `provider-${page}` };
                }
            }
        },
        meta: { requiresAuth: true, title: "Administration" },
        children: [
            {
                path: "users",
                name: "provider-users",
                component: () => import("../src/components/provider/users/Users.vue"),
                meta: { title: "Users" }
            },
            {
                path: "users/:userId",
                name: "provider-user-access",
                component: () => import("../src/components/provider/users/UserAccess.vue"),
                meta: { title: "User access" }
            },
            {
                path: "roles",
                name: "provider-roles",
                component: () => import("../src/components/provider/roles/Roles.vue"),
                meta: { title: "Roles" }
            },
            {
                path: "roles/create",
                name: "provider-role-create",
                component: () => import("../src/components/provider/roles/CreateRole.vue"),
                meta: { title: "Create Role" }
            },
            {
                path: "roles/brand/:roleId",
                name: "provider-brand-role-edit",
                component: () => import("../src/views/Provider/BrandRole.vue"),
                meta: { title: "Edit brand role" }
            },
            {
                path: "roles/brand/:roleId/users",
                name: "provider-brand-role-users",
                component: () => import("../src/views/Provider/BrandRoleUsers.vue"),
                meta: { title: "Brand role users" }
            },
            {
                path: "roles/provider/:roleId",
                name: "provider-provider-role-edit",
                component: () => import("../src/views/Provider/ProviderRole.vue"),
                meta: { title: "Edit provider role" }
            },
            {
                path: "roles/provider/:roleId/users",
                name: "provider-provider-role-users",
                component: () => import("../src/views/Provider/ProviderRoleUsers.vue"),
                meta: { title: "Provider role users" }
            },
            {
                path: "conditions",
                name: "provider-conditions",
                component: () => import("../src/components/provider/conditions/Conditions.vue"),
                meta: { title: "Conditions" }
            },
            {
                path: "conditions/create",
                name: "provider-condition-create",
                component: () => import("../src/components/provider/conditions/CreateCondition.vue"),
                meta: { title: "Create condition" }
            },
            {
                path: "conditions/:conditionId",
                name: "provider-condition-edit",
                component: () => import("../src/components/provider/conditions/EditCondition.vue"),
                meta: { title: "Edit condition" }
            },
            {
                path: "conditions/:conditionId/users",
                name: "provider-condition-users",
                component: () => import("../src/components/provider/conditions/condition/ConditionUsers.vue"),
                meta: { title: "Condition users" }
            },
            {
                path: "settings",
                name: "provider-settings",
                component: () => import("../src/components/provider/settings/ProviderSettings.vue"),
                meta: { title: "Settings" }
            }
        ]
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout,
        meta: { requiresAuth: false }
    },
    {
        path: "/404",
        name: "notfound",
        component: Notfound,
        meta: { requiresAuth: false }
    },
    {
        path: "*",
        redirect: "/login"
    }
];

const router = new VueRouter({
    mode: "history",
    base: "/app",
    routes
});

router.beforeEach(function(to, from, next) {
    const authRequired = to.matched.some(record => record.meta.requiresAuth);
    const token = sessionStorage.getItem("jwt");
    let loggedIn;
    try {
        const decodedJwt = jwt.decode(token);
        loggedIn = decodedJwt.authenticated && moment.unix(decodedJwt.exp).isAfter();
    } catch {
        loggedIn = false;
    }
    if (authRequired && !loggedIn) {
        if (to.name !== "login") {
            return next({ name: "login" });
        }
        return next(
            {
                name: "login",
                params: { redirect: to.path }
            });
    }

    if (to.name === "logout") {
        if (loggedIn) {
            return next();
        } else {
            return next({ name: "login" });
        }
    }

    if (!authRequired && loggedIn) {
        return next("/");
    }

    const permission = to.matched.some(record => record.meta.can);
    if (loggedIn && permission) {
        let authorised = to.matched.reduce((accumulator, record) => {
            if (!record.meta.can) {
                return accumulator && true;
            }
            return accumulator && (store.state.auth.selectedBrandPermissions.find(
                item => item.actions === record.meta.can[0] && item.subject === record.meta.can[1])
                ? true
                : false);
        }, true);
        if (!authorised) {
            return next("/");
        }
    }
    next();
});

router.onError((err) => router.push({ name: "error", params: { error: err } }));

export default router;
