<template>
    <b-dropdown
        block
        no-caret
        variant="clear"
        class="datetime__button form-control text-break text-wrap bg-transparent h-auto p-0 text-left w-100"
        @hide="isValid"
    >
        <template #button-content>
            <span>
                <font-awesome-icon
                    :icon="['fal', 'calendar-alt']"
                />
            </span>
            <label
                class="inline w-100 p-0 m-0"
                text-align="left"
                :class="{ 'text-muted': dateTime === null }"
            >
                {{ displayDateTime }}
            </label>
            <span
                @click="clear"
            >
                <font-awesome-icon
                    :icon="['fal', 'trash']"
                />
            </span>
        </template>
        <template #default>
            <div class="m-2">
                <b-calendar
                    v-model="dateInternal"
                    block
                    v-bind="$attrs"
                    class="mb-1"
                    :min="min"
                    :max="max"
                    :class="{ error: !isValidComputed }"
                    @selected="dateSelected"
                />
                <b-time
                    v-model="timeInternal"
                    block
                    v-bind="$attrs"
                    :class="{ error: !isValidComputed }"
                    @input="dateSelected"
                />
            </div>
        </template>
    </b-dropdown>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        /**
         * The date which is selected or null
         */
        value: {
            type: Date,
            default: null
        },
        /**
         * The minimum allowed value for the date
         */
        min: {
            type: Date,
            default: null
        },
        /**
         * The maximum allowed value for the date
         */
        max: {
            type: Date,
            default: null
        }
    },
    data() {
        return {
            dateInternal: null,
            timeInternal: null,
            dateInvalid: false,
            timeInvalid: false
        };
    },
    computed: {
        /**
         * converts the seperate date and time into a combined datetime object or null if dateInternal or timeInternal
         * is null
         * @returns {Date|null} the selected date time
         */
        dateTime() {
            if (this.dateInternal && this.timeInternal) {
                const [y, m, d] = this.dateInternal.split("-");
                const [h, mi] = this.timeInternal.split(":");
                //month is index based
                return new Date(y, m - 1, d, h, mi);
            } else {
                return null;
            }
        },
        /**
         * converts dateInternal and timeInternal into a human readable format for the button
         * @returns {String} - the currently selected date time in a human readable format or "No Date Time Selected"
         */
        displayDateTime() {
            let dateTime = "";
            if (this.dateInternal) {
                dateTime += this.dateInternal;
            }
            if (this.timeInternal) {
                dateTime += " ";
                dateTime += this.timeInternal.slice(0, 5);
            }
            if (dateTime.trim().length === 0) {
                dateTime = "No Date Time Selected";
            }
            return dateTime;
        },
        isValidComputed(){
            const timeInvalid = this.timeInternal === null || this.timeInternal === "";
            const dateInvalid = this.dateInternal === null || this.dateInternal === "";
            if (timeInvalid !== dateInvalid) {
                return false;
            }else{
                return true;
            }
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal === null || newVal === oldVal) {
                    return;
                }
                this.dateInternal = `${newVal.getFullYear()}-${newVal.getMonth() + 1}-${newVal.getDate()}`;
                this.timeInternal =
                    `${String(newVal.getHours()).padStart(2, "0")}:${String(newVal.getMinutes()).padStart(2, "0")}`;
            }
        },
        isValidComputed(newVal){
            this.$emit("isValid", newVal);

        }
    },
    methods: {
        /**
         * @function dateSelected
         * @description emits the input event
         * @returns {void} - no return value
         */
        dateSelected() {
            /**
             * Emits every time the date or time is changed with either a full date time or null
             *
             * @event input
             * @param {Date|null} dateTime - the currently selected date time
             */
            this.$emit("input", this.dateTime);
        },
        /**
         * @function clear
         * @description clears the selected date
         * @returns {void} - no return value
         */
        clear() {
            this.dateInternal = null;
            this.timeInternal = null;
            /**
             * Emits every time the date or time is changed with either a full date time or null
             *
             * @event input
             * @param {Date|null} dateTime - the currently selected date time
             */
            this.$emit("input", this.dateTime);
        },
        isValid(e) {
            this.timeInvalid = this.timeInternal === null || this.timeInternal === "";
            this.dateInvalid = this.dateInternal === null || this.dateInternal === "";
            if (this.timeInvalid !== this.dateInvalid) {

                return e.preventDefault();
            } else {
                return;
            }
        }
    }
};
</script>
<style lang="sass">
.datetime
    &__button
        label
            padding-left: 10px!important
.inline 
    display: inline-block !important
.b-time
    width: 100%
    background: $lightGrey
    border: 1px solid darken($lightGrey, 10%)
    padding: 10px
    margin-top: 10px
.b-calendar-header, .b-time-header
    display: none
</style>
