<template>
    <div
        v-b-tooltip="tooltip"
        class="navigation-item"
        role="listitem"
        v-on="$listeners"
    >
        <component
            :is="tag"
            :to="to"
            :aria-label="name"
            class="navigation-link"
        >
            <span
                v-if="icon"
                class="navigation-link__icon"
            >
                <font-awesome-icon :icon="icon" />
            </span>
            <span
                v-if="!isCollapsed"
                class="navigation-link__name"
            >{{ name }}</span>
        </component>
    </div>
</template>

<script>
export default {
    name: "SidebarNavigationItem",
    props: {
        to: {
            type: [Object, String],
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        icon: {
            type: [String, Array],
            default: ""
        },
        className: {
            type: String,
            default: ""
        },
        isCollapsed: {
            type: Boolean,
            default: false
        },
        tag: {
            type: String,
            default: "router-link"
        }
    },
    computed: {
        tooltip() {
            return {
                title: this.name,
                placement: "right",
                triggers: "hover focus",
                boundary: "viewport",
                disabled: !this.isCollapsed
            };
        }
    }
};
</script>

<style lang="sass" scoped>
.navigation
    &-link
        display: flex
        align-items: center
        width: 100%
        gap: 15px
        border-radius: 5px
        color: $white
        font-size: 0.8rem
        text-decoration: none
        padding: 10px 13px
        white-space: nowrap
        background-color: transparent
        border: 0
        outline: 0
        &:hover, &:active, &:focus, &.router-link-active
            background: darken($headerBg, 12%)
        &:focus
            outline: 0
            box-shadow: 0 0 0 0.2rem rgba(130, 138, 145)
        &__icon
            display: flex
            align-items: center
            justify-content: center
            color: inherit
            font-size: inherit
            min-width: 20px
            min-height: 20px
            text-align: center
        &__name
            color: inherit
            font-size: 0.8rem
            display: inline-block
            line-height: 20px
    &-item
        display: flex
        padding: 0 10px
        width: 100%
        &:first-child
            padding-top: 4px
        &:last-child
            padding-bottom: 4px
        &.dark
            .navigation-link
                color: $text-color
                background-color: transparent
                &:hover, &:active, &:focus, &.router-link-active
                    background: #e8e0f3
                    box-shadow: none
</style>
