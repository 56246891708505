import Expression from "./Expression";
import randomKey from "random-key";
import { isRequestExpressionValid, isRequestDefaultValid } from "./ExpressionValidators";


export default class RequestExpression extends Expression {
    constructor(id){
        const properties = {
            returns:{  //value holds the type opject
                value: null,
                isValid:null,
                validators:[
                    isRequestExpressionValid(),
                    isRequestDefaultValid()
                ],
                get type(){
                    return this.value.type?this.value.type:null;
                }  //getter to stop having to go into values object e.g returns{type:number}
            },
            expressionType:{
                value: "request",
                isValid:null,
                validators:[]
            }
        };
        super(id,properties);
    }

    update(returns){
        //clone so that we can manipulate the array before setting
        this.returns = returns;
    }


    /**
     * This takes a plain returns object from an engine scheme and adds
     * id's that can be used fot updating. This is necessary as the id's
     * for nested objects might not be unique. E.g a:{a:{a:1}} is valid so
     * we would not be able to update a in the state.
     * @param {Object} returns
     */
    static returnBuilder(returns){
        let newReturns = {};
        newReturns.key = randomKey.generate(6);
        newReturns.type = returns.type;
        newReturns.isValid = false;
        newReturns.default= returns.default;

        if(returns.items){
            newReturns.items = RequestExpression.returnBuilder(returns.items);
        }else if(returns.properties){
            newReturns.properties = returns.properties.map(property => {
                return {
                    key: randomKey.generate(6),
                    id: property.id,
                    ...RequestExpression.returnBuilder(property)
                };
            });
        }

        return newReturns;
    }

    /**
     * This takes a plain returns object from an engine scheme and adds
     * id's that can be used fot updating. This is necessary as the id's
     * for nested objects might not be unique. E.g a:{a:{a:1}} is valid so
     * we would not be able to update a in the state.
     * @param {Object} returns
     */
    static cleanReturns(returns, level=1){
        delete returns.key;
        delete returns.isValid;
        if(level===1){
            delete returns.id;
        }
        if(returns.items){
            RequestExpression.cleanReturns(returns.items, level + 1);
        }else if(returns.properties){
            returns.properties.forEach(property => {
                RequestExpression.cleanReturns(property, level + 1);
            });
        }
        return returns;
    }

    static cleanExpression(exp) {
        const returns = RequestExpression.cleanReturns(exp.returns);
        return {
            id:exp.id,
            expressionType:exp.expressionType,
            description: exp.description,
            tags: exp.tags,
            returns
        };
    }
}
