export default {
    NUMBER: "number",
    STRING: "string",
    TEXT: "text",
    DATE: "date",
    BOOLEAN: "boolean",
    INTEGER: "integer", // trying these out
    DECIMAL: "decimal",
    ARRAY: "array",
    OBJECT: "object",
    PASS: "pass",
    REJECT: "reject"
};
