import endpointsService from "../../services/endpoints";
import schemeService from "../../services/scheme";
import policiesService from "../../services/policies";
import { qrToDataURL } from "../modules/auth";

async function generateJourneyQRCode(journeyLink) {
    try {
        const qrCode = await qrToDataURL(journeyLink);
        return qrCode;
    } catch (err) {
        throw new Error("Unable to generate QR code", err);
    }
} 

export const types = {
    "SET_ENDPOINT_SUMMARY": "SET_ENDPOINT_SUMMARY",
    "SET_ENDPOINT_JOBS": "SET_ENDPOINT_JOBS",
    "SET_ENDPOINT_JOB": "SET_ENDPOINT_JOB",
    "SET_ENDPOINT_DATA_DICTIONARY_SCHEMES": "SET_ENDPOINT_DATA_DICTIONARY_SCHEMES",
    "SET_ENDPOINT_DATA_DICTIONARY_JOURNEYS": "SET_ENDPOINT_DATA_DICTIONARY_JOURNEYS",
    "SET_ENDPOINT_QUOTES": "SET_ENDPOINT_QUOTES",
    "SET_ENDPOINT_QUOTE": "SET_ENDPOINT_QUOTE",
    "SET_ENDPOINT_JOBS_METRIC": "SET_ENDPOINT_JOBS_METRIC",
    "SET_ENDPOINT_QUOTES_METRIC": "SET_ENDPOINT_QUOTES_METRIC",
    "SET_ENDPOINT_ERRORS": "SET_ENDPOINT_ERRORS",
    "SET_ENDPOINT_ERROR": "SET_ENDPOINT_ERROR",
    "SET_ENDPOINT_ERRORS_METRIC": "SET_ENDPOINT_ERRORS_METRIC",
    "SET_ENDPOINT_REJECTS": "SET_ENDPOINT_REJECTS",
    "SET_ENDPOINT_REJECT": "SET_ENDPOINT_REJECT",
    "SET_ENDPOINT_REJECTS_METRIC": "SET_ENDPOINT_REJECTS_METRIC",
    "SET_ENDPOINT_SCHEME": "SET_ENDPOINT_SCHEME",
    "SET_ENDPOINT_SCHEMES": "SET_ENDPOINT_SCHEMES",
    "SET_ENDPOINT_TEMPLATE": "SET_ENDPOINT_TEMPLATE",
    "SET_ENDPOINT_REQUEST_TEMPLATE": "SET_ENDPOINT_REQUEST_TEMPLATE",
    "UPDATE_ENDPOINT_SCHEMES": "UPDATE_ENDPOINT_SCHEMES",
    "SET_LOADING": "SET_LOADING",
    "SET_ERROR": "SET_ERROR",
    "SET_UPDATE_REQUEST_TEMPLATE": "SET_UPDATE_REQUEST_TEMPLATE",
    "SET_UPDATE_RESPONSE_TEMPLATE": "SET_UPDATE_RESPONSE_TEMPLATE",
    "SET_ENDPOINT_SETTINGS": "SET_ENDPOINT_SETTINGS",
    "SET_ENDPOINT_SECURITY_SETTINGS": "SET_ENDPOINT_SECURITY_SETTINGS",
    "SET_ENDPOINT_SCHEME_SCHEDULES": "SET_ENDPOINT_SCHEME_SCHEDULES",
    "SET_ENDPOINT_SCHEME_HISTORIC_SCHEDULES": "SET_ENDPOINT_SCHEME_HISTORIC_SCHEDULES",
    "APPEND_ENDPOINT_SCHEME_SCHEDULES": "APPEND_ENDPOINT_SCHEME_SCHEDULES",
    "SET_POLICY_TEMPLATES": "SET_POLICY_TEMPLATES",
    "SET_ENDPOINT_ERRORS_COUNT": "SET_ENDPOINT_ERRORS_COUNT",
    "SET_ENDPOINT_JOURNEY_TYPES": "SET_ENDPOINT_JOURNEY_TYPES",
    "SET_ENDPOINT_JOURNEYS": "SET_ENDPOINT_JOURNEYS",
    "SET_ENDPOINT_FORMS": "SET_ENDPOINT_FORMS",
    "SET_ENDPOINT_REVISIONS": "SET_ENDPOINT_REVISIONS",
    "SET_ENDPOINT_JOURNEY_CRM": "SET_ENDPOINT_JOURNEY_CRM",
    "SET_ENDPOINT_JOURNEY_D2Cs": "SET_ENDPOINT_JOURNEY_D2Cs",
    "SET_ENDPOINT_SELECTED_JOURNEY": "SET_ENDPOINT_SELECTED_JOURNEY",
    "SET_ENDPOINT_JOURNEY_REVISIONS": "SET_ENDPOINT_JOURNEY_REVISIONS",
    "GET_SERVICE_ERROR_LOGS": "GET_SERVICE_ERROR_LOGS"
};

const getters = {
    schemesErrors(state) {
        let errors = [];
        if (state.current?.endpointDataDictionarySchemes?.length) {
            errors = state.current.endpointDataDictionarySchemes.flatMap((scheme) =>
                scheme.scheme_revisions.map(revision => ({
                    ...revision,
                    "scheme_name": scheme.scheme_name,
                    "scheme_comment": scheme.scheme_comment
                }))
            );
        }
        return errors;
    },
    journeysErrors(state) {
        let errors = [];
        if (state.current?.endpointDataDictionaryJourneys?.length) {
            errors = state.current.endpointDataDictionaryJourneys;
        }
        return errors;
    },
    errorsCount(state, getters) {
        const formErrors = getters.journeysErrors.flatMap(journey => journey.forms);
        return getters.schemesErrors.concat(getters.journeysErrors, formErrors).map(
            (item) => item?.fields?.length ?? 0
        ).reduce((prev, val) => prev + val, 0);
    }
};

// initial state
const state = {
    current: {
        endpointDataDictionarySchemes: null,
        endpointDataDictionaryJourneys: null,
        summary: null,
        template: null,
        requestTemplate: null,
        endpointSchemes: [],
        jobs: [],
        job: null,
        jobsMetrics: [],
        errors: [],
        error: null,
        errorsMetrics: [],
        rejects: [],
        reject: null,
        rejectMetrics: [],
        quotes: [],
        quote: null,
        quotesMetrics: [],
        endpointSettings: [],
        endpointSecuritySettings: [],
        count: 0,
        journeyTypes: [],
        revisions: {}
    },
    updateResponseTemplate: null,
    updateRequestTemplate: null,
    policyTemplates: null,
    schedules: null,
    historicSchedules: [],
    loading: false,
    error: null,
    selectedCRM: "",
    selectedD2Cs: [],
    selectedJourney: {},
    variantRevisions: [],
    serviceErrorLogs: []
};

// actions
const actions = {
    async removeScheme({commit, rootState}, {endpointId, schemeId}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            return await endpointsService.deleteEndpointScheme({
                brandID: rootState.auth.selectedBrand.name_url,
                endpointID: endpointId,
                schemeID: schemeId
            });
        } catch (err) {
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        } finally {
            commit(types.SET_LOADING, false);
        }
    },
    getEndpoint({commit, rootState}, endpointId) {
        // if (
        //     state.current.summary &&
        //     state.current.summary.id === +endpointId
        // ) {
        //     return new Promise((resolve) => resolve(state.current.summary));
        // }
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        return endpointsService.getEndpoint(
            rootState.auth.selectedBrand.name_url,
            endpointId
        ).then((response) => {
            commit(types.SET_UPDATE_RESPONSE_TEMPLATE, JSON.stringify(response.data.response_template, null, 2));
            commit(types.SET_UPDATE_REQUEST_TEMPLATE, JSON.stringify(response.data.request_template, null, 2));
            commit(types.SET_ENDPOINT_SUMMARY, response.data);
            return response.data;
        }).catch((err) => {
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }).finally(() => {
            commit(types.SET_LOADING, false);
        });
    },
    async getEndpointSettings({commit, rootState}, endpointID) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const endpointSettings = await endpointsService.fetchSettings(
                rootState.auth.selectedBrand.name_url,
                endpointID
            );
            const settings = endpointSettings.data.sort((a, b) => a.id - b.id);
            commit(types.SET_ENDPOINT_SETTINGS, settings);
            return settings;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointSecuritySettings({commit, rootState}, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const endpointSecuritySettings = await endpointsService.fetchSecuritySettings(
                rootState.auth.selectedBrand.name_url,
                payload
            );
            const settings = endpointSecuritySettings.data.authKeys.sort((a, b) => a.id - b.id);
            commit(types.SET_ENDPOINT_SECURITY_SETTINGS, settings);
            return settings;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointDataDictionary({commit, rootState}, endpointID) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const endpointDataDictionarySchemes = await endpointsService.getEndpointDataDictionarySchemes(
                rootState.auth.selectedBrand.name_url,
                endpointID
            );

            commit(types.SET_ENDPOINT_DATA_DICTIONARY_SCHEMES, endpointDataDictionarySchemes.data);

            const endpointDataDictionaryJourneys = await endpointsService.getEndpointDataDictionaryJourneys(
                rootState.auth.selectedBrand.name_url,
                endpointID
            );

            commit(types.SET_ENDPOINT_DATA_DICTIONARY_JOURNEYS, endpointDataDictionaryJourneys.data);
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointJourneys({commit, rootState}, endpointID) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const endpointJourneyTypes = await endpointsService.getEndpointJourneyTypes(
                rootState.auth.selectedBrand.name_url,
                endpointID
            );
            const journeys = await endpointsService.getEndpointJourneys(
                rootState.auth.selectedBrand.name_url,
                endpointID
            );
            const selectedCRMs = [
                ...new Set(journeys.data.filter(journey => journey.crm === true).map(e => e.endpoint_journey_id))];
            // get d2c journey ids, removing duplicates
            const selectedD2Cs = [
                ...new Set(journeys.data.filter(journey => journey.d2c === true).map(e => e.endpoint_journey_id))];
            commit(types.SET_LOADING, false);
            commit(types.SET_ENDPOINT_JOURNEY_TYPES, endpointJourneyTypes.data);
            commit(types.SET_ENDPOINT_JOURNEYS, journeys.data);
            if (selectedCRMs) {
                commit(types.SET_ENDPOINT_JOURNEY_CRM, selectedCRMs);
            }
            if (selectedD2Cs) {
                commit(types.SET_ENDPOINT_JOURNEY_D2Cs, selectedD2Cs);
            }
            return endpointJourneyTypes.data;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async deleteJourney({commit, rootState}, data) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            await endpointsService.deleteJourney(
                rootState.auth.selectedBrand.name_url,
                data.endpointId,
                data.journeyId
            );

            commit(types.SET_LOADING, false);

            return true;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async refreshRevisions({commit, rootState}, formId) {
        commit(types.SET_LOADING, true);
        try {

            let revisions = await endpointsService.getEndpointFormRevisions(
                rootState.auth.selectedBrand.name_url,
                formId
            );

            commit(types.SET_ENDPOINT_REVISIONS, {formId: revisions.data});
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async saveJourney({commit, rootState}, {journeys, endpointId, journeyId, comments, tags}) {
        commit(types.SET_LOADING, true);
        try {
            const cacheInterval = +rootState.env.vars.cacheInterval * 1000;
            await Promise.all([
                endpointsService.saveJourney(
                    rootState.auth.selectedBrand.name_url,
                    endpointId,
                    journeyId,
                    journeys,
                    comments,
                    tags
                ),
                new Promise(resolve => setTimeout(resolve, cacheInterval))
            ]);
        } catch (err) {
            commit(types.SET_ERROR, err.message);
            throw(err);
        } finally {
            commit(types.SET_LOADING, false);
        }
    },
    async setJourneyAsRevision({commit, rootState}, {journeys, endpointId, journeyId}) {
        commit(types.SET_LOADING, true);
        try {
            await endpointsService.setJourneyAsRevision(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                journeyId,
                journeys
            );

            commit(types.SET_LOADING, false);

        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    
    async setSelectedJourney({commit}, journey) {
        try {
            commit(types.SET_ENDPOINT_SELECTED_JOURNEY, journey);
        } catch (err) {
            commit(types.SET_ERROR, err.message);
            throw (err);
        }
    },
    async deleteRevision({commit, rootState}, {endpointId, journeyId, revisionId}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            await endpointsService.deleteJourneyRevision(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                journeyId,
                revisionId
            );

            commit(types.SET_LOADING, false);

            return true;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (err);
        }
    },
    async addJourney({commit, rootState}, data) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const flavour = await endpointsService.createJourney(
                rootState.auth.selectedBrand.name_url,
                data.endpointId,
                data.isDefault,
                data.name
            );
            commit(types.SET_LOADING, false);
            return flavour;
        } catch (error) {

            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            commit(types.SET_LOADING, true);
        }
    },
    async setEndpointJourneyFlags({commit, rootState}, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            await endpointsService.setEndpointJourneyFlags(
                rootState.auth.selectedBrand.name_url,
                payload
            );
            if (payload.flag === "d2c" && payload.value === true) {
                const cacheInterval = +rootState.env.vars.cacheInterval * 1000;
                await new Promise(resolve => setTimeout(resolve, cacheInterval));
            }
            commit(types.SET_LOADING, false);
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },

    async getJourneyVariantRevisions({commit, rootState}, payload) {
        const { endpointId, journeyId } = payload;
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const revisions = await endpointsService.getEndpointJourneyVariantRevisions(
                rootState.auth.selectedBrand.name_url,
                endpointId, 
                journeyId
            ) || [];
            commit(types.SET_ENDPOINT_JOURNEY_REVISIONS, revisions.data);
            commit(types.SET_LOADING, false);
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (err);
        }
    },

    async getEndpointForms({commit, rootState}, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {

            const forms = await endpointsService.getEndpointForms(
                rootState.auth.selectedBrand.name_url,
                payload.endpointId,
                payload.partial
            );

            let formsData = [];
            let combinedRevisions2 = forms.data.reduce((combinedRevisions, form) => {
                if (combinedRevisions[form.form_id]) {
                    const revision = {
                        name: form.revision_name,
                        id: form.revision_id
                    };
                    combinedRevisions[form.form_id].push(revision);
                } else {
                    /*eslint-disable*/
                    const formData = {
                        name: form.form_name,
                        id: form.form_id,
                        form_type_name: form.name,
                        form_type_id: form.form_type_id,
                        brand_id: form.brand_id
                    };
                    /*eslint-enable*/
                    const revision = {
                        name: form.revision_name,
                        id: form.revision_id
                    };
                    formsData.push(formData);
                    combinedRevisions[form.form_id] = [revision];
                }
                return combinedRevisions;
            }, {});

            commit(types.SET_ENDPOINT_FORMS, formsData);
            commit(types.SET_LOADING, false);
            commit(types.SET_ENDPOINT_REVISIONS, combinedRevisions2);

            return forms.data;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async updateEndpointSettings({commit, rootState}, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            await endpointsService.updateSettings(
                rootState.auth.selectedBrand.name_url,
                payload
            );
            commit(types.SET_LOADING, false);
        } catch (err) {
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async addEndpointSettings({commit, rootState}, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            await endpointsService.postEndpointSettings(
                rootState.auth.selectedBrand.name_url,
                payload
            );
            commit(types.SET_LOADING, false);
        } catch (err) {
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async deleteEndpointSettings({commit, rootState}, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            await endpointsService.deleteEndpointSettings(
                rootState.auth.selectedBrand.name_url,
                payload
            );
            commit(types.SET_LOADING, false);
        } catch (err) {
            commit(types.SET_ERROR, err.message);
            throw err;
        }
    },
    async addEndpointSecuritySettings({commit, rootState}, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const authKey = await endpointsService.postEndpointSecuritySettings(
                rootState.auth.selectedBrand.name_url,
                payload
            );
            commit(types.SET_LOADING, false);
            return authKey.data;
        } catch (err) {
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async deleteEndpointSecuritySettings({commit, rootState}, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            await endpointsService.deleteEndpointSecuritySettings(
                rootState.auth.selectedBrand.name_url,
                payload
            );
            commit(types.SET_LOADING, false);
        } catch (err) {
            commit(types.SET_ERROR, err.message);
            throw err;
        }
    },
    async getEndpointJobsMetrics({commit, rootState}, {endpointId, from, to, unit}) {
        //dont set error - not an issue if we fail
        try {
            let endpointJobsRequest = await endpointsService.getEndpointJobsMetrics(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                from,
                to,
                unit
            );
            let endpointJobMetrics = endpointJobsRequest.data;
            //process the metric here
            commit(types.SET_ENDPOINT_JOBS_METRIC, endpointJobMetrics);
            return endpointJobMetrics;

        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointJobs({commit, rootState}, {endpointId, ...query}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            let endpointJobsRequest = await endpointsService.getEndpointJobs(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                query
            );


            let endpointJobs = endpointJobsRequest.data.endpointQuotes;
            let [{count}] = endpointJobsRequest.data.count;

            if (count) {
                commit(types.SET_ENDPOINT_ERRORS_COUNT, count);
            }


            commit(types.SET_ENDPOINT_JOBS, endpointJobs);
            commit(types.SET_LOADING, false);
            return endpointJobs;

        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointJob({commit, rootState}, {endpointId, jobId}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            let endpointJobRequest = await endpointsService.getEndpointJob(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                jobId
            );
            let endpointJob = endpointJobRequest.data;
            commit(types.SET_ENDPOINT_JOB, endpointJob);
            commit(types.SET_LOADING, false);
            return endpointJob;

        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointQuotesMetrics({commit, rootState}, {endpointId, from, to, unit}) {
        //dont set error - not an issue if we fail
        try {
            let endpointJobsRequest = await endpointsService.getEndpointQuotesMetrics(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                from,
                to,
                unit
            );
            let endpointQuotesMetrics = endpointJobsRequest.data;
            //process the metric here
            commit(types.SET_ENDPOINT_QUOTES_METRIC, endpointQuotesMetrics);
            return endpointQuotesMetrics;

        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointQuotes({commit, rootState}, {endpointId, ...query}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            let endpointQuotesRequest = await endpointsService.getEndpointQuotes(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                query
            );

            let endpointQuotes = endpointQuotesRequest.data.endpointQuotes;
            let [{count}] = endpointQuotesRequest.data.count;
            if (count) {
                commit(types.SET_ENDPOINT_ERRORS_COUNT, count);
            }

            commit(types.SET_ENDPOINT_QUOTES, endpointQuotes);
            commit(types.SET_LOADING, false);
            return endpointQuotes;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointQuote({commit, rootState}, {endpointId, quoteId}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            let endpointQuoteRequest = await endpointsService.getEndpointQuote(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                quoteId
            );
            let endpointQuote = endpointQuoteRequest.data;
            commit(types.SET_ENDPOINT_QUOTE, endpointQuote);
            commit(types.SET_LOADING, false);
            return endpointQuote;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointErrors({commit, rootState}, {endpointId, ...query}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            let endpointErrorsRequest = await endpointsService.getEndpointErrors(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                query
            );

            let endpointErrors = endpointErrorsRequest.data.endpointErrors;

            let [{count}] = endpointErrorsRequest.data.count;
            if (count) {
                commit(types.SET_ENDPOINT_ERRORS_COUNT, count);
            }


            commit(types.SET_ENDPOINT_ERRORS, endpointErrors);
            commit(types.SET_LOADING, false);
            return endpointErrors;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointError({commit, rootState}, {endpointId, errorId}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            let endpointErrorRequest = await endpointsService.getEndpointError(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                errorId
            );
            let endpointError = endpointErrorRequest.data;
            commit(types.SET_ENDPOINT_ERROR, endpointError);
            commit(types.SET_LOADING, false);
            return endpointError;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointErrorsMetrics({commit, rootState}, {endpointId, from, to, unit}) {
        //dont set error - not an issue if we fail
        try {
            let endpointErrorsRequest = await endpointsService.getEndpointErrorsMetrics(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                from,
                to,
                unit
            );
            let endpointErrorsMetrics = endpointErrorsRequest.data;
            //process the metric here
            commit(types.SET_ENDPOINT_ERRORS_METRIC, endpointErrorsMetrics);
            return endpointErrorsMetrics;

        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },

    async getEndpointRejects({commit, rootState}, {endpointId, ...query}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            let endpointRejectsRequest = await endpointsService.getEndpointRejects(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                query
            );

            let endpointRejects = endpointRejectsRequest.data.endpointErrors;

            let [{count}] = endpointRejectsRequest.data.count;
            if (count) {
                commit(types.SET_ENDPOINT_ERRORS_COUNT, count);
            }

            commit(types.SET_ENDPOINT_REJECTS, endpointRejects);
            commit(types.SET_LOADING, false);
            return endpointRejects;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointReject({commit, rootState}, {endpointId, rejectId}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            let endpointErrorRequest = await endpointsService.getEndpointReject(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                rejectId
            );
            let endpointError = endpointErrorRequest.data;
            commit(types.SET_ENDPOINT_REJECT, endpointError);
            commit(types.SET_LOADING, false);
            return endpointError;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getEndpointRejectsMetrics({commit, rootState}, {endpointId, from, to, unit}) {
        //dont set error - not an issue if we fail
        try {
            let endpointRejectsRequest = await endpointsService.getEndpointRejectsMetrics(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                from,
                to,
                unit
            );
            let endpointRejectsMetrics = endpointRejectsRequest.data;
            //process the metric here
            commit(types.SET_ENDPOINT_REJECTS_METRIC, endpointRejectsMetrics);
            return endpointRejectsMetrics;

        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },

    async getEndpointSchemes({commit, rootState}, {endpointId, limit, offset}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const endpointSchemesRequest = await endpointsService.getEndpointSchemes({
                brandURL: rootState.auth.selectedBrand.name_url,
                endpointID: endpointId,
                limit,
                offset
            });
            await Promise.all(endpointSchemesRequest.data.data.map(async (el) => {
                try {
                    const res = await endpointsService.getEndpointSchemeSchedule({
                        brandID: rootState.auth.selectedBrand.name_url,
                        endpointID: endpointId,
                        schemeID: el.schemeID,
                        limit: 2,
                        offset: 0
                    });
                    el.nextSchedule = res.data.filter(
                        (ns) => !el.currentRevisions[0] || ns.startTime !== el.currentRevisions[0].startTime
                    )[0] || null;
                } catch (err) {
                    el.nextSchedule = null;
                }
            }));
            commit(types.SET_ENDPOINT_SCHEMES, endpointSchemesRequest.data);
            commit(types.SET_LOADING, false);
            return endpointSchemesRequest.data;
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async getPolicyTemplates({commit, rootState}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const res = await policiesService.getPolicyRevisions(rootState.auth.selectedBrand.name_url);
            commit(types.SET_POLICY_TEMPLATES, res.data);
            return res.data;
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }
    },
    async getEndpointSchemeHistoricSchedules({commit, rootState}, {endpointID, schemeID, limit, activeBefore}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const schedules = await endpointsService.getEndpointSchemeSchedule({
                brandID: rootState.auth.selectedBrand.name_url,
                endpointID,
                schemeID,
                limit,
                activeBefore
            });
            commit(types.SET_ENDPOINT_SCHEME_HISTORIC_SCHEDULES, schedules.data);
            return schedules.data;
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }
    },
    async getEndpointSchemeSchedules({commit, rootState}, {endpointID, schemeID, limit, offset}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const schedules = await endpointsService.getEndpointSchemeSchedule({
                brandID: rootState.auth.selectedBrand.name_url,
                endpointID,
                schemeID,
                limit,
                offset
            });
            commit(types.SET_ENDPOINT_SCHEME_SCHEDULES, schedules.data);
            return schedules.data;
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }

    },
    async pageEndpointSchemeSchedules({commit, rootState, state}, {endpointID, schemeID, limit}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const schedules = await endpointsService.getEndpointSchemeSchedule({
                brandID: rootState.auth.selectedBrand.name_url,
                endpointID,
                schemeID,
                limit,
                activeAfter: state.schedules[state.schedules.length - 1].endTime
            });
            commit(types.APPEND_ENDPOINT_SCHEME_SCHEDULES, schedules.data);
            return schedules.data;
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }
    },
    async updateSchedule({commit, rootState, dispatch}, options) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            await endpointsService.updateSchedule({
                scheduleID: options.scheduleID,
                brandID: rootState.auth.selectedBrand.name_url,
                schemeID: options.schemeID,
                endpointID: options.endpointID,
                payload: options.payload
            });
            await dispatch("getEndpointSchemeSchedules", {
                endpointID: options.endpointID,
                schemeID: options.schemeID,
                limit: options.limit,
                offset: options.offset
            });
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }
    },
    async createSchedule({commit, rootState, dispatch}, options) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            await endpointsService.uploadSchedule({
                brandID: rootState.auth.selectedBrand.name_url,
                schemeID: options.schemeID,
                endpointID: options.endpointID,
                payload: options.payload
            });
            await dispatch("getEndpointSchemeSchedules", {
                endpointID: options.endpointID,
                schemeID: options.schemeID,
                limit: options.limit,
                offset: options.offset
            });
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }
    },
    async getScheduleOverlaps({commit, rootState}, options) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            return await endpointsService.getScheduleOverlaps({
                brandID: rootState.auth.selectedBrand.name_url,
                scheduleID: options.scheduleId,
                schemeID: options.schemeId,
                endpointID: options.endpointId,
                name: options.name,
                startTime: options.startTime,
                endTime: options.endTime
            });
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }

    },
    async deleteSchedule({commit, rootState}, options) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            return await endpointsService.deleteSchedule({
                brandID: rootState.auth.selectedBrand.name_url,
                endpointID: options.endpointID,
                schemeID: options.schemeID,
                scheduleID: options.scheduleID
            });
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }

    },
    async patchEndpointTemplate({commit, rootState}, {endpointId, template}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            //this is here as when edited the JSON is turned to a string
            if (typeof template === "string" && template.trim() !== "") {
                template = JSON.parse(template);
            }
            let endpointSchemeRequest = await endpointsService.patchEndpointTemplate(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                template
            );
            let endpointTemplate = endpointSchemeRequest.data.response_template;
            commit(types.SET_ENDPOINT_TEMPLATE, endpointTemplate);
            commit(types.SET_LOADING, false);
        } catch (err) {
            //if we have a axios error
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async patchEndpointRequestTemplate({commit, rootState}, {endpointId, template}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            //this is here as when edited the JSON is turned to a string
            if (typeof template === "string" && template.trim() !== "") {
                template = JSON.parse(template);
            }
            let endpointSchemeRequest = await endpointsService.patchEndpointRequestTemplate(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                template
            );
            let endpointRequestTemplate = endpointSchemeRequest.data.request_template;
            commit(types.SET_ENDPOINT_REQUEST_TEMPLATE, endpointRequestTemplate);
            commit(types.SET_LOADING, false);
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },
    async patchEndpointScheme({commit, rootState}, {endpointId, schemeId, policyTemplateID}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const scheme = await endpointsService.patchEndpointScheme(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                schemeId,
                policyTemplateID
            );
            commit(types.UPDATE_ENDPOINT_SCHEMES, scheme.data);
            commit(types.SET_ERROR, null);
            commit(types.SET_LOADING, false);

        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }
    },

    async patchEndpoint({commit, rootState}, {endpointId, policyTag, enableHashedKeys}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        endpointsService.patchEndpoint(
            rootState.auth.selectedBrand.name_url,
            endpointId,
            {
                policy_tag: policyTag, // eslint-disable-line camelcase
                enable_hashed_keys: enableHashedKeys // eslint-disable-line camelcase
            }
        ).then(() => {
            commit(types.SET_LOADING, false);
        }).catch((err) => {
            commit(types.SET_ERROR, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        });
    },

    async postEndpointScheme({commit, rootState}, {endpointId, schemeId}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            await endpointsService.postEndpointScheme(rootState.auth.selectedBrand.name_url, endpointId, schemeId);
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, err.message);
            throw (new Error(err.message));
        }

        commit(types.SET_LOADING, false);
    },

    async getSchemeRevisionStatus({commit, rootState}, {schemeId, revisionId}) {
        commit(types.SET_ERROR, null);
        try {
            const {data} = await schemeService.getSchemeRevision(
                schemeId,
                revisionId,
                rootState.auth.selectedBrand.name_url,
                {params: {fields: ["status"]}}
            );
            commit(types.UPDATE_ENDPOINT_SCHEMES, {
                ["scheme_id"]: schemeId,
                ["scheme_revision_id"]: revisionId,
                newStatus: data.status
            });
            return data;
        } catch (err) {
            const msg = err.response ? err.response.data.message : err.message;
            commit(types.SET_ERROR, msg);
            throw (new Error(msg));
        }
    },
    async testEndpoint(context, payload) {
        return endpointsService.postEndpointTest(payload.brand, payload.endpoint, payload.payload, payload.url);
    },
    async getServiceErrorLogs({commit, rootState}, { endpointId, ...query }) {
        try {
            commit(types.SET_ERROR, false);
            const result = await endpointsService.getServiceErrorLogs(
                rootState.auth.selectedBrand.name_url,
                endpointId,
                query
            );
            commit(types.GET_SERVICE_ERROR_LOGS, result.data);
        } catch (err) {
            commit(types.SET_ERROR, "Issue getting service error logs");
        }
    },
    async getJourneyUrlD2C({ commit, rootState }, payload) {
        try {
            const { endpointId, endpointNameUrl, journeyName, page = null } = payload;
            const domain = rootState.auth.provider;
            const { name_url: subdomain } = rootState.auth.selectedBrand;
            const result = await endpointsService.getJourneyUrlD2C(subdomain, endpointId, endpointNameUrl, journeyName);
            const hostname = result.data;
            const url = new URL(`${hostname}${page ? "/" + page : ""}`);
            const params = new URLSearchParams();
            const host = url.hostname.toString().split(".");
            if (host.includes("asanto")) {
                params.set("subdomain", subdomain);
                params.set("journey_name", journeyName);
                params.set("endpoint", endpointNameUrl);
            } else if (url.hostname === "localhost" ||
                host.includes(
                    "theidoluat" ||
                    "theidolprod"
                )
            ) {
                params.set("domain", domain);
                params.set("subdomain", subdomain);
                params.set("journey_name", journeyName);
                params.set("endpoint", endpointNameUrl);
            } 
            const combinedUrl = `${url.toString()}${params.toString() ? "?" + params.toString() : ""}`;
            const qrCode = await generateJourneyQRCode(combinedUrl);
            return { journeyUrl: combinedUrl, qrCode };
        } catch (err) {
            commit(types.SET_ERROR, "Unable to retrieve D2C journey URL");
        }
    }
};

// mutations
const mutations = {
    [types.SET_UPDATE_REQUEST_TEMPLATE](state, val) {
        state.updateRequestTemplate = val;
    },
    [types.SET_UPDATE_RESPONSE_TEMPLATE](state, val) {
        state.updateResponseTemplate = val;
    },
    [types.SET_ENDPOINT_SUMMARY](state, summary) {
        state.current.summary = summary;
    },
    [types.SET_ENDPOINT_QUOTES](state, quotes) {
        state.current.quotes = quotes;
    },
    [types.SET_ENDPOINT_QUOTE](state, quote) {
        state.current.quote = quote;
    },
    [types.SET_ENDPOINT_QUOTES_METRIC](state, metrics) {
        state.current.quotes = metrics;
    },
    [types.SET_ENDPOINT_JOBS](state, jobs) {
        state.current.jobs = jobs;
    },
    [types.SET_ENDPOINT_JOB](state, job) {
        state.current.job = job;
    },
    [types.SET_ENDPOINT_JOBS_METRIC](state, metrics) {
        state.current.jobsMetrics = metrics;
    },
    [types.SET_ENDPOINT_ERRORS](state, errors) {
        state.current.errors = errors;
    },
    [types.SET_ENDPOINT_ERRORS_COUNT](state, count) {
        state.current.count = count;
    },
    [types.SET_ENDPOINT_ERROR](state, error) {
        state.current.error = error;
    },
    [types.SET_ENDPOINT_ERRORS_METRIC](state, metrics) {
        state.current.errorsMetrics = metrics;
    },
    [types.SET_ENDPOINT_REJECTS](state, rejects) {
        state.current.rejects = rejects;
    },
    [types.SET_ENDPOINT_REJECT](state, reject) {
        state.current.reject = reject;
    },
    [types.SET_ENDPOINT_REJECTS_METRIC](state, metrics) {
        state.current.rejectsMetrics = metrics;
    },
    [types.SET_ENDPOINT_TEMPLATE](state, template) {
        state.current.template = template;
    },
    [types.SET_ENDPOINT_REQUEST_TEMPLATE](state, template) {
        state.current.requestTemplate = template;
    },
    [types.SET_ENDPOINT_SCHEMES](state, endpointSchemes) {
        state.current.endpointSchemes = endpointSchemes;
    },
    [types.SET_ENDPOINT_SCHEME](state, endpointScheme) {
        state.current.endpointSchemes.push(endpointScheme);
    },
    //eslint-disable-next-line
    [types.UPDATE_ENDPOINT_SCHEMES](state, scheme) {
        //Need to decide whether to clone state and then update
        //this is updating in situ.
        state.current.endpointSchemes.data.map((el) => {
            if (el.scheme_id === scheme.scheme_id) {
                el["revision_id"] = scheme.scheme_revision_id;
                el["engine_status"] = typeof scheme.newStatus !== "undefined" ? scheme.newStatus : null;
            }
            return el;
        });
    },
    [types.SET_LOADING](state, loading) {
        state.loading = loading;
    },
    [types.SET_ERROR](state, error) {
        state.error = error;
    },
    [types.SET_ENDPOINT_SETTINGS](state, endpointSettings) {
        state.current.endpointSettings = endpointSettings;
    },
    [types.SET_ENDPOINT_SECURITY_SETTINGS](state, endpointSecuritySettings) {
        state.current.endpointSecuritySettings = endpointSecuritySettings;
    },
    [types.SET_ENDPOINT_DATA_DICTIONARY_SCHEMES](state, endpointDataDictionarySchemes) {
        state.current.endpointDataDictionarySchemes = endpointDataDictionarySchemes;
    },
    [types.SET_ENDPOINT_DATA_DICTIONARY_JOURNEYS](state, endpointDataDictionaryJourneys) {
        state.current.endpointDataDictionaryJourneys = endpointDataDictionaryJourneys;
    },
    [types.SET_ENDPOINT_SCHEME_SCHEDULES](state, schedules) {
        state.schedules = schedules;
    },
    [types.SET_ENDPOINT_SCHEME_HISTORIC_SCHEDULES](state, schedules) {
        state.historicSchedules = schedules;
    },
    [types.APPEND_ENDPOINT_SCHEME_SCHEDULES](state, schedules) {
        state.schedules = [...state.schedules, ...schedules];
    },
    [types.SET_POLICY_TEMPLATES](state, value) {
        state.policyTemplates = value;
    },
    [types.SET_ENDPOINT_JOURNEYS](state, value) {
        state.current.journeys = value;
    },
    [types.SET_ENDPOINT_JOURNEY_TYPES](state, value) {
        state.current.journeyTypes = value;
    },
    [types.SET_ENDPOINT_FORMS](state, value) {
        state.current.forms = value;
    },
    [types.SET_ENDPOINT_REVISIONS](state, value) {
        state.current.revisions = {...state.current.revisions, ...value};
    },
    [types.SET_ENDPOINT_JOURNEY_CRM](state, crm) {
        state.selectedCRM = crm;
    },
    [types.SET_ENDPOINT_JOURNEY_D2Cs](state, d2c) {
        state.selectedD2Cs = d2c;
    },
    [types.SET_ENDPOINT_SELECTED_JOURNEY](state, journey) {
        state.selectedJourney = journey;
    },
    [types.SET_ENDPOINT_JOURNEY_REVISIONS](state, revisions) {
        state.variantRevisions = revisions;
    },
    [types.GET_SERVICE_ERROR_LOGS](state, serviceErrorLogs) {
        state.serviceErrorLogs = serviceErrorLogs;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
