<template>
    <ul class="baseTree">
        <li
            v-if="typeof obj === 'object'"
            class="header"
            @click="showObjectContents = !showObjectContents"
        >
            <h5 v-if="showObjectContents && typeof obj === 'object'">
                <font-awesome-icon
                    :icon="['fal', 'minus']"
                />
                {{ name }}
            </h5>
            <h5 v-else-if="typeof obj === 'object'">
                <font-awesome-icon
                    :icon="['fal', 'plus']"
                />
                {{ name }}
            </h5>
        </li>
        <li v-if="showObjectContents">
            <span
                v-if="typeof obj === 'string' || typeof obj === 'number'"
                class="baseTree__obj"
            >
                <strong>{{ name }}:</strong> {{ obj }}
            </span>

            <span
                v-else-if="typeof obj === 'boolean'"
                class="baseTree__obj"
            >
                <strong>{{ name }}:</strong> {{ obj ? "true" : "false" }}
            </span>

            <span v-else-if="Array.isArray(obj)">
                <span
                    v-for="(value, index) in obj"
                    :key="index"
                >
                    <tree-view
                        :obj="value"
                        :name="index"
                    />
                </span>
            </span>
            <span v-else-if="typeof obj === 'object'">
                <span
                    v-for="(value, prop) in obj"
                    :key="prop"
                >
                    <tree-view
                        :obj="value"
                        :name="prop"
                    />
                </span>
            </span>
        </li>
    </ul>
</template>

<script>
import TreeView from "./BaseTreeView";

export default {
    name: "TreeView",
    components: {
        TreeView
    },
    props: {
        obj: {
            required: false,
            type: [String, Number, Object, Array, Boolean],
            default: null
        },
        name: {
            required: true,
            type: [String, Number]
        }
    },
    data() {
        return {
            showObjectContents: false
        };
    },
    created() {
        if (["string", "number", "boolean"].includes(typeof this.obj)) {
            this.showObjectContents = true;
        }
    }
};
</script>

<style lang="sass">
.baseTree
  list-style: none
  padding: 0
  margin-top: 15px
  position: relative
  &:before
    background: darken(lightGrey, 0%)
    width: 1px
    content: " "
    z-index: 0
    position: absolute
    left: 7px
    top: 38px
    bottom: 0
  li
    h5
      font-size: 0.9rem
      font-weight: 600
      background: $white
      padding: 10px 0
      background: $lightGrey
      border-bottom: 1px solid darken(lightGrey, 5%)
      cursor: pointer
      margin-bottom: 0px
      position: relative
      svg
        margin: 0 10px
    .baseTree__obj
      padding: 5px
      position: relative
      border-bottom: 1px solid $lightGrey
      background: $white
      word-break: break-all
      position: relative
      z-index: 1
      display: block
      strong
        margin-right: 10px
      &:before
        background: darken(lightGrey, 0%)
        height: 1px
        width: 8px
        content: " "
        position: absolute
        left: -8px
        top: 15px
    ul
      margin-left: 15px
      margin-top: 0
      li
        h5
          &:before
            background: darken(lightGrey, 0%)
            height: 1px
            width: 8px
            content: " "
            position: absolute
            left: -8px
            top: 50%
</style>
