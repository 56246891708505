import Expression from "./Expression";

class ExpressionTree {
    /**
     * @class ExpressionTree
     * @description wraps an array of expressions to provide array like functions, deepMap, deepReduce etc
     * @param {Expression[]} [expressions] - array of expressions to wrap
     */
    constructor(expressions = []) {
        if (!Array.isArray(expressions)) {
            throw new Error(`expressions must be an array. Got: ${typeof expressions}`);
        }

        const invalidExpressions = expressions.filter((el) => el instanceof Expression === false);
        if (invalidExpressions.length > 0) {
            throw new Error("Not all values passed are instances of Expression");
        }

        this._expressions = expressions;

        // TODO find a better way of doing this, adding expressionTree to each expression and then calling it from
        // within the function seem to error on _this.expressionTree is undefined
        const find = this.find.bind(this);
        this.deepForEach((exp) => {
            if (exp.expressionType === "pick") {
                exp.fetchReturns = () => {
                    return find((el) => el.id === exp.items).properties.returns.value.items;
                };
            }
        });

    }

    /**
     * @type {Expression[]}
     * @memberof ExpressionTree
     * @instance
     */
    get expressions() {
        return this._expressions;
    }

    /**
     * @function deepReduce
     * @description applies func to every expression including expressions nested in blocks, func takes 3 param the
     * accumulator, the current element and the context element, this is because func will be called on the blocks
     * property
     * @memberof ExpressionTree
     * @instance
     * @param {function} func - this will be called on every expression
     * @param {*} acc - the results of func will be accumulated in this param and returned
     * @returns {*} result of the reduce
     */
    deepReduce(func, acc) {
        return ExpressionTree._deepReducer(this._expressions, func, acc);
    }

    /**
     * @function _deepReducer
     * @description recursivly callable function to perform the deep reduce
     * @memberof ExpressionTree
     * @static
     * @private
     * @param {Expression[]} expressions - array of expressions to reduce over
     * @param {function} func - function to apply to every expression
     * @param {*} accumulator - the result of the reducer function is stored in this
     * @returns {*} the result of the reduce
     */
    static _deepReducer(expressions, func, accumulator) {
        return expressions.reduce((acc, el) => {
            acc = func(acc, el, el);
            if (el.block) {
                acc = func(acc, ExpressionTree._deepReducer(el.block, func, accumulator), el);
            }
            return acc;
        }, JSON.parse(JSON.stringify(accumulator)));
    }

    /**
     * @function deepMap
     * @description applies the map function to every expression including nested expressions, results of block
     * expressions are included as an array in the next index
     * @memberof ExpressionTree
     * @instance
     * @param {function} func - the function to apply to every expression
     * @returns {*[]} - array of results
     */
    deepMap(func) {
        return ExpressionTree._deepMapper(this._expressions, func);
    }

    /**
     * @function _deepMapper
     * @description recursivly callable function to map over expressions deeply
     * @memberof ExpressionTree
     * @static
     * @private
     * @param {Expression[]} expressions - expressions to map over
     * @param {function} func - function to apply to every expression
     * @returns {*[]} result of map function
     */
    static _deepMapper(expressions, func) {
        return expressions.reduce((acc, el) => {
            acc = [...acc, func(el)];
            if (el.block) {
                acc = [...acc, ExpressionTree._deepMapper(el.block, func)];
            }
            return acc;
        }, []);
    }

    /**
     * @function deepForEach
     * @description runs a function on/for every expression
     * @memberof ExpressionTree
     * @instance
     * @param {function} func - function to run
     * @returns {void} no return value
     */
    deepForEach(func) {
        ExpressionTree._deepForEacher(this._expressions, func);
    }

    /**
     * @function _deepForEacher
     * @description recursivly callable function to for each over the expressions
     * @memberof ExpressionTree
     * @static
     * @private
     * @param {Expression[]} expressions - expressions to for each over
     * @param {function} func - function to call for each expression
     * @returns {void} no return value
     */
    static _deepForEacher(expressions, func) {
        expressions.forEach((el) => {
            func(el);
            if (el.block) {
                ExpressionTree._deepForEacher(el.block, func);
            }
        });
    }

    /**
     * @function deepFind
     * @description searches through all expression for the first matching expression
     * @memberof ExpressionTree
     * @instance
     * @param {function} func - function to evaluate the expression to see if it matches
     * @returns {undefined|Expression} the matching expression or undefined if its not found
     */
    deepFind(func) {
        return ExpressionTree._deepFinder(this._expressions, func);
    }

    /**
     * @function _deepFinder
     * @description recursivly callable function to deep find expressions
     * @memberof ExpressionTree
     * @static
     * @private
     * @param {Expression[]} expressions - the expressions to find from
     * @param {function} func - the function to evaluate if this is the expression we are looking for
     * @returns {undefined|Expression} the matching expression or undegined if it is not found
     */
    static _deepFinder(expressions, func) {
        return expressions.reduce((acc, el) => {
            if (acc) {
                return acc;
            } else if (func(el)) {
                return el;
            } else if (el.block) {
                return ExpressionTree._deepFinder(el.block, func);
            }
        }, undefined);
    }

    /**
     * @function deepFilter
     * @description filter all expressions if expression is in a block it returns it wrapped in an array
     * @memberof ExpressionTree
     * @instance
     * @param {function} func - function which determins if we are filtering the expression out
     * @returns {Expression[]} - array of expressions
     */
    deepFilter(func) {
        return ExpressionTree._deepFilterer(this._expressions, func);
    }

    /**
     * @function _deepFilterer
     * @description recursivly callable function for filetering
     * @memberof ExpressionTree
     * @static
     * @private
     * @param {Expression[]} expressions - expressions to filter
     * @param {function} func - function to determin if we are filtering the expression out
     * @returns {Expression[]} - array of expressions
     */
    static _deepFilterer(expressions, func) {
        return expressions.reduce((acc, el) => {
            if (func(el)) {
                acc.push(el);
            }
            if (el.block) {
                const res = ExpressionTree._deepFilterer(el.block, func);
                if (res.length > 0) {
                    acc.push(ExpressionTree._deepFilterer(el.block, func));
                }
            }
            return acc;
        }, []);
    }

    /**
     * @function reduce
     * @description reduces over the top level expressions
     * @memberof ExpressionTree
     * @instance
     * @param {function} func - reducer function
     * @param {*} acc - accumulates results of reducer
     * @returns {*} result of reducer
     */
    reduce(func, acc) {
        return this._expressions.reduce(func, acc);
    }

    /**
     * @function map
     * @description maps over the top level expressions
     * @memberof ExpressionTree
     * @instance
     * @param {function} func - map function
     * @returns {*} result of map
     */
    map(func) {
        return this._expressions.map(func);
    }

    /**
     * @function forEach
     * @description forEach over the top level expressions
     * @memberof ExpressionTree
     * @instance
     * @param {function} func - forEach function
     * @returns {void} no return function
     */
    forEach(func) {
        this._expressions.forEach(func);
    }

    /**
     * @function find
     * @description finds a top level expression
     * @memberof ExpressionTree
     * @instance
     * @param {function} func - find function
     * @returns {undefined|Expression} the expression if found or undefined
     */
    find(func) {
        return this._expressions.find(func);
    }

    /**
     * @function filter
     * @description filters over the top level expressions
     * @memberof ExpressionTree
     * @instance
     * @param {function} func - filter function
     * @returns {Expression[]} array of filtered expressions
     */
    filter(func) {
        return this._expressions.filter(func);
    }
}

export default ExpressionTree;
