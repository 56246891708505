/*
Regex to validate user's password

- Must contain at least one lowercase letter.
- Must contain at least one uppercase letter.
- Must contain at least one digit.
- Must contain at least one of the specified special characters.
- Must have a length of 12 to 100 characters.
 */
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{12,100}$/;

export const USERPILOT_TRIGGER = "resource_center:N3mf0y_JMd";
