<template>
    <div
        :class="['VueCarousel', {'hasArrows': canForward || canBackward }]"
    >
        <div
            class="VueCarousel-wrapper"
        >
            <div
                ref="carousel"
                class="VueCarousel-inner"
                :style="{
                    'transform': `translate(${dragOffset}px, 0)`,
                    'transition': dragging ? 'none' : '0.25s ease-in-out transform',
                    'flex-basis': `${itemWidth}px`,
                    'visibility': itemWidth ? 'visible' : 'hidden',
                }"
            >
                <div
                    v-for="(item, index) in items"
                    :key="index"
                    :style="{
                        'flex-shrink': 0,
                        'width': `${itemWidth}px`,
                        'ms-flex-preferred-size': `${itemWidth}px`,
                        'webkit-flex-basis': `${itemWidth}px`,
                    }"
                >
                    <!--
                        @slot use this slot to define how the carousel items should be displayed
                        @binding {object} item - an item from the items array prop
                    -->
                    <slot :item="item" />
                </div>
            </div>
            <!-- @slot use this slot to replace the busy overlay -->
            <slot
                v-if="isBusy"
                name="busy"
            >
                <div
                    class="VueCarousel-busy"
                    @mousedown.stop
                    @touchstart.stop
                />
            </slot>
        </div>
        <!--
            @slot use this slot to replace the default navigation buttons, you will be required to manually reach into
            this component and call move('forward') and move('backward') where appropriate
        -->
        <slot
            v-if="navigationEnabled"
            name="navigation"
        >
            <button
                v-if="canForward || canBackward"
                class="VueCarousel-forward"
                :disabled="!canForward"
                type="button"
                @click="move('forward')"
            >
                &gt;
            </button>
            <button
                v-if="canBackward || canForward"
                class="VueCarousel-back"
                :disabled="!canBackward"
                type="button"
                @click="move('backward')"
            >
                &lt;
            </button>
        </slot>
    </div>
</template>

<script>
export default {
    name: "BaseCarousel",
    props: {
        /**
         * The items to populate the carousel with, this must be an array of objects with an 'id' property
         */
        items: {
            type: Array,
            required: true
        },
        /**
         * Should the forward and backward navigation be shown
         */
        navigationEnabled: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * How many of the items should be displayed per carousel page
         */
        perPage: {
            type: Number,
            required: false,
            default: 4
        },
        /**
         * Do we want the user to be able to touch and drag the carousel when using touch screen devices
         */
        touchDrag: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Do we want the user to be able to drag the carouse using the mouse
         */
        mouseDrag: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Displays a grey overlay over the carousel if set to true, used for setting a loading state
         */
        isBusy: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            itemID: null,
            isTouch: typeof window !== "undefined" && "ontouchstart" in window,
            offset: 0,
            dragging: false,
            dragOffset: 0,
            dragStartY: 0,
            dragStartX: 0,
            carouselWidth: 0
        };
    },
    computed: {
        /**
         * Calculates the width of items in the carousel using the carousel width and the items perPage
         * @returns {Number} - the width of the items in px
         */
        itemWidth() {
            return this.carouselWidth / this.perPage;
        },
        /**
         * Finds the id of the last visible item in the carousel
         * @returns {*} - the id of the last visible item in the carousel
         */
        lastVisible() {
            const index = ((this.dragOffset / -1) / this.itemWidth) + this.perPage - 1;
            if (index % 1 !== 0) {
                return null;
            }
            if (index > this.items.length - 1) {
                return this.items[this.items.length - 1].id;
            } else if (index < 0) {
                return this.items[0].id;
            } else {
                return this.items[index].id;
            }
        },
        /**
         * Finds the id of the first visible item in the carousel
         * @returns {*} - the id of the first visible item in the carousel
         */
        firstVisible() {
            const index = (this.dragOffset / -1) / this.itemWidth;
            if (index % 1 !== 0) {
                return null;
            }
            if (this.dragOffset === 0 || index < 0) {
                return this.items[0].id;
            } else if (index > this.items.length) {
                return this.items[this.items.length - 1].id;
            } else {
                return this.items[index].id;
            }
        },
        /**
         * calculates if the carousel has enough items in it to move it forwards
         * @returns {Boolean} - can the carousel be moved forward
         */
        canForward() {
            return Math.abs(this.dragOffset - this.carouselWidth) < this.items.length * this.itemWidth;
        },
        /**
         * calculates if the carousel has enough items in it to move it backwards
         * @returns {Boolean} - can the carousel be moved backwards
         */
        canBackward() {
            return this.dragOffset < 0 && this.itemLength > this.perPage;
        },
        /**
         * @returns {Number} - the number of items in the items prop
         */
        itemLength() {
            return this.items.length;
        }
    },
    watch: {
        itemLength() {
            this.onDragEnd();
        }
    },
    mounted() {
        if ((this.isTouch && this.touchDrag) || this.mouseDrag) {
            this.$refs["carousel"].addEventListener(
                this.isTouch ? "touchstart" : "mousedown",
                this.onDragStart
            );
        }
        this.getCarouselWidth();
        window.addEventListener(
            "resize",
            this.getCarouselWidth
        );
        this.$nextTick(() => this.getCarouselWidth());
    },
    beforeDestroy() {
        // cleanup all event listeners
        window.removeEventListener(
            this.isTouch ? "touchend" : "mouseup",
            this.onDragEnd,
            true
        );
        document.removeEventListener(
            this.isTouch ? "touchmove" : "mousemove",
            this.onDrag,
            true
        );
        window.removeEventListener(
            "resize",
            this.getCarouselWidth
        );
        this.$refs["carousel"].removeEventListener(
            this.isTouch ? "touchstart" : "mousedown",
            this.onDragStart
        );
    },
    methods: {
        /**
         * @function move
         * @description moves the carousel forwards and backwards by one item depending on the value passed
         * @public
         * @param {String} direction - forward/backward the direction in which the carousel should be moved
         * @returns {void} - no return value
         */
        move(direction) {
            if (direction === "forward") {
                this.dragOffset -= this.itemWidth;
            } else if (direction === "backward") {
                this.dragOffset += this.itemWidth;
            }
            this.changePage(direction);
            this.offset = this.dragOffset;
        },
        /**
         * @function getCarouselWidth
         * @description sets the width of the carousel from the dom, and call onDragEnd to correct the position on the
         * carousel
         * @returns {void} - no return value
         */
        getCarouselWidth() {
            this.carouselWidth = this.$refs.carousel.clientWidth;
            this.onDragEnd();
        },
        /**
         * @function changePage
         * @description emits a change page event
         * @param {String} direction - the direction the page is changing
         * @returns {void} - no return value
         */
        changePage(direction) {
            /**
             * Change page event
             * emits everytime the carousel has been moved forward or backward hook into these events if you need to
             * load additional items from an API
             *
             * @event forward
             * @event backward
             * @param {object} page - details about the page
             * @param {*} firstVisible - the id of the first item visible on the left of the carousel
             * @param {*} lastVisible - the id of the last item visible on the right of the carousel
             */
            this.$emit(direction, {
                firstVisible: this.firstVisible,
                lastVisible: this.lastVisible
            });
        },
        /**
         * @function onDragStart
         * @description checks if the user left clicked, adds event listeners for the mouseup/touchend and
         * touchmove/mousemove events and stores the start location of the drag
         * @param {object} e - mousedown/touchstart event
         * @returns {void} - no return value
         */
        onDragStart(e) {
            if (e.button === 2) {
                return;
            }
            e.preventDefault();

            this.dragging = true;

            document.addEventListener(
                this.isTouch ? "touchmove" : "mousemove",
                this.onDrag,
                true
            );

            document.addEventListener(
                this.isTouch ? "touchend" : "mouseup",
                this.onDragEnd,
                true
            );

            this.dragStartX = this.isTouch ? e.touches[0].clientX : e.clientX;
            this.dragStartY = this.isTouch ? e.touches[0].clientY : e.clientY;
        },
        /**
         * @function onDragEnd
         * @description removes the mousemove/touchmove and mouseup/touchend event listeners calculates the new position
         * of the carousel, and calls the change page function
         * @returns {void}
         */
        onDragEnd() {
            document.removeEventListener(
                this.isTouch ? "touchmove" : "mousemove",
                this.onDrag,
                true
            );

            this.dragging = false;

            document.removeEventListener(
                this.isTouch ? "touchend" : "mouseup",
                this.onDragEnd,
                true
            );

            // set the dragOffset to the nearest full item width
            this.dragOffset = Math.round(this.dragOffset / this.itemWidth) * this.itemWidth;

            if (this.dragOffset > 0 || this.itemLength < this.perPage) {
                // if the offset is greater than 0 we have dragged past the start so set the offset to 0
                // if there are less items than the perPage value we need to keep the carousel at the start
                this.dragOffset = 0;
            } else if (Math.abs(this.dragOffset) > ((this.itemWidth * this.itemLength) - this.carouselWidth)) {
                // if the dragOffset is greater than the total items in the carouse minus the carousel width we need to
                // set the dragoffset to the end of the items
                this.dragOffset = -((this.itemWidth * this.itemLength) - this.carouselWidth);
            }

            if (this.dragOffset > this.offset) {
                this.changePage("backward");
            } else if (this.dragOffset < this.offset) {
                this.changePage("forward");
            }

            this.offset = this.dragOffset;
            this.dragStartX = 0;
            this.dragStartY = 0;
        },
        /**
         * @function onDrag
         * @description moves the carousel whilst dragging it, preventing it from over dragging in either direction
         * @param {object} e - mousemove/touchmove event
         * @returns {void} - no return value
         */
        onDrag(e) {
            let newOffsetX = this.dragStartX - (this.isTouch ? e.touches[0].clientX : e.clientX);
            let newOffsetY = this.dragStartY - (this.isTouch ? e.touches[0].clientY : e.clientY);

            if (this.isTouch && Math.abs(newOffsetX) < Math.abs(newOffsetY)) {
                // if on a touch screen only drag if the horizontal drag is greater then the vertical, this prevents
                // accidental drags
                return;
            }

            if (!this.canBackward && newOffsetX < -(this.itemWidth / 4) + this.offset) {
                // if we cannot drag backwards cap the dragging to a quater of an item
                newOffsetX = -(this.itemWidth / 4) + this.offset;
            } else if (
                !this.canForward &&
                this.itemLength < this.perPage &&
                newOffsetX > (this.itemWidth / 4) + this.offset
            ) {
                // if we cannot drag forwards and the carousel is not full cap the dragging at a quater of an item from
                // the start of the carousel
                newOffsetX = (this.itemWidth / 4) + this.offset;
            } else if (
                !this.canForward &&
                this.itemLength >= this.perPage &&
                newOffsetX > ((this.itemLength - this.perPage) * this.itemWidth) + (this.itemWidth / 4) + this.offset
            ) {
                // if we cannot drag forward and the carousel is full cap the dragging at a quater of an item from the
                // end of the carousel
                newOffsetX = ((this.itemLength - this.perPage) * this.itemWidth) + (this.itemWidth / 4) + this.offset;
            }

            this.dragOffset = (newOffsetX / -1) + this.offset;
        }
    }
};
</script>

<style lang="sass" scoped>
.VueCarousel 
    display: flex
    flex-direction: column
    position: relative
    &-wrapper 
        position: relative
        overflow: hidden
    &-busy
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background-color: rgba(128, 128, 128, 0.75)
    &-inner 
        display: flex
        flex-direction: row
        backface-visibility: hidden
    &-forward, &-back
        background: $white
        position: absolute
        bottom: 15px
        color: $green
        left: -30px
        top: 0px
        border: 0
        width: 30px
        &:hover, &:focus
            background: lighten($lightGrey, 2%)
        &:disabled
            background: $white  
            color: #ccc
    &-forward 
        right: -30px
        left: auto
</style>
