/**
 * UserPilot In-app experience module
 */
import { Userpilot } from "userpilot";
/**
 * Initializes the in-app experience module
 * @param {String} req
 */
const initialize = (appToken) => Userpilot.initialize(appToken);

/**
 * Track userpilot events 
 * @param {String} id User's userpilot id
 * @param {String} eventName name of the event
 * @param {Object} meta event details
 */
const track = async (id, eventName, meta) => {
    Userpilot.track(id, eventName, meta);
};

/**
 * Identifies the user/company with the in-app experience module
 * @param {Object} details User and company details
 */
const identify = async (
    { 
        id, 
        name = null, 
        email = null, 
        brandId = null, 
        brandName = null, 
        company = null, 
        created_at: createdAt = null
    }
) => {
    const parameters = {
        name,
        email,
        brandId,
        brandName,
        company,
        createdAt
    };
    Userpilot.identify(id, parameters);
    track(id, "selected_brand", { brandId, brandName });
};

/**
 * Reload on page change
 */
const reload = () => Userpilot.reload();

const createUserpilotId = (providerId, userId) => providerId + "-" + userId; 

export default {
    initialize,
    identify,
    reload,
    createUserpilotId,
    track
};
