import schemeService from "../../../services/scheme";
import ExpressionFactory from "../../../expression/ExpressionFactory";
import Expression from "../../../expression/Expression";
import ExpressionTree from "../../../expression/ExpressionTree";
import testing from "./testing";
import Vue from "vue";

export const types = {
    //lookuptable expression management
    "LOOKUPTABLE_UPDATE":"LOOKUPTABLE_UPDATE",
    "LOOKUPTABLE_UPDATE_HEADINGS":"LOOKUPTABLE_UPDATE_HEADINGS",
    "LOOKUP_TABLE_NULL_MATCHES": "LOOKUP_TABLE_NULL_MATCHES",
    //lookup expression management
    "LOOKUP_CREATE":"LOOKUP_CREATE",
    "LOOKUP_UPDATE_CELL":"LOOKUP_UPDATE_CELL",
    "LOOKUP_INSERT_COLUMN":"LOOKUP_INSERT_COLUMN",
    "LOOKUP_UPDATE_COLUMN":"LOOKUP_UPDATE_COLUMN",
    "LOOKUP_UPDATE_AGGREGATE":"LOOKUP_UPDATE_AGGREGATE",
    "LOOKUP_DELETE_ROW":"LOOKUP_DELETE_ROW",
    "LOOKUP_DELETE_COLUMN":"LOOKUP_DELETE_COLUMN",
    "LOOKUP_INSERT_ROW":"LOOKUP_INSERT_ROW",
    "LOOKUP_MOVE_ROW":"LOOKUP_MOVE_ROW",
    "LOOKUP_MOVE_COLUMN":"LOOKUP_MOVE_COLUMN",
    "LOOKUP_MOVE_ROW_BY_ONE":"LOOKUP_MOVE_ROW_BY_ONE",
    "LOOKUP_MOVE_COLUMN_BY_ONE":"LOOKUP_MOVE_COLUMN_BY_ONE",
    "UPDATE_EXPRESSION_RETURNS": "UPDATE_EXPRESSION_RETURNS",
    //expression management - general - works for all expressions
    "ADD_EXPRESSION": "ADD_EXPRESSION",
    "DELETE_EXPRESSION": "DELETE_EXPRESSION",
    "SET_SELECTED_EXPRESSION": "SET_SELECTED_EXPRESSION",
    "SET_ALL_EXPRESSIONS": "SET_ALL_EXPRESSIONS",
    "UPDATE_EXPRESSION": "UPDATE_EXPRESSION",
    "UPDATE_EXPRESSION_POSITION": "UPDATE_EXPRESSION_POSITION",
    //general scheme managment
    "SET_LOADING": "SET_LOADING",
    "SET_ERROR": "SET_ERROR",
    "SET_SAVED":"SET_SAVED",
    "SET_SCHEME": "SET_SCHEME",
    "SET_REVISION": "SET_REVISION"
};

/**
 * Recursively finds an expression based on a given id
 * @param {Array} expressions An array of expressions
 * @param {String} id The id of the expression
 * @param {Array} [parent=null] the parent of the element
 * @param {Boolean} [detailed=false] allows you to returned detailed information about
 * the element including parent, containing block and element
 * @returns {Expression|null} - the found expression or null if non found
 */
const _getCurrentExpression = function(expressions,id,parent=null,detailed=false){
    for(let exp of expressions){
        let found = null;
        if (exp._expId === id || exp.id === id){
            if(!detailed){
                found = exp;
            }else{
                found = {
                    expression:exp, //the expression we are looking for
                    block: expressions, //the block of expressions that this belongs to
                    parent
                };
            }

        }else if(exp.block){
            found = _getCurrentExpression(exp.block,id,exp,detailed);
        }
        if(found){
            return found;
        }
    }
    return null;
};


/**
 * Recursively deletes an expression based on a given id
 * @param {Array} expressions An array of expressions
 * @param {String} id The id of the expression
 */
const _deleteCurrentExpression = function(expressions,id){
    let deletedExp = null;
    for(let i = 0; i <expressions.length; i++){
        if (expressions[i]._expId === id){
            return expressions.splice(i,1);
        }else if(expressions[i].block){
            deletedExp =  _deleteCurrentExpression(expressions[i].block,id);
            if(deletedExp){
                return deletedExp;
            }
        }
    }
    return deletedExp;
};

/**
 * Recursively collects the name (id's) of expressions
 * @param {Array} expressions An array of expressions
 */
const _getExpressionIdentifiers = function(expressions, parentIdentifier = ""){
    let identifiers = [];
    for(let exp of expressions) {
        identifiers = [
            ...identifiers,
            ...Expression.getPropertyIdentifiers(exp, parentIdentifier)];
        if (exp.block) {
            if (exp.nestedIds) {
                parentIdentifier = `${parentIdentifier}${parentIdentifier ? "." : ""}${exp.id}`;
            }
            identifiers = [
                ...identifiers,
                ..._getExpressionIdentifiers(exp.block, parentIdentifier)
            ];
        }
    }
    return identifiers;
};

/**.
 * Adds a new expression to a list of expressions
 * @param {array} expression the list of expressions
 * @param {object} expression the expression to insert
 * @param {string} [selectedExpressionID=null] the selected expression id
 * @param {boolean} [isSubExpression=false] should the new expression be added as a sub expression of the currently
 * selected expression
 * @param {Object} parent the parent of a blocl
 */
const _addNewExpression = function(expressions, expression, selectedExpressionID, parent, isSubExpression = false){
    if(!selectedExpressionID){
        expressions.push(expression);
        return true;
    }
    //the position in the list
    //start walking the expressions until we find the place to insert it
    for(let [i,e] of expressions.entries()){
        if(e._expId === selectedExpressionID ){
            //if this is an element that contains a block,  add it to the start
            if(isSubExpression && e.block !== undefined){
                e.block = [...e.block, expression];
                return true;
            }
            //this is not a block element so insert it into the expression array
            expressions.splice(i+1,0,expression);
            if(parent){
                //fix for reactivity and validation
                parent.block = expressions;
            }
            return true;
        }
        if(e.block !== undefined){
            //we need to expore this block soi use recursion
            let inserted = _addNewExpression(e.block, expression, selectedExpressionID, e, isSubExpression);
            if(inserted){
                return true;
            }

        }
    }
    return false;
};

/**
 * Moves an expression up or down by one
 * @param {Array} expressions
 * @param {String} selectedExpressionID
 * @param {("up"|"down")} direction
 */
const _moveExpression = function(expressions,expressionId,direction="up"){
    let {expression,parent,block} = _getCurrentExpression(expressions,expressionId,null,true);
    let fromIndex = block.findIndex(expr=>expr._expId===expression._expId);
    let toIndex = direction==="up"?fromIndex-1:fromIndex+1;
    if(toIndex <0 || toIndex >block.length-1){
        if(parent){
            //find the parents/parent
            let parentBlock = _getCurrentExpression(expressions,parent._expId,null,true);
            let parentIndex = parentBlock.block.findIndex(expr=>expr._expId===parent._expId);
            //remove from old block
            block.splice(fromIndex,1);
            //hack to get reactivity to work - could rework splice to slice.
            parent.block = [...block];
            if(direction==="up"){
                parentBlock.block.splice(parentIndex,0,expression);
            }else{
                parentBlock.block.splice(parentIndex+1,0,expression);
            }
            return;
        }
        return;
    }
    //check to see if we are moving into an elment that has a block
    if(block[toIndex].block){
        //remove from the outer block
        if(direction==="up"){
            block[toIndex].block = [...block[toIndex].block,expression];
        }else{
            block[toIndex].block = [expression,...block[toIndex].block];
        }
        block.splice(fromIndex,1);
    }else{
        let to = block[toIndex];
        let from = block[fromIndex];
        Vue.set(block,toIndex,from);
        Vue.set(block,fromIndex,to);
    }
};

const getters = {
    getExpression:(state)=>(id, detailed=false)=> _getCurrentExpression(
        state.current.expressions,
        id,
        null,
        detailed),
    getCurrentExpression: (state) => {
        if(state.current){
            return _getCurrentExpression(
                state.current.expressions,
                state.selectedExpressionExpId);
        }
        return null;
    },
    getExpressionIdentifers: (state) => {
        if(state.current){
            return _getExpressionIdentifiers(state.current.expressions);
        }
        return null;
    },
    schemeValid: (state) => {
        if(state.current){
            return !state.current.expressions.some(exp=>{
                return !exp.isValid();
            });

        }
        return null;
    },
    expressionTree: (state) => {
        if (state.current && state.current.expressions) {
            return new ExpressionTree(state.current.expressions);
        } else {
            return new ExpressionTree();
        }
    }
};
// initial state
const state = {
    current:null,
    // selectedExpression: null, //the id of the currently selected expression,
    selectedExpressionExpId: null,
    loading: false,
    error: null,
    saved:true
};
// actions
//could validate on load - on change ect...
const actions = {
    uploadTestData(_, data) {
        return schemeService.uploadTestData(...data);
    },
    historicTest(_, options) {
        return schemeService.historicTest(...options);
    },
    getScheme({ commit },{schemeId,revisionId,brandURL}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        commit(types.SET_SELECTED_EXPRESSION, null);
        commit(types.SET_SCHEME,null);
        return schemeService.getScheme(schemeId,revisionId,brandURL).then((scheme) => {
            scheme.expressions = scheme.expressions.map(exp=>new ExpressionFactory(exp));
            //scheme.revision = revisionId;
            commit(types.SET_SCHEME,scheme);
            commit(types.SET_LOADING, false);
            return scheme;
        }).catch(err => {
            commit(types.SET_ERROR, err.message);
            commit(types.SET_LOADING, false);
            throw err;
        });
    },
    getSchemeForDownload({ commit, rootState }, { schemeId, revisionId }) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        return schemeService.getScheme(schemeId, revisionId, rootState.auth.selectedBrand.name_url).then((scheme) => {
            commit(types.SET_LOADING, false);
            return scheme;
        }).catch(err => {
            commit(types.SET_ERROR, err.message);
            commit(types.SET_LOADING, false);
            throw err;
        });
    },
    uploadScheme({commit,state,rootState},{comment,tags}){
        commit(types.SET_ERROR, null);
        commit(types.SET_LOADING, true);
        let strippedExpressions = state.current.expressions.map(exp => exp.clean());
        return schemeService.saveScheme(
            state.current.schemeId,
            rootState.auth.selectedBrand.name_url,
            strippedExpressions,
            comment,
            tags,
            state.current.revisionId
        ).then(({revisionId,schemeId})=>{
            commit(types.SET_REVISION, revisionId);
            commit(types.SET_LOADING, false);
            commit(types.SET_SAVED, true);
            return ({revisionId,schemeId});
        }).catch(err=>{
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR,  err.message);
            throw(new Error(err.message));
        });
    },
    //paylaod expression + index
    addExpression({commit},payload){
        let expression = new ExpressionFactory(payload.expression);
        payload.expression = expression;
        commit(types.ADD_EXPRESSION,payload);
        commit(types.SET_SAVED, false);
        commit(types.SET_SELECTED_EXPRESSION,expression._expId);
    },

    deleteExpression({commit},id){
        commit(types.SET_SAVED, false);
        commit(types.DELETE_EXPRESSION,id);
        commit(types.SET_SELECTED_EXPRESSION,null);
    },
    //general update - will update any property with a given value
    updateExpression({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.UPDATE_EXPRESSION,payload);
    },

    updateExpressionPosition({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.UPDATE_EXPRESSION_POSITION,payload);
    },

    setSelectedExpression({commit},payload){
        commit(types.SET_SELECTED_EXPRESSION,payload);
    },

    //manage lookuptable tables
    updateLookupTableExpression({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUPTABLE_UPDATE,payload);
    },
    updateLookupTableHeading({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUPTABLE_UPDATE_HEADINGS,payload);
    },
    updateLookupTableNullMatches({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUP_TABLE_NULL_MATCHES,payload);
    },

    //manage lookup tables
    lookupUpdateCell({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUP_UPDATE_CELL,payload);
    },

    lookupInsertColumn({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUP_INSERT_COLUMN,payload);
    },

    lookupUpdateColumn({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUP_UPDATE_COLUMN,payload);
    },

    lookupUpdateAggregate({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUP_UPDATE_AGGREGATE,payload);
    },

    lookupDeleteRow({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUP_DELETE_ROW,payload);
    },

    lookupDeleteColumn({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUP_DELETE_COLUMN,payload);
    },

    lookupInsertRow({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUP_INSERT_ROW,payload);
    },

    lookupMoveRowByOne({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUP_MOVE_ROW_BY_ONE,payload);
    },
    lookupMoveColumnByOne({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUP_MOVE_COLUMN_BY_ONE,payload);
    },
    lookupCreate({commit},payload){
        commit(types.SET_SAVED, false);
        commit(types.LOOKUP_CREATE,payload);
    },

    updateExpressionReturns({commit},payload){
        commit(types.UPDATE_EXPRESSION_RETURNS, payload);
    },

    testDataCreate({ rootState }, payload) {
        return schemeService.createTestData(payload, rootState.auth.selectedBrand.name_url);
    },

    testDataGet({ rootState }, payload) {
        return schemeService.getTestData(payload, rootState.auth.selectedBrand.name_url);
    }
};

// mutations
const mutations = {
    [types.SET_SAVED](state,payload){
        state.saved = payload;
    },

    [types.SET_REVISION](state, payload) {
        state.current.revisionId = payload;
    },

    [types.SET_SCHEME](state, scheme) {
        state.current = scheme;
    },

    [types.SET_ALL_EXPRESSIONS](state, val) {
        state.current.expressions = val;
    },

    [types.UPDATE_EXPRESSION](state,payload) {
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp[payload.property] = payload.data;
        }
        if(payload.property==="id"){
            state.current.expressions.forEach(exp=>exp.validateProperties());
        }
    },

    [types.UPDATE_EXPRESSION_RETURNS](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.updateReturns(payload.data.type, payload.data.default);
        }
    },

    [types.DELETE_EXPRESSION](state,id){
        let exp = _getCurrentExpression(state.current.expressions, id, null, true);
        _deleteCurrentExpression(state.current.expressions,id);
        if(exp.parent) {
            exp.parent.hasMoved();
        }
    },

    [types.UPDATE_EXPRESSION_POSITION](state,payload) {
        if(payload.direction){
            _moveExpression(state.current.expressions,payload.id,payload.direction);
            let exp = _getCurrentExpression(state.current.expressions, payload.id);
            // calls the hasMoved method triggers a "reload" of the state and provides more
            // instant validation for the scope validator.
            exp.hasMoved();
        }
    },

    [types.ADD_EXPRESSION](state,payload){
        _addNewExpression(
            state.current.expressions,
            payload.expression,
            state.selectedExpressionExpId,
            null,
            payload.subExpression
        );
    },

    [types.SET_SELECTED_EXPRESSION](state,payload){
        state.selectedExpressionExpId = payload;
    },

    [types.SET_LOADING](state, loading) {
        state.loading = loading;
    },

    [types.SET_ERROR](state, error) {
        state.error = error;
    },

    [types.LOOKUPTABLE_UPDATE](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp["tableName"] = payload.data;
            //update the types and column names
            let table = _getCurrentExpression(state.current.expressions,payload.data);
            if(!table || table.expressionType!=="table"){
                //we dont have a table so we cant get the headings
                return;
            }

            exp["mappedHeadings"] = table.columns.map((item)=>item.name);
            exp["mappedReturns"] = new Array(exp["mappedHeadings"].length).fill("true",exp["mappedHeadings"].length-1);
            exp["types"] = [...table.types];
            exp["headings"] = [...exp["mappedHeadings"].slice(0,exp["mappedHeadings"].length-1),"returns"];
            exp["nullMatches"] = exp["mappedHeadings"].length >= 2
                ? Array(exp["mappedHeadings"].length-1).fill(false)
                : [];

            exp.inferReturnType();
        }
    },

    [types.LOOKUPTABLE_UPDATE_HEADINGS](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.headings = [...exp.headings.slice(0,payload.index),payload.data,...exp.headings.slice(payload.index+1)];
        }
    },

    [types.LOOKUP_TABLE_NULL_MATCHES](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.nullMatches = [...exp.nullMatches.slice(0,payload.index),
                payload.match,
                ...exp.nullMatches.slice(payload.index+1)];
        }
    },

    [types.LOOKUP_UPDATE_CELL](state,payload){

        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.setCell(payload.row,payload.column,payload.data);
        }
    },

    [types.LOOKUP_CREATE](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.create(payload.data,payload.headings, payload.types, payload.nullMatches);
        }
    },

    [types.LOOKUP_INSERT_COLUMN](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.insertColumn(
                payload.index,
                payload.identifier,
                payload.type,
                payload.isReturn,
                payload.width,
                payload.isObjectColumn,
                payload.nullMatch
            );
        }
    },

    [types.LOOKUP_UPDATE_COLUMN](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.updateColumn(
                payload.index,
                payload.identifier,
                payload.type,
                payload.isReturn,
                payload.width,
                payload.isObjectColumn,
                payload.nullMatch
            );
        }
    },

    //This is seperate from update expression as this has consequences
    //it needs to make changes as well as just update. For example, if
    //the user chooses all - the return type chnages to array
    [types.LOOKUP_UPDATE_AGGREGATE](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.updateAggregate(payload.aggregate);
        }
    },

    [types.LOOKUP_DELETE_ROW](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.deleteRow(payload.index);
        }
    },

    [types.LOOKUP_DELETE_COLUMN](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.deleteColumn(payload.index);
        }
    },

    [types.LOOKUP_INSERT_ROW](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.insertRow(payload.index,payload.cellID);
        }
    },

    [types.LOOKUP_MOVE_ROW_BY_ONE](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.moveRowByOne(payload.index,payload.direction);
        }
    },

    [types.LOOKUP_MOVE_COLUMN_BY_ONE](state,payload){
        let exp = _getCurrentExpression(state.current.expressions,payload.id);
        if(exp){
            exp.moveColumnByOne(payload.index,payload.direction);
        }
    },

    //requests
    [types.REQUEST_UPDATE](state,payload){
        let exp = _getCurrentExpression(state.current.expressions, payload.id);
        if(exp){
            exp.returns = payload.returns;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules: {
        testing
    }
};
