import testModeServices from "../../services/testMode";
import auditServices from "../../services/audit";

export const types = {
    "SET_TEST_MODE": "SET_TEST_MODE",
    "GET_TEST_JOURNEYS_D2C": "GET_TEST_JOURNEYS_D2C"
};

const state = {
    testing: false,
    testJourneysD2C: []
};

const actions = {
    async setTestMode({commit, rootState}, { value, audit = true }) {
        try {
            const brand = rootState.auth.selectedBrand.name_url;
            const payload = {
                eventType: "Test Mode",
                event: {toggle: value ? "ON" : "OFF", brand},
                userId: rootState.auth.user.userID
            };
            if (audit) {
                await auditServices.postAuditableEvent(brand, payload);
            }
        } catch {
            // ignore error
        } finally {
            commit(types.SET_TEST_MODE, value);
        }
    },
    async getTestJourneysD2C({commit, rootState}) {
        const { name_url: brandUrl } = rootState.auth.selectedBrand;
        const { data } = await testModeServices.getTestJourneysD2C(brandUrl);
        commit(types.GET_TEST_JOURNEYS_D2C, data);
    }
};

const mutations = {
    [types.SET_TEST_MODE]: (state, value) => (state.testing = value),
    [types.GET_TEST_JOURNEYS_D2C]: (state, journeys) => (state.testJourneysD2C = journeys)
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
