import reportService from "../../services/reports";

function errGetter(err) {
    if (err.response) {
        return err.response.data.message;
    } else {
        return err.message;
    }
}

const state = {
    aRevision: null,
    aRevisionPromise: null,
    loadingRevison: false,
    errorRevision: null
};

const getters = {
};

const mutations = {
    SET_A_REVISION(state, val) {
        state.aRevision = val;
    },
    SET_A_REVISION_PROMISE(state, val) {
        state.aRevisionPromise = val;
    },
    SET_LOADING_REVISION(state, val) {
        state.loadingRevision = val;
    },
    SET_ERROR_REVISION(state, val) {
        state.errorRevision = val;
    }
};

const actions = {
    fetchARevision(
        { commit, state, rootState },
        { revisionId, reportId }
    ) {
        commit("SET_LOADING_REVISION", true);
        if (state.aRevisionPromise !== null) {
            return state.aRevisionPromise;
        }
        let fetching = reportService.fetchARevision({
            brandURL: rootState.auth.selectedBrand.name_url,
            revisionId,
            reportId
        }).then((response) => {
            commit("SET_A_REVISION", response.data);
            return response.data;
        }).catch((err) => {
            commit("SET_ERROR_REVISION", errGetter(err));
            throw err;
        }).finally(() => {
            commit("SET_A_REVISION_PROMISE", null);
            commit("SET_LOADING_REVISION", false);
        });
        commit("SET_A_REVISION_PROMISE", fetching);
        return fetching;
    },
    async createARevision(
        { commit, rootState },
        { revision, reportId }
    ) {
        try {
            const result = await reportService.postARevision({
                brandURL: rootState.auth.selectedBrand.name_url,
                reportId: reportId,
                payload: revision});
            return result;
        } catch (error) {
            commit("SET_ERROR_REVISION", errGetter(error));
            throw error;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
