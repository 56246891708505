<template>
    <div class="sidebar-provider-navigation">
        <SidebarNavigationItem
            :to="{ name: 'provider' }"
            name="Administration"
            :icon="['fal', 'door-open']"
            :is-collapsed="isCollapsedSideBar"
            class="navigation-item"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import SidebarNavigationItem from "./SidebarNavigationItem.vue";

export default {
    name: "SidebarProviderNavigation",
    components: {
        SidebarNavigationItem
    },
    computed: {
        ...mapState({
            isCollapsedSideBar: (state) => state.application.isCollapsedSideBar
        })
    }
};
</script>

<style lang="sass" scoped>
.sidebar-provider-navigation
    padding: 4px 0
    border-bottom: 1px solid rgba(255, 255, 255, 0.3)
</style>
