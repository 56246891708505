<template>
    <div 
        v-if="customPolicyNumbers && policyNumberCount <= lowTidemark"
        :class="`banner ${hideBanner ? 'hideBanner' : ''}`"
        @click="handleHideBanner"
    >
        {{ 
            `There are currently only ${policyNumberCount} custom policy numbers remaining.
            ${$can("edit", "admin") ? "Click here to add more." : "Please inform an administrator."}` 
        }}
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    props: {
        delayMS: {
            type: Number,
            required: false,
            default: 600000
        }
    },
    data() {
        return {
            running: false,
            hideBanner: false
        };
    },
    computed: {
        ...mapState({
            customPolicyNumbers: state => state.admin.customPolicyNumbers,
            policyNumberCount: state => state.admin.customPolicyNumberCount,
            lowTidemark: state => state.admin.customPolicyLowTidemark
        })
    },
    mounted() {
        this.running = true;
        this.checkCustomPolicyNumbersHealth();
    },
    unmounted() {
        this.running = false;
    },
    methods: {
        async checkCustomPolicyNumbersHealth() {
            while (this.running) {        
                await this.$store.dispatch("admin/getPolicyNumberingMethod");
                await this.$store.dispatch("admin/getLastPolicyNumber");
                if (this.customPolicyNumbers && this.policyNumberCount <= this.lowTidemark) {
                    this.hideBanner = false;
                }
                await new Promise(resolve => {
                    setTimeout(resolve, this.delayMS);
                });
            }
        },
        handleHideBanner() {
            this.hideBanner = true;
            if (this.$can("edit", "admin") && this.$route.name !== "policy-numbers") {
                this.$router.push({ name: "policy-numbers" });
            }
        }
    }
};
</script>

<style lang="sass" scoped>
.banner
    cursor: pointer
    animation: 1.5s linear 1s moveInLeft
    animation-fill-mode: both
    position: absolute
    top: 0
    left: 0
    width: 100%
    z-index: 1000
    padding: 20px
    font-size: 1rem
    text-align: center
    color: darken($secondary, 30%)
    background: rgba(lighten($secondary, 30%), .7)
    border: 1px solid rgba($secondary, .8)
    border-radius: 5px

.hideBanner
    animation: 1.5s linear 1s moveOutRight
    animation-fill-mode: both

@keyframes moveInLeft
    0%
        transform: translateX(-100vw)
    100%
        transform: translate(0px)

@keyframes moveOutRight
    0%
        transform: translateX(0px)
    100%
        transform: translate(-100vw)
        visibility: hidden

</style>