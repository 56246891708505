import crmService from "../../services/crm";
const types = Object.freeze({
    RESET: "RESET",
    SET_FORM_DATA: "SET_FORM_DATA",
    SET_RESULTS_DATA: "SET_RESULTS_DATA",
    SET_QUOTE_DATA: "SET_QUOTE_DATA",
    SET_ERROR: "SET_ERROR",
    RESET_STEPPERS: "RESET_STEPPERS"
});

export const state = () => ({
    error: null,
    form: {
        discovery: {
            additional: {
                isStartDateEditable: true
            }
        },
        details: {},
        buy: {},
        success: {}
    },
    results: [],
    quote: {}
});

export const mutations = {
    [types.RESET](_state) {
        Object.assign(_state, state());
    },
    [types.SET_ERROR](state, error) {
        state.error = error;
    },
    [types.SET_FORM_DATA](state, { payload, page }) {
        state.form[page] = payload;
    },
    [types.SET_RESULTS_DATA](state, results) {
        state.results = results;
    },
    [types.SET_QUOTE_DATA](state, quote) {
        state.quote = quote;
    },
    [types.RESET_STEPPERS](state) {
        for (const page in state.form) {
            const form = state.form[page] ?? {};
            const steppers = Object.keys(form).filter(k => k.startsWith("stepper_"));

            steppers.forEach((stepper) => {
                form[stepper] = 0;
            });
        }
    }
};

export const actions = {
    async adjust({ dispatch}, { data, params }) {
        dispatch("setError", null);
        await crmService.adjust(data, params);
    },
    reset({ commit }) {
        commit("RESET");
    },
    setError({ commit }, payload) {
        commit("SET_ERROR", payload);
    },
    setFormData({ commit }, payload) {
        commit("SET_FORM_DATA", payload);
    },
    resetSteppers({ commit }) {
        commit("RESET_STEPPERS");
    },
    // eslint-disable-next-line camelcase
    async setMarketingPreferences({ commit, rootState }, { config, email, policy_reference, preferences }) {
        try {
            commit("SET_ERROR", null);
            await crmService.saveMarketingPreferences(
                // eslint-disable-next-line max-len
                `${config.apiUrl}/public/provider/${rootState.auth.provider}/brand/${rootState.auth.selectedBrand.name_url}/customer/marketing-preferences`,
                {
                    // eslint-disable-next-line camelcase
                    policy_reference,
                    email,
                    preferences
                }
            );
        } catch (error) {
            commit("SET_ERROR", error);
        }
    },
    async setResults({ commit, dispatch, rootState }, { 
        page, 
        url, 
        renewalReference, 
        previousPolicyEndDate, 
        previousPolicyType,
        previousTaxPaid,
        previousPolicyReference
    }) {
        try {
            dispatch("setError", null);
            const discoveryFormData = rootState.data.form[page];
            delete discoveryFormData.additional;
            /* eslint-disable camelcase */
            discoveryFormData.renewal_reference = renewalReference;
            discoveryFormData.previous_policy_end_date = previousPolicyEndDate;
            discoveryFormData.previous_policy_type = previousPolicyType;
            discoveryFormData.previous_tax_paid = previousTaxPaid;
            discoveryFormData.previous_policy_reference = previousPolicyReference;
            /* eslint-enable camelcase */

            const res = await crmService.fetchResults(url, discoveryFormData);

            if(res?.data?.response?.quotes?.length) {
                commit("SET_RESULTS_DATA", res.data.response.quotes);
            } else {
                commit("SET_RESULTS_DATA", []);
            }
            if(res?.data?.response?.errors?.length) {
                dispatch("setError",
                    res?.data?.response?.errors.map((error) =>
                        `${error.policy_type}  ${error.error.message || error.error}`));
            }
            // fallback
            if(!res?.data?.response?.quotes?.length && !res?.data?.response?.errors?.length) {
                dispatch("setError", "Missing quotes");
            }
        } catch (error) {
            dispatch("setError", error);
        }
    },
    async setQuote({ commit, rootState }, { config, q, qr, type }) {
        try {
            commit("SET_ERROR", null);
            const res = await crmService.fetchQuote(
                `${config.apiUrl}/payments/${rootState.auth.provider}/${q}/?qr=${qr}&type=${type}`);

            commit("SET_QUOTE_DATA", res?.data);
        } catch (error) {
            commit("SET_ERROR", error);
        }
    },
    async purchase({ commit, dispatch }, { data, params }) {
        dispatch("setError", null);
        const response = await crmService.purchase(data, params);
        commit("SET_FORM_DATA", { payload: response.data, page: "success" });
        const payload = {...params, policyID: response.data.policyNumber};

        await dispatch("crm/getPolicy", payload, {root:true});
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};

