<template>
    <div
        id="app"
    >
        <b-overlay
            blur="1rem"
            class="h-100 w-100"
            :show="expired || shownOverlay"
            @shown="shownOverlay = true"
        >
            <router-view />
            <template #overlay>
                <b-card
                    body-class="p-0"
                >
                    <div
                        class="dashboard login-container"
                    >
                        <form
                            class="form-signin"
                        >
                            <a
                                class="icon logout-btn bg-danger"
                                @click="logout"
                            >
                                <font-awesome-icon
                                    :icon="['fal', 'sign-out-alt']"
                                />
                                <span
                                    class="d-none"
                                >
                                    Logout
                                </span>
                            </a>
                            <img
                                class="Asanto"
                                src="./assets/asanto-white.svg"
                                alt
                            >
                            <p
                                v-if="showLogin"
                            >
                                Your session has expired.
                                <br>
                                Please reauthenticate to continue.
                            </p>
                            <LoginForm
                                v-if="showLogin"
                                :provider="$store.state.auth.provider"
                                @loggedIn="loggedIn"
                                @twoFactor="twoFactor"
                                @twoFactorSetup="twoFactorSetup"
                                @forgot="forgot"
                            />
                            <TwoFactorSetup
                                v-else-if="showTwoFactorSetup"
                                @loggedIn="loggedIn"
                                @forgot="forgot"
                            />
                            <TwoFactor
                                v-else-if="showTwoFactor"
                                @loggedIn="loggedIn"
                            />
                        </form>
                    </div>
                </b-card>
            </template>
        </b-overlay>
    </div>
</template>
<script>
export default {
    name: "App",
    components: {
        LoginForm: () => import("./components/auth/Login"),
        TwoFactor: () => import("./components/auth/TwoFactor"),
        TwoFactorSetup: () => import("./components/auth/TwoFactorSetup")
    },
    data() {
        return {
            showLogin: true,
            showTwoFactor: false,
            showTwoFactorSetup: false,
            shownOverlay: false
        };
    },
    computed: {
        expired() {
            return this.$store.state.auth.tokenExpired;
        }
    },
    methods: {
        forgot() {
            this.$store.dispatch("auth/logout", false);
            this.$router.push({ name: "forgotPassword" });
            this.shownOverlay = false;
        },
        twoFactorSetup() {
            this.showLogin = false;
            this.showTwoFactorSetup = true;
            this.showTwoFactor = false;
        },
        loggedIn() {
            this.showLogin = true;
            this.showTwoFactor = false;
            this.showTwoFactorSetup = false;
            this.shownOverlay = false;
        },
        twoFactor() {
            this.showLogin = false;
            this.showTwoFactorSetup = false;
            this.showTwoFactor = true;
        },
        logout() {
            this.$store.dispatch("auth/logout", true);
        }
    }
};
</script>

<style lang="sass">
    @import 'styles/styles.sass'
</style>

<style scoped>
.logout-btn {
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: 0.5rem;
    z-index: 1;
    cursor: pointer;
    color: white;
}
.logout-btn > svg {
    transform: rotate(180deg);
}
</style>

