<template>
    <div>
        <b-form-group
            v-if="showFilter"
            :state="dataLocalIsEmpty"
        >
            <b-form-input
                v-model="text"
                type="search"
                placeholder="Start typing to search for an item"
                @input="filterData($event)"
            />
            <b-form-invalid-feedback v-if="dataLocalIsEmpty">
                Cannot find {{ text ? text : "item" }}, please try again.
            </b-form-invalid-feedback>
        </b-form-group>
        <ul>
            <base-item-display
                v-for="(value, item, key) in dataLocal"
                :key="key"
                :item="item"
                :value="value"
            />
        </ul>
    </div>
</template>

<script>
/**
 * Turns JSON data into pretty format and allows filtering. 
 */
export default {
    components: {
        BaseItemDisplay: () => import("./BaseItemDisplay.vue")
    },
    props: {
        data: {
            type: [Object, Array],
            default: () => {
                return {};
            } 
        },
        showFilter: {
            type: Boolean,
            default: true
            
        }
    },
    data() {
        return {
            dataLocal: this.data,
            text: ""
        };
    },
    computed: {
        dataLocalIsEmpty() {
            return Object.keys(this.dataLocal).length === 0;
        }
    },
    methods: {
        filterData(value) {
            if (value.length >= 3) {
                const items = {};
                for (let i in this.dataLocal) {
                    if (i.toLowerCase().includes(value.toLowerCase())) {
                        items[i] = this.dataLocal[i];
                    }
                }
                this.dataLocal = items;
            } else {
                this.dataLocal = this.data;
            }
        }
    }
    
};
</script>