import schemesService from "../../services/schemes";
import schemeService from "../../services/scheme";

export const types = {
    "SET_SCHEME": "SET_SCHEME",
    "SET_SCHEMES": "SET_SCHEMES",
    "SET_LOADING": "SET_LOADING",
    "SET_ERROR": "SET_ERROR",
    "SET_SCHEME_REVISIONS": "SET_SCHEME_REVISIONS",
    "UPDATE_ARCHIVED":"UPDATE_ARCHIVED"
};

const getters = {

};
// initial state
const state = {
    all: [],
    archived:[],
    noArchived: [],
    loading: false,
    error: null,
    schemeRevisions: []
};
// actions
const actions = {
    getAllSchemes({ commit, rootState }, options = {}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        schemesService.getSchemes(rootState.auth.selectedBrand.name_url, options.archived)
            .then((schemes) => {
                commit(types.SET_SCHEMES, schemes.data);
                commit(types.SET_LOADING, false);
            }).catch(err => {
                commit(types.SET_ERROR, err.message);
                commit(types.SET_LOADING, false);
            });
    },
    postScheme({commit, rootState},{name, comment, tags, scheme = []}){
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        return schemesService.postScheme(rootState.auth.selectedBrand.name_url, name, scheme, comment, tags)
            .then((scheme) => {
                //fix as the schems route returns id and post scheme_id
                commit(types.SET_SCHEME,{...scheme.data, id:scheme.data.scheme_id});
                commit(types.SET_LOADING, false);
                return scheme.data;
            }).catch(err => {
                commit(types.SET_ERROR, err.message);
                commit(types.SET_LOADING, false);
                throw(new Error(err.message));
            });
    },
    async patchArchiveScheme({commit,rootState},{schemeId,archived}){
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try{
            await schemesService.patchSchemeArchive(rootState.auth.selectedBrand.name_url,schemeId,{archived});
            commit(types.UPDATE_ARCHIVED, {schemeId,archived});
            commit(types.SET_LOADING, false);
        }catch(err){
            //if we have a axios error
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR,  err.message);
            throw(new Error(err.message));
        }
    },
    cloneScheme({commit,rootState},{name,comment,tags,schemeId,revisionId}){
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        let brandURL = rootState.auth.selectedBrand.name_url;
        return schemeService.getScheme(schemeId,revisionId,brandURL)
            .then(scheme=>{
                return schemesService.postScheme(
                    rootState.auth.selectedBrand.name_url,
                    name,
                    scheme.expressions,
                    comment,
                    tags);
            }).then(scheme => {
                scheme.data.id = scheme.data.scheme_id;
                commit(types.SET_SCHEME,scheme.data);
                commit(types.SET_LOADING, false);
                return scheme.data;
            }).catch(err => {
                commit(types.SET_ERROR, err.message);
                commit(types.SET_LOADING, false);
                throw(new Error(err.message));
            });
    },
    async getSchemeRevisions({commit}, {schemeID, brandURL}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const result = await schemeService.getSchemeRevisions(schemeID, brandURL);
            commit(types.SET_SCHEME_REVISIONS, result.data);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
        }
    }
};

// mutations
const mutations = {
    [types.SET_SCHEME](state, scheme) {
        state.noArchived.push(scheme);
        state.all.push(scheme);
    },
    [types.SET_SCHEMES](state, schemes) {
        let archived = [];
        let noArchived = [];
        schemes.forEach(scheme=>{
            scheme.archived?archived.push(scheme):noArchived.push(scheme);
        });

        state.archived = archived;
        state.noArchived = noArchived;
        state.all = schemes;
    },
    [types.UPDATE_ARCHIVED](state, {schemeId,archived}) {
        let removedScheme = state.all.find(scheme=>schemeId===scheme.id);
        removedScheme.archived = archived;

        let archivedSchemes = [];
        let noArchivedSchemes = [];
        state.all.forEach(scheme=>{
            scheme.archived?archivedSchemes.push(scheme):noArchivedSchemes.push(scheme);
        });

        state.archived = archivedSchemes;
        state.noArchived = noArchivedSchemes;
    },
    [types.SET_LOADING](state, loading) {
        state.loading = loading;
    },
    [types.SET_ERROR](state, error) {
        state.error = error;
    },
    [types.SET_SCHEME_REVISIONS](state, revisions) {
        state.schemeRevisions = revisions;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
