import paymentService from "../../services/payments";

const state = {
    loading: false,
    error: false,
    checked: false,
    step: 0,
    contactInfo: {
        "address_line_1": "",
        "address_line_2": "",
        city: "",
        county: "",
        postcode: ""
    },
    customerData: [],
    documents: {},
    productInfo: {},
    disabled: false,
    policyNumber: "",
    purchased: false,
    lookup: false,
    addresses: [],
    find: false,
    card: false,
    clientKey: "",
    addressError: false,
    address: {},
    is3dsData: {},
    aggregatorInfo: null,
    agent: ""
};

const getters = {
    filteredProductInfo: state => {
        let products = JSON.parse(JSON.stringify(state.productInfo));
        delete products.premium;
        delete products.end_date;
        delete products.start_date;
        delete products.quote_number;
        return products;
    }
};

const actions = {
    async getQuoteInformation({ commit }, quoteParams) {
        try {
            let data = await paymentService.getQuoteData(quoteParams);
            if (data.documents.length < 0 ||
                data.productInfo.length < 0 ||
                data.travellers.length < 0 ||
                data.contactInfo.length < 0) {
                return commit("error", true);
            }
            commit("step", 0);
            commit("documents", data.documents);
            commit("productInfo", data);
            commit("customerData", data.travellers);
            commit("contactInfo", {...data.contactInfo, ...state.contactInfo});
            commit("clientKey", data.client_key);
        } catch(err) {
            commit("error", true);
            throw err;
        } finally {
            commit("loading",false);
        }
    },
    async processPayment({ commit }, payload) {
        try {
            commit("loading", true);
            commit("disabled", true);
            commit("error", false);
            // make it look like were turning the cogs to take a payment
            setTimeout(async () => {
                try {
                    let result = await paymentService.processPayment(payload);
                    commit("policyNumber", result.policyNumber);
                    commit("aggregatorInfo", result.aggregatorInfo);
                    commit("loading", false);
                    commit("step", 1);
                } catch (err) {
                    commit("loading", false);
                    commit("error", err.message);
                    commit("step", 2);
                }
            }, 3000);
        } catch (error) {
            commit("loading", false);
            commit("error", error.message);
            commit("step", 2); 
        }
    },
    setStep({ commit }, status) {
        commit("step", status);
    },

    async findAddress({commit}, payload) {
        try {
            commit("card", false);
            let res = await paymentService.findAddress(payload);
            commit("addresses", res.Items);
            commit("find", true);
        } catch (error) {
            commit("addressError", true);
        }

    },
    async buildAddress({ commit, state, dispatch }, payload) {
        try {
            let res = await paymentService.getSelectedAddress(payload);
            let items = res.Items[0];
            let custData = {
                "address_line_1": items.Line1,
                "address_line_2": items.Line2,
                city: items.City,
                county: items.Province,
                postcode: items.PostalCode
            };
            for (let i in state.contactInfo) {
                if (i === "email" || i === "telephone") {
                    custData[i] = state.contactInfo[i];
                }
            }
            
            if (payload.callType === "ep") {
                dispatch("crm/setAddress", custData, { root: true });
            } else {
                commit("contactInfo", custData);
                commit("address", custData);
            }
            commit("lookup", false);
            commit("card", true);
        } catch (err) {
            commit("addressError", true);
        }
    },
    togglePostCodeSearch({commit}, payload) {
        Object.entries(payload).forEach(p => {
            commit(p[0],p[1]);
        });
    },
    toggleAddressError({commit, state}) {
        if (state.addressError) {
            commit("addressError", false);
        }
    },
    setAgent({ commit }, agent) {
        commit("agent", agent);
    },
    clearPaymentData({ commit }) {
        commit("clearContactInfo");
        commit("clearCustomerData");
        commit("clearProductInfo");
    }
};

const mutations = {
    loading: (state, loading) => (state.loading = loading),
    quoteInformation: (state, quoteInformation) => (state.quoteInformation = quoteInformation),
    documents: (state, documents) => (state.documents = documents),
    productInfo: (state, productInfo) => (state.productInfo = productInfo),
    customerData: (state, customerData) => (state.customerData = customerData),
    contactInfo: (state, contactInfo) => (state.contactInfo = contactInfo),
    clearContactInfo: (state) => {
        state.contactInfo = {
            "address_line_1": "",
            "address_line_2": "",
            city: "",
            county: "",
            postcode: ""
        };
    },
    step: (state, step) => (state.step = step),
    checked: (state, checked) => (state.checked = checked),
    policyNumber: (state, policyNumber) => (state.policyNumber = policyNumber),
    disabled: (state, disabled) => (state.disabled = disabled),
    error: (state, error) => (state.error = error),
    lookup: (state, lookup) => (state.lookup = lookup),
    addresses: (state, addresses) => (state.addresses = addresses),
    address: (state, address) => (state.address = address),
    find: (state, find) => (state.find = find),
    card: (state, card) => (state.card = card),
    clientKey: (state, key) => (state.clientKey = key),
    addressError: (state, addressError) => (state.addressError = addressError),
    is3dsData: (state, is3dsData) => (state.is3dsData = is3dsData),
    aggregatorInfo: (state, val) => state.aggregatorInfo = val,
    agent: (state, agent) => state.agent = agent,
    clearCustomerData: (state) => state.customerData = [],
    clearProductInfo: (state) => state.productInfo = {}
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
