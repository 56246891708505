import axios from "./axios";

export default {
    /**
     * Adjust policy
     *
     * @param {Object} data
     * @param {String} params.brand
     * @param {String} params.qr
     * @param {String} params.qn
     * @param {String} policy_reference
     * @return {Promise<Object>}
     */
    adjust(data, params) {
        // eslint-disable-next-line max-len
        return axios.post(`/brands/${params.brand}/policies/${params.policy_reference}/adjustment?qr=${params.qr}
&qn=${params.qn}`, data);
    },
    calculateAdjustment(payload) {
        return axios.post(`/brands/${payload.brand}/adjustments/calculate-adjustment`, payload);
    },
    fetchCustomerData(payload) {
        return axios.get(
            `/brands/${payload.brand}/policies/${payload.policyID}/customer-data`
        );
    },
    postCallNote(payload) {
        return axios.post(`/brands/${payload.brand}/quotes/quote-notes`, payload);
    },
    saveMarketingPreferences(url, payload) {
        return axios.post(url, payload);
    },
    fetchResults(url, payload) {
        return axios.post(url, payload);
    },
    fetchQuote(url) {
        return axios.get(url);
    },
    /**
     * Purchase policy
     *
     * @param {Object} data
     * @param {String} params.brand
     * @param {String} params.qr
     * @param {String} params.qn
     * @return {Promise<Object>}
     */
    purchase(data, params) {
        return axios.post(`/brands/${params.brand}/policies/purchase?qr=${params.qr}&qn=${params.qn}`, data);
    },
    async getPoliciesByCustomerDetails(payload) {
        const url = `/brands/${payload.brand}/policies/search-customer`;
        const body = {
            params: {
                email: payload.email,
                postcode: payload.postcode,
                surname: payload.surname,
                forename: payload.forename,
                startDate: payload.startDate
            }
        };
        return axios.get(url, body);
    }
};

