
import Expression from "./Expression";
import randomKey from "random-key";
import {
    isCellValid,
    isRowsValid} from "./ExpressionValidators";


/**
 * Creates a Table Expression
 * TODO - This needs to be refactored into the LookupExpression
 * Lookuop expression can inherit from table and just add the aggregate field
 */
export default class TableExpression extends Expression {
    constructor(id){
        const properties = {
            returns:{  //this will be calculated based on the input - could this move into expression?
                value: null,
                isValid:null,
                validators:[],
                get type(){
                    return this.value.type?this.value.type:null;
                },  //getter to stop having to go into values object e.g returns{type:number}
                get default(){
                    return this.value.default?this.value.default:null;
                }  //getter to stop having to go into values object e.g returns{type:number}
            },
            expressionType:{
                value: "table",
                isValid:null,
                validators:[]
            },
            types:{
                value: null,
                isValid:null,
                validators:[]
            },
            columns:{
                value: null,
                isValid:null,
                validators:[]
            },
            rows:{
                value: null,
                isValid:null,
                validators:[
                    //This is very heavy on cpu - maybe swap?
                    isRowsValid()
                ]
            },
            output:{
                value: null,
                isValid:null,
                validators:[]
            }
        };
        super(id,properties);
        this.invalidCells = new Map();
    }

    //simple static helpers to convert data to columns and rows
    //this also adds id's to help rendering
    static rowBuilder(data,types){
        if(!Array.isArray(data)){
            return [];
        }
        return data.map(row=>({id:randomKey.generate(5),
            rowData:row.map((rowItem,i)=>{
                let id = randomKey.generate(6);
                let isReturn = i+1===row.length;
                let isValid = isCellValid().validate(rowItem,types[i],isReturn);
                return ({id,data:rowItem,dirty:false,isValid});
            })}));
    }
    static columnBuilder(headings){
        if(!Array.isArray(headings)){
            return [];
        }
        return headings.map((col)=>({name: col, width:150, return:false}));
    }

    //if we just validate all cells every update - we waste time
    //we know which cells are invalid
    setCell(rowIndex,columnIndex,data){
        let row = {...this.rows[rowIndex]};
        let cell = {...row.rowData[columnIndex]};
        //is this a returns columns
        // let isReturns = columnIndex + 1 === this.columns.length;
        cell.data = data;
        cell.dirty = true;
        cell.isValid = isCellValid(`Cell ${rowIndex,columnIndex}`).validate(data,this.types[columnIndex],false);
        if(!cell.isValid){
            this.invalidCells.set(cell.id,true);
            this.properties.rows.isValid = false;

        }else{
            this.invalidCells.delete(cell.id);
            if(this.invalidCells.size === 0){
                this.properties.rows.isValid = true;
            }
        }
        row.rowData.splice(columnIndex,1,cell);
    }

    /* Used by the import tools - wipes any data and inserts new
    */
    create(data,headings,types ){
        //TODO - we need to vaidate the input against the input type
        this.properties.types.value = types;
        this.properties.rows.value = TableExpression.rowBuilder(data,types);
        const headingNames = headings.map(heading=>heading.name?heading.name:heading);
        this.properties.columns.value = TableExpression.columnBuilder(headingNames);
        this.properties.rows.isValid = !this.properties.rows.value.some(row=>row.rowData.some(cell=>!cell.isValid));
        //update valid properties
    }

    createNewRow(){
        let row = {id:randomKey.generate(6),rowData:[]};
        for (let columnIndex = 0; columnIndex < this.columns.length; columnIndex++) {
            row.rowData.push({id:randomKey.generate(6),isValid:false, isDirty:false, data:undefined});
        }
        return row;
    }

    insertRow(index){
        this.rows = [...this.properties.rows.value.slice(0,index),
            this.createNewRow(),...this.properties.rows.value.slice(index)];
    }

    createNewCell(data=undefined){
        return ({id:randomKey.generate(6),data, isValid:false, isDirty:false});
    }

    insertColumn(index,identifier,type,isReturn,width){

        //TODO - add calculated returns..
        this.types = [...this.properties.types.value.slice(0,index),type,...this.properties.types.value.slice(index)];
        this.columns = [
            ...this.properties.columns.value.slice(0,index),
            {name:identifier,width, isReturn},
            ...this.properties.columns.value.slice(index)];
        this.rows  = this.properties.rows.value.map((row)=>({id:row.id,
            rowData:[
                ...row.rowData.slice(0,index),
                this.createNewCell(),
                ...row.rowData.slice(index)]}));
    
    }

    updateColumn(index,identifier,type,isReturn,width){
        this.types = [...this.properties.types.value.slice(0,index-1),
            type,
            ...this.properties.types.value.slice(index)];
        this.columns = [...this.properties.columns.value.slice(0,index-1),
            {name:identifier,width, isReturn}
            ,...this.properties.columns.value.slice(index)];
    }


    deleteRow(index){
        this.rows[index].rowData.forEach(cell=>this.invalidCells.delete(cell.id));
        this.rows = [...this.rows.slice(0,index),...this.rows.slice(index+1)];
    }

    deleteColumn(index){
        this.columns = [...this.properties.columns.value.slice(0,index),
            ...this.properties.columns.value.slice(index+1)];

        this.types = [
            ...this.properties.types.value.slice(0, index),
            ...this.properties.types.value.slice(index + 1)
        ];

        if(this.columns.length===0){
            this.rows = [];
        }else{
            this.rows = this.rows.map(row=>{
                this.invalidCells.delete(row.rowData[index].id);
                return {
                    id:row.id,
                    rowData:[ ...row.rowData.slice(0,index),
                        ...row.rowData.slice(index+1)]};

            });
        }
    }

    moveRowByOne(index,direction){
        let from = index;
        let [rows] = this.moveByOne(this.rows,from || 0,direction);
        this.rows = rows;
    }

    moveColumnByOne(index,direction){
        let columnFromIndex = index || 0;
        let [columns] = this.moveByOne(this.columns,columnFromIndex,direction);
        let rows = this.rows.map(row=>
            ({id:row.id,rowData:this.moveByOne(row.rowData,columnFromIndex,direction)[0]}));
        let types = this.moveByOne([...this.types],columnFromIndex,direction)[0];
        this.columns = columns,
        this.rows = rows;
        this.types = types;
    }

    moveRowToIndex(from,to){
        this.rows = this.moveToIndex(this.rows,from,to);
    }

    moveColToIndex(from,to){
        this.columns = this.moveToIndex(this.columns,from,to);
    }

    //item = row or column  array
    moveToIndex(array,from,to){
        if(to===from || to >= array.length || to < 0){
            return array;
        }
        let newArray = [];
        let itemToBeMoved = array[from];
        array.forEach((item,i) => {
            if(to < from){
                if(i===to){
                    newArray.push(itemToBeMoved);
                }
                if(i!==from){
                    newArray.push(item);
                }
            }else{
                if(i!==from){
                    newArray.push(item);
                }
                if(i===to){
                    newArray.push(itemToBeMoved);
                }
            }
        });
        return newArray;
    }

    moveByOne(array, currentPosition,direction){
        //copy row that we are dragging/moving
        //when we get to index insert into new list
        let toPosition;
        if(direction==="d" || direction==="r"){
            toPosition = currentPosition +1;

        }else{
            toPosition = currentPosition -1;
        }

        if(toPosition >= array.length || toPosition < 0){
            return [array,currentPosition];
        }

        let newArray = this.moveToIndex(array,currentPosition,toPosition);
        return [newArray,toPosition];
    }

    static cleanExpression(exp) {
        return {
            id:exp.id,
            expressionType:exp.expressionType,
            headings:exp.columns.map(heading=>heading.name),
            types:exp.types,
            returns:exp.returns,
            data: exp.rows.map(row=>row.rowData.map((rowItem)=> rowItem.data === "$null" ? null : rowItem.data)),
            output:exp.output,
            description: exp.description,
            tags: exp.tags
        };
    }

}
