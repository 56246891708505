import axios from "./axios";
export default {
    postPolicyTemplate(brandURL, template) {
        return axios.post(`/brands/${brandURL}/brand-documents`,
            template);
    },
    getPolicyRevisions(brandURL) {
        return axios.get(`/brands/${brandURL}/brand-documents`);
    },
    /**
     * Geta a policy by reference
     *
     * @param {Object} payload
     * @param {String} payload.brandURL
     * @param {String} payload.policyReference
     * @return {Promise<Object>}
     */
    getAPolicy(payload) {
        // eslint-disable-next-line max-len
        return axios.get(`/brands/${payload.brandURL}/policies/${payload.policyReference}${payload.extended ? "?extended=true" : ""}`);
    },
    /**
    * Gets a policy revision by revision ID
    *
    * @param {Object} payload
    * @param {String} payload.brandURL
    * @param {String} payload.policyReference
    * @param {String} payload.revision
    * @return {Promise<Object>}
    */
    getARevision(payload) {
        return axios.get(
            `/brands/${payload.brandURL}/policies/${payload.policyReference}/revision/${payload.revision}`
        );
    },
    getPolicyPrintSendCount(brandURL) {
        return axios.get(`/brands/${brandURL}/brand-documents/policy?type=printsendcount`);
    },
    getPolicyPrintSendDetails(brandURL) {
        return axios.get(`/brands/${brandURL}/brand-documents/policy?type=printsenddetails`);
    },
    fetchEndpointSettings(payload) {
        return axios.get(`/payments/${payload.provider}/${payload.brand}/${payload.endpoint}/settings`);
    },
    /**
     * Get a customer and policy from the api
     *
     * @param {Object} payload
     * @param {String} payload.brandURL
     * @param {String} payload.policyID
     * @return {Promise<Object>}
     */
    getPolicyCustomer(payload) {
        return axios.get(`/brands/${payload.brandURL}/policies/${payload.policyID}/customer-data`);
    },
    updateAutoRenewalStatus({ brand, revisionId, autoRenewalStatus, endpointId, autoOptOutReason }) {
        return axios.patch(`/brands/${brand}/policies/revision/${revisionId}/auto-renewal`, {
            autoRenewalStatus,
            endpointId,
            autoOptOutReason
        });
    }
};
