import authService from "../../services/auth";
import QRCode from "qrcode";
import router from "../../router/router";
import userpilotService from "../../services/userpilot-service";

export const types = {
    "SET_AUTHENTICATED": "SET_AUTHENTICATED",
    "SET_NOT_AUTHORISED": "SET_NOT_AUTHORISED",
    "SET_2F_SECRET":"SET_2F_SECRET",
    "SET_2F_IMAGE":"SET_2F_IMAGE",
    "SET_BRANDS": "SET_BRANDS",
    "SET_PROVIDER": "SET_PROVIDER",
    "SET_SELECTED_BRAND": "SET_SELECTED_BRAND",
    "SET_BRAND_PERMISSIONS":"SET_BRAND_PERMISSIONS",
    "SET_PROVIDER_PERMISSIONS":"SET_PROVIDER_PERMISSIONS",
    "SET_LOADING": "SET_LOADING",
    "SET_USER": "SET_USER",
    "SET_USER_BRAND_ACCESS": "SET_USER_BRAND_ACCESS",
    "SET_USER_PROVIDER_ACCESS": "SET_USER_PROVIDER_ACCESS",
    "SET_USER_ACCESS": "SET_USER_ACCESS",
    "SET_ERROR": "SET_ERROR",
    "SET_TOKEN_EXPIRY": "SET_TOKEN_EXPIRY",
    "CLEAR_TOKEN_TIMEOUT": "CLEAR_TOKEN_TIMEOUT",
    "SET_BRAND_LOCKED": "SET_BRAND_LOCKED",
    "SET_USERPILOT_USER": "SET_USERPILOT_USER",
    "SET_PROVIDER_TWO_FACTOR_REQUIRED": "SET_PROVIDER_TWO_FACTOR_REQUIRED"
};


const initalState = {
    tokenExpiry: null,
    tokenExpired: false,
    tokenTimeout: null,
    authenticated: false,
    twofactorToken:null,
    twoFactorSecret:"",
    twoFactorImage:"",
    providerTwoFactorRequired: false,
    brands:[],
    provider:null,
    selectedBrand:null,
    selectedBrandPermissions:[],
    providerPermissions:[],
    loading: false,
    error: null,
    user: "",
    userBrandAccess: [],
    userProviderAccess: [],
    userAccess: [],
    locked: false,
    userpilotUser: null
};

const errorTypes = {
    brandLocked: "Forbidden.",
    reAuthAsOther: "Re-authenticating as a different user."
};
// mutations
const mutations = {
    [types.CLEAR_TOKEN_TIMEOUT](state) {
        if (state.tokenTimeout !== null) {
            clearTimeout(state.tokenTimeout);
            state.tokenTimeout = null;
            state.tokenExpired = false;
        }
    },
    [types.SET_TOKEN_EXPIRY](state, expiry) {
        state.tokenExpiry = expiry;
        if (state.tokenTimeout !== null) {
            clearTimeout(state.tokenTimeout);
            state.tokenTimeout = null;
        }
        state.tokenExpired = false;
        // Set timeout to change token expiry, make it 1/2 second shorter than real expiry just be be sure its shown
        state.tokenTimeout = setTimeout(() => state.tokenExpired = true, expiry - new Date().getTime() - 500);
    },
    [types.SET_AUTHENTICATED](state, authenticated) {
        state.authenticated = authenticated;
    },
    [types.SET_2F_SECRET](state, secret) {
        state.twoFactorSecret = secret;
    },
    [types.SET_2F_IMAGE](state, image) {
        state.twoFactorImage = image;
    },
    [types.SET_LOADING](state, loading) {
        state.loading = loading;
    },
    [types.SET_ERROR](state, error) {
        state.error = error;
    },
    [types.SET_BRANDS](state,brands){
        state.brands = brands;
    },
    [types.SET_SELECTED_BRAND](state,brand){
        state.selectedBrand = brand;
    },
    [types.SET_BRAND_PERMISSIONS](state,brandPermissions){
        state.selectedBrandPermissions = brandPermissions;
    },
    [types.SET_PROVIDER_PERMISSIONS](state,providerPermissions){
        state.providerPermissions = providerPermissions;
    },
    [types.SET_PROVIDER](state,provider){
        state.provider = provider;
    },
    [types.SET_USER](state, user) {
        state.user = user;
    },
    [types.SET_USER_ACCESS](state, access) {
        state.userAccess = access;
    },
    [types.SET_USER_BRAND_ACCESS](state, access) {
        state.userBrandAccess = access;
    },
    [types.SET_USER_PROVIDER_ACCESS](state, access) {
        state.userProviderAccess = access;
    },
    [types.SET_BRAND_LOCKED](state, locked) {
        state.locked = locked;
    },
    [types.SET_USERPILOT_USER](state, user) {
        state.userpilotUser = user;
    },
    [types.SET_PROVIDER_TWO_FACTOR_REQUIRED](state, value) {
        state.providerTwoFactorRequired = value;
    }
};

const jwt = sessionStorage.getItem("jwt");
const brands = JSON.parse(localStorage.getItem("brands"));
const brandPermissions = JSON.parse(localStorage.getItem("brandPermissions"));
const provider = localStorage.getItem("provider");
const providerPermissions = JSON.parse(localStorage.getItem("providerPermissions"));

if (jwt) {
    const jwtExpiry = new Date(JSON.parse(atob(jwt.split(".")[1])).exp * 1000);
    if (jwtExpiry.getTime() < new Date().getTime()) {
        sessionStorage.removeItem("jwt");
    } else {
        mutations[types.SET_TOKEN_EXPIRY](initalState, jwtExpiry);
    }
}


//if we are authenticated set the inital state
let state = jwt ? {...initalState,authenticated:true} : {...initalState,authenticated:false};

if(brands){
    state = {...state, brands:brands.brands, selectedBrand:brands.selectedBrand};
}
if(brandPermissions){
    state = {...state,selectedBrandPermissions:brandPermissions };
}
if(provider){
    state = {...state, provider};
}
if(providerPermissions){
    state = {...state, providerPermissions};
}

const getters = {
    isAuthenticated: (state)=>{
        return state.authenticated;
    },
    selectedBrand(state) {
        return state.selectedBrand;
    }
};
// actions


//wrap toDataURL in a promise to make it compatible
export const qrToDataURL = function(opauth){
    return new Promise((resolve,reject)=>{
        QRCode.toDataURL(opauth, (err, url) => {
            if(err){
                return reject();
            }
            return resolve(url);
        });
    });
};

const actions = {
    async setSelectedBrand({commit,state},brand){
        try{
            const result = await authService.getUserPermissions(brand.id);
            const { permissions, brandLockedStatus } = result.data;
            commit(types.SET_BRAND_LOCKED, brandLockedStatus.locked);
            localStorage.setItem("brands",JSON.stringify({brands:state.brands,selectedBrand:brand,provider}));
            localStorage.setItem("brandPermissions",JSON.stringify(permissions));
            commit(types.SET_SELECTED_BRAND, brand);
            commit(types.SET_BRAND_PERMISSIONS, permissions);
            const { fullName, userID, email } = state.user;
            const userPilotId = userpilotService.createUserpilotId(state.provider, userID);
            const userpilot = {
                id: userPilotId,
                name: fullName,
                email: email,
                brandId: brand?.id,
                brandName: brand?.name,
                company: {
                    id: state.provider,
                    name: state.provider
                }
            };
            commit(types.SET_USERPILOT_USER, userpilot);
        }catch(err){
            commit(types.SET_LOADING, false);
            if(err.response){ //axios errors
                //if we have an express error, we will have an errors object
                let errMessage;
                if(err.response.data.error){
                    errMessage  = err.response.data.error[Object.keys(err.response.data.error)[0]].msg ||
                    err.response.data.message;
                }else{
                    errMessage = err.response.data.message;
                }
                commit(types.SET_ERROR,  errMessage);
                throw(new Error(errMessage));
            }else{
                commit(types.SET_ERROR,  err.message);
                throw(new Error(err.message));
            }
        }
    },

    setPermission({commit},rules){
        commit(types.SET_BRAND_PERMISSIONS, rules);
    },
    async getBrandLockedStatus({commit, state}) {
        const { id: brandId } = state.selectedBrand;
        const result = await authService.getBrandLockedStatus(brandId);
        const { locked } = result.data;
        commit(types.SET_BRAND_LOCKED, locked);
    },
    async login({commit, dispatch},credentials){
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try{
            // We want to test if the user is re-authenticating so get the current jwt if there is one
            let  jwt = sessionStorage.getItem("jwt");
            const oldUserID = jwt ? JSON.parse(atob(jwt.split(".")[1])).userID : null;
            const {
                provider,
                twoFactorEnabled,
                twoFactorRequired,
                providerTwoFactorRequired,
                brands,
                selectedBrand,
                selectedBrandPermissions,
                providerPermissions,
                user,
                userID,
                brandLockedStatus
            } = await authService.login(credentials);
            commit(types.SET_PROVIDER, provider);
            commit(types.SET_BRAND_LOCKED, brandLockedStatus);
            commit(types.SET_LOADING, false);
            commit(types.SET_PROVIDER_TWO_FACTOR_REQUIRED, providerTwoFactorRequired);

            jwt = sessionStorage.getItem("jwt");
            const newUserID = JSON.parse(atob(jwt.split(".")[1])).userID;
            if (oldUserID) {
                if (oldUserID !== newUserID) {
                    dispatch("logout");
                    throw new Error(errorTypes.reAuthAsOther);
                }
            }
            let userpilot= {};
            //if not 2fa store brands
            if(!providerTwoFactorRequired && !twoFactorEnabled && !twoFactorRequired){
                commit(types.SET_TOKEN_EXPIRY, new Date(JSON.parse(atob(jwt.split(".")[1])).exp * 1000));
                commit(types.SET_AUTHENTICATED, true);
                commit(types.SET_BRANDS, brands);
                commit(types.SET_SELECTED_BRAND, selectedBrand);
                commit(types.SET_BRAND_PERMISSIONS, selectedBrandPermissions);
                commit(types.SET_PROVIDER_PERMISSIONS, providerPermissions);
                sessionStorage.setItem("user", JSON.stringify(user));
                const userPilotId = userpilotService.createUserpilotId(provider, userID);
                userpilot = {
                    id: userPilotId,
                    name: user,
                    email: credentials.email,
                    brandId: selectedBrand?.id,
                    brandName: selectedBrand?.name,
                    company: {
                        id: provider,
                        name: provider
                    }
                };
            }
            return {
                provider,
                twoFactorEnabled,
                twoFactorRequired,
                providerTwoFactorRequired,
                userpilot
            };
        }catch(err){
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR,  err.message);
            throw(new Error(err.message));
        }
    },
    async loginSecondFactor({commit, state},code){
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try{
            const {
                brands,
                providerPermissions,
                selectedBrand,
                selectedBrandPermissions,
                user,
                userID,
                email
            } = await authService.loginSecondFactor(code);
            const jwt = sessionStorage.getItem("jwt");
            const { exp } = JSON.parse(atob(jwt.split(".")[1]));

            commit(types.SET_TOKEN_EXPIRY, new Date(exp * 1000));
            commit(types.SET_2F_SECRET,null);
            commit(types.SET_2F_IMAGE,null);
            commit(types.SET_AUTHENTICATED, true);
            commit(types.SET_BRANDS, brands);
            commit(types.SET_SELECTED_BRAND, selectedBrand);
            commit(types.SET_BRAND_PERMISSIONS, selectedBrandPermissions);
            commit(types.SET_PROVIDER_PERMISSIONS, providerPermissions);
            commit(types.SET_LOADING, false);
            sessionStorage.setItem("user", JSON.stringify(user));
            const userPilotId = userpilotService.createUserpilotId(state.provider, userID);
            const userpilot = {
                id: userPilotId,
                name: user,
                email,
                brandId: selectedBrand?.id,
                brandName: selectedBrand?.name,
                company: {
                    id: state.provider,
                    name: state.provider
                }
            };
            return {
                userpilot
            };
        }catch(err){
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR,  err.message);
            throw(new Error(err.message));
        }
    },
    async loginSecondFactorSetup({commit}){
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try{
            let setup = await authService.loginSecondFactorSetup();
            commit(types.SET_LOADING, false);
            let image = await qrToDataURL(setup.url);
            commit(types.SET_2F_SECRET, setup.secret );
            commit(types.SET_2F_IMAGE, image);
            return setup;
        }catch(err){
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR,  err.message);
            throw(new Error(err.message));
        }
    },
    async logout({ dispatch }, reload = true){
        //only logout if we are not on a login page
        //stops a 401 causing the refresh
        if(!["login","twofactor","twofactorsetup"].includes(router.currentRoute.name)){
            await dispatch("testMode/setTestMode", { value: false, audit: false }, { root: true });
            return authService.logout(reload);
        }
    },
    async validateToken({ commit }, credentials) {
        commit(types.SET_LOADING, true);
        try {
            await authService.validateToken(
                credentials.provider_id,
                credentials.jwt
            );
        } finally {
            commit(types.SET_LOADING, false);
        }
    },
    async resetPassword({ commit }, credentials) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            await authService.resetPassword(
                {
                    password: credentials.password,
                    password2: credentials.confirmPassword
                },
                credentials.jwt
            );
            commit(types.SET_LOADING, false);
        } catch (err) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR,  err.message);
            throw(new Error(err.message));
        }
    },
    forgotten({}, credentials) { // eslint-disable-line no-empty-pattern
        authService.forgottenPassword({
            ["provider_id"]: credentials.provider,
            email: credentials.email
        });
    },
    async fetchProfile({ commit }) {
        try {
            const { data } = await authService.fetchProfile();
            commit(types.SET_USER, data.user);
        } catch (err) {
            commit(types.SET_ERROR,  err.message);
        }
    },
    async updateProfile({ commit }, payload) {
        const { data } = await authService.updateProfile(payload);
        commit(types.SET_USER, data.user);
    },
    async fetchUserBrandAccess({ commit }) {
        try {
            const { data } = await authService.fetchUserBrandAccess();
            commit(types.SET_USER_BRAND_ACCESS, data);
        } catch (err) {
            commit(types.SET_ERROR,  err.message);
        }
    },
    async fetchUserProviderAccess({ commit }) {
        try {
            const { data } = await authService.fetchUserProviderAccess();
            commit(types.SET_USER_PROVIDER_ACCESS, data);
        } catch (err) {
            commit(types.SET_ERROR,  err.message);
        }
    },
    clearUserpilot({commit}) {
        commit(types.SET_USERPILOT_USER, null);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
