import {isCorrectFormat,isNotEmpty} from "./ExpressionValidators";
import Expression from "./Expression";

export default class ValueExpression extends Expression {
    constructor(id){
        const properties = {
            data:{
                value: null,
                isValid:null,
                dirty:false,
                errors:[],
                validators:[
                    isNotEmpty("Value"),
                    isCorrectFormat("Value")
                ]
            },
            expressionType:{
                value: "value",
                isValid:null,
                validators:[]
            }
        };
        super(id,properties);
    }

    static cleanExpression(exp) {
        return {
            id:exp.id,
            returns:exp.returns,
            data:exp.data,
            expressionType:exp.expressionType,
            description: exp.description,
            tags: exp.tags
        };
    }
}
