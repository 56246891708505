<template>
    <div class="dashboard login-container">
        <form
            class="form-signin"
            @submit.prevent
        >
            <img
                class="Asanto"
                src="../assets/asanto-white.svg"
                alt
            >
            <router-view
                @loggedIn="loggedIn"
                @twoFactorSetup="twoFactorSetup"
                @twoFactor="twoFactor"
            />
        </form>
        <screen-size />
    </div>
</template>

<script>
import ScreenSize from "../components/layout/ScreenSize";

export default {
    components: {
        ScreenSize
    },
    props: {
        redirect: {
            type: String,
            required: false,
            default: "/dashboard"
        }
    },
    methods: {
        loggedIn() {
            return this.$router.push(this.redirect);
        },
        twoFactorSetup(url) {
            return this.$router.push({
                name:"twofactorsetup",
                params: { redirect: this.redirect, otpauth: url }
            });
        },
        twoFactor() {
            return this.$router.push({
                name: "twofactor",
                params: { redirect: this.redirect }
            });
        }
    }
};
</script>
