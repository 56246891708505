<template>
    <div>
        <b-modal
            id="showEmailSent"
            v-model="showEmailSent"
            hide-footer
            title="Please check your emails"
            no-close-on-backdrop
            centered
            @hidden="goToLogin()"
        >
            <p>
                Thank you for submitting your details.
                We've sent an email with a secure link that will allow you to reset your password.
                Please keep in mind that it may take a few minutes for the email to reach your inbox.
            </p>
        </b-modal>
        <fieldset>
            <legend>
                Please enter your details
            </legend>
            <div class="form-group">
                <label
                    for="inputProvider"
                    class="sr-only"
                >
                    Provider
                </label>
                <input
                    id="inputProvider"
                    v-model="provider"
                    v-validate="'required'"
                    data-vv-as="Provider"
                    class="form-control"
                    data-lpignore="true"
                    name="inputProvider"
                    placeholder="Organisation name"
                    autofocus
                >
                <font-awesome-icon
                    :icon="['fal', 'industry']"
                />
            </div>
            <div class="form-group">
                <label
                    for="email"
                    class="sr-only"
                >
                    Email Address
                </label>
                <div class="input-email">
                    <input
                        id="inputEmail"
                        v-model="email"
                        v-validate="'required|email'"
                        class="form-control"
                        type="email"
                        data-lpignore="true"
                        name="inputEmail"
                        data-vv-as="Email address"
                        placeholder="Email"
                    >
                    <font-awesome-icon
                        :icon="['fal', 'envelope-open-text']"
                    />
                </div>
            </div>
            <button
                type="button"
                class="btn-login"
                @click="handleSubmit"
            >
                Request Password Reset
            </button>
        </fieldset>
        <div class="input-errors">
            <b-form-invalid-feedback
                v-if="veeErrors.first('inputProvider')"
                id="inputProviderError"
                class="input-errors"
            >
                {{ veeErrors.first('inputProvider') }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
                v-if="veeErrors.first('inputEmail')"
                id="inputEmailError"
                class="input-errors"
            >
                {{ veeErrors.first('inputEmail') }}
            </b-form-invalid-feedback>
        </div>
        <router-link
            to="login"
            class="resetPassword"
        >
            Return to <u>login</u>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "ForgottenPassword",
    data() {
        return {
            email: null,
            provider: null,
            showEmailSent: false
        };
    },
    methods: {
        goToLogin() {
            this.$router.push({name: "login"});
        },
        async handleSubmit() {
            const isValid = await this.$validator.validateAll();
            if (!isValid) {
                return;
            }
            this.$store.dispatch("auth/forgotten", {
                email: this.email,
                provider: this.provider
            });
            this.showEmailSent = true;
        }
    }
};
</script>
