<template>
    <div>
        <b-overlay
            :show="loading === true"
            rounded="sm"
        >
            <div
                class="dashboard__content--filters"
            >
                <b-form-group
                    v-if="searchVisible"
                    class="dashboard__content--filters--search"
                    label-for="filterInput"
                >
                    <label
                        for="filterInput"
                        class="sr-only"
                    >Filter</label>
                    <b-form-input
                        id="filterInput"
                        type="search"
                        :placeholder="placeholder"
                        autocomplete="off"
                        :value="filter"
                        @input="updateFilter"
                        @blur="filterOnBlur"
                    />
                    <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                    >
                        <span>Clear</span>
                        <font-awesome-icon
                            :icon="['far', 'times']"
                        />
                    </b-button>
                </b-form-group>
            </div>
            <slot name="table-prepend" />
            <div
                class="table"
            >
                <b-table
                    show-empty
                    :busy="busy"
                    :sticky-header="sticky ? sticky : null"
                    hover
                    borderless
                    thead-tr-class="asantoHead"
                    tbody-tr-class="asantoRow"
                    no-border-collapse
                    sort-icon-left
                    :thead-class="hideHeader ? 'd-none': sticky ? 'sticky': '' "
                    :per-page="perPage"
                    :current-page="currentTablePage"
                    :class="[{'hasPagination': totalRows >= perPage }]"
                    :fields="fields"
                    :items="items"
                    :primary-key="primaryKey"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :fixed="fixed"
                    :select-mode="selectMode"
                    :selectable="selectable"
                    @filtered="onFiltered"
                    @row-selected="onRowSelected"
                >
                    <template
                        v-for="(_, slot) of $scopedSlots"
                        #[slot]="scope"
                    >
                        <slot
                            :name="slot"
                            v-bind="scope"
                        />
                    </template>
                </b-table>
            </div>
            <div
                v-if="totalRows >= perPage"
                class="dashboard__content--pagination row no-gutters justify-content-end"
                :class="stickyFooter ? 'sticky': '' "
            >
                <b-pagination
                    v-model="currentPage"
                    limit="4"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="quotes"
                    first-number
                    last-number
                    @change="changePage"
                />
            </div>
        </b-overlay>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    props:{
        placeholder: {
            type: String,
            required: false,
            default: "Type to Search"
        },
        items:{
            type:Array,
            required:true
        },
        fields:{
            type:Array,
            required:true
        },
        busy: {
            type: Boolean,
            required: false,
            default: false
        },
        sticky: {
            type: String,
            required: false,
            default: ""
        },
        stickyFooter: {
            type: Boolean,
            required: false,
            default: false
        },
        searchVisible: {
            type: Boolean,
            required: false,
            default: true
        },
        count: {
            type: Number,
            required: false,
            default: 0
        },
        serverside: {
            type: Boolean,
            required: false,
            default: false
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        perPage: {
            type: Number,
            required: false,
            default: 10
        },
        hideHeader: {
            type: Boolean,
            required: false,
            default: false
        },
        primaryKey: {
            type: String,
            required: false,
            default: ""
        },
        fixed: {
            type: Boolean,
            required: false,
            default: false
        },
        selectable: {
            type: Boolean,
            default: false
        },
        selectMode: {
            type: String,
            default: "multi"
        }
    },
    data() {
        return {
            sortBy: null,
            sortDesc: true,
            currentPage: 1,
            currentTablePage: 1,
            pageOptions: [5, 10, 15],
            filter: null,
            filterOn: [],
            filteredTotal: 0
        };
    },
    computed: {
        totalRows() {
            if (this.serverside) {
                return this.count;
            } else if (this.filter) {
                return this.filteredTotal;
            } else {
                return this.items.length;
            }
        }
    },
    watch: {
        sortBy(sortBy) {
            this.$emit("changePage", 1, sortBy, true);
            this.$emit("changePage", 1, sortBy, this.sortDesc, true);
            this.currentPage = 1;
        },
        sortDesc(sortDesc) {
            this.$emit("changePage", 1, this.sortBy, sortDesc, true);
            this.currentPage = 1;
        },
        "$route.params.schemeId": function() {
            this.currentPage = 1;
        },
        currentPage(val) {
            this.changePage(val);
        }
    },
    methods: {
        filterOnBlur() {
            this.$emit("filterOnBlur");
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            if (!this.serverside) {
                this.filteredTotal = filteredItems.length;
            }
            this.currentPage = 1;
        },
        changePage(page) {
            if (!this.serverside) {
                this.currentTablePage = page;
            }
            this.$emit("changePage", page, this.sortBy, this.sortDesc, false);
        },
        updateFilter(val) {
            if (!this.serverside) {
                this.filter = val;
                return;
            }
            this.throttleFilter(val);
        },
        throttleFilter: _.debounce(function(val) {
            this.currentPage = 1;
            this.$emit("filter", val);
        }, 500),
        onRowSelected(items) {
            this.$emit("rowSelected", items);
        }
    }
};
</script>

