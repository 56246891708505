import Vue from "vue";
import axios from "../../services/axios";
import policyServices from "../../services/policies";

export const types = {
    "SET_POLICY": "SET_POLICY",
    "SET_POLICIES": "SET_POLICIES",
    "SET_ERROR": "SET_ERROR",
    "SET_FORM_DATA": "SET_FORM_DATA",
    "SET_MEDICAL": "SET_MEDICAL",
    "SET_ENDPOINT_SETTINGS": "SET_ENDPOINT_SETTINGS",
    "SET_POLICY_REVISIONS": "SET_POLICY_REVISIONS",
    "SET_AUTO_RENEWAL_STATUS": "SET_AUTO_RENEWAL_STATUS"
};

const state = {
    customer: "",
    error: false,
    revisions: null,
    policies: [],
    policy: {
        "policy_data": {
            customerData: [],
            contactInfo: {},
            endDate: ""
        }
    },
    form: {
        "trip_type": "ST",
        "start_date": "",
        "end_date": "",
        "travel_destinations": [],
        "chosen_area": [],
        firstname: "",
        surname: "",
        email: "",
        grouptype: "",
        travellers: [],
        medical: {},
        dob: {},
        selectedTravellers: 1,
        premedquestions: {
            doyouhave: "", 
            "pre_screening_failed": "", 
            "requires_screening": ""
        },
        wintersports: false,
        cruisecover: false,
        businesstrip: false,
        gadget: false,
        gadgetValue: "",
        triparea: [],
        "requester_id": "d2c",
        endpoint: "d2c"
    },
    endpointSettings: {}
};

const actions = {
    async getPolicy({ commit }, payload) {
        try {
            commit(types.SET_ERROR, false);
            let result = await axios.get(
                `/brands/${payload.brand}/policies/${payload.policyID}/customer-data`
            );
            commit(types.SET_POLICY, result.data );
            let formData = JSON.parse(result.data.form_data);
            // pass in VERISK object that contains BASE64 encoded XML
            formData.travellers.forEach((traveller, index) => {
                traveller.screeningId = "";
                traveller.verisk = result.data.quotes_data.travellers[index].verisk;
            });
            formData.premedquestions["pre_screening_failed"] = "";
            formData.premedquestions["requires_screening"] = "";
            commit(types.SET_FORM_DATA, formData);
        } catch (error) {
            return commit(types.SET_ERROR, error.message);
        }
    },
    updateMedicalForm({commit}, payload) {
        commit(types.SET_MEDICAL, payload);
    },
    async getEndpointSettings({commit, rootState}, payload) {
        commit(types.SET_ERROR, false);
        try {
            const endpoint = rootState.crm.endpoint?.name_url ?? "d2c";
            const endpointSettings = await policyServices.fetchEndpointSettings({ ...payload, endpoint });
            commit(types.SET_ENDPOINT_SETTINGS, endpointSettings.data);
        } catch (error) {
            commit(types.SET_ERROR, true);
        }
    },
    async fetchAPolicy({commit}, payload) {
        try {
            commit(types.SET_ERROR, false);
            const result = await policyServices.getAPolicy(payload);
            commit(types.SET_POLICY_REVISIONS, result.data);
            return result.data;
        } catch (err) {
            commit(types.SET_ERROR, "Issue getting policy");
            throw err;
        }
    },
    async fetchARevision({commit}, payload) {
        try {
            commit(types.SET_ERROR, false);
            const result = await policyServices.getARevision(payload);
            commit(types.SET_POLICY, result.data);
            return result.data;
        } catch (err) {
            commit(types.SET_ERROR, "Issue getting revision");
        }
    },
    async updateAutoRenewalStatus({ commit }, payload) {
        try {
            commit(types.SET_ERROR, false);
            const { data: autorenewal } = await policyServices.updateAutoRenewalStatus(payload);
            commit(types.SET_AUTO_RENEWAL_STATUS, autorenewal);
        } catch (err) {
            commit(types.SET_ERROR, "Issue with updating auto renewal opt in/out");
            throw err;
        }
    }
};

const mutations = {
    [types.SET_POLICY]: (state, policy) => (state.policy = policy),
    [types.SET_ERROR]: (state, error) => (state.error = error),
    [types.SET_FORM_DATA]: (state, form) => (state.form = form),
    [types.SET_MEDICAL]: (state, medical) => (state.form.premedquestions = medical),
    [types.SET_ENDPOINT_SETTINGS]: (state, endpointSettings) => (state.endpointSettings = endpointSettings),
    [types.SET_POLICY_REVISIONS]: (state, revisions) => state.revisions = revisions,
    [types.SET_A_REVISION]: (state, revision) => state.revision = revision,
    [types.SET_AUTO_RENEWAL_STATUS]: (state, status) => {
        Vue.set(state.policy.policy_data, "autorenewal", status);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
