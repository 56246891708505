<template>
    <div class="sidebar-footer">
        <SidebarNavigationItem
            v-if="activeUserpilot"
            name="Help"
            :icon="['fal', 'question-circle']"
            :is-collapsed="isCollapsedSideBar"
            tag="button"
            class="navigation-item"
            @click="helpTriggerHandler"
        />
        <SidebarDropdown class="sidebar-dropdown-wrapper">
            <template #activator>
                <SidebarDropdownItem
                    :avatar-text="nameInitials(user.fullName)"
                    :title="user.fullName"
                    :subtitle="user.email"
                    :is-collapsed="isCollapsedSideBar"
                    :icon="['fal', 'angle-down']"
                />
            </template>
            <template #default>
                <div class="px-3 py-2">
                    <SidebarDropdownItem
                        :avatar-text="nameInitials(user.fullName)"
                        avatar-variant="dark"
                        avatar-size="30px"
                        :title="user.fullName"
                        :subtitle="user.email"
                    />
                </div>
                <b-dropdown-divider />
                <div class="navigation-list">
                    <b-dropdown-item
                        v-for="(item, index) in navigationItems"
                        :key="index"
                        :to="item.to"
                        class="navigation-item dark"
                    >
                        <font-awesome-icon
                            :icon="item.icon"
                        />
                        {{ item.title }}
                    </b-dropdown-item>
                </div>
                <b-dropdown-divider />
                <b-dropdown-item
                    :to="{
                        name: 'logout'
                    }"
                    class="navigation-item dark"
                >
                    <font-awesome-icon
                        :icon="['fal', 'sign-out-alt']"
                    />
                    Logout
                </b-dropdown-item>
            </template>
        </SidebarDropdown>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { USERPILOT_TRIGGER } from "../../helpers/constants";
import SidebarDropdown from "./SidebarDropdown.vue";
import SidebarDropdownItem from "./SidebarDropdownItem.vue";
import SidebarNavigationItem from "./SidebarNavigationItem.vue";

export default {
    name: "SidebarLogout",
    components: {
        SidebarNavigationItem,
        SidebarDropdown,
        SidebarDropdownItem
    },
    data() {
        return {
            navigationItems: [
                {
                    title: "Profile",
                    to: {
                        name: "account-profile"
                    },
                    icon: ["fal", "user"]
                },
                {
                    title: "Security",
                    to: {
                        name: "account-security"
                    },
                    icon: ["fal", "shield"]
                }
            ]
        };
    },
    computed: {
        ...mapState({
            isCollapsedSideBar: (state) => state.application.isCollapsedSideBar,
            user: (state) => state.auth.user
        }),
        activeUserpilot() {
            return !!window.userpilot;
        }
    },
    methods: {
        nameInitials(name) {
            if (!name) {
                return "";
            }
            const firstLetters = name.split(" ").map((item) => item.slice(0, 1).toUpperCase());
            if (firstLetters.length === 1) {
                return firstLetters[0];
            }
            const [first, second] = firstLetters;
            return first + second;
        },
        helpTriggerHandler() {
            window.userpilot.trigger(USERPILOT_TRIGGER);
        }
    }
};
</script>

<style lang="sass" scoped>
.sidebar-footer
    border-top: 1px solid rgba(255, 255, 255, 0.3)
    margin-top: auto
    padding: 10px 0
    display: flex
    flex-flow: column
    gap: 3px
    .sidebar-divider
        width: 100%
        height: 1px
        background-color: rgba(255, 255, 255, 0.3)
        margin: 10px 0
    .sidebar-dropdown-wrapper
        padding: 0 10px
    .navigation-list
        display: flex
        flex-flow: column
        gap: 3px
    ::v-deep .navigation-item
        display: flex
        padding: 0 10px !important
        width: 100%
        .dropdown-item
            color: $text-color
            background: transparent
            display: flex
            align-items: center
            width: 100%
            gap: 15px
            border-radius: 5px
            font-size: 0.8rem
            text-decoration: none
            padding: 10px 13px
            white-space: nowrap
            &:hover, &:active, &:focus
                outline: 0
                background: #e8e0f3
                box-shadow: none
            &:focus
                box-shadow: 0 0 0 0.2rem rgb(180, 164, 189)
</style>
