<template>
    <div>
        <h3 class="mb-3">
            Two-factor authentication
        </h3>
        <div class="d-flex mb-1">
            <div>
                Authenticator app
            </div>
            <div class="ml-2">
                <BaseBadge
                    :variant="badgeVariant"
                    size="small"
                >
                    {{ badgeText }}
                </BaseBadge>
            </div>
        </div>
        <div class="text-muted">
            After entering your password, verify your identity with an authentication method
        </div>
        <div
            id="toggleTwoFactorButton"
            class="d-inline-flex mt-3"
        >
            <b-button
                :disabled="isTwoFactorUpdateDisabled"
                :variant="buttonVariant"
                class="mt-0"
                @click="toggleTwoFactor"
            >
                {{ buttonText }}
            </b-button>
        </div>
        <b-tooltip
            v-if="isTwoFactorUpdateDisabled"
            target="toggleTwoFactorButton"
            triggers="hover"
        >
            Two-factor authentication is required and cannot be turned off
        </b-tooltip>
        <UserSecurityTwoFactorSetup @changed="onTwoFactorEnabled" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import UserSecurityTwoFactorSetup from "./UserSecurityTwoFactorSetup.vue";
import authService from "../../../../services/auth";
import BaseBadge from "../../base/BaseBadge.vue";

export default {
    name: "UserSecurityTwoFactor",
    components: {
        UserSecurityTwoFactorSetup,
        BaseBadge
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
            providerTwoFactorRequired: state => state.auth.providerTwoFactorRequired
        }),
        isTwoFactorEnabled() {
            return this.user.twoFactorEnabled;
        },
        isTwoFactorUpdateDisabled() {
            return this.user.twoFactorEnabled && (this.user.twoFactorRequired || this.providerTwoFactorRequired);
        },
        badgeVariant() {
            return this.isTwoFactorEnabled ? "success" : "danger";
        },
        badgeText() {
            return this.isTwoFactorEnabled ? "Enabled" : "Disabled";
        },
        buttonText() {
            return this.isTwoFactorEnabled ? "Turn off two-factor" : "Turn on two-factor";
        },
        buttonVariant() {
            return this.isTwoFactorEnabled ? "outline-primary" : "primary";
        }
    },
    methods: {
        showTwoFactorSetupModal() {
            this.$bvModal.show("twoFactorSetup");
        },
        hideTwoFactorSetupModal() {
            this.$bvModal.hide("twoFactorSetup");
        },
        toggleTwoFactor() {
            if (this.isTwoFactorEnabled) {
                return this.disableTwoFactor();
            }
            this.showTwoFactorSetupModal();
        },
        async disableTwoFactor() {
            try {
                const createElement = this.$createElement;
                const messageVNode = createElement("div",
                    "Are you sure you want to turn off two-factor authentication? " +
                    "By removing this authentication method, your account may be less secure."
                );

                const isConfirmed = await this.$bvModal.msgBoxConfirm([messageVNode], {
                    title: "Update two-factor authentication confirmation",
                    okVariant: "primary",
                    cancelVariant: "outline-secondary",
                    okTitle: "Turn off",
                    cancelTitle: "Cancel",
                    hideHeaderClose: false,
                    centered: true
                });

                if (isConfirmed) {
                    await authService.updateTwoFactor({
                        "two_factor_enabled": false
                    });
                    await this.$store.dispatch("auth/fetchProfile");
                    this.$bvToast.toast("Two factor has been disabled", {
                        title: "Success",
                        variant: "success"
                    });
                }

            } catch(err) {
                this.$bvToast.toast(err.message, {
                    title: "Error",
                    variant: "danger"
                });
            }
        },
        async onTwoFactorEnabled() {
            try {
                await this.$store.dispatch("auth/fetchProfile");
                this.hideTwoFactorSetupModal();
                this.$bvToast.toast("Two factor has been enabled", {
                    title: "Success",
                    variant: "success"
                });
            } catch(err) {
                this.$bvToast.toast(err.message, {
                    title: "Error",
                    variant: "danger"
                });
            }
        }
    }
};
</script>
