import isDecimal from "validator/lib/isDecimal";
import Lexer from "../interpreter/Lexer";
import Parser from "../interpreter/Parser";
import store from "../store/index";
import {
    isNumberGTLT,
    isNumberRange,
    isIdentifer,
    isNumber,
    isDateSingle,
    isDateGTLT,
    isDateRange,
    isBoolean as isBool,
    isString,
    isObject,
    isArray
} from "../lib/validators";

export const isRequestDefaultValid = () => ({
    err: null,
    validate(v) {
        if (v.default === null || typeof v.default === "undefined") {
            return true;
        }
        switch (v.type) {
            case "string":
                if (isString(v.default) === null && v.default !== "") {
                    this.err = "expected string";
                    return false;
                }
                break;
            case "number":
                if (isNumber(v.default) === null) {
                    this.err = "expected number";
                    return false;
                }
                break;
            case "date":
                if (isDateSingle(v.default) === null) {
                    this.err = "expected date";
                    return false;
                }
                break;
            case "boolean":
                if (isBool(v.default) === null) {
                    this.err = "expected boolean";
                    return false;
                }
                break;
            case "object":
                if (isObject(v.default) === null) {
                    this.err = "expected object";
                    return false;
                }
                break;
            case "array":
                if (isArray(v.default) === null) {
                    this.err = "expected array";
                    return false;
                }
                break;
            default:
                return false;
        }
        return true;
    },
    message() {
        if (!this.err) {
            return "Default value is not compatible with the return type";
        }
        return `Default value is not compatible with the return type: ${this.err}.`;
    }
});

/**
 * @typedef {object} Validator
 * @description a validator object used on expressions
 * @property {function} validate - the validation function
 * @property {function} message - builds a validation error message
 * @property {string | null} err - error message cached from a validation run
 */

/**
 * Checks to see if all of fields have names
 * We could also implement a check for duplicate names

 * @param {*} propertyName
 */
export const isRequestExpressionValid = () => ({
    err: null,
    validate(v) {
        if (v === null) {
            return false;
        }
        const requestDefaultValidator = isRequestDefaultValid();
        if (!requestDefaultValidator.validate(v)) {
            this.err = requestDefaultValidator.message();
            return false;
        }
        //look down the expression tree -if we find a value without a name
        //set valid to false
        if (v.id !== undefined && v.id === "") {
            this.err = "The request has missing property names";
            return false;
        } else if (v.items) {
            return this.validate(v.items);
        } else if (v.properties) {
            const seen = {};
            for (let index = 0; index < v.properties.length; index++) {
                if (seen[v.properties[index].id]) {
                    this.err = `Duplicate property key "${v.properties[index].id}"`;
                    return false;
                }
                seen[v.properties[index].id] = true;
                let valid = this.validate(v.properties[index]);
                if (!valid) {
                    return false;
                }
            }
            this.err = null;
            return true;
        } else if (v.returns) {
            return this.validate(v.returns);
        } else {
            this.err = null;
            //nothing to validate so we assume OK
            return true;
        }

        //this assumes that a default has been set
    },
    message() {
        if (this.err !== null) {
            return this.err;
        } else {
            return "The request has missing property names";
        }
    }
});

/**
 * Checks to see if a provided return value is valid
 * It checks the value is the same type as the return
 * @param {*} propertyName
 */
export const isDefaultValid = () => ({
    err: null,
    validate(v) { //look at how this could be better handled - maybe as options
        if (v === null) {
            return false;
        }
        if (v.default === "reject" || v.default === "pass") {
            return true;
        }
        switch (v.type) {
            case "string":
                if (isString(v.default) === null) {
                    this.err = "expected string";
                    return false;
                }
                break;
            case "number":
                if (isNumber(v.default) === null) {
                    this.err = "expected number";
                    return false;
                }
                break;
            case "date":
                if (isDateSingle(v.default) === null) {
                    this.err = "expected date";
                    return false;
                }
                break;
            case "boolean":
                if (isBool(v.default) === null) {
                    this.err = "expected boolean";
                    return false;
                }
                break;
            default:
                return false;
        }

        return true;

        //this assumes that a default has been set
    },
    message() {
        if (!this.err) {
            return "Default value is not compatible with the return type";
        }
        return `Default value is not compatible with the return type: ${this.err}.`;
    }
});

//TODO - refactor other validors to match this pattern
export const isCellValid =  (propertyName="")=>({
    err:null,
    validate(v,type,isReturns){ //look at how this could be better handled - maybe as options
        if (v === null & isReturns) {
            return true;
        }
        if( v===null  || v===undefined){
            return false;
        }
        //eslint-disable-next-line
        let items = isReturns ? [v.toString()] : v.toString().split(",");
        for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
            const item = items[itemIndex];
            if( (item === "reject" || item === "$null") && isReturns){
                break;
            }
            switch (type) {
                case "string":
                    if (isString(item) === null) {
                        this.err = "invalid string";
                        return false;
                    }
                    break;
                case "number":
                    if (isReturns && isNumber(item) === null) {
                        this.err = "invalid number";
                        return false;
                    }
                    if (isNumber(item) === null &&
                        isNumberRange(item) === null &&
                        isNumberGTLT(item) === null &&
                        item !== "*") {
                        this.err = "invalid number";
                        return false;
                    }
                    break;
                case "date":
                    if (isReturns && isDateSingle(item) === null) {
                        this.err = "invalid date - must be in format dd/mm/yyyy";
                        return false;
                    }
                    if (isDateSingle(item) === null &&
                        isDateRange(item) === null &&
                        isDateGTLT(item) === null &&
                        item !== "*") {
                        this.err = "invalid date - must be in format dd/mm/yyyy";
                        return false;
                    }
                    break;
                case "boolean":
                    if (isBool(item) === null &&
                        item !== "*") {
                        this.err = "invalid boolean";
                        return false;
                    }
                    break;
                default:
                    return false;
            }

        }
        return true;
    },
    message() {
        if (!this.err) {
            return `${propertyName} contains one or more errors`;
        }
        return `${propertyName} ${this.err}`;
    }
});

//Not yet used as will be expensive
export const isRowsValid = (propertyName) => ({
    err: null,
    validate(v, { properties, invalidCells } = {}) {
        let valid = true;
        if (v === null || v === undefined) {
            return false;
        }
        for (let rowIndex = 0; rowIndex < v.length; rowIndex++) {
            const row = v[rowIndex];
            for (let cellIndex = 0; cellIndex < row.rowData.length; cellIndex++) {
                const cell = row.rowData[cellIndex];
                let isReturn = false;
                if (properties.columns.value[cellIndex]) {
                    isReturn = properties.columns.value[cellIndex].return;
                } else {
                    isReturn = cellIndex + 1 === row.rowData.length;
                }

                if (!isCellValid("cell").validate(cell.data, properties.types.value[cellIndex], isReturn)) {
                    invalidCells.set(cell.id, true);
                    cell.isValid = false;
                    valid = false;
                } else {
                    invalidCells.delete(cell.id);
                    cell.isValid = true;
                }
            }
        }

        return valid;
    },
    message(property) {
        if (!this.err) {
            return `${propertyName || property} contains one or more errors`;
        }
        return `${this.err}`;
    }
});

export const isFormulaValid = (propertyName = "") => ({
    err: null,
    validate(v) {
        let lexer = new Lexer(v);
        let parser = new Parser(lexer);
        parser.parse();
        if (parser.errors.length > 0) {
            this.err = parser.errors[0];
            return false;
        }
        return true;
    },
    message(property) {
        if (!this.err) {
            return `${propertyName || property} contains a syntax error`;
        }
        return `${this.err}`;
    }
});

export const isNotEmpty = (propertyName = "") => ({
    validate(v) {
        return v !== null && v !== undefined & String(v).trim() !== "";
    },
    message(property) {
        return `${propertyName || property} cannot be empty`;
    }
});

export const isNumeric = (propertyName = "") => ({
    validate(v) {
        return isDecimal(String(v));
    },
    message(property) {
        return `${propertyName || property} is not a number`;
    }
});

export const isValidIdentifier = (propertyName = "") => ({
    validate(v) {
        return isIdentifer(v);
        ///return isText(String(v));
    },
    message(property) {
        return `${propertyName || property} is not a valid identifier`;
    }
});

export const isBoolean = (propertyName = "") => ({
    validate(v) {
        let val = String(v);
        return val === "true" || val === "false" || val === "yes" || val === "no";
    },
    message(property) {
        return `${propertyName || property} is not a boolean value`;
    }
});

//optional properties can be used to check against
//checks to see if the value is in the correct format based on the chosen dataType
export const isCorrectFormat = (propertyName = "") => ({
    err: null,
    validate(v, { properties } = {}) {
        if (!isNotEmpty().validate(properties["returns"].value)) {
            return false;
        }
        switch (properties["returns"].type) {
            case "number":
                if (!isNumeric(propertyName).validate(v)) {
                    this.err = "number";
                    return false;
                }
                return true;
            case "boolean":
                if (!isBoolean(propertyName).validate(v)) {
                    this.err = "boolean";
                    return false;
                }
                return true;
            default:
                return true;
        }
    },
    message(property) {
        return `${propertyName || property} is not ${this.err ? "a " + this.err : "the correct"} data type`;
    }
});

//checks to see if the expression id exists e.g when
//choosing a list name in a countif.
//Is there an list called travellers
export const isExpressionIdPresent = () => ({
    err: null,
    validate(v) {
        let expr = store.getters["scheme/getExpressionIdentifers"];
        if (!expr) {
            //this is here as when we initiall load
            //we dont have the expressions. We have to assume
            //that this is valid as it has been previously saved.
            return true;
        }
        //eslint-disable-next-line
        return store.getters["scheme/getExpressionIdentifers"].find((id) => id === v) === undefined ? false : true;
    },
    message() {
        return "Cannot find an expression with this name";
    }
});

//checks to see if the expression returns an array
export const isExpressionReturningArray = () => ({
    err: null,
    validate(v) {
        let expr = store.getters["scheme/getExpressionIdentifers"];
        if (!expr) {
            //this is here as when we initially load
            //we dont have the expressions. We have to assume
            //that this is valid as it has been previously saved.
            return true;
        }
        let exp = store.getters["scheme/getExpression"](v);
        if (exp && exp.returns.type === "array") {
            return true;
        }
        return false;
    },
    message() {
        return "This expression does not return a list";
    }
});

/**
 * @function isExpressionReturning
 * @description tests if the referenced expression returns the provided type
 * @param {string} type - the type the expression should return
 * @param {string} [userType] - this value is used in the error message as the type, defaults to type
 * @returns {Validator} - new validator object
 */
export const isExpressionReturning = (type, userType) => ({
    err: null,
    validate(v) {
        let expr = store.getters["scheme/getExpressionIdentifers"];
        if (!expr) {
            //this is here as when we initially load
            //we dont have the expressions. We have to assume
            //that this is valid as it has been previously saved.
            return true;
        }
        let exp = store.getters["scheme/getExpression"](v);
        if (exp && exp.returns.type === type) {
            return true;
        }
        return false;
    },
    message() {
        return `This expression does not return a ${userType ? userType : type}`;
    }
});

//checks to see if the given property returns an array of strings
export const isPropertyArrayOfStrings = () => ({
    err: null,
    validate(v) {

        if (!v) {
            //this is here as when we initially load
            //we dont have the expressions. We have to assume
            //that this is valid as it has been previously saved.
            return true;
        }

        if (v && Array.isArray(v)) {
            return v.length === 0 || v.every(el => typeof el === "string");
        }
        return false;
    },
    message() {
        return "This expression does not return an array of strings";
    }
});

//used by a lookup to check that a selected table exists
export const isExpressionTable = () => ({
    err: null,
    validate(v) {
        let expr = store.getters["scheme/getExpressionIdentifers"];
        if (!expr) {
            //this is here as when we initially load
            //we dont have the expressions. We have to assume
            //that this is valid as it has been previously saved.
            return true;
        }
        let exp = store.getters["scheme/getExpression"](v);
        if (exp && exp.expressionType === "table") {
            return true;
        }
        return false;
    },
    message() {
        return "This expression is not a table";
    }
});

//checks to see if the expression id exists e.g when
//choosing a list name in a countif.
//Is there an list called travellers
export const isExpressionIdUnique = () => ({
    err: null,
    validate(v) {
        let expr = store.getters["scheme/getExpressionIdentifers"];
        if (!expr) {
            //this is here as when we initiall load
            //we dont have the expressions. We have to assume
            //that this is valid as it has been previously saved.
            return true;
        }
        return store.getters["scheme/getExpressionIdentifers"].find((id) => id === v) === undefined ? true : false;
    },
    message() {
        return "This expression name has already been used.";
    }
});

//checks to see if the looklup table has the correct
//number of columns.and that all values are present
export const isLookupTableHeadingValid = () => ({
    err: null,
    validate(v, { properties } = {}) {

        this.err = "All mappings must contain a value";
        let expr = store.getters["scheme/getExpressionIdentifers"];
        if (!expr) {
            //this is here as when we initiall load
            //we dont have the expressions. We have to assume
            //that this is valid as it has been previously saved.
            return true;
        }
        //let table =  store.getters["scheme/getExpression"](properties["tableName"].value);
        if (!Array.isArray(properties["mappedHeadings"].value) || !Array.isArray(v)) {
            return false;
        }

        let returnLength = 0;
        if(properties["mappedReturns"].value){
            returnLength = properties["mappedReturns"].value.filter(isReturn => isReturn).length;

        }
        if(returnLength<2&&properties["mappedHeadings"].value.length !== v.length ){
            this.err = "There are some unmapped headings";
            return false;
        }

        if (v.some((val) => val.trim() === "")) {
            this.err = "Pease ensure all mappings have been completed";
            return false;
        }

        return true;
    },
    message() {
        return this.err;
    }
});

//checks to see if the expression id exists e.g when
//choosing a list name in a countif.
//Is there an list called travellers
export const hasValidChildren = () => ({
    err: null,
    validate(v) {
        if (v === undefined) {
            return true;
        }
        let valid = !v.some(e => ["request", "foreach", "if", "block"].includes(e.expressionType));
        return valid;
    },
    message() {
        return "This expression cannot contain request, if, block or foreach expressions";
    }
});

// checks to see if the block contains unique child ids
// used to validate ids within an output group
export const hasUniqueChildIds= () => ({
    err: null,
    validate(v) {
        if (v === undefined) {
            return true;
        }
        const ids = v.map(e => e.id);
        let valid = new Set(ids).size === ids.length;
        return valid;
    },
    message() {
        return "This expression cannot contain duplicate child ids";
    }
});

// checks to see if expression relies
// on another expression and lets the
// user know if that expression is not
// within scope.

export const isExpressionInScope = () => ({
    err: null,
    validate(v, expression) {
        if (v === undefined || v === null) {
            return true;
        }
        let valid;
        const expressions = store.getters["scheme/getExpressionIdentifers"];
        if (!expressions || !expression) {
            return true;
        }
        const indexOfCurrent = expressions.indexOf(expression.properties.id.value);
        const expressionsToCheck = expressions.map((exp, index) => {
            if (exp !== expression.properties.id.value && index < indexOfCurrent) {
                return exp;
            }
        });
        if (expression.expressionType === "lookuptable"
            && store.getters["scheme/getExpression"](expression.properties._expId.value, true) === null
            || expression.expressionType === "lookup"
            && store.getters["scheme/getExpression"](expression.properties._expId.value, true) === null) {
            return true;
        }

        const { parent } = store.getters["scheme/getExpression"](expression.properties._expId.value, true);
        let values = [...v];

        switch (expression.properties.expressionType.value) {
            case "pick":
            case "countif":
            case "aggregate":
            case "verisktwo":
            case "foreach":
                valid = expressionsToCheck.includes(v);
                break;
            case "lookup":
                // eslint-disable-next-line no-case-declarations
                const dependencies = values.map(v => v.name);
                // eslint-disable-next-line no-case-declarations
                let sliceValue = 0 - expression.properties.columns.value.filter(column => column.return).length;

                sliceValue = sliceValue < 0 ? sliceValue : -1;
                valid = dependencies.slice(0, sliceValue).every(property => {
                    property = property.split(".")[0];
                    if (parent !== null) {
                        const pValue = parent.properties.item && parent.properties.item.value ?
                            parent.properties.item.value : undefined;
                        return expressionsToCheck.includes(property) || property === pValue;
                    } else {
                        return expressionsToCheck.includes(property);
                    }
                });
                break;
            case "lookuptable":
                // eslint-disable-next-line no-case-declarations
                let slice = 0-expression.properties.mappedReturns.value.filter(isReturn=>isReturn).length;
                slice = slice<0?slice:-1;

                valid = values.slice(0, slice).every(property => {
                    property = property.split(".")[0];
                    if (parent !== null) {
                        const pValue = parent.properties.item.value ? parent.properties.item.value : undefined;
                        return expressionsToCheck.includes(property) || property === pValue;
                    } else {
                        return expressionsToCheck.includes(property);
                    }
                });
                break;
        }
        return valid;
    },
    message(property, expression) {
        // const current = store.getters["scheme/getCurrentExpression"];
        const current = expression;
        let message;
        switch (current.properties.expressionType.value) {
            case "pick":
            case "countif":
            case "aggregate":
            case "foreach":
                message = `Current expression requires ${current.properties.items.value} to be within scope, 
                please ensure ${current.properties.id.value} is below ${current.properties.items.value}`;
                break;
            case "lookuptable":
                // eslint-disable-next-line no-case-declarations
                const lookuptableErrors = [...current.properties.headings.value].slice(0, -1);
                message = `Current expression requires ${lookuptableErrors.join(", ")} 
                to be within scope, please ensure
                    ${current.properties.id.value} is below ${lookuptableErrors.join(", ")}`;
                break;
            case "lookup":
                // eslint-disable-next-line no-case-declarations
                let sliceValue = 0 - current.columns.filter(column => column.return).length;

                sliceValue = sliceValue < 0 ? sliceValue : -1;


                // eslint-disable-next-line no-case-declarations
                const lookupformattedHeadings = [...current.properties.columns.value]
                    .map(heading => heading.name).slice(0, sliceValue);

                message = `Current expression requires ${lookupformattedHeadings.join(", ")} 
                to be within scope, please ensure
                    ${current.properties.id.value} is below ${lookupformattedHeadings.join(", ")}`;

                break;
            case "verisktwo":
                message = `Current expression requires ${current.properties[property].value} to be within scope, 
                    please ensure ${current.properties.id.value} is below ${current.properties[property].value}`;
                break;
        }
        return message;
    }
});

/**
 * @function isIn
 * @description creates a validator which checks if the value is in the provided array
 * @param {Array} arr - array of possible values
 * @return {object} - validator object
 */
export const isIn = (arr) => ({
    err: null,
    validate(v) {
        return arr.includes(v);
    },
    message() {
        return `Value must be one of: ${arr.join(", ")}`;
    }
});

/**
 * @function orValidate
 * @description takes two validator arrays and returns true if either is valid
 * @param {Validator[]} leftValidators - array of validators to try
 * @param {Validator[]} rightValidators - array of validators to try
 * @param {string} message - message to display if the validation fails
 * @returns {Validator} - a validator
 */
export const orValidate = (leftValidators, rightValidators, errorMessage) => ({
    err: null,
    validate(...v) {
        if (
            leftValidators.some((f) => f.validate(...v) === false) &&
            rightValidators.some((f) => f.validate(...v) === false)
        ) {
            this.error = errorMessage;
            return false;
        }
        return true;
    },
    message() {
        return errorMessage;
    }
});

export const isExpressionDuplicate = () => ({
    err: null,
    validate(v) {
        const exp = store.getters["scheme/getExpressionIdentifers"];
        if (!exp) {
            return true;
        }
        return exp.filter(e => e === v).length < 2;
    },
    message() {
        return "Expression ID already exists";
    } 
});
