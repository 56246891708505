import Expression from "./Expression";
import {
    hasUniqueChildIds,
    hasValidChildren
} from "./ExpressionValidators";
export default class OutputGroupExpression extends Expression {
    constructor(id){
        const properties = {
            expressionType: {
                value: "outputgroup",
                isValid: null,
                validators: []
            },
            block: {
                value: undefined,
                isValid: null,
                validators: [
                    hasValidChildren(),
                    hasUniqueChildIds()
                ]
            },
            nestedIds: {
                value: true,
                isValid: null,
                validators: []
            }
        };
        super(id,properties);

    }
    static cleanExpression(exp) {
        return {
            id: exp.id,
            returns: exp.returns,
            expressionType: exp.expressionType,
            description: exp.description,
            tags: exp.tags,
            block: exp.block.map((item) => item.clean())
        };
    }
}
