import {isNotEmpty} from "./ExpressionValidators";
import Expression from "./Expression";

export default class ResourceExpression extends Expression {
    constructor(id){
        const properties = {
            data:{
                value: null,
                isValid: null,
                dirty: false,
                errors: [],
                validators: [
                    isNotEmpty("Value")
                ]
            },
            resourceType: {
                value: null,
                isValid: null,
                validators: []
            },
            expressionType:{
                value: "resource",
                isValid: null,
                validators: []
            }
        };
        super(id,properties);
    }

    static cleanExpression(exp) {
        return {
            id: exp.id,
            returns: exp.returns,
            data: exp.data,
            resourceType: exp.resourceType,
            expressionType: exp.expressionType,
            description: exp.description,
            tags: exp.tags
        };
    }
}
