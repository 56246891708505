<template>
    <div>
        <BaseHeader
            title="Profile"
            :breadcrumbs="breadcrumbs"
        />
        <div class="profile-wrapper">
            <h3 class="mb-3">
                Details
            </h3>
            <UserProfileForm />
            <div class="mt-5">
                <h3 class="mb-3">
                    Access
                </h3>
                <UserProfileAccessList
                    :access="userProviderAccess"
                    :fields="providerFields"
                    no-records-message="You have no provider access"
                />
                <UserProfileAccessList
                    :access="userBrandAccess"
                    :fields="brandFields"
                    no-records-message="You have no brand access"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import BaseHeader from "../base/BaseHeader.vue";
import UserProfileForm from "./UserProfileForm.vue";
import UserProfileAccessList from "./UserProfileAccessList.vue";
import store from "../../../store";

async function fetchProfile(to, from, next) {
    try {
        await store.dispatch("auth/fetchProfile");
        await store.dispatch("auth/fetchUserBrandAccess");
        await store.dispatch("auth/fetchUserProviderAccess");
        return next();
    } catch(err) {
        return next((vm)=> {
            vm.$bvToast.toast(err.message, {
                title: "Error",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true
            });
        });
    }
}
export default {
    name: "UserProfile",
    components: {
        BaseHeader,
        UserProfileForm,
        UserProfileAccessList
    },
    beforeRouteEnter(to, from, next) {
        fetchProfile(to, from, next);
    },
    beforeRouteUpdate(to, from, next) {
        fetchProfile(to, from, next);
    },
    data() {
        return {
            providerFields: [
                {
                    key: "brand",
                    label: "Provider"
                },
                {
                    key: "roles",
                    label: "Roles"
                }
            ],
            brandFields: [
                {
                    key: "brand",
                    label: "Brand"
                },
                {
                    key: "roles",
                    label: "Roles"
                }
            ],
            breadcrumbs: [
                {
                    to: { name: "account" },
                    title: "Account"
                },
                {
                    title: "Profile"
                }
            ]
        };
    },
    computed: {
        ...mapState({
            userBrandAccess: state => state.auth.userBrandAccess,
            provider: state => state.auth.provider
        }),
        userProviderAccess() {
            return this.$store.state.auth.userProviderAccess.map(access => ({
                ...access,
                "brand_id": this.provider,
                "brand_name": this.provider
            }));
        }
    }
};
</script>

<style lang="sass" scoped>
.profile-wrapper
    max-width: 400px
</style>
