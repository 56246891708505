import dayjs from "dayjs";
import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import App from "./App.vue";
import store from "../store";
import router from "../router/router";
import VueRouter from "vue-router";
import userpilotService from "../services/userpilot-service";
import axios from "../services/axios";

//********** Font Awesome ***************/

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faCodeMerge } from "@fortawesome/pro-light-svg-icons";
import { faLock } from "@fortawesome/pro-light-svg-icons";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { faPlaneDeparture } from "@fortawesome/pro-light-svg-icons";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { faGlobeEurope } from "@fortawesome/pro-light-svg-icons";
import { faBackpack } from "@fortawesome/pro-light-svg-icons";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import { faMapMarkedAlt } from "@fortawesome/pro-light-svg-icons";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";
import { faUpload } from "@fortawesome/pro-light-svg-icons";
import { faSave } from "@fortawesome/pro-light-svg-icons";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { faTag } from "@fortawesome/pro-light-svg-icons";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { faMinus } from "@fortawesome/pro-light-svg-icons";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { faPalette } from "@fortawesome/pro-light-svg-icons";
import { faTachometerAltSlowest } from "@fortawesome/pro-light-svg-icons";
import { faFileUpload } from "@fortawesome/pro-light-svg-icons";
import { faShip } from "@fortawesome/pro-light-svg-icons";
import { faHandshake } from "@fortawesome/pro-light-svg-icons";
import { faSkiing } from "@fortawesome/pro-regular-svg-icons";
import { faBold } from "@fortawesome/pro-regular-svg-icons";
import { faItalic } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faStrikethrough } from "@fortawesome/pro-regular-svg-icons";
import { faUnderline } from "@fortawesome/pro-regular-svg-icons";
import { faParagraph } from "@fortawesome/pro-regular-svg-icons";
import { faCode } from "@fortawesome/pro-regular-svg-icons";
import { faListUl } from "@fortawesome/pro-regular-svg-icons";
import { faListOl } from "@fortawesome/pro-regular-svg-icons";
import { faUndo } from "@fortawesome/pro-regular-svg-icons";
import { faRedo } from "@fortawesome/pro-regular-svg-icons";
import { faTable } from "@fortawesome/pro-regular-svg-icons";
import { faHorizontalRule } from "@fortawesome/pro-regular-svg-icons";
import { faQuoteRight } from "@fortawesome/pro-regular-svg-icons";
import { faH1 } from "@fortawesome/pro-regular-svg-icons";
import { faH2 } from "@fortawesome/pro-regular-svg-icons";
import { faH3 } from "@fortawesome/pro-regular-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { faComment } from "@fortawesome/pro-light-svg-icons";
import { faMousePointer } from "@fortawesome/pro-light-svg-icons";
import { faMoneyCheckEdit } from "@fortawesome/pro-light-svg-icons";
import { faVialCircleCheck } from "@fortawesome/pro-light-svg-icons";



import { faEdit } from "@fortawesome/pro-light-svg-icons";
import { faBug } from "@fortawesome/pro-light-svg-icons";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import { faSignOutAlt } from "@fortawesome/pro-light-svg-icons";
import { faChartBar } from "@fortawesome/pro-light-svg-icons";
import { faBan } from "@fortawesome/pro-light-svg-icons";
import { faProjectDiagram } from "@fortawesome/pro-light-svg-icons";
import { faFileAlt } from "@fortawesome/pro-light-svg-icons";
import { faCreditCard } from "@fortawesome/pro-light-svg-icons";
import { faFileSearch } from "@fortawesome/pro-light-svg-icons";
import { faLink } from "@fortawesome/pro-light-svg-icons";
import { faDoorOpen } from "@fortawesome/pro-light-svg-icons";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { faAngleRight } from "@fortawesome/pro-light-svg-icons";
import { faAngleLeft } from "@fortawesome/pro-light-svg-icons";
import { faAngleUp } from "@fortawesome/pro-light-svg-icons";
import { faBell } from "@fortawesome/pro-light-svg-icons";
import { faEnvelopeOpenText } from "@fortawesome/pro-light-svg-icons";
import { faLockAlt } from "@fortawesome/pro-light-svg-icons";
import { faIndustry } from "@fortawesome/pro-light-svg-icons";
import { faKeyboard } from "@fortawesome/pro-light-svg-icons";
import { faHeadset } from "@fortawesome/pro-light-svg-icons";
import { faArrowUp } from "@fortawesome/pro-light-svg-icons";
import { faArrowDown } from "@fortawesome/pro-light-svg-icons";
import { faMobile } from "@fortawesome/pro-light-svg-icons";
import { faBinoculars } from "@fortawesome/pro-light-svg-icons";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { faDesktopAlt } from "@fortawesome/pro-light-svg-icons";
import { faTabletAndroid } from "@fortawesome/pro-light-svg-icons";
import { faGolfClub } from "@fortawesome/pro-light-svg-icons";
import { faRingsWedding } from "@fortawesome/pro-light-svg-icons";
import { faPlane } from "@fortawesome/pro-light-svg-icons";
import { faBiking } from "@fortawesome/pro-light-svg-icons";
import { faChartPie } from "@fortawesome/pro-light-svg-icons";
import { faClone } from "@fortawesome/pro-light-svg-icons";
import { faPlay } from "@fortawesome/pro-light-svg-icons";
import { faFileDownload } from "@fortawesome/pro-light-svg-icons";
import { faUsers } from "@fortawesome/pro-light-svg-icons";
import { faUser } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { faCodeBranch } from "@fortawesome/pro-light-svg-icons";
import { faPuzzlePiece } from "@fortawesome/pro-light-svg-icons";
import { faHistory } from "@fortawesome/pro-light-svg-icons";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { faFolders } from "@fortawesome/pro-light-svg-icons";
import { faShield } from "@fortawesome/pro-light-svg-icons";
import { faTh } from "@fortawesome/pro-solid-svg-icons";
import { faLockOpenAlt } from "@fortawesome/pro-light-svg-icons";
import { faSortAmountUp } from "@fortawesome/pro-solid-svg-icons";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { faRotate } from "@fortawesome/pro-light-svg-icons";



library.add(faXmark);
library.add(faSortAmountUp);
library.add(faCodeMerge);
library.add(faLock);
library.add(faMousePointer);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faHistory);
library.add(faBug);
library.add(faCodeBranch);
library.add(faCheck);
library.add(faExclamationCircle);
library.add(faUsers);
library.add(faUser);
library.add(faInfoCircle);
library.add(faFileDownload);
library.add(faPlay);
library.add(faClone);
library.add(faChartPie);
library.add(faBiking);
library.add(faPlane);
library.add(faRingsWedding);
library.add(faGolfClub);
library.add(faTabletAndroid);
library.add(faDesktopAlt);
library.add(faBinoculars);
library.add(faMobile);
library.add(faHeadset);
library.add(faComment);
library.add(faArrowDown);
library.add(faArrowUp);
library.add(faEdit);
library.add(faKeyboard);
library.add(faIndustry);
library.add(faLockAlt);
library.add(faEnvelopeOpenText);
library.add(faTimes);
library.add(faChevronDown);
library.add(faTrashAlt);
library.add(faBold);
library.add(faItalic);
library.add(faStrikethrough);
library.add(faUnderline);
library.add(faParagraph);
library.add(faBan);
library.add(faCode);
library.add(faListUl);
library.add(faListOl);
library.add(faUndo);
library.add(faRedo);
library.add(faTable);
library.add(faHorizontalRule);
library.add(faQuoteRight);
library.add(faH1);
library.add(faH2);
library.add(faH3);
library.add(faFileUpload);
library.add(faTachometerAltSlowest);
library.add(faPalette);
library.add(faTrash);
library.add(faPlus);
library.add(faMinus);
library.add(faBell);
library.add(faCog);
library.add(faAngleDown);
library.add(faAngleUp);
library.add(faAngleRight);
library.add(faAngleLeft);
library.add(faDoorOpen);
library.add(faLink);
library.add(faFileSearch);
library.add(faCreditCard);
library.add(faFileAlt);
library.add(faProjectDiagram);
library.add(faChartBar);
library.add(faSignOutAlt);
library.add(faBars);
library.add(faSearch);
library.add(faTag);
library.add(faTimesCircle);
library.add(faCheckCircle);
library.add(faSave);
library.add(faUpload);
library.add(faFilePdf);
library.add(faGlobeEurope);
library.add(faMapMarkedAlt);
library.add(faCalendarAlt);
library.add(faBackpack);
library.add(faPlaneDeparture);
library.add(faShip);
library.add(faHandshake);
library.add(faSkiing);
library.add(faQuestionCircle);
library.add(faPuzzlePiece);
library.add(faArchive);
library.add(faClock);
library.add(faMoneyCheckEdit);
library.add(faFolders);
library.add(faShield);
library.add(faTh);
library.add(faPen);
library.add(faLockOpenAlt);
library.add(faRotate);
library.add(faVialCircleCheck);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);

Vue.config.productionTip = false;

//********** Axios ***************/
Vue.prototype.$http = axios;

//********** Moment ***************/
import moment from "moment";
Vue.prototype.$moment = moment;

//********** Bootstrap / Styling ***************/
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "nprogress/nprogress.css";
Vue.use(BootstrapVue);

//********** Validation ***************/
import VeeValidate, { Validator } from "vee-validate";

//********** VueFormulate *************/
import { renderComponents } from "@atomic/atomic-components";
import VCalendar from "v-calendar";

Vue.use(VCalendar);

import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

Vue.use(VueFormulate, {
    useInputDecorators: false,
    plugins: Object.values(renderComponents).map((el) => el && el.addToVueFormulate),
    rules: {
        ukmobile: ({ value }) => value.match(/(^$)|(^(07|(00|\+)?447)\d{3}(\s)?\d{6}$)/),
        // eslint-disable-next-line
        ukphone: ({ value }) => value.match(/(^$)|(^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|#)\d{3,4})?$)/),
        alphabetHyphenApostropheDot: ({ value }) => value.match(/^([.\-']?[a-zA-ZÀ-ž][.\-']?)+$/g),
        // eslint-disable-next-line
        customEmail: ({ value }) => value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
        vehicleModel: ({ value }) => value.match(/^([A-Za-zÀ-ÖØ-öø-ÿ0-9][.\-' !]?)+$/g),
        noSpecialCharacters: ({ value }) => value.match(/^([\w\s-])*$/g),
        dob: ({ value }) => {
            if (dayjs(value, "YYYY-MM-DD").format("YYYY-MM-DD") === value) {
                return true;
            }
            return false;
        },
        minAge: ({ value }, minAge) => {
            if (minAge) {
                const [yyyy, mm, dd] = value.split("-");
                // Month is indexed from zero
                const enteredDate = new Date(yyyy, mm - 1, dd);
                const youngestDOB = new Date();
                youngestDOB.setHours(0, 0, 0, 0);
                youngestDOB.setFullYear(youngestDOB.getFullYear() - minAge);
                if (enteredDate > youngestDOB) {
                    return false;
                }
            }
            return true;
        },
        maxAge: ({ value }, maxAge) => {
            if (maxAge) {
                const [yyyy, mm, dd] = value.split("-");
                // Month is indexed from zero
                const enteredDate = new Date(yyyy, mm - 1, dd);
                const oldestDOB = new Date();
                oldestDOB.setHours(0, 0, 0, 0);
                oldestDOB.setFullYear(oldestDOB.getFullYear() - maxAge);
                if (enteredDate < oldestDOB) {
                    return false;
                }
            }
            return true;
        },
        dateInPast: ({ value }) => {
            const [yyyy, mm, dd] = value.split("-");
            // Month is indexed from zero
            const enteredDate = new Date(yyyy, mm - 1, dd);
            const today = new Date();
            if (enteredDate > today) {
                return false;
            }
            return true;
        }
    }
});

const dictionary = {
    en: {
        messages: {
            required: (field) => `${field} is required`
        }
    }
};

Validator.localize(dictionary);

Vue.use(VeeValidate, {
    // This is the default
    inject: true,
    // Important to name this something other than 'fields'
    fieldsBagName: "veeFields",
    // This is not required but avoids possible naming conflicts
    errorBagName: "veeErrors",
    validity: true
});

//********** Base Components ***************/

const requireComponent = require.context(
    // The relative path of the components folder
    "./components/base",
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);
    const componentName = fileName.split("/").pop().replace(/\.\w+$/, "");

    // Register component globally
    Vue.component(
        componentName,
        componentConfig.default || componentConfig
    );
});


//********** Logging ***************/
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
//eslint-disable-next-line
if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://eb8f0465cbd34835b2764b2f3b5bd4cf@sentry.theidol.com/37",
        integrations: [new Integrations.Vue({ Vue, attachProps: true })]
    });
}

//********** userpilot ***************/
const userPilotSetup = async () => {
    await fetch("/app/config").then(async (res) => {
        const envs = await res.json();
        store.dispatch("env/setEnvironmentVariables", envs).then(() => {
            if (envs.userPilotAppToken) {
                userpilotService.initialize(envs.userPilotAppToken);
            }
        });
    }).catch((err)=> {
        Sentry.captureException(new Error("Unable to retrieve userpilot token", { cause: err }));
    });
};
userPilotSetup();

//********** Vue Config ***************/

Vue.config.productionTip = false;

//********** Custom Directives ***************/
import { ClickOutside } from "./helpers/directives";

Vue.directive("focus", {
    // When the bound element is inserted into the DOM...
    inserted: function(el) {
        // Focus the element
        el.focus();
    }
});

Vue.directive("scroll", {
    inserted: function(el, binding) {
        let f = function(evt) {
            binding.value(evt, el);
        };

        el.addEventListener("scroll", f);
        //add a custom destroy method so that we can
        //call it on unbind.
        el.$destroy = () => {
            el.removeEventListener("scroll", f);
        };
    },
    unbind: function(el) {
        el.$destroy();
    }
});

Vue.directive("outside-click", ClickOutside);

Vue.use(VueRouter);

import AbilityPlugin from "../ability/abilityPlugin";
Vue.use(AbilityPlugin, { store });

new Vue({
    store,
    router,
    watch: {
        $route: {
            handler() {
                return userpilotService.reload();
            },
            deep: true,
            immediate: true
        }
    },
    render: h => h(App)
}).$mount("#app");

