<template>
    <BaseTable
        :items="groupedAccess"
        :search-visible="false"
        :fields="fields"
        fixed
    >
        <template #cell(brand)="{ item }">
            {{ item.brand_name }}
        </template>
        <template #cell(roles)="{ item }">
            {{ item.roles.join(", ") }}
        </template>
        <template #empty>
            <div class="text-center">
                {{ noRecordsMessage }}
            </div>
        </template>
    </BaseTable>
</template>

<script>
export default {
    name: "UserProfileAccessList",
    props: {
        access: {
            type: Array,
            default: () => []
        },
        fields: {
            type: Array,
            required: true
        },
        noRecordsMessage: {
            type: String,
            default: "No records to show"
        }
    },
    computed: {
        groupedAccess() {
            return this.access.reduce((acc, curr) => {
                const categoryIndex = acc.findIndex((item) => item.brand_id === curr.brand_id);

                if (categoryIndex === -1) {
                    acc.push({
                        "brand_id": curr.brand_id,
                        "brand_name": curr.brand_name,
                        roles: [curr.role_name]
                    });
                } else {
                    acc[categoryIndex].roles.push(curr.role_name);
                }

                return acc;
            }, []);
        }
    }
};
</script>
