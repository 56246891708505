import * as usersService from "../../../../services/provider/users";

export const types = {
    "SET_USERS": "SET_USERS"
};

const state = {
    users: []
};

const actions = {
    async getUsers({ commit }) {
        const { data } = await usersService.getUsers();
        commit(types.SET_USERS, data);
    }
};

const mutations = {
    [types.SET_USERS]: (state, users) => (state.users = users)
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
