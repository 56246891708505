import * as rolesService from "../../../../services/provider/roles";

export const types = {
    "SET_PROVIDER_ROLES": "SET_PROVIDER_ROLES",
    "SET_BRAND_ROLES": "SET_BRAND_ROLES"
};

const state = {
    providerRoles: [],
    brandRoles: []
};

const actions = {
    async getProviderRoles({ commit }) {
        const { data } = await rolesService.getProviderRoles();
        commit(types.SET_PROVIDER_ROLES, data);
    },
    async getBrandRoles({ commit }) {
        const { data } = await rolesService.getBrandRoles();
        commit(types.SET_BRAND_ROLES, data);
    }
};

const mutations = {
    [types.SET_PROVIDER_ROLES]: (state, providerRoles) => (state.providerRoles = providerRoles),
    [types.SET_BRAND_ROLES]: (state, brandRoles) => (state.brandRoles = brandRoles)
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
