import adminService from "../../services/admin";

export const types = {
    "GET_LOCKED": "GET_LOCKED",
    "SET_ERROR": "SET_ERROR",
    "SET_RETENTION_RULES": "SET_RETENTION_RULES",
    "SET_RULE": "SET_RULE",
    "GET_WEBHOOKS": "GET_WEBHOOKS",
    "GET_WEBHOOK_TYPES": "GET_WEBHOOK_TYPES",
    "GET_WEBHOOK_SERVICE_ERRORS": "GET_WEBHOOK_SERVICE_ERRORS",
    "GET_POLICY_NUMBER_METHOD": "GET_POLICY_NUMBER_METHOD",
    "GET_LAST_POLICY_NUMBER": "GET_LAST_POLICY_NUMBER",
    "GET_ADD_POLICY_NUMBERS_RESULT": "GET_ADD_POLICY_NUMBERS_RESULT",
    "SET_LOADING": "SET_LOADING",
    "GET_CUSTOM_POLICY_NUMBER_COUNT": "GET_CUSTOM_POLICY_NUMBER_COUNT",
    "SET_CUSTOM_POLICY_LOWTIDEMARK": "SET_CUSTOM_POLICY_LOWTIDEMARK"
};

const state = {
    error: "",
    locked: false,
    retentionRules: [],
    rule: [],
    webhooks: [],
    // eslint-disable-next-line camelcase
    webhook_types: [],
    webhooksServiceErrors: [],
    customPolicyNumbers: false,
    lastPolicyNumber: {},
    addPolicyNumbersResult: null,
    loading: false,
    customPolicyNumberCount: 0,
    customPolicyLowTidemark: 0
};

const actions = {
    async fetchRetentionRules({ commit, rootState }) {
        const brand = rootState.auth.selectedBrand.name_url;
        commit(types.SET_ERROR, "");
        try {
            const retentionRules = await adminService.fetchRetentionRules(brand);
            commit(types.SET_RETENTION_RULES, retentionRules.data);
        } catch (error) {
            commit(types.SET_ERROR, "Problem getting retention rules, please try again");
        }
    },
    async fetchRuleById({ commit, rootState }, payload) {
        const brand = rootState.auth.selectedBrand.name_url;
        commit(types.SET_ERROR, "");
        try {
            const rule = await adminService.fetchRuleById(brand, payload);
            commit(types.SET_RULE, rule.data);
        } catch (error) {
            commit(types.SET_ERROR, "Problem getting retention rules, please try again");
        }
    },
    updateLockedValue({commit}, payload) {
        commit(types.GET_LOCKED, payload);
    },
    async updateRetentionRule({ commit, rootState }, payload) {
        const brand = rootState.auth.selectedBrand.name_url;
        commit(types.SET_ERROR, "");
        try {
            await adminService.updateRetentionRule(brand, payload);
        } catch (error) {
            commit(types.SET_ERROR, "Problem getting retention rules, please try again");
        }
    },
    async addRetentionRule({ commit, rootState }, payload) {
        commit(types.SET_ERROR, "");
        const brand = rootState.auth.selectedBrand.name_url;
        try {
            await adminService.postRetentionRule(brand, payload);
        } catch (error) {
            commit(types.SET_ERROR, "Problem getting retention rules, please try again");
        }
    },
    async getWebhooks({commit, rootState}) {
        try {
            commit(types.SET_ERROR, false);
            const result = await adminService.getWebhooks(rootState.auth.selectedBrand.name_url);
            commit(types.GET_WEBHOOKS, result.data);
        } catch (err) {
            commit(types.SET_ERROR, "Issue getting webhooks");
        }
    },
    async getWebhookTypes({commit, rootState}) {
        try {
            commit(types.SET_ERROR, false);
            const result = await adminService.getWebhookTypes(rootState.auth.selectedBrand.name_url);
            commit(types.GET_WEBHOOK_TYPES, result.data);
        } catch (err) {
            commit(types.SET_ERROR, "Issue getting webhook types");
        }
    },
    async createWebhook({ commit, rootState }, payload) {
        try {
            await adminService.createWebhook(rootState.auth.selectedBrand.name_url, payload);
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        }
    },
    async updateWebhook({commit, rootState}, payload) {
        try {
            await adminService.updateWebhook(rootState.auth.selectedBrand.name_url, payload);
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        }
    },
    async deleteWebhook({commit, rootState}, webhook) {
        try {
            await adminService.deleteWebhook(rootState.auth.selectedBrand.name_url, webhook.id);
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        }
    },
    async getWebhooksServiceErrors({commit, rootState}) {
        try {
            commit(types.SET_ERROR, false);
            const result = await adminService.getWebhooksServiceErrors(rootState.auth.selectedBrand.name_url);
            commit(types.GET_WEBHOOK_SERVICE_ERRORS, result.data);
        } catch (err) {
            commit(types.SET_ERROR, "Issue getting service errors");
        }
    },
    async getPolicyNumberingMethod({commit, rootState}) {
        try {
            commit(types.SET_ERROR, false);
            const response = await adminService.getPolicyNumberingMethod(rootState.auth.selectedBrand.name_url);
            const {
                custom_policy_numbers: customNumbers,
                custom_policy_low_tidemark: lowTidemark
            } = response.data;
            commit(types.GET_POLICY_NUMBER_METHOD, customNumbers);
            commit(types.SET_CUSTOM_POLICY_LOWTIDEMARK, lowTidemark);
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        }
    },
    async setPolicyNumberingMethod({commit, rootState}, payload) {
        try {
            commit(types.SET_LOADING, true);
            const result = await adminService.setPolicyNumberingMethod(
                rootState.auth.selectedBrand.name_url,
                payload
            );
            const [customPolicyMethod] = result.data;
            commit(types.GET_POLICY_NUMBER_METHOD, customPolicyMethod["custom_policy_numbers"]);
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }
    },
    async getLastPolicyNumber({commit, rootState}) {
        try {
            commit(types.SET_ERROR, false);
            commit(types.SET_LOADING, true);
            const lastPolicyNumber = await adminService.getLastPolicyNumber(rootState.auth.selectedBrand.name_url);
            const [policyNumber] = lastPolicyNumber.data;
            commit(types.GET_LAST_POLICY_NUMBER, policyNumber["policy_reference"]);
            commit(types.GET_CUSTOM_POLICY_NUMBER_COUNT, policyNumber["count"]);
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }
    },
    async addpolicyNumbers({commit, rootState}, payload) {
        try {
            commit(types.SET_ERROR, false);
            commit(types.SET_LOADING, true);
            const result = await adminService.addPolicyNumbers(rootState.auth.selectedBrand.name_url, payload);
            commit(types.GET_ADD_POLICY_NUMBERS_RESULT, result.data);
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }
    },
    async setPolicyNumberLowTidemark({commit, rootState}, lowTidemark = 10000) {
        try {
            commit(types.SET_LOADING, true);
            const result = await adminService.setPolicyNumberLowTidemark(
                rootState.auth.selectedBrand.name_url,
                lowTidemark
            );
            const [data] = result.data;
            commit(types.SET_CUSTOM_POLICY_LOWTIDEMARK, data["custom_policy_low_tidemark"]);
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }
    }
};

const mutations = {
    [types.SET_ERROR]: (state, error) => (state.error = error),
    [types.GET_LOCKED]: (state, locked) => (state.locked = locked),
    [types.SET_RETENTION_RULES]: (state, retentionRules) => (state.retentionRules = retentionRules),
    [types.SET_RULE]: (state, rule) => (state.rule = rule),
    [types.GET_WEBHOOKS]: (state, webhooks) => (state.webhooks = webhooks),
    // eslint-disable-next-line camelcase
    [types.GET_WEBHOOK_TYPES]: (state, webhook_types) => (state.webhook_types = webhook_types),
    [types.GET_WEBHOOK_SERVICE_ERRORS]: (state, webhooksServiceErrors) =>
        (state.webhooksServiceErrors = webhooksServiceErrors),
    [types.GET_POLICY_NUMBER_METHOD]: (state, customPolicyNumbers) => (state.customPolicyNumbers = customPolicyNumbers),
    [types.GET_LAST_POLICY_NUMBER]: (state, lastPolicyNumber) => (state.lastPolicyNumber = lastPolicyNumber),
    [types.SET_LOADING]: (state, loading) => (state.loading = loading),
    [types.GET_ADD_POLICY_NUMBERS_RESULT]: (state, addPolicyNumbersResult) => (
        state.addPolicyNumbersResult = addPolicyNumbersResult),
    [types.GET_CUSTOM_POLICY_NUMBER_COUNT]: (state, policyNumberCount) => (
        state.customPolicyNumberCount = policyNumberCount),
    [types.SET_CUSTOM_POLICY_LOWTIDEMARK]: (state, customPolicyLowTidemark) => (
        state.customPolicyLowTidemark = customPolicyLowTidemark
    )
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
