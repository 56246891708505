<template>
    <span>
        <span
            v-if="pressed"
        >
            <b-progress
                :value="currentProgress"
                :max="maxProgress"
                :show-progress="showProgress"
                :show-value="showValue"
            />
            <a
                ref="downloadLink"
                :href="downloadData"
                :download="filename"
            />
        </span>
        <a
            class="downloadButton btn-multiple"
            @click="startDownload"
        >
            <slot>
                Download
            </slot>
        </a>
    </span>
</template>

<script>
export default {
    name: "DownloadViaXHR",
    props: {
        showProgress: {
            type: Boolean,
            default: true
        },
        showValue: {
            type: Boolean,
            default: false
        },
        downloadFunction: {
            type: Function,
            required: true
        },
        filename: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            pressed: false,
            currentProgress: 0,
            maxProgress: 100,
            downloadData: null
        };
    },
    beforeDestroy() {
        this.revokeDownloadData();
    },
    methods: {
        /**
         * @function revokeDownloadData
         * @description the download object remains in existence until the document is unloaded we need to call revoke
         *   on the object to manually destroy it to prevent memory leaks. this wraps the revoking
         * @returns {void} - no return value
         */
        revokeDownloadData() {
            if (this.downloadData) {
                this.downloadData.revokeObjectURL();
            }
        },
        /**
         * @function clickDownload
         * @description clicks the download button
         * @returns {void} - no return value
         */
        clickDownload() {
            this.$nextTick(() =>this.$refs.downloadLink.click());
        },
        /**
         * @function startDownload
         * @description starts the download of the file, uses the function passed in to download, passes in a custom
         * onDownloadProgress function to increment the progress bar. Once download is complete it creates an object
         * url and clicks it.
         * @async
         * @returns {void} - no return value
         */
        async startDownload() {
            if (this.pressed) {
                this.clickDownload();
                return;
            }
            this.revokeDownloadData();
            this.pressed = true;
            const res = await this.downloadFunction({
                onDownloadProgress: (event) => {
                    this.maxProgress = event.total;
                    this.currentProgress = event.loaded;
                }
            });

            this.downloadData = window.URL.createObjectURL(
                new Blob([res.data], { type: res.headers["content-type"], encoding: "UTF-8" }) 
            );

            this.clickDownload();
        }
    }
};
</script>

<style lang="sass">
.downloadButton 
    position: relative !important
    float: right
    margin-right: 0!important
    margin-bottom: 0!important
.progress 
    margin-bottom: 5px
    width: 100%
    &-bar 
        background-color: $green
        text-indent: -9999px

</style>
