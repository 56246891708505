import axios from "./axios";

const getAllowDocumentAttachments = (brand) => {
    return axios.get(`/brands/${brand}/email-templates/allow-document-attachments`);
};

const setAllowDocumentAttachments = (brand, payload) => {
    return axios.patch(`/brands/${brand}/email-templates/allow-document-attachments`, payload);
};

export default {
    getAllowDocumentAttachments,
    setAllowDocumentAttachments
};