<template>
    <div>
        <h3 class="mb-3">
            Password
        </h3>
        <ValidationObserver
            ref="form"
            v-slot="{ pending }"
            tag="form"
            @submit.prevent="updatePassword"
        >
            <ValidationProvider
                v-slot="{ errors }"
                :rules="{
                    required: true,
                }"
                name="Current Password"
                slim
                mode="eager"
            >
                <b-form-group
                    label-for="currentPassword"
                    :invalid-feedback="errors[0]"
                    :state="!errors[0] ? null : false"
                >
                    <template #label>
                        Current password
                        <span
                            aria-hidden="true"
                            class="text-danger"
                        >*</span>
                    </template>
                    <b-form-input
                        id="currentPassword"
                        v-model="form.current_password"
                        type="password"
                        autocomplete="current-password"
                        placeholder="Enter your current password"
                        :state="!errors[0] ? null : false"
                    />
                </b-form-group>
            </ValidationProvider>
            <ValidationProvider
                v-slot="{ errors }"
                :rules="{
                    required: true,
                    regex: passwordRegex
                }"
                name="New password"
                vid="password"
                mode="eager"
            >
                <b-form-group
                    label-for="password"
                    :invalid-feedback="errors[0]"
                    :state="!errors[0] ? null : false"
                >
                    <template #label>
                        New password
                        <span
                            aria-hidden="true"
                            class="text-danger"
                        >*</span>
                    </template>
                    <b-form-input
                        id="password"
                        v-model="form.password"
                        autocomplete="new-password"
                        type="password"
                        placeholder="Enter new password"
                        :state="!errors[0] ? null : false"
                    />
                </b-form-group>
                <UserPasswordValidationPopover
                    :value="form.password"
                    target="password"
                    :is-invalid="!!errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider
                v-slot="{ errors }"
                :rules="{
                    required: true,
                    confirmed: 'password'
                }"
                name="Confirm password"
                slim
                mode="eager"
            >
                <b-form-group
                    label-for="password2"
                    :invalid-feedback="errors[0]"
                    :state="!errors[0] ? null : false"
                >
                    <template #label>
                        Confirm password
                        <span
                            aria-hidden="true"
                            class="text-danger"
                        >*</span>
                    </template>
                    <b-form-input
                        id="password2"
                        v-model="form.password2"
                        autocomplete="new-password"
                        type="password"
                        placeholder="Confirm new password"
                        :state="!errors[0] ? null : false"
                    />
                </b-form-group>
            </ValidationProvider>
            <b-button
                variant="primary"
                type="submit"
                :disabled="pending"
            >
                Change password
            </b-button>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, Validator } from "vee-validate";
import authService from "../../../../services/auth";
import { PASSWORD_REGEX } from "../../../helpers/constants";
import UserPasswordValidationPopover from "./UserPasswordValidationPopover.vue";

const dict = {
    custom: {
        "Confirm password": {
            confirmed: "Confirm password does not match new password"
        }
    }
};

Validator.localize("en", dict);

const DEFAULT_FORM = Object.freeze({
    "current_password": "",
    password: "",
    password2: ""
});

export default {
    name: "UserPassword",
    components: {
        UserPasswordValidationPopover,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            form: { ...DEFAULT_FORM },
            passwordRegex: PASSWORD_REGEX
        };
    },
    methods: {
        async updatePassword() {
            try {
                const isValid = await this.$refs.form.validate();
                if (isValid) {
                    await authService.updatePassword(this.form);

                    this.$bvToast.toast("Your password has been changed", {
                        title: "Success",
                        variant: "success"
                    });

                    this.form = { ...DEFAULT_FORM };
                    await this.$refs.form.reset();
                }
            } catch (err) {
                this.$bvToast.toast(err.message, {
                    title: "Error",
                    variant: "danger"
                });
            }
        }
    }
};
</script>
