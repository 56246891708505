import templateServices from "../../services/templates";

export const types = {
    "SET_LOADING": "SET_LOADING",
    "SET_ERROR": "SET_ERROR",
    "SET_TEMPLATES": "SET_TEMPLATES",
    "SET_TEMPLATE_REVISIONS": "SET_TEMPLATE_REVISIONS",
    "SET_ID": "SET_ID",
    "SET_TEMPLATE_PREVIEW": "SET_TEMPLATE_PREVIEW",
    "SET_POLICY_TEMPLATES": "SET_POLICY_TEMPLATES",
    "SET_EMAIL_TEMPLATES": "SET_EMAIL_TEMPLATES",
    "UPDATE_EMAIL_TEMPLATE": "UPDATE_EMAIL_TEMPLATE"
};

const getters = {};

const state = {
    templates: [],
    loading: false,
    error: null,
    templateRevisions: [],
    templateID: null,
    templatePreview: null,
    subjectPreview: null,
    policyTemplates: [],
    emailTemplates: []
};

const actions = {
    async fetchTemplates({ commit }, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const templates = await templateServices.fetchTemplates(payload);
            commit(types.SET_TEMPLATES, templates.data);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async fetchEmailTemplates({ commit }, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const result = await templateServices.fetchEmailTemplates(payload);            
            commit(types.SET_EMAIL_TEMPLATES, result.data);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async createEmailTemplate({ commit, rootState }, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const insertedTemplate = await templateServices.createEmailTemplate(
                rootState.auth.selectedBrand.name_url, payload);
            commit(types.SET_LOADING, false);
            return insertedTemplate.data.id;
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async createTemplate({ commit, rootState }, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const insertedTemplate = await templateServices.createTemplate(
                rootState.auth.selectedBrand.name_url, payload);
            commit(types.SET_ID, insertedTemplate.data.templateID);
            commit(types.SET_LOADING, false);
            return insertedTemplate.data.templateID;
        } catch (error) {
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async fetchTemplateRevisions({ commit, rootState }, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        commit(types.SET_TEMPLATE_REVISIONS, []);
        try {
            const revisions = await templateServices.fetchRevisions(rootState.auth.selectedBrand.name_url, 
                payload.templateID, payload.tags, payload.comment);            
            commit(types.SET_TEMPLATE_REVISIONS, revisions.data.revisions);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },    
    async createRevision({ commit, rootState }, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            if(payload.templateID === null) {
                payload.templateID = this.state.templates.templateID;
            }
            await templateServices.postRevision(rootState.auth.selectedBrand.name_url, 
                payload.templateID, payload.template, payload.comment, payload.tags, payload.subject);
            commit(types.SET_LOADING, false);
        } catch(error) {
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async previewTemplate({ commit, rootState }, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const { revision, policyNumber } = payload;
            const template = await templateServices.previewTemplate(rootState.auth.selectedBrand.name_url, 
                revision.templateID, revision.revisionID, revision.template, revision.subject, policyNumber);
            commit(types.SET_TEMPLATE_PREVIEW, template.data);
            commit(types.SET_LOADING, false);
        } catch(error) {
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async setPolicyTemplates({commit, rootState}) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            const brandID = rootState.auth.selectedBrand.name_url;
            const result = await templateServices.getPolicyTemplates(brandID);
            commit(types.SET_POLICY_TEMPLATES, result.data);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async savePolicyTemplates({ commit, rootState}, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try {
            payload.brandID = rootState.auth.selectedBrand.name_url;
            await templateServices.savePolicyTemplates(payload);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    }
};

const mutations = {
    [types.SET_ERROR]: (state, error) => (state.error = error),
    [types.SET_LOADING]: (state, loading) => (state.loading = loading),
    [types.SET_TEMPLATES]: (state, templates) => (state.templates = templates),
    [types.SET_TEMPLATE_REVISIONS]: (state, revisions) => (state.templateRevisions = revisions),
    [types.SET_ID]: (state, id) => (state.templateID = id),
    [types.SET_TEMPLATE_PREVIEW]: (state, data) => {
        state.templatePreview = data.html;
        state.subjectPreview = data.subject;
    },
    [types.SET_POLICY_TEMPLATES]: (state, policyTemplates) => (state.policyTemplates = policyTemplates),
    [types.SET_EMAIL_TEMPLATES]: (state, emailTemplates) => (state.emailTemplates = emailTemplates)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};