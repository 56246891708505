/*
    Helper function to find a nested object.
    Usage - given an object, key and value, will search the given object for
    a nested object containing the provided key/value combination and return
    true if found, false otherwise.
*/
function formHasElementWithKey(data, key, value) {
    for (const element of data) {
        if (!element || typeof element !== "object" || element === null) {
            return false;
        }
        
        if (element[key] === value) {
            return true;
        }

        if (Array.isArray(element.children)) {
            if (formHasElementWithKey(element.children, key, value)) {
                return true;
            }
        }            
    }

    return false;
}

export {
    formHasElementWithKey
};