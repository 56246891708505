import reportsService from "../../services/reports";

function errGetter(err) {
    if (err.response) {
        return err.response.data.message;
    } else {
        return err.message;
    }
}

const state = {
    allReportsRan: null,
    allReports: null,
    aReport: null,
    allReportsRanPromise: null,
    allReportsPromise: null,
    aReportPromise: null,
    loadingReport: false,
    errorReport: null
};

const getters = {
};

const mutations = {
    SET_ALL_REPORTS_RAN_PROMISE(state, val) {
        state.allReportsRanPromise = val;
    },
    SET_ALL_REPORTS_RAN(state, val) {
        state.allReportsRan = val;
    },
    SET_ALL_REPORTS(state, val) {
        state.allReports = val;
    },
    PUSH_TO_ALL_REPORTS(state, val) {
        state.allReports.push(val);
    },
    SET_A_REPORT(state, val) {
        state.aReport = val;
    },
    SET_ALL_REPORTS_PROMISE(state, val) {
        state.allReportsPromise = val;
    },
    SET_A_REPORT_PROMISE(state, val) {
        state.aReportPromise = val;
    },
    SET_LOADING_REPORT(state, val) {
        state.loadingReport = val;
    },
    SET_ERROR_REPORT(state, val) {
        state.errorReport = val;
    }
};

const actions = {
    fetchResultsDownloadUrl(store, options) {
        return reportsService.fetchResultsDownloadUrl(options);
    },
    deleteReportRan(store, options) {
        return reportsService.deleteReportRan(options);
    },
    deleteReportTemplate(store, options) {
        return reportsService.deleteReportTemplate(options);
    },
    fetchAllReportsRan({ commit, state, rootState }, options) {
        commit("SET_LOADING_REPORT", true);
        if (state.allReportsRanPromise !== null) {
            return state.allReportsRanPromise;
        }
        let fetching = reportsService.fetchAllReportsRan({
            brandURL: rootState.auth.selectedBrand.name_url,
            options
        }).then((response) => {
            commit("SET_ALL_REPORTS_RAN", response.data);
            return response.data;
        }).catch((err) => {
            commit("SET_ERROR_REPORT", errGetter(err));
            throw err;
        }).finally(() => {
            commit("SET_ALL_REPORTS_RAN_PROMISE", null);
            commit("SET_LOADING_REPORT", false);
        });
        commit("SET_ALL_REPORTS_RAN_PROMISE", fetching);
        return fetching;
    },
    fetchAllReports({ commit, state, rootState }) {
        commit("SET_LOADING_REPORT", true);
        if (state.allReportsPromise !== null) {
            return state.allReportPromise;
        }
        let fetching = reportsService.fetchAllReport({
            brandURL: rootState.auth.selectedBrand.name_url
        }).then((response) => {
            commit("SET_ALL_REPORTS", response.data);
            return response.data;
        }).catch((err) => {
            commit("SET_ERROR_REPORT", errGetter(err));
            throw err;
        }).finally(() => {
            commit("SET_ALL_REPORTS_PROMISE", null);
            commit("SET_LOADING_REPORT", false);
        });
        commit("SET_ALL_REPORTS_PROMISE", fetching);
        return fetching;
    },
    fetchAReport({ commit, state, rootState }, id) {
        commit("SET_LOADING_REPORT", true);
        if (state.aReportPromise !== null) {
            return state.aReportPromise;
        }
        let fetching = reportsService.fetchAReport({
            brandURL: rootState.auth.selectedBrand.name_url,
            id: id
        }).then((response) => {
            commit("SET_A_REPORT", response.data);
            return response.data;
        }).catch((err) => {
            commit("SET_ERROR_REPORT", errGetter(err));
            throw err;
        }).finally(() => {
            commit("SET_LOADING_REPORT", false);
            commit("SET_A_REPORT_PROMISE", null);
        });
        commit("SET_A_REPORT_PROMISE", fetching);
        return fetching;
    },
    createAReport({ commit, rootState }, report) {
        commit("SET_LOADING_REPORT", true);
        return reportsService.postAReport({
            brandURL: rootState.auth.selectedBrand.name_url,
            name: report.name
        }).then((response) => {
            commit("PUSH_TO_ALL_REPORTS", response.data);
            return response;
        }).catch((err) => {
            commit("SET_ERROR_REPORT", errGetter(err));
            throw err;
        }).finally(() => {
            commit("SET_LOADING_REPORT", false);
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
