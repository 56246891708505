import * as providerService from "../../../services/provider";
import users from "./users/users";
import userAccess from "./users/userAccess";
import roles from "./roles/roles";
import role from "./roles/role";
import permissions from "./permissions/permissions";
import conditions from "./conditions/conditions";
import condition from "./conditions/condition";

export const types = {
    "SET_BRANDS": "SET_BRANDS",
    "SET_SETTINGS": "SET_SETTINGS"
};

const state = {
    brands: [],
    settings: []
};

const actions = {
    async getBrands({ commit }) {
        const { data } = await providerService.getBrands();
        commit(types.SET_BRANDS, data);
    },
    async getSettings({ commit }) {
        const { data } = await providerService.getSettings();
        commit(types.SET_SETTINGS, data);
    }
};

const mutations = {
    [types.SET_BRANDS]: (state, brands) => (state.brands = brands),
    [types.SET_SETTINGS]: (state, settings) => (state.settings = settings)
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        users,
        userAccess,
        roles,
        role,
        permissions,
        conditions,
        condition
    }
};
