import axios from "./axios";

export default {
    async getScheme(schemeId, revisionId, brandURL, options) {
        let response = await axios.get(`brands/${brandURL}/schemes/${schemeId}/revisions/${revisionId}`, options);
        return {
            schemeId: response.data.scheme_id,
            expressions: response.data.scheme,
            name: response.data.name + " " + (response.data.comment || ""),
            revisionId: response.data.id
        };
    },
    async saveScheme(schemeId,brandURL,expressions,comment,tags,branchedFrom=null) {
        let response = await axios.patch(`brands/${brandURL}/schemes/${schemeId}`,{
            expressions,
            comment,tags,
            "branched_from":branchedFrom});
        return {
            revisionId:response.data.revision_id,
            schemeId:response.data.scheme_id
        };
    },
    async getSchemeRevisions(schemeId, brandURL, options = {}) {
        return axios.get(`brands/${brandURL}/schemes/${schemeId}/revisions`, { params: options });
    },
    getSchemeRevision(schemeId, revisionId, brandURL, options) {
        return axios.get(`brands/${brandURL}/schemes/${schemeId}/revisions/${revisionId}`, options);
    },
    uploadTestData(data, schemeId, brandURL, revisionId) {
        return axios.post(
            `brands/${brandURL}/schemes/${schemeId}/revisions/${revisionId}/test?type=multiple&engine=production`,
            data
        );
    },
    uploadDetailedTest(data, schemeId, brandURL, revisionId, engine) {
        //eslint-disable-next-line
        return axios.post(`brands/${brandURL}/schemes/${schemeId}/revisions/${revisionId}/test?type=detailed&engine=${engine}`, data);
    },
    historicTest(data, schemeId, brandURL, revisionId) {
        //eslint-disable-next-line
        return axios.post(`brands/${brandURL}/schemes/${schemeId}/revisions/${revisionId}/test?type=historic&engine=production`, data);
    },
    createTestData(data, brandURL) {
        return axios.post(`brands/${brandURL}/schemes/testdata`, data);
    },
    getTestData({ id, title, tags }, brandURL) {
        return axios.get(`brands/${brandURL}/schemes/testdata`, {
            params: {
                id,
                title,
                tags
            }
        });
    },
    async uploadBatchTestData(data, engine, schemeId, brandURL, revisionId) {
        const res = await axios.post(
            `brands/${brandURL}/schemes/${schemeId}/revisions/${revisionId}/batch-test`,
            data,
            { params: { engine } }
        );

        return res.data;
    },
    async getBatchTests(schemeId, brandURL, revisionId) {
        const res = await axios.get(
            `brands/${brandURL}/schemes/${schemeId}/revisions/${revisionId}/batch-test`
        );

        return res.data;
    },
    async getABatchTest(id, schemeId, brandURL, revisionId) {
        const res = await axios.get(
            `brands/${brandURL}/schemes/${schemeId}/revisions/${revisionId}/batch-test/${id}`
        );

        return res.data;
    },
    async deleteABatchTest(id, schemeId, brandURL, revisionId) {
        const res = await axios.delete(
            `brands/${brandURL}/schemes/${schemeId}/revisions/${revisionId}/batch-test/${id}`
        );

        return res.data;
    }

};
