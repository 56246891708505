/*
*This allows us to set fake environment variables and make them available project wide
*/

export const types = {
    "SET_ENV_VARS": "SET_ENV_VARS"
};

const actions = {
    setEnvironmentVariables({commit}, vars){
        commit(types.SET_ENV_VARS, vars);
    }
};

const initialVars = {
    /* eslint-disable no-process-env */
    apiUrl: process.env.VUE_APP_API_URL || "",
    engineUrl: process.env.VUE_APP_ENGINE_URL || "",
    engineTestingUrl:  process.env.VUE_APP_ENGINE_TESTING_URL || "",
    dashboardVersion: process.env.VUE_APP_DASHBOARD_VERSION || "",
    publicPath: process.env.NODE_ENV === "production" ? "/app/" : "/",
    userPilotAppToken: process.env.USERPILOT_APP_TOKEN || "",
    documentSecretKey: process.env.DOCUMENT_SECRET_KEY || "dev",
    cacheInterval: process.env.CACHE_INTERVAL || "3",
    env: process.env.NODE_ENV || "development"
    /* eslint-enable no-process-env */  
};

const state = {
    vars:{...initialVars}
};

const mutations = {
    [types.SET_ENV_VARS](state, vars) {
        state.vars = {...state.vars, ...vars};
    }
};
export default {
    namespaced: true,
    state,
    actions,
    mutations
};
