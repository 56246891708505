import axios from "../services/axios";

export default {
    getRenewals(brand) {
        return axios.get(`/brands/${brand}/renewals`);
    },
    createRenewal({ brand, data }) {
        return axios.post(`/brands/${brand}/renewals`, data);
    },
    deleteRenewal({ brand, renewalID }) {
        return axios.delete(`/brands/${brand}/renewals/${renewalID}`);
    },
    patchRenewal({ brand, renewalID, data }) {
        return axios.patch(`/brands/${brand}/renewals/${renewalID}`, data);
    },
    getRenewalRules({ brand, renewalID }) {
        return axios.get(`/brands/${brand}/renewals/${renewalID}/renewal-rules`);
    },
    createRenewalRule({ brand, renewalID, data }) {
        return axios.post(`/brands/${brand}/renewals/${renewalID}/renewal-rules`, data);
    },
    updateRenewalRules({ brand, renewalID, data }) {
        return axios.patch(`/brands/${brand}/renewals/${renewalID}/renewal-rules`, data);
    },
    deleteRenewalRule({ brand, renewalID, renewalRuleID }) {
        return axios.delete(`/brands/${brand}/renewals/${renewalID}/renewal-rules/${renewalRuleID}`);
    },
    fetchRevisions(brandID, templateID) {
        return axios.get(`brands/${brandID}/templates/${templateID}`);
    },
    fetchTemplates(brandID) {
        return axios.get(`brands/${brandID}/templates`);
    },
    testRenewal({brandID, renewalID, payload}) {
        return axios.post(`brands/${brandID}/renewals/test-renewal/${renewalID}`, payload);
    },
    getRenewal({brandID, renewalID}) {
        return axios.get(`brands/${brandID}/renewals/${renewalID}`);
    }
};