<template>
    <div class="user-profile-form">
        <ValidationObserver
            v-if="form"
            ref="userForm"
            v-slot="{ dirty, pending }"
            tag="form"
            @submit.prevent="updateProfile"
        >
            <ValidationProvider
                v-slot="{ errors }"
                :rules="fullNameRules"
                name="First name"
                slim
                mode="eager"
            >
                <b-form-group
                    label-for="firstName"
                    :invalid-feedback="errors[0]"
                    :state="!errors[0] ? null : false"
                >
                    <template #label>
                        First name
                        <span
                            aria-hidden="true"
                            class="text-danger"
                        >*</span>
                    </template>
                    <b-form-input
                        id="firstName"
                        v-model="form.firstName"
                        placeholder="Enter first name"
                        :state="!errors[0] ? null : false"
                    />
                </b-form-group>
            </ValidationProvider>
            <ValidationProvider
                v-slot="{ errors }"
                :rules="fullNameRules"
                name="Surname"
                slim
                mode="eager"
            >
                <b-form-group
                    label-for="surname"
                    :invalid-feedback="errors[0]"
                    :state="!errors[0] ? null : false"
                >
                    <template #label>
                        Surname
                        <span
                            aria-hidden="true"
                            class="text-danger"
                        >*</span>
                    </template>
                    <b-form-input
                        id="surname"
                        v-model="form.surname"
                        placeholder="Enter surname"
                        :state="!errors[0] ? null : false"
                    />
                </b-form-group>
            </ValidationProvider>
            <b-form-group label-for="email">
                <template #label>
                    Email
                    <span
                        aria-hidden="true"
                        class="text-danger"
                    >*</span>
                </template>
                <b-form-input
                    id="email"
                    v-model="form.email"
                    placeholder="Enter email"
                    disabled
                />
            </b-form-group>
            <ValidationProvider
                v-slot="{ errors }"
                :rules="{ isphonenumber: form.mobile }"
                name="Mobile phone"
                slim
                mode="eager"
            >
                <b-form-group
                    label-for="mobile"
                    :invalid-feedback="errors[0]"
                    :state="!errors[0] ? null : false"
                >
                    <template #label>
                        Mobile phone
                    </template>
                    <VueTelInput
                        id="mobile"
                        v-model="form.mobile"
                        default-country="GB"
                        :input-options="{
                            showDialCode: false,
                        }"
                        :input-classes="errors[0] ? 'form-control is-invalid' : 'form-control'"
                        :wrapper-classes="errors[0] ? 'is-invalid' : ''"
                        mode="international"
                        :state="!errors[0] ? null : false"
                    />
                </b-form-group>
            </ValidationProvider>
            <b-button
                variant="primary"
                type="submit"
                :disabled="!dirty || pending"
            >
                Update
            </b-button>
        </ValidationObserver>
    </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import { mapState } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    name: "UserProfileForm",
    components: {
        VueTelInput,
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            form: null,
            fullNameRules: { required: true, regex: /^[a-z A-Z]+([' -][a-z A-Z]+)*$/ }
        };
    },
    computed: {
        ...mapState({
            user: state => state.auth.user
        })
    },
    mounted() {
        this.form = { ...this.user };
    },
    methods: {
        async updateProfile() {
            try {
                const userForm = this.$refs.userForm;
                const isValid = await userForm.validate();

                if (!isValid) {
                    return;
                }

                const payload = {
                    firstName: this.form.firstName,
                    surname: this.form.surname,
                    mobile: this.form.mobile
                };

                await this.$store.dispatch("auth/updateProfile", payload);

                this.$bvToast.toast("Profile was updated", {
                    title: "Success",
                    variant: "success"
                });

                await this.$nextTick();
                userForm.reset();
            } catch(err) {
                this.$bvToast.toast(err.message, {
                    title: "Error",
                    variant: "danger"
                });
            }
        }
    }
};
</script>
