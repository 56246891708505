export default {
    data() {
        return {
            testModeRoutes: ["account", "crm", "customers", "error", "test_mode"],
            showConfirmLeaveTestModeModal: false
        };
    },
    computed: {
        testing() {
            return this.$store.state.testMode.testing;
        },
        currentRoute() {
            return this.$route.path;
        },
        leavingTestMode() {
            return this.testing && !this.testModeRoutes.includes(this.currentRoute.replace("/", "").split("/")[0]);
        }
    },
    watch: {
        leaveingTestMode(newNavigation) {
            this.showConfirmLeaveTestModeModal = newNavigation;
        }
    },
    mounted() {
        this.showConfirmLeaveTestModeModal = this.leavingTestMode;
    },
    methods: {
        returnToTestMode() {
            window.history.back();
            this.$nextTick(async () => {
                this.$bvModal.hide("testmode-navigation-modal");
            });
        },
        async leaveTestMode() {
            this.showConfirmLeaveTestModeModal = false;
            await this.$store.dispatch("testMode/setTestMode", { value: false });
            this.$nextTick(() => {
                this.$bvModal.hide("testmode-navigation-modal");
            });
        }
    }
};
