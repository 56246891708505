import axios from "./axios";

const searchForAQuote = payload => {
    return axios.get(`/brands/${payload.brand}/quotes/${payload.value}`);
};

const sendPolicyNote = (brand, payload) => {
    const policyReference = payload.get("policyReference");
    return axios.post(`brands/${brand}/policies/${policyReference}/notes`, payload);
};

const getPolicyNotes = (brand, payload) => {
    return axios.get(`brands/${brand}/policies/${payload.policyReference}/notes/`);
};

const getQuoteNotes = (brand, payload) => {
    return axios.get(`brands/${brand}/quotes/${payload.quoteNumber}/notes`);
};

const setNoteImportantTag = (brand, payload) => {
    return axios.patch(`brands/${brand}/policies/${payload.policyReference}/notes/${payload.id}/set-important-tag`, {
        important: payload.important
    });
};

export default {
    searchForAQuote,
    sendPolicyNote,
    getPolicyNotes,
    getQuoteNotes,
    setNoteImportantTag
};

