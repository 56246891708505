import endpointsService from "../../services/endpoints";

export const types = {
    "SET_ENDPOINTS": "SET_ENDPOINTS",
    "SET_ENDPOINT": "SET_ENDPOINT",
    "SET_LOADING": "SET_LOADING",
    "SET_ERROR": "SET_ERROR",
    "UPDATE_ARCHIVED":"UPDATE_ARCHIVED"
};

const getters = {

};

// initial state
const state = {
    all: [],
    archived:[],
    noArchived: [],
    loading: false,
    error: null
};

// actions
const actions = {
    getAllEndpoints({ commit, rootState }) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        return endpointsService.getEndpoints(rootState.auth.selectedBrand.name_url).then((endpoints) => {
            commit(types.SET_ENDPOINTS, endpoints.data);
            commit(types.SET_LOADING, false);
        }).catch(err => {
            commit(types.SET_ERROR, err.message);
            commit(types.SET_LOADING, false);
            throw(err);
        });
    },
    postEndpoint({commit,rootState},{name,testing}){
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        return endpointsService.postEndpoint(
            rootState.auth.selectedBrand.name_url,
            name,
            undefined,
            undefined,
            testing
        ).then((endpoint) => {
            commit(types.SET_ENDPOINT, endpoint.data);
            commit(types.SET_LOADING, false);
            return endpoint.data;
        }).catch(err => {
            commit(types.SET_ERROR, err.message);
            commit(types.SET_LOADING, false);
            throw(err);
        });
    },
    async patchArchiveEndpoint({commit,rootState},{endpointID,archived}){
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, null);
        try{
            await endpointsService.patchEndpoint(rootState.auth.selectedBrand.name_url,endpointID,{archived});
            commit(types.UPDATE_ARCHIVED, {endpointID,archived});
            commit(types.SET_LOADING, false);
        }catch(err){
            //if we have a axios error
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR,  err.message);
            throw(new Error(err.message));
        }
    }

};

// mutations
const mutations = {
    [types.SET_ENDPOINT](state, endpoint) {
        state.noArchived.push(endpoint);
        state.all.push(endpoint);
    },
    [types.SET_ENDPOINTS](state, endpoints) {
        let archived = [];
        let noArchived = [];
        endpoints.forEach(endpoint=>{
            endpoint.archived?archived.push(endpoint):noArchived.push(endpoint);
        });
        
        state.archived = archived;
        state.noArchived = noArchived;
        state.all = endpoints;
    },
    [types.UPDATE_ARCHIVED](state, {endpointID,archived}) {

        let removedEndpoint= state.all.find(endpoint=>endpointID===endpoint.id);
        removedEndpoint.archived = archived;

        let archivedEndpoints = [];
        let noArchivedEndpoints = [];
        state.all.forEach(endpoint=>{
            endpoint.archived?archivedEndpoints.push(endpoint):noArchivedEndpoints.push(endpoint);
        });

        state.archived = archivedEndpoints;
        state.noArchived = noArchivedEndpoints;


    },
    [types.SET_LOADING](state, loading) {
        state.loading = loading;
    },
    [types.SET_ERROR](state, error) {
        state.error = error;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
