import docService from "../../services/documents";

export const types = {
    "SET_DOCUMENTS": "SET_DOCUMENTS",
    "POST_DOCUMENT": "POST_DOCUMENT",
    "SET_LOADING": "SET_LOADING",
    "SET_MESSAGE": "SET_MESSAGE",
    "SET_ERROR_UPLOAD": "SET_ERROR_UPLOAD",
    "SET_ERROR_GET_DOCUMENTS": "SET_ERROR_GET_DOCUMENTS",
    "SET_FILE_TYPES": "SET_FILE_TYPES",
    "SET_FILTERED": "SET_FILTERED"
};

const state = {
    documents:[],
    loading: false,
    postDocuments: [],
    errorGetDocuments: false,
    errorUploadDocument: false,
    message: null,
    fileTypes: [],
    filtered: []
};

const getters = {

};

const actions = {
    createFileExtensions(documents) {
        let extensions = documents.map(x => {
            return x.key.toLowerCase().slice(x.key.search(/\./));
        });
        let removeDuplicates = extensions.filter((item, index) => {
            return extensions.indexOf(item) === index;
        });
        return [...removeDuplicates];
    },
    async getDocuments({ commit }, brandId) {
        try {
            commit(types.SET_LOADING, true);
            commit(types.SET_ERROR_GET_DOCUMENTS, false);
            let response = await docService.getDocuments(brandId);
            let documents = response.data.getDocs;
            commit(types.SET_LOADING, false);
            commit(types.SET_DOCUMENTS, documents);
            commit(types.SET_FILTERED, documents);
            commit(types.SET_FILE_TYPES, actions.createFileExtensions(documents));
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR_GET_DOCUMENTS, error.message);
        }
    },
    async sendDocuments({ commit }, payload) {
        try {
            commit(types.SET_ERROR_UPLOAD, false);
            commit(types.SET_LOADING, true);
            await docService.sendDocuments(payload);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR_UPLOAD, error.message);
        }
    },
    clearErrors({commit}) {
        commit(types.SET_ERROR, false),
        commit(types.SET_MESSAGE, false);
    }
};

const mutations = {
    [types.SET_DOCUMENTS]: (state, documents) => (state.documents = documents),
    [types.SET_LOADING]: (state, loading) => (state.loading = loading),
    [types.SET_ERROR_GET_DOCUMENTS]: (state, error) => (state.errorGetDocuments = error),
    [types.SET_ERROR_UPLOAD]: (state, error) => (state.errorUploadDocument = error),
    [types.SET_MESSAGE]: (state, message) => (state.message = message),
    [types.SET_FILE_TYPES]: (state, fileTypes) => (state.fileTypes = fileTypes),
    [types.SET_FILTERED]: (state, filtered) => (state.filtered = filtered)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
