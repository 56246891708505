import dataDictionary from "../../services/data-dictionary";
import Vue from "vue";

export const types = {
    "SET_ACTIVE_DATA_DICTIONARY_REVISION_DATA": "SET_ACTIVE_DATA_DICTIONARY_REVISION_DATA",
    "SET_DATA_DICTIONARY_REVISIONS": "SET_DATA_DICTIONARY_REVISIONS",
    "SET_DISPLAYED_DATA_DICTIONARY_DATA": "SET_DISPLAYED_DATA_DICTIONARY_DATA",
    "UPDATE_DISPLAYED_DATA_DICTIONARY_DATA": "UPDATE_DISPLAYED_DATA_DICTIONARY_DATA",
    "UPDATE_DISPLAYED_DATA_DICTIONARY_DATA_TERM": "UPDATE_DISPLAYED_DATA_DICTIONARY_DATA_TERM",
    "ADD_DISPLAYED_DATA_DICTIONARY_DATA_TERM": "ADD_DISPLAYED_DATA_DICTIONARY_DATA_TERM",
    "REMOVE_DISPLAYED_DATA_DICTIONARY_DATA_TERM": "REMOVE_DISPLAYED_DATA_DICTIONARY_DATA_TERM"
};

const state = {
    dataDictionaryRevisions: [],
    activeDataDictionaryRevisionData: {},
    displayedDataDictionaryData: {}
};

const actions = {
    /**
     * Fetch all revisions for a brand
     * @param { object } context
     * @param { function } context.commit The commit function for Vuex mutations
     * @param { object } context.rootState The rootState object for Vuex
     * @param { string } [brand]
     * @returns {Promise<void>}
     */
    async fetchDataDictionaryRevisions({commit, rootState}, brand) {
        try {
            const dictionaries = await dataDictionary.getDataDictionaryRevisions(
                brand || rootState.auth.selectedBrand.name_url
            );
            commit(types.SET_DATA_DICTIONARY_REVISIONS, dictionaries.data);
        } catch (err) {
            throw (new Error(err.message));
        }
    },
    /**
     * Fetch the active data dictionary for a brand
     * @param { object } context
     * @param { function } context.commit The commit function for Vuex mutations
     * @param { object } context.rootState The rootState object for Vuex
     * @returns { Promise<void> }
     */
    async fetchActiveDataDictionary({commit, rootState}, payload) {
        try {
            const { data } = await dataDictionary.getActiveDataDictionaryRevisionData(
                rootState.auth.selectedBrand.name_url,
                payload
            );
            commit(types.SET_ACTIVE_DATA_DICTIONARY_REVISION_DATA, data);
        } catch (err) {
            throw (new Error(err.message));
        }
    },
    /**
     * Save data dictionary
     * @param { object } context
     * @param { object } context.rootState The rootState object for Vuex
     * @param { object } context.state The state object for Vuex
     * @throws { Error } If no response or error from the backend
     * @returns { Promise<void> }
     */
    async saveDataDictionary({rootState}, payload) {
        try {
            const { data } = await dataDictionary.saveDataDictionaryRevision(
                rootState.auth.selectedBrand.name_url,
                payload
            );

            return data;
        } catch (err) {
            throw (new Error(err.message));
        }
    },
    /**
     * Load data dictionary version
     * @param context
     * @param context.rootState
     * @param { number } id
     * @throws { Error } If no response or error from the backend
     * @returns { Promise<void> }
     */
    async updateActiveDataDictionaryVersion({rootState}, id) {
        try {
            await dataDictionary.setActiveDataDictionaryRevision(
                rootState.auth.selectedBrand.name_url,                 {
                    "revision_id": id
                }
            );
        } catch (err) {
            throw (new Error(err.message));
        }
    },
    /**
     * Switch displayed version
     * @param { object } context
     * @param { function } context.commit The commit function for Vuex mutations
     * @param { object } context.state The state object for Vuex
     * @param { Number } version
     */
    changeSelectedVersion({commit, state}, version) {
        const data = state.dataDictionaryRevisions.find((elt) => {
            return elt.id === version;
        });
        commit(types.SET_DISPLAYED_DATA_DICTIONARY_DATA, data);
    },
    /**
     * Update displayed version's term
     * @param { object } context
     * @param { function } context.commit The commit function for Vuex mutations
     * @param { object } context.state The state object for Vuex
     * @param { object } payload
     */
    updateDataDictionaryTerm({commit}, payload) {
        commit(types.UPDATE_DISPLAYED_DATA_DICTIONARY_DATA_TERM, payload);
    },
    /**
     * Add term to displayed version
     * @param { object } context
     * @param { function } context.commit The commit function for Vuex mutations
     * @param { object } context.state The state object for Vuex
     * @param { object } payload
     */
    addDataDictionaryTerm({commit}, payload) {
        commit(types.ADD_DISPLAYED_DATA_DICTIONARY_DATA_TERM, payload);
    },    
    deleteDataDictionaryTerm({commit}, indexSelectedRow) {
        commit(types.REMOVE_DISPLAYED_DATA_DICTIONARY_DATA_TERM, indexSelectedRow);
    }
};

const mutations = {
    [types.SET_ACTIVE_DATA_DICTIONARY_REVISION_DATA]: (state, data) => (state.activeDataDictionaryRevisionData = data),
    [types.SET_DATA_DICTIONARY_REVISIONS]: (state, list) => (state.dataDictionaryRevisions = list),
    [types.SET_DISPLAYED_DATA_DICTIONARY_DATA]: (state, data) => 
        (state.displayedDataDictionaryData = structuredClone(data)),    
    [types.UPDATE_DISPLAYED_DATA_DICTIONARY_DATA_TERM]: (state, payload) => 
        (Vue.set(state.displayedDataDictionaryData.data, payload.index, payload.data)),    
    [types.ADD_DISPLAYED_DATA_DICTIONARY_DATA_TERM]: (state, payload) => 
        (state.displayedDataDictionaryData.data.unshift(payload.data)),
    [types.REMOVE_DISPLAYED_DATA_DICTIONARY_DATA_TERM]: (state, rowNumber) =>
        (state.displayedDataDictionaryData.data.splice(rowNumber, 1))
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
