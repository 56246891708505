import * as usersService from "../../../../services/provider/users";

export const types = {
    "SET_USER": "SET_USER",
    "SET_USER_BRAND_ACCESS": "SET_USER_BRAND_ACCESS",
    "UPDATE_USER_BRAND_ROLES": "UPDATE_USER_BRAND_ROLES",
    "SET_USER_PROVIDER_ACCESS": "SET_USER_PROVIDER_ACCESS",
    "UPDATE_USER_PROVIDER_ROLES": "UPDATE_USER_PROVIDER_ROLES",
    "SET_USER_CONDITIONS": "SET_USER_CONDITIONS"
};

const state = {
    user: null,
    userBrandAccess: [],
    userProviderAccess: [],
    userConditions: []
};

const actions = {
    async getUserById({ commit }, userId) {
        const { data } = await usersService.getUserById(userId);
        commit(types.SET_USER, data);
    },
    async getUserProviderAccess({ commit, rootState }, userId) {
        const { data } = await usersService.getUserProviderAccess(userId);
        const providerId = rootState.auth.provider || "";
        commit(types.SET_USER_PROVIDER_ACCESS, { providerId, access: data });
    },
    async updateUserProviderRoles({ commit }, { providerId, roles }) {
        commit(types.UPDATE_USER_PROVIDER_ROLES, { providerId, roles });
    },
    async getUserBrandAccess({ commit }, userId) {
        const { data } = await usersService.getUserBrandAccess(userId);
        commit(types.SET_USER_BRAND_ACCESS, data);
    },
    async updateUserBrandRoles({ commit }, { brandId, roles }) {
        commit(types.UPDATE_USER_BRAND_ROLES, { brandId, roles });
    },
    async getUserConditions({ commit }, userId) {
        const { data } = await usersService.getUserConditions(userId);
        commit(types.SET_USER_CONDITIONS, data);
    }
};

const mutations = {
    [types.SET_USER]: (state, user) => (state.user = user),
    [types.SET_USER_BRAND_ACCESS](state, access) {
        state.userBrandAccess = access.reduce((acc, curr) => {
            const accessIndex = acc.findIndex((item) => item.id === curr.brand_id);

            if (accessIndex === -1) {
                acc.push({
                    id: curr.brand_id,
                    name: curr.brand_name,
                    roles: [
                        {
                            id: curr.role_id,
                            name: curr.role_name,
                            "access_id": curr.id
                        }
                    ]
                });
            } else {
                acc[accessIndex].roles.push({
                    id: curr.role_id,
                    name: curr.role_name,
                    "access_id": curr.id
                });
            }

            return acc;
        }, []);
    },
    [types.UPDATE_USER_BRAND_ROLES](state, { brandId, roles }) {
        const index = state.userBrandAccess.findIndex(brand => brand.id === brandId);
        if (index >= 0) {
            state.userBrandAccess[index].roles = roles;
        }
    },
    [types.SET_USER_PROVIDER_ACCESS](state, { providerId, access }) {
        state.userProviderAccess = access.reduce((acc, curr) => {
            const accessIndex = acc.findIndex((item) => item.id === providerId);

            if (accessIndex === -1) {
                acc.push({
                    id: providerId,
                    name: providerId,
                    roles: [
                        {
                            id: curr.role_id,
                            name: curr.role_name,
                            "access_id": curr.id
                        }
                    ]
                });
            } else {
                acc[accessIndex].roles.push({
                    id: curr.role_id,
                    name: curr.role_name,
                    "access_id": curr.id
                });
            }

            return acc;
        }, []);
    },
    [types.UPDATE_USER_PROVIDER_ROLES](state, { providerId, roles }) {
        const index = state.userProviderAccess.findIndex(provider => provider.id === providerId);
        if (index >= 0) {
            state.userProviderAccess[index].roles = roles;
        }
    },
    [types.SET_USER_CONDITIONS]: (state, conditions) => (state.userConditions = conditions)
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
