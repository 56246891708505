import axios from "./axios";

export default {
    postEndpoint(
        brandURL,
        name,
        requestTemplate = undefined,
        responseTemplate = undefined,
        testing=false
    ) {
        return axios.post(`/brands/${brandURL}/endpoints`, {
            name,
            ["request_template"]: requestTemplate,
            ["response_template"]: responseTemplate,
            testing
        });
    },
    postEndpointScheme(brandURL,endpointId,schemeId) {
        return axios.post(`/brands/${brandURL}/endpoints/${endpointId}/schemes`,{["scheme_id"]:schemeId});
    },
    /**
     * @function deleteEndpointScheme
     * @description send a delete http request to remove a scheme from an endpoint
     * @param {object} options - options object
     * @param {String} options.brandID - the sluggified name of the brand
     * @param {Number} options.endpointID - the id of the endpoint we are removing the scheme from
     * @param {Number} options.schemeID - the id of the scheme we are removing
     * @return {object} - axios response object
     */
    deleteEndpointScheme({ brandID, endpointID, schemeID }) {
        return axios.delete(`/brands/${brandID}/endpoints/${endpointID}/schemes/${schemeID}`);
    },
    getEndpoints(brandURL) {
        return axios.get(`/brands/${brandURL}/endpoints`);
    },
    getEndpointSchemes({ brandURL, endpointID, limit, offset }) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointID}/schemes`, { params: { limit, offset } } );
    },
    getEndpoint(brandURL,endpointId) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}`);
    },
    async getEndpointError(brandURL,endpointId,errorId) {
        const res = await axios.get(`/brands/${brandURL}/endpoints/${endpointId}/errors/${errorId}`);
        res.data.id = BigInt(res.data.id);
        res.data["job_id"] = BigInt(res.data.job_id);
        return res;
    },
    async getEndpointErrors(brandURL,endpointId,params) {
        const res = await axios.get(`/brands/${brandURL}/endpoints/${endpointId}/errors`,{params});
        res.data.endpointErrors = res.data.endpointErrors.map((el) => ({
            ...el,
            "job_id": BigInt(el.job_id),
            id: BigInt(el.id)
        }));
        return res;
    },
    getEndpointErrorsMetrics(brandURL,endpointId,from,to,unit="day") {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/errors/metrics`,{params:{from,to,unit}});
    },
    async getEndpointReject(brandURL,endpointId,rejectId) {
        const res = await axios.get(`/brands/${brandURL}/endpoints/${endpointId}/rejects/${rejectId}`);
        res.data["job_id"] = BigInt(res.data.job_id);
        return res;
    },
    async getEndpointRejects(brandURL,endpointId,params) {
        const res = await axios.get(`/brands/${brandURL}/endpoints/${endpointId}/rejects`,{params});
        res.data.endpointErrors = res.data.endpointErrors.map((el) => ({ ...el, "job_id": BigInt(el.job_id) }));
        return res;
    },
    getEndpointRejectsMetrics(brandURL,endpointId,from,to,unit="day") {
        //eslint-disable-next-line
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/rejects/metrics`,{params:{from,to,unit}});
    },
    getEndpointQuotes(brandURL,endpointId,params) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/quotes`,{params});
    },
    async getEndpointQuote(brandURL,endpointId,quoteId) {
        const res = await axios.get(`/brands/${brandURL}/endpoints/${endpointId}/quotes/${quoteId}`);
        res.data.id = BigInt(res.data.id);
        return res;
    },
    getEndpointQuotesMetrics(brandURL,endpointId,from,to,unit="day") {
        //eslint-disable-next-line
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/quotes/metrics`,{params:{from,to,unit}});
    },
    async getCrmEndpoint(brandURL) {
        return axios.get(`/brands/${brandURL}/endpoints/crm`);
    },
    async getEndpointCrmJourney(brandURL,endpointId) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/crm-journey`);
    },
    async getEndpointJobs(brandURL,endpointId,params) {
        const res = await axios.get(`/brands/${brandURL}/endpoints/${endpointId}/jobs`,{params});
        res.data.endpointQuotes = res.data.endpointQuotes.map((el) => ({ ...el, id: BigInt(el.id) }));
        return res;
    },
    async getEndpointJob(brandURL,endpointId,jobId) {
        const res = await axios.get(`/brands/${brandURL}/endpoints/${endpointId}/jobs/${jobId}`);
        res.data.id = BigInt(res.data.id);
        return res;
    },
    async getEndpointJourneyTypes(brandURL,endpointId) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/journeys/types`);
    },
    async getEndpointJourneys(brandURL,endpointId) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/journeys/`);
    },
    async getEndpointForms(brandURL, endpointId, partial = false) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/forms?partial=${partial}`);
    },
    async getEndpointFormRevisions(brandURL,formId) {
        return axios.get(`/brands/${brandURL}/forms/${formId}/revisions`);
    },
    getEndpointDataDictionaryJourneys(brandURL,endpointId) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/data-dictionary/journeys`);
    },
    getEndpointDataDictionarySchemes(brandURL,endpointId) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/data-dictionary/schemes`);
    },
    saveJourney(brandURL, endpointId, journeyId, journeys, comments, tags){
        return axios.patch(`/brands/${brandURL}/endpoints/${endpointId}/journeys/${journeyId}`, {
            journeys,
            comments,
            tags
        });
    },
    setJourneyAsRevision(brandURL, endpointId, journeyId, journeys){
        return axios.patch(`/brands/${brandURL}/endpoints/${endpointId}/journeys/${journeyId}/revision`, {journeys});
    },
    createJourney(brandURL, endpointId, isDefault,name){
        return axios.post(`/brands/${brandURL}/endpoints/${endpointId}/journeys/`,{
            isDefault,
            name
        });
    },
    setEndpointJourneyFlags(brandURL, payload) {
        return axios.patch(`/brands/${brandURL}/endpoints/${payload.endpointId}/journeys/${payload.journeyId}/flags`,
            {
                flag: payload.flag,
                value: payload.value,
                testing: payload.testing
            });
    },

    getEndpointJourneyVariantRevisions(brandURL, endpointId, journeyId) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/journeys/${journeyId}/revisions`);
    },

    deleteJourney(brandURL, endpointId, journeyId){
        return axios.delete(`/brands/${brandURL}/endpoints/${endpointId}/journeys/${journeyId}`);
    },
    deleteJourneyRevision(brandURL, endpointId, journeyId, revisionId) {
        return axios.delete(
            `/brands/${brandURL}/endpoints/${endpointId}/journeys/${journeyId}/revisions/${revisionId}`
        );
    },
    getEndpointJobsMetrics(brandURL,endpointId,from,to,unit="day") {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/jobs/metrics`,{params:{from,to,unit}});
    },
    patchEndpointScheme(brandURL, endpointId, schemesId, policyTemplate) {
        return axios.patch(`/brands/${brandURL}/endpoints/${endpointId}/schemes/${schemesId}`,
            { policyTemplate: policyTemplate });
    },
    patchEndpointTemplate(brandURL,endpointId,template) {
        return axios.patch(`/brands/${brandURL}/endpoints/${endpointId}`,
            {["response_template"]:template});
    },
    patchEndpointRequestTemplate(brandURL,endpointId,template) {
        return axios.patch(`/brands/${brandURL}/endpoints/${endpointId}`,
            {["request_template"]:template});
    },
    postEndpointTest(brandURL, endpointId, request, options) {
        return axios.post(`/brands/${brandURL}/endpoints/${endpointId}/test`, request, options);
    },
    patchEndpoint(brandURL, endpointId, payload) {
        return axios.patch(`brands/${brandURL}/endpoints/${endpointId}`, payload);
    },
    fetchSettings(brandURL, endpointID) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointID}/settings`);
    },
    fetchSecuritySettings(brandURL, {endpointID, type}) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointID}/security-settings`, {
            params: {
                type
            }
        });
    },
    updateSettings(brandURL, payload) {
        return axios.patch(`/brands/${brandURL}/endpoints/${payload.endpointID}/settings`, payload);
    },
    postEndpointSettings(brandURL, payload) {
        return axios.post(`/brands/${brandURL}/endpoints/${payload.endpointID}/settings`, payload);
    },
    postEndpointSecuritySettings(brandURL, payload) {
        return axios.post(`/brands/${brandURL}/endpoints/${payload.endpointID}/security-settings`, payload);
    },
    deleteEndpointSettings(brandURL, payload) {
        return axios.delete(`/brands/${brandURL}/endpoints/settings/${payload.id}`);
    },
    deleteEndpointSecuritySettings(brandURL, payload) {
        return axios.delete(`/brands/${brandURL}/endpoints/${payload.endpointID}/security-settings/${payload.id}`);
    },
    getEndpointUpdateTests(brandURL, endpointId, expectedField="total_retail") {
        //eslint-disable-next-line
        return axios.post(`/brands/${brandURL}/endpoints/${endpointId}/test-update?detailed=true&expected=${expectedField}&limit=200`);
    },
    /**
     * @function getEndpointTestResults
     * @description queries the api for the endpoint test results
     * @param {string} brandURL - the url name of the brand
     * @param {number} endpointId - the id of the endpoint to get the test results for
     * @param {number} limit - the number of results to return
     * @param {number} offset - the offset for the results
     * @returns {Promise<object>} - A promise containing an object with a data array, and a total count of results
     */
    getEndpointTestResults(brandURL, endpointId, limit, offset) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/test-update?limit=${limit}&offset=${offset}`);
    },
    /**
     * @function getEndpointTestResultFile
     * @description fetches the test results file from the api
     * @param {string} brandURL - the url name of the brand
     * @param {number} endpointId - the id of the endpoint the test was ran on
     * @param {number} id - the id of the test which we want the file for
     * @param {object} [options={}] - optional additional options object
     * @returns {Promise<file>} - the file we want
     */
    getEndpointTestResultFile(brandURL, endpointId, id, options = {}) {
        return axios.get(`/brands/${brandURL}/endpoints/${endpointId}/test-update/${id}`, options);
    },
    /**
     * @function deleteEndpointTestResult
     * @description makes api call to delete a test result
     * @param {string} brandURL - the url name of the brand
     * @param {number} endpointId - the id of the endpoint we are deleting a test from
     * @param {number} id - the id of the the test which is to be deleted
     * @returns {Promise} - a promise containing the request
     */
    deleteEndpointTestResult(brandURL, endpointId, id) {
        return axios.delete(`/brands/${brandURL}/endpoints/${endpointId}/test-update/${id}`);
    },
    /**
     * @function getEndpointSchemeSchedules
     * @description makes an api call to download the schedule for the scheme on an endpoint
     * @async
     * @param {object} options - options object
     * @param {String} options.brandID - the sluggified name of the current brand
     * @param {Number} options.endpointID - the id of the endpoint on which we are getting the schedule for
     * @param {Number} options.schemeID - the id of the scheme we want the schedule for
     * @param {Number} [options.limit] - only return this number of results
     * @param {Number} [options.offset] - return results after this number
     * @param {String} [options.activeAfter] - the date after which we want the schedules
     * @param {String} [options.activeBefore] - the date before which we want the schedules
     * @returns {object} - axios response object
     */
    getEndpointSchemeSchedule({ brandID, endpointID, schemeID, limit, offset, activeAfter, activeBefore }) {
        return axios.get(`/brands/${brandID}/endpoints/${endpointID}/schemes/${schemeID}/schedule`, {
            params: { limit, offset, activeAfter, activeBefore }
        });
    },
    /**
     * @function getScheduleOverlaps
     * @description gets the schedules which overlaps the posted schedule
     * @async
     * @param {object} options - the options object
     * @param {Number} options.brandID - ths id of the brand
     * @param {Number} options.schemeID - the id of the current scheme
     * @param {Number} options.endpointID - the id of the endpoint
     * @param {String} [options.name] - the name of the new schedule
     * @param {?String} [options.startTime] - ISO8601 date when the new scheme is to start
     * @param {?String} [options.endTime] - ISO8601 date when the new scheme is to end
     * @returns {object} - axios response object
     */
    getScheduleOverlaps({ brandID, endpointID, schemeID, ...options }) {
        return axios.post(`brands/${brandID}/endpoints/${endpointID}/schemes/${schemeID}/schedule/getOverlaps`, {
            scheduleID: options.scheduleID,
            scheduleName: options.name,
            startTime: options.startTime,
            endTime: options.endTime
        });
    },
    /**
     * @function uploadSchedule
     * @description uploads a new schedule
     * @async
     * @param {object} options - options object
     * @param {Number} options.brandID - the id of the brand
     * @param {Number} options.schemeID - the id of the scheme for the current schedule
     * @param {Number} options.endpointdID - the id of the endpoint we are setting the schedule on
     * @param {object} options.payload - the schedule settings
     * @param {?String} [options.payload.startTime] - the start time of the schedule
     * @param {?String} [options.payload.endTime] - the end time of the scheudle
     * @param {String} [options.payload.scheduleName] - the name of the new schedule
     * @param {Array<object>} options.payload.revisions - an array of revision objects
     * @returns {object} - axios return object
     */
    uploadSchedule({ brandID, schemeID, endpointID, payload }) {
        return axios.post(`brands/${brandID}/endpoints/${endpointID}/schemes/${schemeID}/schedule`, payload);
    },
    /**
     * @function uploadSchedule
     * @description uploads a new schedule
     * @async
     * @param {object} options - options object
     * @param {Number} options.brandID - the id of the brand
     * @param {Number} options.schemeID - the id of the scheme for the current schedule
     * @param {Number} options.endpointdID - the id of the endpoint we are altering the schedule on
     * @param {Number} options.scheduleID - the id of the schedule we are updating
     * @param {object} options.payload - the schedule settings
     * @param {?String} [options.payload.startTime] - the start time of the schedule
     * @param {?String} [options.payload.endTime] - the end time of the scheudle
     * @param {String} [options.payload.scheduleName] - the name of the new schedule
     * @param {Array<object>} options.payload.revisions - an array of revision objects
     * @returns {object} - axios return object
     */
    updateSchedule({ brandID, schemeID, endpointID, scheduleID, payload }) {
        return axios.patch(
            `brands/${brandID}/endpoints/${endpointID}/schemes/${schemeID}/schedule/${scheduleID}`,
            payload
        );
    },
    /**
     * @function deleteSchedule
     * @description deletes a schedule
     * @async
     * @param {object} options - options object
     * @param {Number} options.brandID - the id of the brand
     * @param {Number} options.endpointdID - the id of the endpoint we are deleting the schedule from
     * @param {Number} options.schemeID - the id of the scheme
     * @param {Number} options.scheduleID - the id of the schedule we are deleting
     * @returns {object} - axios return object
     */
    deleteSchedule({ brandID, schemeID, endpointID, scheduleID }) {
        return axios.delete(`brands/${brandID}/endpoints/${endpointID}/schemes/${schemeID}/schedule/${scheduleID}`);
    },
    getServiceErrorLogs(brand, endpointId, params) {
        return axios.get(`/brands/${brand}/endpoints/${endpointId}/service-errors`, { params });
    },
    getJourneyUrlD2C(brand, endpointId, endpointNameUrl, journeyName) {
        const params = { endpointNameUrl, journeyName };
        return axios.get(`/brands/${brand}/endpoints/${endpointId}/journey-url-d2c`, { params });
    }
};
