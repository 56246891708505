import axios from "./axios";

/**
 * Gets the user permissions for a given brand
 * @param {String} brandId
 */
const getUserPermissions = function(brandId){
    return axios.get(`auth/brand-permissions/${brandId}`);
};

/**
 * Gets the user provider permissions
 */
const getUserProviderPermissions = function(){
    return axios.get("auth/provider-permissions");
};

/**
 * Gets the locked status for a given brand
 * @param {String} brandId
 */
const getBrandLockedStatus = function(brandId){
    return axios.get(`auth/brand-locked/${brandId}`);
};

/**
 * Fetches the brands and the current permissions for
 * the specified brand.
 */
const fetchBrands = async function(){
    let brandsRequest = await axios.get("brands");
    let brands = brandsRequest.data;
    if(Array.isArray(brands) && brands.length === 0){
        return {
            brands:[],
            selectedBrand:undefined,
            selectedBrandPermissions: [],
            brandLockedStatus: false
        };
    }else{

        const selectedBrand = brands[0];
        const result = await getUserPermissions(selectedBrand.id);
        const { permissions, brandLockedStatus } = result.data;
        localStorage.setItem("brands",JSON.stringify({brands,selectedBrand:brands[0]}));
        localStorage.setItem("brandPermissions",JSON.stringify(permissions));
        return {
            brands,
            selectedBrand,
            selectedBrandPermissions: permissions,
            brandLockedStatus: brandLockedStatus.locked
        };
    }
};
const login = async function(credentials){
    const authResponse = await axios.post("auth/login", credentials, {
        headers: {
            UI: true
        }
    });
    const { token, twoFactorEnabled, twoFactorRequired, providerTwoFactorRequired, user } = authResponse.data;
    const provider = credentials.provider_id.toLowerCase();
    sessionStorage.setItem("jwt", token);
    localStorage.setItem("provider",provider);
    if(!providerTwoFactorRequired && !twoFactorRequired && !twoFactorEnabled){
        //if not required or enabled, we should set the brands
        const brands = await fetchBrands();
        const providerPermissionResponse =
            await getUserProviderPermissions();
        localStorage.setItem("providerPermissions", JSON.stringify(providerPermissionResponse?.data?.permissions));
        return {
            twoFactorEnabled,
            twoFactorRequired,
            providerTwoFactorRequired,
            provider,
            providerPermissions: providerPermissionResponse?.data?.permissions,
            user: user.fullName,
            userID: user.userID,
            ...brands
        };
    }
    return {
        twoFactorEnabled,
        twoFactorRequired,
        providerTwoFactorRequired,
        provider
    };
};
const logout = function(reload) {
    // remove user from local storage to log user out
    sessionStorage.removeItem("jwt");
    localStorage.removeItem("brands");
    localStorage.removeItem("provider");
    localStorage.removeItem("brandPermissions");
    localStorage.removeItem("providerPermissions");
    localStorage.removeItem("testMode");
    if (reload) {
        location.reload();
    }
};
const loginSecondFactor = async function(code){
    const authResponse = await axios.post(
        "auth/login/two-factor",
        code
    );
    const { token, user } = authResponse.data;
    //reset as this one is a fully athenticated token
    sessionStorage.setItem("jwt", token);
    const brands = await fetchBrands();
    const providerPermissionResponse =
        await getUserProviderPermissions();
    localStorage.setItem("providerPermissions", JSON.stringify(providerPermissionResponse?.data?.permissions));
    return {
        token,
        user: user.fullName,
        userID: user.userID,
        email: user.email,
        providerPermissions: providerPermissionResponse?.data?.permissions,
        ...brands
    };
};
const loginSecondFactorSetup = async function(){
    let setupResponse = await axios.get(
        "auth/login/two-factor-setup");
    return setupResponse.data;
};
const validateToken = async function(provider, jwt) {
    return axios.get(`auth/validate-token/${jwt}`, {params: {provider}});
};
const resetPassword = function(passwords, jwt) {
    return axios.post(`auth/forgotten-password/${jwt}`, passwords, {
        headers: {
            UI: true
        }
    });
};
const forgottenPassword = function(credentials) {
    return axios.post("auth/forgotten-password", credentials, {
        headers: {
            UI: true
        }
    });
};

const fetchProfile = function() {
    return axios.get("auth/profile");
};

const updateProfile = function(payload) {
    return axios.patch("auth/profile", payload);
};

const fetchUserBrandAccess = function() {
    return axios.get("auth/profile/brand-access");
};

const fetchUserProviderAccess = function() {
    return axios.get("auth/profile/provider-access");
};

const fetchTwoFactorSetupData = function() {
    return axios.get("auth/security/2fa");
};

const updateTwoFactor = function(payload) {
    return axios.patch("auth/security/2fa", payload);
};

const updatePassword = function(payload) {
    return axios.patch("auth/security/password", payload);
};

export default {
    login,
    loginSecondFactor,
    loginSecondFactorSetup,
    getUserPermissions,
    getUserProviderPermissions,
    logout,
    fetchBrands,
    validateToken,
    resetPassword,
    forgottenPassword,
    getBrandLockedStatus,
    fetchProfile,
    updateProfile,
    fetchUserBrandAccess,
    fetchUserProviderAccess,
    fetchTwoFactorSetupData,
    updateTwoFactor,
    updatePassword
};
