import * as conditionsService from "../../../../services/provider/conditions";
import { DEFAULT_CONDITION } from "../../../../src/components/provider/conditions/conditionConstants";

export const types = {
    "SET_CONDITION": "SET_CONDITION",
    "SET_CONDITION_USERS": "SET_CONDITION_USERS",
    "UPDATE_CONDITION_FIELD": "UPDATE_CONDITION_FIELD",
    "SET_FORM_STATE": "SET_FORM_STATE"
};


const state = {
    condition: structuredClone(DEFAULT_CONDITION),
    conditionUsers: [],
    isFormDirty: false
};

const actions = {
    async getCondition({ commit }, conditionId) {
        const { data } = await conditionsService.getCondition(conditionId);
        commit(types.SET_CONDITION, data);
    },
    async getConditionUsers({ commit }, conditionId) {
        const { data } = await conditionsService.getConditionUsers(conditionId);
        commit(types.SET_CONDITION_USERS, data);
    },
    resetCondition({ commit }) {
        commit(types.SET_CONDITION, structuredClone(DEFAULT_CONDITION));
    },
    updateConditionField({ commit }, payload) {
        commit(types.UPDATE_CONDITION_FIELD, payload);
        commit(types.SET_FORM_STATE, true);
    },
    resetFormState({ commit }) {
        commit(types.SET_FORM_STATE, false);
    }
};

const mutations = {
    [types.SET_CONDITION]: (state, condition) => (state.condition = condition),
    [types.SET_CONDITION_USERS]: (state, users) => (state.conditionUsers = users),
    [types.UPDATE_CONDITION_FIELD]: (state, { field, value }) => (state.condition[field] = value),
    [types.SET_FORM_STATE]: (state, value) => (state.isFormDirty = value)
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
