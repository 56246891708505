import axios from "./axios";

export default {
    fetchAllReport({ brandURL }) {
        return axios.get(`brands/${brandURL}/reports`);
    },
    fetchAReport({ brandURL, id }) {
        return axios.get(`brands/${brandURL}/reports/${id}`);
    },
    postAReport({ brandURL, name }) {
        return axios.post(`brands/${brandURL}/reports`,
            {
                name
            }
        );
    },
    fetchARevision({ brandURL, reportId, revisionId }) {
        return axios.get(`brands/${brandURL}/reports/${reportId}/revision/${revisionId}`);
    },
    postARevision({ brandURL, reportId, payload }) {
        return axios.post(`brands/${brandURL}/reports/${reportId}/revision`, payload);
    },
    runReport(url, query, body) {
        return axios.post(
            `brands/${url.brandURL}/reports/${url.reportId}/revision/${url.revisionId}/run`,
            body,
            {
                params: {
                    fromDate: query.fromDate,
                    toDate: query.toDate
                }
            }
        );
    },
    fetchAllReportsRan({ brandURL, options}) {
        return axios.get(`brands/${brandURL}/reports/ran`, options);
    },
    fetchResultsDownloadUrl({ brandURL, id }) {
        return axios.get(`brands/${brandURL}/reports/ran/${id}`);
    },
    deleteReportRan({ params }) {
        return axios.delete(`brands/${params.brandURL}/reports/ran/${params.reportId}`);
    },
    deleteReportTemplate({ params }) {
        return axios.delete(`brands/${params.brandURL}/reports/${params.reportId}`);
    }
};
