import emailService from "../../services/email";

export const types = {
    "GET_ALLOW_DOCUMENT_ATTACHMENTS": "GET_ALLOW_DOCUMENT_ATTACHMENTS",
    "SET_ALLOW_DOCUMENT_ATTACHMENTS": "SET_ALLOW_DOCUMENT_ATTACHMENTS",
    "SET_LOADING": "SET_LOADING",
    "SET_ERROR": "SET_ERROR"
};

const state = {
    allowDocumentAttachments: false,
    error: "",
    loading: false
};

const actions = {
    async getAllowDocumentAttachments({commit, rootState}) {
        try {
            commit(types.SET_ERROR, "");
            commit(types.SET_LOADING, true);
            const brandURL = rootState.auth.selectedBrand.name_url;
            const result = await emailService.getAllowDocumentAttachments(brandURL);
            const { allow_document_attachments: value } = result.data;
            commit(types.SET_ALLOW_DOCUMENT_ATTACHMENTS, value);
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }
    },
    async setAllowDocumentAttachments({commit, rootState}, payload) {
        try {
            commit(types.SET_ERROR, "");
            commit(types.SET_LOADING, true);
            const brandURL = rootState.auth.selectedBrand.name_url;
            await emailService.setAllowDocumentAttachments(
                brandURL,
                payload
            );
            commit(types.SET_ALLOW_DOCUMENT_ATTACHMENTS, payload.value);        
        } catch (err) {
            commit(types.SET_ERROR, err);
            throw err;
        } finally {
            commit(types.SET_LOADING, false);
        }
    }
};

const mutations = {
    [types.SET_ERROR]: (state, error) => (state.error = error),
    [types.SET_LOADING]: (state, loading) => (state.loading = loading),
    [types.SET_ALLOW_DOCUMENT_ATTACHMENTS]: (state, value) => (
        state.allowDocumentAttachments = value
    )
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
