<template>
    <div>
        <fieldset>
            <div
                v-if="checkUrl && provider === null"
                class="form-group"
            >
                <label
                    for="inputProvider"
                    class="sr-only"
                >Organisation</label>
                <input
                    id="inputProvider"
                    v-model="providerLocal"
                    type="text"
                    class="form-control"
                    data-lpignore="true"
                    placeholder="Organisation name"
                    required
                    @keyup.enter="handleLogin"
                >
                <font-awesome-icon
                    :icon="['fal', 'industry']"
                />
            </div>
            <div class="form-group">
                <label
                    for="inputEmail"
                    class="sr-only"
                >Email address</label>
                <input
                    id="inputEmail"
                    v-model="email"
                    data-lpignore="true"
                    type="email"
                    class="form-control"
                    placeholder="Email address"
                    required
                    autofocus
                    @keyup.enter="handleLogin"
                >
                <font-awesome-icon
                    :icon="['fal', 'envelope-open-text']"
                />
            </div>
            <div class="form-group">
                <label
                    for="inputPassword"
                    class="sr-only"
                >Password</label>
                <input
                    id="inputPassword"
                    v-model="password"
                    type="password"
                    data-lpignore="true"
                    class="form-control"
                    placeholder="Password"
                    required
                    @keyup.enter="handleLogin"
                >
                <font-awesome-icon
                    :icon="['fal', 'lock-alt']"
                />
            </div>
            <div
                v-if="error"
                class="error"
            >
                {{ error }}
            </div>
            <button
                class="btn-login"
                type="button"
                :disabled="loading"
                @click="handleLogin"
            >
                Sign in
            </button>
        </fieldset>
        <router-link
            to="forgotPassword"
            class="resetPassword"
            @click.native="$emit('forgot')"
        >
            Forgot your password? <u>Reset it</u>
        </router-link>
        <a
            href="https://documents.theidol.com/api/privacy/asantopro"
            class="privacyLink"
            target="_blank"
        >
            Privacy Policy
        </a>
    </div>
</template>

<script>
import userpilotService from "../../../services/userpilot-service";

export default {
    beforeRouteLeave(_to, _from, next) {
        if (this.user && !this.error && !this.twoFactorLogin) {
            userpilotService.identify(this.user);
        }
        return next();
    },
    props: {
        provider: {
            type: String,
            required: false,
            default: null
        },
        redirect: {
            type: String,
            required: false,
            default: "/dashboard"
        }
    },
    data(){
        return {
            email:"",
            password:"",
            providerLocal:"",
            loading:false,
            error:"",
            user: null
        };
    },
    computed: {
        checkUrl() {
            return window.location.hostname.search("localhost") >= 0
            || window.location.hostname.search("theidoluat.com") >= 0
            || window.location.hostname.search("theidolprod.com") >= 0;
        },
        twoFactorLogin() {
            return this.user.twoFactorEnabled && this.user.twoFactorRequired;
        }
    },
    mounted() {
        if (!this.checkUrl) {
            this.providerLocal = window.location.hostname.split(".")[0];
        } else if (this.provider !== null) {
            this.providerLocal = this.provider;
        }
    },
    methods:{
        handleLogin(e){
            e.preventDefault();
            this.loading = true;
            this.$store.dispatch("auth/login", {
                ["provider_id"]:this.providerLocal,
                email:this.email,
                password:this.password
            }).then((login) => {
                if(!login.providerTwoFactorRequired && !login.twoFactorRequired && !login.twoFactorEnabled){
                    this.user = login.userpilot;
                    this.$emit("loggedIn");
                }else if(!login.twoFactorEnabled){
                    this.$emit("twoFactorSetup", login.url);
                } else{
                    this.$emit("twoFactor");
                }
                this.loading = false;
            }).catch((err)=>{
                this.loading = false;
                this.error = err.message;
            });
        }
    }
};
</script>
