<template>
    <div>
        <BaseHeader
            title="Security"
            :breadcrumbs="breadcrumbs"
        />
        <div class="security-wrapper">
            <UserPassword class="mb-5" />
            <UserSecurityTwoFactor />
        </div>
    </div>
</template>

<script>
import store from "../../../../store";
import BaseHeader from "../../base/BaseHeader.vue";
import UserPassword from "./UserPassword.vue";
import UserSecurityTwoFactor from "./UserSecurityTwoFactor.vue";

async function fetchProfile(to, from, next) {
    try {
        await store.dispatch("auth/fetchProfile");
        return next();
    } catch(err) {
        return next((vm)=> {
            vm.$bvToast.toast(err.message, {
                title: "Error",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true
            });
        });
    }
}

export default {
    name: "UserSecurity",
    components: {
        UserPassword,
        UserSecurityTwoFactor,
        BaseHeader
    },
    beforeRouteEnter(to, from, next) {
        fetchProfile(to, from, next);
    },
    beforeRouteUpdate(to, from, next) {
        fetchProfile(to, from, next);
    },
    data() {
        return {
            breadcrumbs: [
                {
                    to: { name: "account" },
                    title: "Account"
                },
                {
                    title: "Security"
                }
            ]
        };
    }
};
</script>

<style lang="sass" scoped>
.security-wrapper
    max-width: 400px
</style>
