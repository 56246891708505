<template>
    <div class="smallDevice d-block d-sm-block d-md-block d-lg-none">
        <div>
            <h1>Please increase screen size</h1>
            <p>The screen width seems to be too low. (minimal supported width is 1024px)</p>
            <p>For optimal display of Asanto please use one of the following methods:</p>
            <ul>
                <li>
                    <font-awesome-icon
                        :icon="['fal', 'desktop-alt']"
                    />
                    <span><strong>Resize browser window</strong> to at least 1024px</span>
                </li>
                <li class="rotate">
                    <font-awesome-icon
                        :icon="['fal', 'tablet-android']"
                    />
                    <span><strong>Rotate your device</strong> to landscape orientation</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "ScreenSize"
};
</script>
