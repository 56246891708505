module.exports = {
    ILLEGAL: "ILLEGAL",
    EOF: "EOF",
    IF: "IF",

    IDENT: "IDENT",
    NUMBER: "NUMBER",
    STRING: "STRING",
    DATE: "DATE",

    TRUE: "TRUE",
    FALSE: "FALSE",

    ADD: "+",
    MINUS: "-",
    MULTIPLY: "*",
    DIVIDE: "/",
    GT: ">",
    GTE: ">=",
    LT: "<",
    LTE: "<=",
    NEQ: "<>",
    EQ: "=",
    COMMA: ",",
    LPAREN: "(",
    RPAREN: ")",
    LSQR: "[",
    RSQR: "]",
    QUOTES: "\"",
    HASH: "#",

    AND: "AND",
    OR: "OR",
    NOT: "NOT"
};
