<template>
    <div
        class="dashboard"
        :class="{ collapsed: isCollapsedSideBar }"
    >
        <a
            href="#content"
            class="skip"
            tabindex="0"
        >
            Skip to content
        </a>
        <div class="dashboard__inner">
            <div
                v-show="currentRouteName != 'form' && currentRouteName != 'page'"
                class="dashboard__sideBar"
                :class="{ collapsed: isCollapsedSideBar, 'dashboard__sideBar_test_mode': testMode === 'true'}"
            >
                <Sidebar :routes="routes" />
            </div>
            <div :class="['dashboard__content', {'testModeContent': testMode}]">
                <transition name="slide">
                    <testmode-header v-show="testMode" />
                </transition>
                <div id="content">
                    <router-view />
                </div>
            </div>
        </div>
        <policy-numbers-warning />
        <base-modal
            id="testmode-navigation-modal"
            :visible="showConfirmLeaveTestModeModal"
            title="Leaving Test Mode"
            message="Are you sure you want to leave Test Mode?"
            cancel-label="Stay in Test Mode"
            action-label="Leave Test Mode"
            @confirm="leaveTestMode"
            @cancel="returnToTestMode"
            @hide="leaveTestMode"
            @close="leaveTestMode"
        />        
        <screen-size />
    </div>
</template>
<script>
import Sidebar from "../sidebar/Sidebar";
import ScreenSize from "./ScreenSize.vue";
import PolicyNumbersWarning from "../admin/policyNumbers/PolicyNumbersWarning.vue";
import TestmodeHeader from "../testMode/TestmodeHeader.vue";
import {mapState} from "vuex";
import store from "../../../store";
import testModeNavigationMixin from "../../mixins/testModeNavigationMixin";

async function getUserProfile(to, from, next) {
    try {
        await store.dispatch("auth/fetchProfile");
        return next();
    } catch(err) {
        return next(err);
    }
}

export default {
    components: {
        Sidebar,
        ScreenSize,
        PolicyNumbersWarning,
        TestmodeHeader
    },
    mixins: [testModeNavigationMixin],
    beforeRouteEnter(to, from, next) {
        getUserProfile(to, from, next);
    },
    data() {
        return {
            routes: [
                {
                    show: true,
                    to: {
                        name: "dashboard"
                    },
                    icon: ["fal", "tachometer-alt-slowest"],
                    name: "Dashboard"
                },
                {
                    show: this.$can("view", "customers"),
                    to: {
                        name: "customers"
                    },
                    icon: ["fal", "users"],
                    name: "Customers"
                },
                {
                    show: this.$can("edit", "customers"),
                    to: {
                        name: "crm"
                    },
                    icon: ["fal", "headset"],
                    name: "CRM"
                },
                {
                    show: this.$can("view", "endpoints"),
                    to: {
                        name: "endpoints"
                    },
                    icon: ["fal", "link"],
                    name: "Endpoints"
                },
                {
                    show: this.$can("view", "schemes"),
                    to: {
                        name: "schemes"
                    },
                    icon: ["fal", "project-diagram"],
                    name: "Schemes"
                },
                {
                    show: this.$can("view", "documents"),
                    to: {
                        name: "resources"
                    },
                    icon: ["fal", "folders"],
                    name: "Resources"
                },
                {
                    show: this.$can("view", "admin"),
                    to: {
                        name: "admin"
                    },
                    icon: ["fal", "cog"],
                    name: "Configuration"
                },
                {
                    show: this.$can("view", "audit"),
                    to: {
                        name: "audit"
                    },
                    icon: ["fal", "file-search"],
                    name: "Audit"
                },
                {
                    show: this.$can("view", "editor"),
                    to: {
                        name:"forms",
                        params: {
                            brand: this.selectedBrand?.name_url,
                            provider: this.provider
                        }
                    },
                    icon: ["fal", "money-check-edit"],
                    name: "Forms"
                },
                {
                    show: this.$can("view", "reports"),
                    to: {
                        name: "report_templates"
                    },
                    icon: ["fal", "chart-pie"],
                    name: "Reports"
                },
                {
                    show: this.$can("view", "documents"),
                    to: {
                        name: "template_editor"
                    },
                    icon: ["fal", "puzzle-piece"],
                    name: "Templates"
                },
                {
                    show: this.$can("view", "renewals"),
                    to: {
                        name: "renewals"
                    },
                    icon: ["fal", "clock"],
                    name: "Renewals"
                },
                {
                    show: this.$can("view", "testmode"),
                    to: {
                        name: "test_mode"
                    },
                    icon: ["fal", "vial-circle-check"],
                    name: "Test Mode"
                }
            ]
        };
    },
    computed: {
        ...mapState({
            testMode: (state) => state.testMode.testing,
            isCollapsedSideBar: (state) => state.application.isCollapsedSideBar
        }),
        currentRouteName() {
            return this.$route.name;
        }
    }
};

</script>
