import abilityHelper from "./abilityHelper";
export default class AbilityPlugin{
}
AbilityPlugin.install = function(Vue,options){
    Vue.mixin({
        methods: {
            $can(action,subject) {
                return options.store.state.auth.selectedBrandPermissions.find(
                    item=>item.actions === action && item.subject === subject)?true:false;
            },
            $canOnProvider(action,subject) {
                return abilityHelper.canOnProvider(action, subject, options.store);
            }
        }
    });
};

