<template>
    <div class="base__date row">
        <div class="base__date--timePeriod col-12 col-xl-3">
            <label for="timePeriod">
                Date Range
            </label>
            <select
                id="timePeriod"
                v-model="timePeriod"
                class="form-control"
                @change="setRange"
            >
                <option value="today">
                    Today
                </option>
                <option value="week">
                    Last 7 days
                </option>
                <option value="month">
                    Last 30 days
                </option>
                <option value="custom">
                    Custom
                </option>
            </select>
        </div>
        <div class="base__date--start col-12 col-md-4 col-xl-3">
            <!--<label for="startDate">From</label>
            <div class="base__date--picker">
                <font-awesome-icon
                    :icon="['fal', 'calendar-alt']"
                />
                <Datepicker
                    id="startDate"
                    v-model="from"
                    name="startdate"
                />
            </div>-->
            <label for="startDate">From</label>
            <div class="base__date--picker">
                <b-form-datepicker
                    id="startDate"
                    v-model="from"
                    data-vv-as="From Date"
                    name="startdate"
                    :format="customFormatter"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    class="startDate"
                    input-class="form-control form-control-md"
                    :max="disabledDates"
                    :disabled="timePeriod !== 'custom'"
                />
            </div>
        </div>
        <div class="base__date--end  col-12 col-md-4 col-xl-3">
            <!--<label for="enddate">To</label>
            <div class="base__date--picker">
                <font-awesome-icon
                    :icon="['fal', 'calendar-alt']"
                />
                <Datepicker
                    id="enddate"
                    v-model="to"
                    name="endDate"
                />
            </div>-->
            <label for="enddate">To</label>
            <div class="base__date--picker">
                <b-form-datepicker
                    id="enddate"
                    v-model="to"
                    data-vv-as="To Date"
                    name="endDate"
                    class="endDate"
                    :format="customFormatter"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    input-class="form-control form-control-md"
                    :max="disabledDates"
                    :disabled="timePeriod !== 'custom'"
                />
            </div>
        </div>
        <div class="base__date--button pt-4 col-12 col-md-4 col-xl-3">
            <b-button
                variant="outline-primary"
                class="m-0 w-100"
                @click="handleUpdateDates"
            >
                Update
            </b-button>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    data() {
        return {
            timePeriod: "today",
            to: new Date(this.$moment.now()),
            from: new Date(this.$moment.now()),
            disabledDates: new Date()
        };
    },
    methods:{
        customFormatter(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        setRange(){
            if (this.timePeriod === "custom") {
                return;
            }

            let from = this.$moment();

            switch(this.timePeriod) {
                case "week":
                    from = from.subtract(7, "days");
                    break;
                case "month":
                    from = from.subtract(30, "days");
                    break;
            }

            this.from = from.toDate();
            this.to = new Date(this.$moment.now());

            this.handleUpdateDates();
        },
        handleUpdateDates(){
            let err = null;
            //set the to date to now if not provided
            if(this.to === null){
                this.to = new Date(this.$moment.now());
            }

            if(this.from === null){
                err = "You must include at least a from date";
            } else if(!this.$moment(this.from).isSameOrBefore(this.to)){
                err = "The from date must be the same or before the to date";
            }
            if(err){
                return this.$bvToast.toast(err, {
                    title: "Error",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
            }

            this.$emit("updated",{
                from:this.$moment(this.from).format("YYYY-MM-DD"),
                to:this.$moment(this.to).format("YYYY-MM-DD")
            });
        }
    }
};
</script>

<style lang="sass">
.base
  &__date
    margin-bottom: 15px
    &--start
    &--end
    label
      display: block
      margin-bottom: 3px
      margin-top: 5px
    &--picker
      position: relative



</style>