import axios from "../axios";

export const getConditions = () => axios.get("provider/conditions");

export const getConditionTypes = () => axios.get("provider/conditions/types");

export const getCondition = (conditionId) => axios.get(`provider/conditions/${conditionId}`);

export const getConditionUsers = (conditionId) => axios.get(`provider/conditions/${conditionId}/users`);

export const createCondition = (payload) => axios.post("provider/conditions", payload);

export const updateCondition = ({ id, name, data }) => axios.patch(`provider/conditions/${id}`, { name, data });

export const deleteCondition = (conditionId) => axios.delete(`provider/conditions/${conditionId}`);

export const unassignUsersFromCondition = ({ conditionId, users }) =>
    axios.put(`provider/conditions/${conditionId}/users`, { users });

export const assignUsersToCondition = ({ conditionId, users }) =>
    axios.post(`provider/conditions/${conditionId}/users`, { users });
