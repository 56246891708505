import imgService from "../../services/images";

export const types = {
    "SET_IMAGES": "SET_IMAGES",
    "SET_LOADING": "SET_LOADING",
    "SET_MESSAGE": "SET_MESSAGE",
    "SET_ERROR_UPLOAD": "SET_ERROR_UPLOAD",
    "SET_ERROR_GET_IMAGES": "SET_ERROR_GET_IMAGES",
    "SET_FILE_TYPES": "SET_FILE_TYPES"
};

const state = {
    images:[],
    loading: false,
    errorGetImages: false,
    errorUploadImage: false,
    message: null,
    fileTypes: []
};

const actions = {
    createFileExtensions(images) {
        let extensions = images.map(x => {
            return x.key.toLowerCase().slice(x.key.search(/\./));
        });
        let removeDuplicates = extensions.filter((item, index) => {
            return extensions.indexOf(item) === index;
        });
        return [...removeDuplicates];
    },
    async getImages({ commit }, brandId) {
        try {
            commit(types.SET_LOADING, true);
            commit(types.SET_ERROR_GET_IMAGES, false);
            let response = await imgService.getImages(brandId);
            let images = response.data.getImages;
            commit(types.SET_LOADING, false);
            commit(types.SET_IMAGES, images);
            commit(types.SET_FILE_TYPES, actions.createFileExtensions(images));
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR_GET_IMAGES, error.message);
        }
    },
    async sendImages({ commit }, payload) {
        try {
            commit(types.SET_ERROR_UPLOAD, false);
            commit(types.SET_LOADING, true);
            await imgService.sendImages(payload);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR_UPLOAD, error.message);
        }
    },
    clearErrors({commit}) {
        commit(types.SET_ERROR_UPLOAD, false);
        commit(types.SET_MESSAGE, false);
    }
};

const mutations = {
    [types.SET_IMAGES]: (state, images) => (state.images = images),
    [types.SET_LOADING]: (state, loading) => (state.loading = loading),
    [types.SET_ERROR_GET_IMAGES]: (state, error) => (state.errorGetImages = error),
    [types.SET_ERROR_UPLOAD]: (state, error) => (state.errorUploadImage = error),
    [types.SET_MESSAGE]: (state, message) => (state.message = message),
    [types.SET_FILE_TYPES]: (state, fileTypes) => (state.fileTypes = fileTypes)
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
