import {
    isNotEmpty,
    isExpressionIdPresent,
    isExpressionReturningArray,
    isValidIdentifier,
    isExpressionInScope
} from "./ExpressionValidators";
import Expression from "./Expression";

export default class PickExpression extends Expression {
    constructor(id){
        const properties = {
            expressionType:{
                value: "pick",
                isValid:null,
                validators:[]
            },
            type:{
                value: null,
                isValid:null,
                validators:[
                    isNotEmpty("Pick")
                ]
            },
            items:{
                value: null,
                isValid:null,
                validators:[
                    isExpressionIdPresent(),
                    isExpressionReturningArray(),
                    isNotEmpty(),
                    isValidIdentifier(),
                    isExpressionInScope()
                ]
            },
            item:{
                value: null,
                isValid:null,
                validators:[
                    isValidIdentifier()
                ]
            }
        };
        super(id,properties);
    }

    static cleanExpression(exp) {
        return {
            id:exp.id,
            returns:exp.returns,
            expressionType:exp.expressionType,
            items:exp.items,
            item:exp.item,
            type:exp.type,
            description: exp.description,
            tags: exp.tags
        };
    }
    // function which triggers a "reload" of the state
    // this is necessary to allow instant validation feedback
    // for the isExpressionInScope validator.
    hasMoved() {
        // eslint-disable-next-line no-self-assign
        this.items = this.items;
    }
}
