const DEFAULT_CONDITIONAL = true;
const DEFAULT_ROOT_EL = "#app";

function directive(e, el, binding) {
    const handler = typeof binding.value === "function" ? binding.value : binding.value?.handler;

    const isActive = (typeof binding.value === "object" && binding.value?.closeConditional !== undefined)
        ? binding.value.closeConditional
        : DEFAULT_CONDITIONAL;

    // The include element callbacks below can be expensive
    // so we should avoid calling them when we're not active.
    // Explicitly check for false to allow fallback compatibility
    // with non-toggleable components
    if (!e || isActive === false) {
        return;
    }

    // Check if additional elements were passed to be included in check
    // (click must be outside all included elements, if any)
    const elements = ((typeof binding.value === "object" && binding.value.include) || (() => []))();
    // Add the root element for the component this directive was defined on
    elements.push(el);

    // Check if it's a click outside our elements, and then if our callback returns true.
    // Non-toggleable components should take action in their callback and return falsy.
    // Toggleable can return true if it wants to deactivate.
    // Note that, because we're in the capture phase, this callback will occur before
    // the bubbling click event on any outside elements.
    // eslint-disable-next-line no-unused-expressions
    !elements.some(el => {
        if (!el) {
            return false;
        }
        return el.contains(e.target);
    }) && setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        isActive && handler && handler(e);
    }, 0);
}

const ClickOutside = {
    // [data-app] may not be found
    // if using bind, inserted makes
    // sure that the root element is
    // available, iOS does not support
    // clicks on body
    inserted(el, binding) {
        const onClick = (e) => directive(e, el, binding);

        const rootElSelector = (typeof binding.value === "object" && binding.value.rootEl) || DEFAULT_ROOT_EL;

        // iOS does not recognize click events on document
        // or body, this is the entire purpose of the v-app
        // component and [data-app], stop removing this
        const app = document.querySelector(rootElSelector)
            || document.body; // This is only for unit tests
        app.addEventListener("mousedown", onClick, true);
        app.addEventListener("touchstart", onClick, true);
        el._clickOutside = onClick;
    },

    unbind(el, binding) {
        if (!el._clickOutside) {
            return;
        }

        const rootElSelector = (typeof binding.value === "object" && binding.value.rootEl) || DEFAULT_ROOT_EL;

        const app = document.querySelector(rootElSelector)
            || document.body; // This is only for unit tests
        // eslint-disable-next-line no-unused-expressions
        app && app.removeEventListener("mousedown", el._clickOutside, true);
        // eslint-disable-next-line no-unused-expressions
        app && app.removeEventListener("touchstart", el._clickOutside, true);
        delete el._clickOutside;
    }
};

export {
    ClickOutside
};
