var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"mb-3"},[_vm._v(" Password ")]),_c('ValidationObserver',{ref:"form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ pending }){return [_c('ValidationProvider',{attrs:{"rules":{
                required: true,
            },"name":"Current Password","slim":"","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{attrs:{"label-for":"currentPassword","invalid-feedback":errors[0],"state":!errors[0] ? null : false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Current password "),_c('span',{staticClass:"text-danger",attrs:{"aria-hidden":"true"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"currentPassword","type":"password","autocomplete":"current-password","placeholder":"Enter your current password","state":!errors[0] ? null : false},model:{value:(_vm.form.current_password),callback:function ($$v) {_vm.$set(_vm.form, "current_password", $$v)},expression:"form.current_password"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                regex: _vm.passwordRegex
            },"name":"New password","vid":"password","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{attrs:{"label-for":"password","invalid-feedback":errors[0],"state":!errors[0] ? null : false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" New password "),_c('span',{staticClass:"text-danger",attrs:{"aria-hidden":"true"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"password","autocomplete":"new-password","type":"password","placeholder":"Enter new password","state":!errors[0] ? null : false},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('UserPasswordValidationPopover',{attrs:{"value":_vm.form.password,"target":"password","is-invalid":!!errors[0]}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                confirmed: 'password'
            },"name":"Confirm password","slim":"","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{attrs:{"label-for":"password2","invalid-feedback":errors[0],"state":!errors[0] ? null : false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Confirm password "),_c('span',{staticClass:"text-danger",attrs:{"aria-hidden":"true"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"password2","autocomplete":"new-password","type":"password","placeholder":"Confirm new password","state":!errors[0] ? null : false},model:{value:(_vm.form.password2),callback:function ($$v) {_vm.$set(_vm.form, "password2", $$v)},expression:"form.password2"}})],1)]}}],null,true)}),_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":pending}},[_vm._v(" Change password ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }