<template>
    <div>
        <div class="row">
            <div
                class="col-12"
                style="padding-top: 15px"
            >
                <h1
                    class="dashboard__content--header pb-3"
                >
                    {{ $route.meta.title }}
                </h1>
                <div :class="['dashboard__dateRange', {'hasCustom': timePeriod === 'custom'}]">
                    <label for="timePeriod">Date range</label>
                    <select
                        id="timePeriod"
                        v-model="timePeriod"
                        class="form-control"
                        :disabled="loading"
                        @change="setRange"
                    >
                        <option value="today">
                            Today
                        </option>
                        <option value="week">
                            Last 7 days
                        </option>
                        <option value="month">
                            Last 30 days
                        </option>
                        <option value="custom">
                            Custom dates
                        </option>
                    </select>
                </div>
                <div v-if="timePeriod === 'custom'">
                    <TitleDatePicker
                        :selected="endpointSelected"
                        @range="updateRange"
                    />
                </div>
            </div>
            <div style="position: relative">
                <div class="dashboard__selectEndpoint">
                    <label for="selectEndpoint">Select endpoint</label>
                    <select
                        id="selectEndpoint"
                        v-model="endpointSelected"
                        class="form-control"
                        :disabled="loading"
                    >
                        <option
                            v-for="endpoint in endpoints"
                            :key="endpoint.name"
                            :value="endpoint"
                            class="dashboard__options--check"
                        >
                            {{ endpoint.name }}
                        </option>
                    </select>
                </div>
            </div>
            <Metrics
                :endpoint="endpointSelected"
                :selected="endpointSelected.id"
                :range="range"
                :time-period="timePeriod"
            />
        </div>
    </div>
</template>

<script>
import Metrics from "../components/dashboard-metrics/Metrics";
import TitleDatePicker from "../components/dashboard-metrics/TitleDatePicker";
import { mapActions, mapState } from "vuex";
import store from "../../store";

async function getEndpoints(to, from, next) {
    try {
        await store.dispatch("dashboardMetrics/getEndpoints");
        return next();
    } catch(err) {
        return next(err);
    }
}

export default {
    components: {
        Metrics,
        TitleDatePicker
    },
    beforeRouteEnter(to, from, next) {
        getEndpoints(to, from, next);
    },
    data() {
        return {
            stickyHeight: 0,
            testValue: "",
            endpointSelected: {
                id: 0
            },
            timePeriod: "today",
            range: 0,
            settings:[]
        };
    },
    computed: {
        ...mapState({
            endpoints: state => state.dashboardMetrics.endpointsData,
            customRange: state => state.dashboardMetrics.customRange,
            loading: state => state.dashboardMetrics.loading
        })
    },
    async mounted() {
        if (this.endpoints && this.endpoints[0]) {
            this.endpointSelected = this.endpoints[0];
        }
    },
    methods:{
        ...mapActions("dashboardMetrics", ["runAll"]),
        setRange() {
            switch(this.timePeriod) {
                case "today":
                    this.range = 0;
                    break;
                case "week":
                    this.range = 7;
                    break;
                case "month":
                    this.range = 30;
                    break;
                case "custom":
                    this.range = this.customRange;
                    break;
            }
        },
        updateRange(range) {
            this.range = range;
        }
    }
};

</script>
