<template>
    <div class="dashboard__sideBar--inner">
        <SidebarHeader />
        <SidebarProviderNavigation v-if="!testMode && userHasProviderAccess" />
        <SidebarSelectBrand
            v-if="showBrandSelector && userHasBrandAccess"
            :test-mode="testMode"
        />
        <SidebarNavigation
            v-if="userHasBrandAccess"
            :routes="routes"
        />
        <SidebarFooter />
    </div>
</template>
<script>
import { mapState } from "vuex";
import SidebarSelectBrand from "./SidebarSelectBrand";
import SidebarNavigation from "./SidebarNavigation";
import SidebarHeader from "./SidebarHeader";
import SidebarFooter from "./SidebarFooter.vue";
import SidebarProviderNavigation from "./SidebarProviderNavigation.vue";

export default {
    name: "Sidebar",
    components: {
        SidebarProviderNavigation,
        SidebarFooter,
        SidebarHeader,
        SidebarNavigation,
        SidebarSelectBrand
    },
    props: {
        routes: {
            type: Array,
            required: true
        },
        showBrandSelector: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isCollapsedSideBarLocal: false
        };
    },
    computed: {
        ...mapState({
            isCollapsedSideBar: (state) => state.application.isCollapsedSideBar,
            selectedBrandPermissions: state => state.auth.selectedBrandPermissions,
            providerPermissions: state => state.auth.providerPermissions,
            testMode: state => state.testMode.testing
        }),
        userHasBrandAccess() {
            return !!this.selectedBrandPermissions.length;
        },
        userHasProviderAccess() {
            return !!this.providerPermissions.length;
        }
    },
    methods: {
        toggleSideBar() {
            this.$store.dispatch("application/toggleSideBar", !this.isCollapsedSideBar);
        }
    }
};
</script>
