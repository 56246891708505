<template>
    <div class="sidebar-dropdown">
        <b-dropdown
            class="sidebar-dropdown-activator"
            :toggle-class="['dropdown-btn', sidebarToggleClass]"
            no-caret
            dropright
            :disabled="testMode"
        >
            <template #button-content>
                <slot name="activator" />
            </template>
            <div class="sidebar-dropdown-menu">
                <slot />
            </div>
        </b-dropdown>
    </div>
</template>
<script>
export default {
    name: "SidebarDropdown",
    props: {
        sidebarToggleClass: {
            type: String,
            default: ""
        },
        testMode: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="sass" scoped>
.sidebar-dropdown
    width: 100%
    ::v-deep
        .sidebar-dropdown-activator
            width: 100%
            min-width: initial
            color: $white
            &.show
                .dropdown-btn
                    background: darken($headerBg, 12%) !important
        .dropdown-btn
            width: 100%
            max-height: initial
            height: 52px
            border-radius: 5px
            color: inherit
            background: transparent
            font-size: 0.8rem
            text-decoration: none
            padding: 10px
            white-space: nowrap
            border: none
            &:hover, &:active, &:focus, &.router-link-active
                background: darken($headerBg, 12%) !important
            &:focus-within:not(:focus)
                outline: 1px solid $white
        .sidebar-dropdown-menu
            min-width: 250px
            font-size: 0.8rem !important
            padding: 0.5rem 0
            background-color: $white
</style>
