<template>
    <b-modal
        id="base-modal"
        v-model="isVisible"
        :title="title"
        centered
        :no-close-on_esc="noCloseOnEsc"
        :no-close-on-backdrop="noCloseOnBackdrop"
        :hide-header-close="hideHeaderClose"
        @close="$emit('close')"
        @hide="$emit('hide')"
    >
        {{ message }}
        <slot />
        <template #modal-footer>
            <b-button
                variant="outline-primary"
                @click="$emit('cancel')"
            >
                {{ cancelLabel }}
            </b-button>
            <b-button
                variant="primary"
                :disabled="disableAction"
                @click="$emit('confirm')"
            >
                {{ actionLabel }}
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        /**
         * The title of the modal
         */
        title: {
            required: false,
            type: String,
            default: "Confirm Action"
        },
        /**
         * The message to be displayed
         */
        message: {
            required: false,
            type: String,
            default: "Please confirm your selection."
        },
        /**
         * Toggles the visibility of the model
         */
        visible: {
            required: true,
            type: Boolean
        },
        /**
         * The label for the close/cancel button
         */
        cancelLabel : {
            required: false,
            type: String,
            default: "Cancel"
        },
        /**
         * The label for the action button
         */
        actionLabel: {
            required: false,
            type: String,
            default: "Confirm"
        },
        noCloseOnEsc: {
            required: false,
            type: Boolean,
            default: false
        },
        noCloseOnBackdrop: {
            required: false,
            type: Boolean,
            default: false
        },
        hideHeaderClose: {
            required: false,
            type: Boolean,
            default: false
        },
        disableAction: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isVisible: false
        };
    },
    watch: {
        isVisible() {
            this.$emit("update:visible", this.isVisible);
        },
        visible() {
            this.isVisible = this.visible;
        }
    }
};
</script>
