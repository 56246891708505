<template>
    <header
        class="testmode-header"
    >
        <div class="lozenge">
            Test Mode
        </div>
        <div class="text">
            <span>You are currently in Test Mode.</span>
            <span
                v-if="updatedDate"
                class="updated"
            >
                Form last updated at {{ formattedDate }}
            </span>
        </div>
    </header>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            updatedDate: state => state.crm.testInfo.updatedDate,
            revisionId: state => state.crm.testInfo.revisionId
        }),
        formattedDate() {
            return this.updatedDate ? dayjs(this.updatedDate).format("DD/MM/YYYY") : undefined;
        }
    }
};
</script>
<style scoped lang="sass">
header
    &.testmode-header
        background-color: #595b60
        color: #fff
        position: sticky
        top: 0
        z-index: 100
        margin: 0 -15px
        width: calc(100% + 30px)
        border-bottom: 1px solid rgba(204, 204, 204, 0.58)
        height: 61px
        box-shadow: 0px 4px 3px 0px rgba(224, 224, 224, 0.16)
        display: flex
        align-items: center
        justify-content: flex-start
        font-family: "ArialMT", "Arial", sans-serif
        gap: 10px
        padding: 0
        box-sizing: border-box
        flex-wrap: nowrap
        transition: all 1s ease-in-out


div.lozenge
    width: 100px
    min-width: 100px
    margin: 10px 0 10px 10px
    height: 33px
    padding: 4px
    border-radius: 6px
    border: 1px solid #ffbe17
    background-color: #ffcc15
    box-sizing: border-box
    color: #494b50
    text-align: center
    font-size: 16px

div.text
    display: flex
    align-items: center
    margin: 0
    line-height: 33px
    justify-content: flex-start
    flex-wrap: nowrap
    font-size: 15px
    margin-top: 3px
    gap: 4px

> span
    margin-right: 10px

span.updated
    font-size: 16px
@media (max-width: 768px)
    div.text
        flex-wrap: wrap
        font-size: 18px
        align-items: center

    span
        flex-basis: 100%

        &.updated
            font-size: 10px
</style>
