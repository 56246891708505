<template>
    <div class="sidebar-select-brand">
        <SidebarDropdown
            :test-mode="testMode"
            class="sidebar-dropdown-wrapper"
        >
            <template #activator>
                <SidebarDropdownItem
                    :avatar-text="nameInitials(selectedBrand.name)"
                    :title="selectedBrand.name"
                    :subtitle="selectedBrand.name_url"
                    :is-collapsed="isCollapsedSideBar"
                    :icon="['fal', 'angle-down']"
                />
            </template>
            <template #default>
                <div class="sidebar-dropdown-list">
                    <SidebarDropdownItem
                        v-for="(brand) in orderedBrands"
                        :key="brand.id"
                        :avatar-text="nameInitials(brand.name)"
                        avatar-variant="dark"
                        avatar-size="30px"
                        :title="brand.name"
                        :subtitle="brand.name_url"
                        :icon="brandAppendIcon(brand)"
                        class="sidebar-dropdown-list_item"
                        role="button"
                        tabindex="0"
                        @keydown.enter="switchBrand(brand)"
                        @click="switchBrand(brand)"
                    />
                </div>
            </template>
        </SidebarDropdown>
    </div>
</template>
<script>
import { mapState } from "vuex";
import SidebarDropdown from "./SidebarDropdown.vue";
import SidebarDropdownItem from "./SidebarDropdownItem.vue";

export default {
    name: "SidebarSelectBrand",
    components: {
        SidebarDropdown,
        SidebarDropdownItem
    },
    props: {
        testMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            brands: (state) => state.auth.brands,
            selectedBrand: (state) => state.auth.selectedBrand,
            isCollapsedSideBar: (state) => state.application.isCollapsedSideBar
        }),
        orderedBrands() {
            return Array.from(this.brands).sort((a,b) => a.name.localeCompare(b.name));
        }
    },
    methods: {
        nameInitials(name) {
            if (!name) {
                return "";
            }
            const firstLetters = name.split(" ").map((item) => item.slice(0, 1).toUpperCase());
            if (firstLetters.length === 1) {
                return firstLetters[0];
            }
            const [first, second] = firstLetters;
            return first + second;
        },
        brandAppendIcon(brand) {
            return this.selectedBrand.id === brand.id ? ["far", "check"] : [];
        },
        async switchBrand(brand) {
            await this.$store.dispatch("auth/setSelectedBrand", brand);
            return (this.$route.name === "dashboard") ?
                location.reload() : this.$router.push({ name: "dashboard" }).then(() => {
                    this.$router.go(0);
                });
        }
    }
};
</script>

<style lang="sass" scoped>
.sidebar-select-brand
    border-bottom: 1px solid rgba(255, 255, 255, 0.3)
    .sidebar-dropdown-wrapper
        padding: 10px
    .sidebar-dropdown-list
        display: flex
        flex-flow: column
        gap: 3px
        max-height: 250px
        overflow-y: auto
        &_item
            padding: 0.5rem 1rem
            &:hover
                background-color: #e8e0f3
                cursor: pointer
</style>
