import {
    isFormulaValid,
    isNotEmpty,
    hasValidChildren
} from "./ExpressionValidators";
import Expression from "./Expression";

export default class IfExpression extends Expression {
    constructor(id){
        const properties = {
            condition:{
                value: null,
                isValid:null,
                dirty:false,
                errors:[],
                validators:[
                    isNotEmpty(),
                    isFormulaValid()
                ]
            },
            expressionType:{
                value: "if",
                isValid:null,
                validators:[]
            },
            block:{
                value: undefined,
                isValid:null,
                validators:[hasValidChildren()]
            }
        };
        super(id,properties);

    }
    static cleanExpression(exp) {
        return {
            id:exp.id,
            returns:exp.returns,
            expressionType:exp.expressionType,
            condition:exp.condition,
            description: exp.description,
            tags: exp.tags,
            block:exp.block.map((item) => item.clean())
        };
    }
}
