<template>
    <div>
        <fieldset>
            <legend>
                Please Enter Your Two Factor Code
            </legend>
            <div class="form-group">
                <label
                    for="inputEmail"
                    class="sr-only"
                >Code</label>
                <input
                    id="inputCode"
                    v-model="code"
                    data-lpignore="true"
                    type="text"
                    class="form-control"
                    placeholder="Code"
                    required
                    @keyup.enter="handleLogin"
                >
                <font-awesome-icon
                    :icon="['fal', 'keyboard']"
                />
            </div>
            <button
                class="btn-login"
                type="button"
                @click="handleLogin"
            >
                Sign in
            </button>
        </fieldset>
        <div
            v-if="error"
            class="error"
        >
            {{ error }}
        </div>
        <router-link
            to="forgotPassword"
            class="resetPassword"
        >
            Forgotten Password
        </router-link>
    </div>
</template>

<script>
import userpilotService from "../../../services/userpilot-service";

export default {
    beforeRouteLeave(_to, _from, next) {
        if (this.user && !this.error) {
            userpilotService.identify(this.user);
        } 
        return next();        
    },
    data(){
        return {
            code: "",
            loading: false,
            error: "",
            timeout: null,
            user: null
        };
    },
    created() {
        this.timeout = setTimeout(() => {
            this.$router.push("/login");
        }, 300000);
    },
    methods:{
        handleLogin(e) {
            e.preventDefault();
            this.loading = true;
            this.$store.dispatch("auth/loginSecondFactor", {
                code:this.code
            }).then((login) => {
                this.user = login.userpilot;
                this.loading = false;
                clearTimeout(this.timeout);
                this.$emit("loggedIn");
            }).catch((err) => {
                this.loading = false;
                this.error = err.message;
            });
        }
    }
};
</script>
