import axios from "./axios";

export default {
    getImages(brandId) {
        return axios.get(`brands/${brandId}/images`);
    },
    sendImages(payload) {
        const brandId = payload.get("brandId");
        payload.delete("brandId");
        return axios.post(`brands/${brandId}/images`, payload);
    },
    authenticateCustomer({token, postcode, provider, brand}) {
        return axios.post(
            `customer-images/auth/${provider}/${brand}/${token}?postcode=${postcode}`
        );
    },
    getImage({ token, postcode, provider, brand , format}) {
        return axios.get(
            `customer-images/${provider}/${brand}/${token}/generate?postcode=${postcode}&format=${format}`
        );
    }
};
