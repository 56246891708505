<template>
    <div>
        <b-overlay
            variant="transparent"
            :show="loading"
        >
            <div
                v-show="total && total > 0"
                class="dashboard__charts--chart"
            >
                <canvas :id="chartId" />
                <h4>{{ title }}</h4>
            </div>
        </b-overlay>
        <div
            v-show="!total || total <= 0"
            class="dashboard__charts--chart"
        >
            <h3 class="warning">
                Data not available
            </h3>
        </div>
    </div>
</template>
<script>
import Chart from "chart.js";
export default {
    props: {
        chartdata: {
            type: Array,
            required: true,
            default: function() {
                return [{ label: "", data: "" }];
            }
        },
        options: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            default: "Untitled"
        },
        identifier: {
            type: String,
            required: true
        },
        endpoint: {
            type: Object,
            required: true
        },
        range: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            ctx: null,
            chart: null,
            chartId: this.generateUUID()
        };
    },
    computed: {
        total() {
            return this.chartdata.reduce((acc, curr) => {
                return acc + curr.data;
            }, []);
        },
        loading() {
            return this.$store.state.dashboardMetrics.loading;
        }
    },
    watch: {
        chartdata: function(newChartData) {
            this.updateChart(newChartData);
        }
    },

    mounted() {
        this.ctx = document.getElementById(this.chartId).getContext("2d");
        this.createChart([]);
    },
    methods: {
        createChart() {
            this.chart = new Chart(this.ctx, {
                type: "doughnut"
            });
        },
        updateChart(chartData) {
            this.chart.data.labels = chartData.map(d => d.label);
            this.chart.data.datasets = [
                {
                    data: chartData.map(d => d.data),
                    backgroundColor: this.options.backgroundColor,
                    borderColor: this.options.borderColor,
                    borderWidth: this.options.borderWidth,
                    hoverColor: this.options.hoverColor
                }
            ];
            this.chart.update();
        },
        generateUUID() {
            return Math.random()
                .toString(16)
                .slice(2);
        }
    }
};
</script>

<style scoped lang="sass">
.bg-light
    background-color: transparent!important
</style>