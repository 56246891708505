import axios from "./../../services/axios";

// import docService from "../../services/documents";

export const types = {
    "SET_CANCELLATION": "SET_CANCELLATION",
    "SET_ERROR": "SET_ERROR",
    "SET_REFUND": "SET_REFUND",
    "SET_PAYMENT": "SET_PAYMENT",
    "SET_SUCCESS": "SET_SUCCESS",
    "SET_LOADING": "SET_LOADING",
    "RESET": "RESET"
};

export const state = () => ({
    cancellation: [],
    error: false,
    refund: "",
    payment: "",
    success: false,
    loading: false
});
const getters = {

};

const actions = {
    async fetchCancellation({commit, rootState}, payload) {
        try {
            commit(types.SET_ERROR, false);
            commit(types.SET_CANCELLATION, []);
            payload.brand = rootState.auth.selectedBrand.name_url;

            const result = await axios.post(`/brands/${payload.brand}/adjustments/calculate-cancellation`, payload );
            commit(types.SET_CANCELLATION, result.data);
        } catch (error) {
            commit(types.SET_ERROR, error.message);
        } 
    },
    async cancellationQuote({commit, rootState}, payload) {
        try {
            commit(types.SET_ERROR, false);
            commit(types.SET_CANCELLATION, []);
            const brand = rootState.auth.selectedBrand.name_url;
            const result =
                // eslint-disable-next-line max-len
                await axios.post(`/brands/${brand}/policies/${payload.policy_revision_id}/cancellation-quote?endpoint=${payload.endpointNameUrl}`,
                    payload.data);
            if(result?.data?.response?.quotes?.length) {
                commit(types.SET_CANCELLATION, result.data.response.quotes);
            } else {
                commit(types.SET_ERROR, "Missing quote");
            }
        } catch (error) {
            commit(types.SET_ERROR, error.message);
        }
    },
    async processCancellation({commit, rootState}, payload) {
        try {
            commit(types.SET_ERROR, false);
            commit(types.SET_LOADING, true);
            commit(types.SET_SUCCESS, false);
            const brand = rootState.auth.selectedBrand.name_url;
            const result =
                await axios.post(`/brands/${brand}/policies/${payload.policy_number}/cancellation?qr=${payload.qr}
&qn=${payload.qn}`, payload.data);
            commit(types.SET_LOADING, false);
            if(!result?.data.policy_status) {
                commit(types.SET_ERROR, "Not cancelled");
            } else {
                commit(types.SET_SUCCESS, true);
            }
        } catch (error) {
            commit(types.SET_ERROR, error.message);
            commit(types.SET_LOADING, false);
        }
    },
    async processRefund({commit, rootState}, payload) {
        try {
            commit(types.SET_ERROR, false);
            commit(types.SET_LOADING, true);
            payload.brand = rootState.auth.selectedBrand.name_url;
            const result = await axios.post(`/brands/${payload.brand}/adjustments/refund`, payload );
            commit(types.SET_REFUND, result.data);
            commit(types.SET_SUCCESS, true);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_ERROR, error.message);
            commit(types.SET_LOADING, false);
        } 
    },
    async processAdjustment({commit, rootState, dispatch}, payload) {
        try {
            commit(types.SET_ERROR, false);
            commit(types.SET_LOADING, true);
            payload.policyNumber = rootState.crm.policy.policy_reference; 
            payload.brand = rootState.auth.selectedBrand.name_url;
            // eslint-disable-next-line max-len
            if (rootState.crm.adjustType === "remove_traveller" || rootState.crm.adjustType === "change_in_health_remove") {
                payload.adjustReason = true;
            }
            const result = await axios.post(`/brands/${payload.brand}/adjustments/payment`, payload );
            commit(types.SET_PAYMENT, result.data);
            commit(types.SET_SUCCESS, true);
            commit(types.SET_LOADING, false);
            dispatch("payments/setStep", 1, {root: true});
            dispatch("crm/clearPolicyStateData", null, { root: true });
        } catch (error) {
            commit(types.SET_ERROR, error.message);
            dispatch("payments/setStep", 2, {root: true});
            commit(types.SET_LOADING, false);
        } 
    },
    reset({ commit }) {
        commit("RESET");
    },
    async updateCustomerInfo({commit, rootState}, payload) {
        try {
            commit(types.SET_ERROR, false);
            commit(types.SET_LOADING, true);
            payload.policyNumber = rootState.crm.policy.policy_reference; 
            payload.brand = rootState.auth.selectedBrand.name_url;
            await axios.post(`/brands/${payload.brand}/adjustments/update-info`, payload );
            commit(types.SET_SUCCESS, true);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_ERROR, error.message);
            commit(types.SET_LOADING, false);
        } 
    }
};

const mutations = {
    [types.RESET](_state) {
        Object.assign(_state, state());
    },
    [types.SET_CANCELLATION]: (state, cancellation) => (state.cancellation = cancellation),
    [types.SET_ERROR]: (state, error) => (state.error = error),
    [types.SET_REFUND]: (state, refund ) => (state.refund = refund),
    [types.SET_PAYMENT]: (state, payment ) => (state.payment = payment),
    [types.SET_SUCCESS]: (state, success ) => (state.success = success),
    [types.SET_LOADING]: (state, loading) => (state.loading = loading)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
