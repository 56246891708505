import Expression from "./Expression";
import {
    isDefaultValid,
    // isExpressionTable,
    isNotEmpty,
    isLookupTableHeadingValid
    // isExpressionInScope
} from "./ExpressionValidators";

export default class LookupTableExpression extends Expression {
    constructor(id){
        const properties = {
            returns:{  //this will be calculated based on the input - could this move into expression?
                value: null,
                isValid:null,
                validators:[
                    isDefaultValid()
                ],
                get type(){
                    return this.value.type?this.value.type:null;
                },  //getter to stop having to go into values object e.g returns{type:number}
                get default(){
                    return this.value.default?this.value.default:null;
                }  //getter to stop having to go into values object e.g returns{type:number}
            },
            expressionType:{
                value: "lookuptable",
                isValid:null,
                validators:[]
            },
            types:{
                value: null,
                isValid:null,
                validators:[]
            },
            headings:{
                value: null,
                isValid:null,
                validators:[
                    isLookupTableHeadingValid()
                    // isExpressionInScope()
                ]
            },
            //we use this to hold the name of the
            //table columns we are mapping to
            mappedHeadings:{
                value: null,
                isValid:null,
                validators:[]
            },
            mappedReturns:{
                value: [],
                isValid:null,
                validators:[]
            },
            //we have provided an additional set and get
            //as if the aggregate is set we must update
            //the type. This should be automatted
            aggregate:{
                value: null,
                isValid:null,
                validators:[
                    isNotEmpty("Multiple Matches")
                ]

            },
            //used to store the table to refence if we are not
            //storing the data
            tableName:{
                value: null,
                isValid:null,
                validators:[
                    isNotEmpty("Table Name")
                    // isExpressionTable()
                ]
            },
            nullMatches: {
                value: null,
                isValid:null,
                validators:[]
            }
        };
        super(id,properties);
    }
    // function which triggers a "reload" of the state
    // this is necessary to allow instant validation feedback
    // for the isExpressionInScope validator.
    hasMoved() {
        // eslint-disable-next-line no-self-assign
        this.mappedHeadings = this.mappedHeadings;
    }

    /* Used by the import tools - wipes any data and inserts new
    */
    // create(data,headings,types){
    //     this.properties.types.value = types;
    //     //update valid properties
    // }

    updateAggregate(aggregate){
        this.aggregate = aggregate;
        this.inferReturnType();
    }

    // for existing schemes nullMatches won't exsist so
    // set them to false on load if nullMatches is undefind
    static nullMatchesBuilder(headers, nullMatches) {
        if (!headers) {
            return [];
        }
        if (!nullMatches && Array.isArray(headers) && headers.length > 1) {
            return new Array(headers.length - 1).fill(false);
        }
        return nullMatches;
    }
    
    static objectTypesBuilder(types, returns){
        if(!returns){
            return null;
        }
        if(returns.type!=="object"||(returns.items&&returns.items.type!=="object")) {
            return types;
        }
        const dynamicReturnsForArrays = returns.type === "object" ? returns.properties : returns.items.properties;

        let typesFromObject = dynamicReturnsForArrays.reduce((types,currentType)=>{
            types.push(currentType.type);
            return types;
        },[]);



        const typesWithoutObject = types.filter(type=>type!=="object");
        const combinedTypes = typesWithoutObject.concat(typesFromObject);

        return combinedTypes;
    }

    static objectHeadingsBuilder(headings, returns){
        if(!returns){
            return null;
        }
        
        if(returns.type!=="object"||(returns.items&&returns.items.type!=="object")) {
            return headings;
        }
        const dynamicReturnsForArrays = returns.type === "object" ? returns.properties : returns.items.properties;



        let headingsExcludeObject = [...headings];
        headingsExcludeObject.pop();

        const typesFromObject = dynamicReturnsForArrays.map(heading=>heading.id);
        let combinedHeadings = headingsExcludeObject.concat(typesFromObject);
        combinedHeadings[combinedHeadings.length-1] = "returns";

        return combinedHeadings;
    }

    updateReturns(index, returns){
        if(!returns){
            this.mappedReturns = this.mappedReturns.map((returns,i)=>{
                if(i<=index){
                    returns = false;
                }
                return returns;
            });
            this.nullMatches.push(false);
        }else{
            this.mappedReturns[index] = returns;
            this.mappedReturns = [...this.mappedReturns];
            this.nullMatches.splice(index, 1);
        }
        this.inferReturnType();
    }

    updateReturnInExpression(type, default_){
        if(type==="array"){
            this.returns = {
                type,
                default:default_,
                items: {
                    type: this.returns.type,
                    items: this.returns.properties
                }
            };

        } else if(type!=="object"){
            this.returns ={
                type,
                default:default_
            };
        }else{
            this.returns ={
                type,
                default:default_,
                properties:[]
            };
        }
    }

    inferReturnType(){
        if (this.columns === null) {
            return;
        }
        if (this.aggregate==="all" || this.aggregate === "all-no-duplicates" || this.aggregate === "null") {
            return this.updateReturnInExpression("array", this.returns.default);
        }

        const amountOfReturnColumn = this.mappedReturns.filter(isReturn => isReturn).length;

        if (amountOfReturnColumn > 1) {
            return this.updateReturnInExpression("object", this.returns.default);
        } else {
            return this.updateReturnInExpression(this.types[this.types.length-1], this.returns.default);
        }
    }

    static cleanObjectExpression(exp, isArrayObject){
        let properties =[];
        exp.mappedHeadings.forEach((heading,i)=>{
            if(exp.mappedReturns[i]){
                properties.push({
                    id:heading,
                    type: exp.types[i]
                });
            }
        });

        const objectLength = properties.length;
        const totalColumnsLength = exp.mappedHeadings.length;
        let returns = {};
        if(isArrayObject){
            returns = {
                type: "array",
                items: {
                    type: "object",
                    properties
                },
                default: "reject"
            };
        }else{
            returns = {
                type: "object",
                default: "reject",
                properties
            };

        }

        let types = [];
        for(let i = 0; i<totalColumnsLength-objectLength;i++){
            types.push(exp.types[i]);
        }

        types.push("object");

        let filteredHeadings = [...exp.headings];
        filteredHeadings.splice(0-objectLength);
        let headings = filteredHeadings;
        headings.push("returns");

        return {
            id: exp.id,
            default: exp.default,
            expressionType: "lookup",
            headings,
            mappedHeadings:exp.mappedHeadings,
            mappedReturns:exp.mappedReturns,
            aggregate: exp.aggregate || "first",
            types,
            returns,
            data: exp.tableName,
            description: exp.description,
            tags: exp.tags,
            nullMatches: exp.nullMatches
        };

    }
    static cleanArrayExpression(exp){

        let returns = {
            type:"array",
            items: {
                type: exp.types[exp.types.length-1]
            },
            default: "reject"

        };

        let x =  {
            id:exp.id,
            default:exp.default,
            //chnage this back to the lookuop type
            expressionType:"lookup",
            headings:exp.headings,
            mappedHeadings:exp.mappedHeadings,
            mappedReturns:exp.mappedReturns,
            aggregate:exp.aggregate,
            types:exp.types,
            returns,
            data: exp.tableName,
            description: exp.description,
            tags: exp.tags,
            nullMatches: exp.nullMatches
        };

        return x;


    }

    static cleanExpression(exp) {
        let isArrayObject = exp.returns.type==="array";
        //console.log(exp);
        let amountOfReturnColumns = 0;
        if(exp.mappedReturns){
            amountOfReturnColumns = exp.mappedReturns.filter(isReturn => isReturn).length;
        }

        if(amountOfReturnColumns>1){
            return this.cleanObjectExpression(exp,isArrayObject);
        }

        if(exp.returns.type==="array"){
            return this.cleanArrayExpression(exp);
        }
        return {
            id:exp.id,
            default:exp.default,
            //chnage this back to the lookup type
            expressionType:"lookup",
            headings:exp.headings,
            mappedHeadings:exp.mappedHeadings,
            mappedReturns:exp.mappedReturns,
            aggregate:exp.aggregate,
            types:exp.types,
            returns:exp.returns,
            data: exp.tableName,
            description: exp.description,
            tags: exp.tags,
            nullMatches: exp.nullMatches
        };
    }
}
