import { render, staticRenderFns } from "./SidebarFooter.vue?vue&type=template&id=63bf6775&scoped=true&"
import script from "./SidebarFooter.vue?vue&type=script&lang=js&"
export * from "./SidebarFooter.vue?vue&type=script&lang=js&"
import style0 from "./SidebarFooter.vue?vue&type=style&index=0&id=63bf6775&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63bf6775",
  null
  
)

export default component.exports