<template>
    <div
        v-b-tooltip
        :title="title"
        :class="`${disabled ? 'pointerNotAllowed': ''}`"
    >
        <label
            :for="toggleId"   
            :class="`track ${disabled ? 'disabled' : ''}`"
        >
            <span class="sr-only">
                {{ title }}
            </span>
            <input
                :id="toggleId"
                v-model="toggle"
                type="checkbox"
                @click="handleToggle"
            >
            <div class="thumb round" />
        </label>
    </div>
</template>

<script>

export default {
    props: {
        initialValue: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            toggle: false,
            toggleId: null
        };
    },
    mounted() {
        this.toggle = this.initialValue;
        this.toggleId = this._id;
    },
    methods: {
        handleToggle() {
            if (this.disabled) {
                return;
            }
            this.toggle = !this.toggle;
            this.$emit("setToggleValue", this.toggle);
        }
    }
};
</script>

<style scoped lang="sass">
div
	&.pointerNotAllowed
		cursor: not-allowed
.track 
	position: relative 
	display: inline-block 
	width: 35px 
	height: 14px 
	input 
		display: none
	&.disabled
		pointer-events: none
.thumb 
	position: absolute 
	cursor: pointer 
	top: 0 
	left: 0 
	right: 0 
	bottom: 0 
	padding: 2px 
	border: 1px solid #bdc1c5 
	background-color: #bdc1c5 
	box-sizing: border-box 
	-webkit-transition: 0.4s 
	transition: 0.4s
	&:before 
		position: absolute 
		content: "" 
		height: 20px 
		width: 20px 
		left: 0px 
		bottom: -4px 
		background-color: #ffffff 
		-webkit-transition: 0.4s 
		transition: 0.4s 
		border: 1px solid #ededed 
		box-sizing: border-box 
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35) 
	&.round 
		border-radius: 34px 
		&:before 
			border-radius: 50%
input
	&:checked 
		& + .thumb 
			background-color: #a5d9cd 
			&:before 
				-webkit-transform: translateX(14px) 
				-ms-transform: translateX(14px) 
				transform: translateX(14px) 
				border: 1px solid #1eaf8e 
				background-color: #1eaf8e 
				box-sizing: border-box 
				box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35) 
	&:focus 
		& + .thumb 
			box-shadow: 0 0 1px #101010 	
</style>