<template>
    <div
        :id="endpoint.name"
        class="dashboard__charts row"
    >
        <div class="col-12 p-0">
            <h2>{{ requester ? requester : endpoint.name }}  </h2>
        </div>
        
        <div class="col-6 dashboard__charts--data col-lg-6 col-xl-7">
            <Dashboard
                :endpoint="endpoint"
                :range="range"
                :selected="selected"
                :time-period="timePeriod"
                :quotes="quotesData"
                :errors="errorsData"
                :conversions="conversionData"
                :requests="requestsData"
                :rejects="rejectsData"
            />
        </div>
        <div class="col-6 dashboard__charts--chart col-lg-6 col-xl-5">
            <polar-chart
                :title="polarChartTitle"
                identifier="quotes"
                :endpoint="endpoint"
                :chartdata="chartData"
                :options="options"
                :range="range"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PolarChart from "./PolarChart";
import Dashboard from "./Dashboard";

export default {
    name: "PolarChartContainer",
    components: { PolarChart, Dashboard },
    props: {
        endpoint: {
            type: Object,
            required: true
        },
        selected: {
            type: Number,
            required: true
        },
        range: {
            type: Number,
            required: true
        },
        timePeriod: {
            type: String,
            required: true
        },
        requester: {
            type: String,
            required: false,
            default: ""
        }
    },
    data: () => ({
        chartdata: null,
        options: {
            backgroundColor: [
                "rgba(255, 210, 93, 0.5)",
                "rgba(51, 191, 151, 0.5)",
                "rgba(62, 183, 233, 0.5)",
                "rgba(75, 75, 75, 0.5)",
                "rgba(98, 65, 147, 0.5)"
            ],
            borderColor: [
                "rgba(255, 210, 93, 0)",
                "rgba(51, 191, 151, 0)",
                "rgba(62, 183, 233, 0)",
                "rgba(75, 75, 75, 0)",
                "rgba(51, 191, 151, 0)"
            ],
            borderWidth: 0,
            hoverColor: ["rgba(255, 210, 93, 1))", "rgba(51, 191, 151, 1)"]
        }
    }),
    computed: {
        quotesData() {
            return this.$store.state.dashboardMetrics.current[this.selected] !==
        undefined
                ? this.$store.state.dashboardMetrics.current[this.selected]
                    .quotesChartData
                : 0;
        },
        conversionData() {
            return this.$store.state.dashboardMetrics.current[this.selected] !==
        undefined
                ? this.$store.state.dashboardMetrics.current[this.selected]
                    .conversionChartData
                : 0;
        },
        rejectsData() {
            return this.$store.state.dashboardMetrics.current[this.selected] !==
        undefined
                ? this.$store.state.dashboardMetrics.current[this.selected]
                    .rejectsChartData
                : 0;
        },
        loading() {
            return this.$store.state.dashboardMetrics.loading;
        },
        requestsData() {
            return this.$store.state.dashboardMetrics.current[this.selected] !==
        undefined
                ? this.$store.state.dashboardMetrics.current[this.selected]
                    .requestsChartData
                : 0;
        },
        errorsData() {
            return this.$store.state.dashboardMetrics.current[this.selected] !==
        undefined
                ? this.$store.state.dashboardMetrics.current[this.selected]
                    .errorsChartData
                : 0;
        },
        ...mapState({
            loading: state => state.dashboardMetrics.loading,
            error: state => state.dashboardMetrics.error,
            customRange: state => state.dashboardMetrics.customRange,
            chartData: state => state.dashboardMetrics.chartData
        }),
        polarChartTitle() {
            const days = this.timePeriod === "custom" ? this.customRange : this.range;

            if (days === 0) {
                return "Today's rundown";
            }

            return `Your ${days} day rundown`;
        }
    },
    watch: {
        async range(newRange) {
            const data = {
                endpointID: this.selected,
                unit: "day",
                from: this.$moment()
                    .subtract(newRange, "days")
                    .format("YYYY-MM-DD"),
                to: this.$moment().format("YYYY-MM-DD"),
                toTime: "23:59:59",
                fromTime: "00:00:00",
                range: newRange,
                requester: this.requester
            };  
            await this.runAll(data);
        }
    },
    async mounted() {
        const metricdata = {
            endpointID: this.selected,
            unit: "day",
            from: this.$moment()
                .subtract(this.range, "days")
                .format("YYYY-MM-DD"),
            to: this.$moment().format("YYYY-MM-DD"),
            toTime: "23:59:59",
            fromTime: "00:00:00",
            range: this.range,
            requester: this.requester
        };
        await this.runAll(metricdata);
    },
    methods: {
        ...mapActions("dashboardMetrics", [
            "runAll"
        ])
    }
};
</script>

