import {
    isNotEmpty,
    isExpressionIdPresent,
    isExpressionIdUnique,
    isValidIdentifier,
    hasValidChildren
    // isExpressionInScope
} from "./ExpressionValidators";
import Expression from "./Expression";

export default class ForeachExpression extends Expression {
    constructor(id) {
        const properties = {
            expressionType: {
                value: "foreach",
                isValid: null,
                validators: []
            },
            item: {
                value: null,
                isValid: null,
                dirty: false,
                errors: [],
                validators: [
                    isNotEmpty("Item"),
                    isValidIdentifier(),
                    isExpressionIdUnique()
                ]
            },
            items: {
                value: null,
                isValid: null,
                dirty: false,
                errors: [],
                validators: [
                    isNotEmpty("List name"),
                    isValidIdentifier(),
                    isExpressionIdPresent()
                    // isExpressionInScope()
                ]
            },
            block: {
                value: undefined,
                isValid: null,
                validators: [
                    hasValidChildren()
                ]
            }
        };
        super(id, properties);
    }
    // function which triggers a "reload" of the state
    // this is necessary to allow instant validation feedback
    // for the isExpressionInScope validator.
    hasMoved() {
        // eslint-disable-next-line no-self-assign
        this.items = this.items;
    }
    updateReturns(default_) {
        this.returns = {
            type: "array",
            default: default_,
            items: {
                type: this.returns.type,
                items: this.returns.properties
            }
        };
    }
    static formatReturns(prop) {
        if (prop.properties) {
            return {
                id: prop.id,
                type: "object",
                properties: prop.properties.map(prop => {
                    return ForeachExpression.formatReturns(prop);
                })
            };
        } else if (prop.items) {
            return {
                id: prop.id,
                type: "array",
                items: prop.items
            };
        } else {
            return { id: prop.id, type: prop.type };
        }
    }
    static cleanExpression(exp) {
        const items = exp.properties.block.value.map(v => {
            if (!v.returns.id) {
                v.returns.id = v.id;
            }
            return ForeachExpression.formatReturns(v.returns);
        });
        const returns = {
            type: "array",
            items: {
                type: "object",
                properties: items
            }
        };
        return {
            id: exp.id,
            returns,
            expressionType: exp.expressionType,
            items: exp.items,
            item: exp.item,
            description: exp.description,
            tags: exp.tags,
            block: exp.block.map((item) => item.clean())
        };
    }
}
