import axios from "../../services/axios";
import paymentServices from "../../services/payments";

export const types = {
    "SET_QUOTES": "SET_QUOTES",
    "SET_ERROR": "SET_ERROR",
    "SET_SETTINGS": "SET_SETTINGS",
    "SET_FILTERED_PRODUCTS": "SET_FILTERED_PRODUCTS",
    "SET_FILTERED_HEADINGS": "SET_FILTERED_HEADINGS",
    "SET_VERISK_DISABLED": "SET_VERISK_DISABLED"
};

function filterProducts(quotes, request) {
    const standardProducts = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
        "13", "14", "15", "15", "19"];
    if(request.wintersports) {
        standardProducts.push("17");
    }
    if(request.golf) {
        standardProducts.push("18");
    }
    if(request.wedding) {
        standardProducts.push("20");
    }
    if(request.cruisecover) {
        standardProducts.push("21");
    }
    if(request.disruption) {
        standardProducts.push("12");
    } 
    if(request.gadget_level==="A") {
        standardProducts.push("8.1");
    }
    if (request.gadget_level==="B") {
        standardProducts.push("8.2");
    }
    if (request.gadget_level==="C") {
        standardProducts.push("8.3");
    }

    const filter = quotes.map(quote => {
        const products = quote.products;
        const filteredProducts = products.filter(p => {
            if (standardProducts.includes(p[0])) {
                return p;
            }
        });
        return filteredProducts;
    });
    
    const filteredProducts = [];
    filter.forEach(quote => {
        let sortedFilteredProducts = quote.sort((a,b)=>{
            if(a[8]<0){
                return 1;
            }
            if(b[8]<0){
                return -1;
            }
            return a[8]-b[8];
        });
        filteredProducts.push(sortedFilteredProducts);
    });
    return filteredProducts;
}

const state = {
    quotes:[],
    error: false,
    settings: [],
    filteredProducts: [],
    veriskDisabled: false,
    filteredHeadings: []
};

const getters = {
    totalTripTypesLength: state => state.quotes.length,
    quotesReturned: state => state.quotes.filter(quote => {
        if (quote.quotationResponse.quotationResult.resp.quotes.length > 0) {
            return quote.quotationResponse.quotationResult.resp.quotes;
        }
    }),
    settingsByKey: state => (key) => {
        const settings = state.settings.find(setting => setting.key === key);
        return settings?.value || [];
    }
};

const actions = {
    async getQuotes({ commit, rootState }, payload) {
        try {
            commit(types.SET_ERROR, false);
            commit(types.SET_QUOTES, []);
            commit(types.SET_FILTERED_HEADINGS, []);
            commit(types.SET_FILTERED_PRODUCTS, []);
            const upsell = [];
            const expiryDays = 14;
            const pathname = `/payments/${payload.provider}/${payload.brand}/${payload.agent}/`;
            // if it's an existing policy we just want to adjust that policy that it was bought on
            if (rootState.crm.callType === "ep" && expiryDays > 0) {
                // const schemeId = rootState.crm.policy.scheme_revision_id;
                const policyId = rootState.crm.policy.policy_revision_id;
                const result = await axios.post(
                    pathname + policyId, payload
                );
                upsell.push(result.data);
                commit(types.SET_QUOTES, upsell);
                // if it's a new quote on the CRM and single TRIP we retrieve an AMT quote back as well
            } else if(payload.url === "crm" && rootState.crm.callType !== "ep" && payload.trip_type === "ST") {
                const single = await axios.post(pathname, payload);
                const multi = await axios.post(pathname + "?amt=true", payload);
                upsell.push(single.data, multi.data);
                commit(types.SET_QUOTES, upsell);
                // if it's another triptype as noraml
            } else if(payload.url === "crm" && payload.trip_type !== "ST" ) {
                const single = await axios.post(pathname, payload);
                upsell.push(single.data);
                commit(types.SET_QUOTES, upsell);
            }
            const request = upsell[0].quotationResponse.quotationResult.resp.request;
            const quotes = upsell[0].quotationResponse.quotationResult.resp.quotes;
            const filteredProducts = filterProducts(quotes, request);   
            const allHeadings = filteredProducts[0].map(product => {
                return product[6];
            });
            commit(types.SET_FILTERED_HEADINGS, ["Show All", ...new Set(allHeadings)]);
            commit(types.SET_FILTERED_PRODUCTS, filteredProducts);
        } catch (error) {
            if(error.response) {
                commit(types.SET_ERROR, true);
            }
            commit(types.SET_ERROR, true);
        }
    },
    async getEndpointSettings({commit, rootState}, payload) {
        commit(types.SET_ERROR, false);
        try {
            const endpoint = rootState.crm.endpoint?.name_url ?? "d2c";
            const endpointSettings = await paymentServices.fetchEndpointSettings({ ...payload, endpoint });
            commit(types.SET_SETTINGS, endpointSettings.data);
        } catch (error) {
            commit(types.SET_ERROR, true);
        }
    },
    disableVerisk({commit}, payload) {
        commit(types.SET_VERISK_DISABLED, payload);
    },
    filterProducts({commit, rootState}, payload) { 
        const quotes = rootState.buyjourney.quotes[0].quotationResponse.quotationResult.resp.quotes;
        const request = rootState.buyjourney.quotes[0].quotationResponse.quotationResult.resp.request;
        const filteredProducts = filterProducts(quotes, request);
        const result = filteredProducts.map(product => {
            return product.filter(p => {
                if (payload === "Show All") {
                    return true;
                }
                if (p[6] === payload) {
                    return true;
                }
            });
        });
        commit(types.SET_FILTERED_PRODUCTS, result);
    }
};

const mutations = {
    [types.SET_QUOTES]: (state, quotes) => (state.quotes = quotes),
    [types.SET_ERROR]: (state, error) => (state.error = error),
    [types.SET_SETTINGS]: (state, settings) => (state.settings = settings),
    [types.SET_FILTERED_PRODUCTS]: (state, filteredProducts) => (state.filteredProducts = filteredProducts),
    [types.SET_FILTERED_HEADINGS]: (state, headings) => (state.filteredHeadings = headings),
    [types.SET_VERISK_DISABLED]: (state, veriskDisabled) => (state.veriskDisabled = veriskDisabled)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
