import axios from "./axios";

export default {
    getMetrics(brandURL) {
        return axios.get(`/brands/${brandURL}/metrics`);
    },
    getQuotesMetric(brandURL, endpointID, unit, from, to, requester) {
        return axios.get(`brands/${brandURL}/metrics/quote_summary`, {
            params: {
                endpointID,
                unit,
                from,
                to,
                requester
            }
        });
    },
    getJobsMetric(brandURL, endpointID, unit, from, to, requester) {
        return axios.get(`brands/${brandURL}/metrics/job_summary`, {
            params: {
                endpointID,
                unit,
                from,
                to,
                requester
            }
        });
    },
    getErrorsMetric(brandURL, endpointID, unit, from, to, requester) {
        return axios.get(`brands/${brandURL}/metrics/errors_summary`, {
            params: {
                endpointID,
                unit,
                from,
                to,
                requester
            }
        });
    },
    getConversionsMetric(brandURL, endpointID, unit, from, to, requester) {
        return axios.get(`brands/${brandURL}/metrics/conversion_summary`, {
            params: {
                endpointID,
                unit,
                from,
                to,
                requester
            }
        });
    },
    getEndpoints(brandURL) {
        return axios.get(`brands/${brandURL}/endpoints`);
    },
    getRejectsMetric(brandURL, endpointID, unit, from, to, requester) {
        return axios.get(`brands/${brandURL}/metrics/rejects_summary`, {
            params: {
                endpointID,
                unit,
                from,
                to,
                requester
            }
        });
    },
    getCancellationsSummary(brandURL, endpointID, unit, from, to, requester) {
        return axios.get(`brands/${brandURL}/metrics/cancellations_summary`, {
            params: {
                endpointID,
                unit,
                from,
                to,
                requester
            }
        });
    },
    getIncomeSummary(brandURL, endpointID, unit, from, to, requester) {
        return axios.get(`brands/${brandURL}/metrics/income_summary`, {
            params: {
                endpointID,
                unit,
                from,
                to,
                requester
            }
        });
    }
};
