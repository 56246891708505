const {
    NumberLiteral,
    ExpressionStatement,
    Identifier,
    PrefixExpression,
    InfixExpression,
    CallExpression,
    IFExpression,
    AndExpression,
    OrExpression,
    NotExpression,
    BooleanLiteral,
    StringLiteral,
    DateLiteral
} = require("./AST");
const TokenType = require("./TokenType");

/**
 * Parser
 * @class
 * @classdesc Creates a Parser
 */
class Parser {
    /**
   * Represents a Parser
   * @constructor
   * @param {Lexer} lexer - The lexer used by the parser
   */
    constructor(lexer) {
        this.lexer = lexer;
        this.currentToken = undefined;
        this.peekToken = undefined;
        this.errors = [];
        this.dev = false; // set this to true to get more detailed debugging information

        this.prefixParseFns = new Map();
        this.infixParseFns = new Map();

        this.registerPrefix(TokenType.NUMBER, this.parseNumber);
        this.registerPrefix(TokenType.TRUE, this.parseBoolean);
        this.registerPrefix(TokenType.FALSE, this.parseBoolean);
        this.registerPrefix(TokenType.STRING, this.parseString);
        this.registerPrefix(TokenType.DATE, this.parseDate);
        this.registerPrefix(TokenType.IDENT, this.parseIndentifier);
        this.registerPrefix(TokenType.IF, this.parseIfExpression);
        this.registerPrefix(TokenType.AND, this.parseAndExpression);
        this.registerPrefix(TokenType.OR, this.parseOrExpression);
        this.registerPrefix(TokenType.NOT, this.parseNotExpression);
        this.registerPrefix(TokenType.MINUS, this.parsePrefixExpression);
        this.registerPrefix(TokenType.LPAREN, this.parseGroupedExpression);

        this.registerInfix(TokenType.ADD, this.parseInfixExpression);
        this.registerInfix(TokenType.MINUS, this.parseInfixExpression);
        this.registerInfix(TokenType.DIVIDE, this.parseInfixExpression);
        this.registerInfix(TokenType.MULTIPLY, this.parseInfixExpression);
        this.registerInfix(TokenType.EQ, this.parseInfixExpression);
        this.registerInfix(TokenType.NEQ, this.parseInfixExpression);
        this.registerInfix(TokenType.LT, this.parseInfixExpression);
        this.registerInfix(TokenType.GT, this.parseInfixExpression);
        this.registerInfix(TokenType.GTE, this.parseInfixExpression);
        this.registerInfix(TokenType.LTE, this.parseInfixExpression);
        this.registerInfix(TokenType.LPAREN, this.parseCallExpression);

        this.precedences = new Map([
            [TokenType.EQ, Parser.EQUALS],
            [TokenType.NEQ, Parser.EQUALS],
            [TokenType.LT, Parser.LESSGREATER],
            [TokenType.LTE, Parser.LESSGREATER],
            [TokenType.GT, Parser.LESSGREATER],
            [TokenType.GTE, Parser.LESSGREATER],
            [TokenType.ADD, Parser.SUM],
            [TokenType.MINUS, Parser.SUM],
            [TokenType.DIVIDE, Parser.PRODUCT],
            [TokenType.MULTIPLY, Parser.PRODUCT],
            [TokenType.LPAREN, Parser.CALL]
        ]);

        // read two so that both current and peek are set
        this.nextToken();
        this.nextToken();
    }

    /**
   * Reads the next next token
   */
    nextToken() {
        this.currentToken = this.peekToken;
        this.peekToken = this.lexer.nextToken();
    }

    currentTokenIs(type) {
        return this.currentToken.type === type;
    }

    peekTokenIs(type) {
        return this.peekToken.type === type;
    }

    expectPeek(type) {
        if (this.peekTokenIs(type)) {
            this.nextToken();
            return true;
        }
        this.peekError(type);
        return false;
    }

    peekError(type) {
        this.errors.push(
            this.dev
                ? `peekError: expected next token to be ${type}. got ${this.peekToken.type}`
                : `Formula: Unexpected character at position ${this.peekToken.pos}.`
        );
    }

    registerPrefix(tokenType, prefixParseFn) {
        this.prefixParseFns.set(tokenType, prefixParseFn);
    }

    registerInfix(tokenType, infixParseFn) {
        this.infixParseFns.set(tokenType, infixParseFn);
    }

    noPrefixParseFnError(tokenType) {
        this.errors.push(
            this.dev
                ? `noPrefixParseError: no prefix parse function for ${tokenType} found.`
                : `Formula: Unexpected character at position ${this.peekToken.pos}`
        );
    }

    peekPrecedence() {
        let precedence = this.precedences.get(this.peekToken.type);
        if (precedence !== undefined) {
            return precedence;
        }
        return Parser.LOWEST;
    }

    curPrecedence() {
        let precedence = this.precedences.get(this.currentToken.type);
        if (precedence !== undefined) {
            return precedence;
        }
        return Parser.LOWEST;
    }

    parse() {
        if (!this.currentTokenIs(TokenType.EQ)) {
            this.errors.push(
                this.dev
                    ? `unexpected token: Formula must start with an = got ${this.currentToken.literal}`
                    : "Formula: All formula must start with = ."
            );
        }

        this.nextToken();

        let statement = new ExpressionStatement(this.currentToken);

        statement.expression = this.parseExpression(Parser.LOWEST);

        this.nextToken();
        if (!this.currentTokenIs(TokenType.EOF)) {
            this.errors.push(
                this.dev
                    ? `unexpected token: Expected EOF. got ${this.currentToken.type} at pos ${this.currentToken.pos}`
                    : `Formula: Unexpected end at position ${this.currentToken.pos} are you missing a bracket?`
            );
        }
        return statement;
    }

    parseExpression(precedence) {
        let prefix = this.prefixParseFns.get(this.currentToken.type);
        if (prefix === undefined) {
            this.noPrefixParseFnError(this.currentToken.type);
            return null;
        }
        let leftExp = prefix.call(this);

        while (precedence < this.peekPrecedence()) {
            let infix = this.infixParseFns.get(this.peekToken.type);
            if (infix === undefined) {
                return leftExp; // break out of loop
            }
            this.nextToken();
            leftExp = infix.call(this, leftExp);
        }

        return leftExp;
    }

    parseIfExpression() {
        let expression = new IFExpression(this.currentToken);
        this.expectPeek(TokenType.LPAREN);
        this.nextToken();

        expression.condition = this.parseExpression(Parser.LOWEST);

        this.expectPeek(TokenType.COMMA);
        this.nextToken();

        expression.trueExp = this.parseExpression(Parser.LOWEST);

        this.expectPeek(TokenType.COMMA);
        this.nextToken();

        expression.falseExp = this.parseExpression(Parser.LOWEST);

        if (!this.expectPeek(TokenType.RPAREN)) {
            return null;
        }

        return expression;
    }
    // TODO - This definately can be refactored - EXCEL has
    // lots of formula that evaluate an expression.

    parseAndExpression() {
        let expression = new AndExpression(this.currentToken);
        this.expectPeek(TokenType.LPAREN);
        this.nextToken();

        expression.param1 = this.parseExpression(Parser.LOWEST);

        this.expectPeek(TokenType.COMMA);
        this.nextToken();

        expression.param2 = this.parseExpression(Parser.LOWEST);

        if (!this.expectPeek(TokenType.RPAREN)) {
            return null;
        }

        return expression;
    }

    parseOrExpression() {
        let expression = new OrExpression(this.currentToken);
        this.expectPeek(TokenType.LPAREN);
        this.nextToken();

        expression.param1 = this.parseExpression(Parser.LOWEST);

        this.expectPeek(TokenType.COMMA);
        this.nextToken();

        expression.param2 = this.parseExpression(Parser.LOWEST);

        if (!this.expectPeek(TokenType.RPAREN)) {
            return null;
        }

        return expression;
    }

    parseNotExpression() {
        let expression = new NotExpression(this.currentToken);
        this.expectPeek(TokenType.LPAREN);
        this.nextToken();

        expression.param1 = this.parseExpression(Parser.LOWEST);

        if (!this.expectPeek(TokenType.RPAREN)) {
            return null;
        }

        return expression;
    }

    parseIndentifier() {
        return new Identifier(this.currentToken, this.currentToken.literal);
    }

    parseBoolean() {
        return new BooleanLiteral(this.currentToken, this.currentToken.type === TokenType.TRUE);
    }

    parseString() {
        return new StringLiteral(this.currentToken, this.currentToken.literal);
    }

    parseDate() {
        // TODO: add validation of date
        return new DateLiteral(this.currentToken, this.currentToken.literal);
    }

    parseNumber() {
        let value = Number.parseFloat(this.currentToken.literal);
        if (isNaN(value)) {
            this.errors.push(
                this.dev
                    ? `parseNumberLiteral: Cannot convert ${this.currentToken.literal} to a number`
                    // eslint-disable-next-line max-len
                    : `Formula: Cannot convert ${this.currentToken.literal} to a number at position ${this.currentToken.pos} `
            );
        }
        return new NumberLiteral(this.currentToken, value);
    }

    parsePrefixExpression() {
        let expression = new PrefixExpression(
            this.currentToken,
            this.currentToken.literal
        );
        this.nextToken();
        expression.right = this.parseExpression(Parser.PREFIX);
        return expression;
    }

    parseInfixExpression(left) {
        let expression = new InfixExpression(this.currentToken);
        expression.left = left;
        expression.operator = this.currentToken.literal;
        let precedence = this.curPrecedence();
        this.nextToken();
        expression.right = this.parseExpression(precedence);
        return expression;
    }

    parseGroupedExpression() {
        this.nextToken();
        let exp = this.parseExpression(Parser.LOWEST);
        if (!this.expectPeek(TokenType.RPAREN)) {
            return null;
        }
        return exp;
    }

    parseCallExpression(func) {
        let exp = new CallExpression(this.currentToken, func);
        exp.args = this.parseCallArguments();
        return exp;
    }

    parseCallArguments() {
        let args = [];
        if (this.peekTokenIs(TokenType.RPAREN)) {
            this.nextToken();
            return args;
        }
        this.nextToken();
        args.push(this.parseExpression(Parser.LOWEST));
        while (this.peekTokenIs(TokenType.COMMA)) {
            this.nextToken();
            this.nextToken();
            args.push(this.parseExpression(Parser.LOWEST));
        }
        if (!this.expectPeek(TokenType.RPAREN)) {
            return null;
        }
        return args;
    }
}

Parser.LOWEST = 0;
Parser.EQUALS = 1;
Parser.LESSGREATER = 2;
Parser.SUM = 3;
Parser.PRODUCT = 4;
Parser.PREFIX = 5;
Parser.CALL = 6;

module.exports = Parser;
