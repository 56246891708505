import Expression from "./Expression";

export default class BlockExpression extends Expression {
    constructor(id){
        const properties = {
            expressionType:{
                value: "block",
                isValid:null,
                validators:[]
            },
            block:{
                value: undefined,
                isValid:null,
                validators:[]
            }
        };
        super(id,properties);

    }
    static cleanExpression(exp) {
        return {
            id:exp.id,
            returns:exp.returns,
            expressionType:exp.expressionType,
            description: exp.description,
            tags: exp.tags,
            block:exp.block.map((item) => item.clean())
        };
    }
}
