<template>
    <div
        class="sidebar-dropdown-item"
        :class="{ disabled }"
        v-on="$listeners"
    >
        <b-avatar
            :variant="avatarVariant"
            :size="avatarSize"
            class="sidebar-dropdown-item_avatar"
        >
            <font-awesome-icon
                v-if="avatarIcon"
                :icon="avatarIcon"
            />
            <span v-else-if="avatarText">
                {{ avatarText }}
            </span>
        </b-avatar>
        <div
            v-if="!isCollapsed"
            class="sidebar-dropdown-item_wrapper"
        >
            <div class="sidebar-dropdown-item_title">
                {{ title }}
            </div>
            <div
                v-if="subtitle"
                class="sidebar-dropdown-item_subtitle"
            >
                {{ subtitle }}
            </div>
        </div>
        <span
            v-if="icon.length && !isCollapsed"
            class="sidebar-dropdown-item_icon"
        >
            <font-awesome-icon
                :icon="icon"
            />
        </span>
    </div>
</template>

<script>
export default {
    name: "SidebarDropdownItem",
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            default: ""
        },
        avatarVariant: {
            type: String,
            default: "light"
        },
        avatarText: {
            type: String,
            default: ""
        },
        avatarSize: {
            type: String,
            default: "25px"
        },
        avatarIcon: {
            type: [String, Array],
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        icon: {
            type: [String, Array],
            default: ""
        },
        isCollapsed: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="sass" scoped>
.sidebar-dropdown-item
    display: grid
    grid-template-columns: min-content auto 1fr
    gap: 12px
    align-items: center
    width: 100%
    &.disabled
        opacity: 0.5
        pointer-events: none
        cursor: initial
    &_wrapper
        color: inherit
        font-size: 0.8rem
        text-align: left
        overflow: hidden
    &_title, &_subtitle
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
    &_subtitle
        opacity: 0.6
    &_avatar
        font-size: 0.8rem
        span
            line-height: 25px
    &_icon
        font-size: 1rem
        margin-left: auto
        padding-right: 0.25rem
</style>
