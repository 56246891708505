<template>
    <div>
        <b-input-group class="m-0">
            <b-form-input
                v-model="tag"
                class="form-control"
                v-bind="$attrs"
                @keypress.prevent.stop.enter="handleAddTag"
            />
            <template #append>
                <b-button @click.prevent.stop="handleAddTag">
                    Add
                </b-button>
            </template>
        </b-input-group>
        <div class="base-input__tags">
            <base-tag
                v-for="(t,i) in tags"
                :key="i"
                :tag="t"
                @delete="handleDeleteTag(i)"
            />
        </div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props:{
        value:{
            type:Array,
            default: () => []
        }
    },
    data(){
        return {
            tags:this.value || [],
            tag:""
        };
    },
    watch:{
        tags(newTags){
            this.$emit("input",newTags);
        }
    },
    methods:{
        handleAddTag(){
            if(this.tag && !this.tags.includes(this.tag)){
                this.tags.push(this.tag);
            }
            this.tag="";
        },
        handleDeleteTag(index){
            this.tags.splice(index,1);
        }
    }
};
</script>

<style scoped lang="sass">
.base-input__tags
  display: flex
  flex-wrap: wrap
  margin: 5px 0px
  &:empty 
    display: none
input
  min-height: 40px
</style>

