import axios from "../../services/axios";
import policiesServices from "../../services/policies";
import customerServices from "../../services/customers";
import crmServices from "../../services/crm";
import moment from "moment";
import store from "../../store";

export const types = {
    "SET_CUSTOMERS": "SET_CUSTOMERS",
    "SET_CUSTOMER": "SET_CUSTOMER",
    "SET_POLICIES": "SET_POLICIES",
    "SET_POLICY": "SET_POLICY",
    "SET_ERROR": "SET_ERROR",
    "SET_QUOTE": "SET_QUOTE",
    "SET_QUOTES": "SET_QUOTES",
    "SET_POLICY_NOTES": "SET_POLICY_NOTES",
    "SET_QUOTE_NOTES": "SET_QUOTE_NOTES",
    "SET_POLICIES_COUNT": "SET_POLICIES_COUNT"
};

const state = {
    customers:[],
    customer: "",
    error: false,
    policies: [],
    policy: {},
    quote: {},
    quotes: [],
    policyNotes: [],
    quoteNotes: [],
    policiesCount: 0
};

const getters = {

};

const actions = {
    async getPrintSend({ commit, rootState }) {
        commit(types.SET_ERROR, false);
        const brandURL = rootState.auth.selectedBrand.name_url;
        return await policiesServices.getPolicyPrintSendDetails(brandURL).then((response) => {
            commit(types.SET_POLICIES, response.data);
            return response.data;
        }).catch((err) => {
            commit(types.SET_ERROR, true);
            throw err;
        });
    },
    async getCustomers({ commit }, payload) {
        try {
            commit(types.SET_ERROR, false);
            let result = await axios.get(
                `/brands/${payload.brand}/customers?search=${payload.value}`
            );
            commit(types.SET_CUSTOMERS, result.data);
        } catch (error) {
            commit(types.SET_CUSTOMERS, []);
            commit(types.SET_ERROR, error.message);
            throw error;
        }
    },
    async updateCustomer({commit}, payload) {
        try {
            commit(types.SET_ERROR, false);
            await axios.patch(
                `/brands/${payload.brand}/customers/${payload.customerID}/`
                , payload.form);
        } catch (error) {
            commit(types.SET_ERROR, true);
            throw error;
        }
    },
    async getCustomerPolicies({ commit }, payload) {
        const params = {
            offset: payload.offset || 0,
            limit: payload.limit || 8
        };
        try {
            commit(types.SET_ERROR, false);
            const result = await axios.get(`/brands/${payload.brand}/customers/${payload.customerID}/`, { params });
            commit(types.SET_CUSTOMER, result.data.customer);
            commit(types.SET_POLICIES, result.data.policies);
            commit(types.SET_POLICIES_COUNT, result.data.count);
        } catch (e) {
            commit(types.SET_ERROR, true);
        }
    },
    async getCustomerPolicy({commit}, payload) {
        try {
            commit(types.SET_ERROR, false);
            let result = await policiesServices.getPolicyCustomer(payload);
            store.dispatch("crm/setExistingPolicyNumber", result.data.policy_reference);
            commit(types.SET_POLICY, result.data );
        } catch (error) {
            commit(types.SET_ERROR, true);
        }
    },
    async searchForQuote({ commit }, payload) {
        try {
            commit(types.SET_ERROR, false);
            commit(types.SET_QUOTE, {});
            // commit(types.SET_QUOTES, []);
            let result = await customerServices.searchForAQuote(payload);
            if(Array.isArray(result.data)) {
                commit(types.SET_QUOTES, result.data);
            } else {
                commit(types.SET_QUOTE, result.data);
            }
        } catch (error) {
            commit(types.SET_QUOTE, []);
            commit(types.SET_ERROR, error.message);
            throw error;
        }
    },
    async addAPolicyNote({commit, rootState}, payload) {
        try {
            await customerServices.sendPolicyNote(
                rootState.auth.selectedBrand.name_url,
                payload
            );
        } catch (err) {
            commit(types.SET_ERROR, "Oops, there was a problem adding this note. Please try again");
            throw new Error(err);
        }
    },
    async retrievePolicyNotes({ commit, rootState }, payload) {
        try {
            commit(types.SET_ERROR, false);
            const policyNotesRequest = await customerServices.getPolicyNotes(
                rootState.auth.selectedBrand.name_url,
                payload
            );
            const policyNotes = policyNotesRequest.data;
            commit(types.SET_POLICY_NOTES, policyNotes);
        } catch (err) {
            commit(types.SET_ERROR, "Oops, there was a problem retrieving notes for this policy. Please try again");
            throw new Error(err);
        }
    },
    async retrieveQuoteNotes({ commit, rootState }, payload) {
        try {
            commit(types.SET_ERROR, false);
            const quoteNotesRequest = await customerServices.getQuoteNotes(
                rootState.auth.selectedBrand.name_url,
                payload
            );
            const quoteNotes = quoteNotesRequest.data;
            quoteNotes.forEach(quote => {
                quote["created_at"] = moment(quote.created_at).format("DD/MM/YYYY");
                quote["modified_at"] = moment(quote.modified_at).format("DD/MM/YYYY HH:mm");
            });
            commit(types.SET_QUOTE_NOTES, quoteNotes);
        } catch (err) {
            commit(types.SET_ERROR, "Oops, there was a problem retrieving notes for this quote. Please try again");
            throw new Error(err);
        }
    },
    async setNoteImportantTag({ commit, rootState }, payload) {
        try {
            await customerServices.setNoteImportantTag(
                rootState.auth.selectedBrand.name_url,
                payload
            );
        } catch(err) {
            commit(types.SET_ERROR, "Error occurred when setting important tag");
            throw err;
        }
    },
    // eslint-disable-next-line camelcase
    async setMarketingPreferences({ commit, rootState }, { config, email, policy_reference, preferences }) {
        try {
            commit("SET_ERROR", null);
            await crmServices.saveMarketingPreferences(
                // eslint-disable-next-line max-len
                `${config.apiUrl}/brands/${rootState.auth.selectedBrand.name_url}/customers/marketing-preferences`,
                {
                    // eslint-disable-next-line camelcase
                    policy_reference,
                    email,
                    preferences
                }
            );
        } catch (error) {
            commit(types.SET_ERROR, error);
            throw(error);
        }
    }        
};

const mutations = {
    [types.SET_CUSTOMERS]: (state, customers) => (state.customers = customers),
    [types.SET_POLICIES]: (state, policies) => (state.policies = policies),
    [types.SET_POLICY]: (state, policy) => (state.policy = policy),
    [types.SET_QUOTE]: (state, quote) => (state.quote = quote),
    [types.SET_CUSTOMER]: (state, name) => (state.customer = name),
    [types.SET_ERROR]: (state, error) => (state.error = error),
    [types.SET_QUOTES]: (state, quotes) => (state.quotes = quotes),
    [types.SET_POLICY_NOTES]: (state, policyNotes) => (state.policyNotes = policyNotes),
    [types.SET_QUOTE_NOTES]: (state, quoteNotes) => (state.quoteNotes = quoteNotes),
    [types.SET_POLICIES_COUNT]: (state, policiesCount) => (state.policiesCount = policiesCount)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
