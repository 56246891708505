<template>
    <div>
        <fieldset>
            <div v-if="showResetSuccess">
                <p>
                    Your password has been successfully reset.
                </p>
                <button
                    class="btn-login"
                    @click="goToLogin"
                >
                    Login
                </button>
            </div>
            <div v-if="showResetForm">
                <legend>
                    Please enter your new password.
                </legend>
                <div class="warning">
                    Your password must contain at least 12 characters, one uppercase character, a number
                    and one special character.
                </div>
                <div class="form-group">
                    <label
                        for="inputPassword"
                        class="sr-only"
                    >
                        Password
                    </label>
                    <input
                    
                        id="inputPassword"
                        v-model="password"
                        v-validate="{ required: true, 
                                      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{12,100}$/ }"
                        class="form-control"
                        data-lpignore="true"
                        autocomplete="new-password"
                        name="inputPassword"
                        data-vv-as="password"
                        type="password"
                        placeholder="Password"
                        autofocus
                    >
                    <font-awesome-icon
                        :icon="['fal', 'lock-alt']"
                    />
                </div>
                <div class="form-group">
                    <label
                        for="inputConfirmPassword"
                        class="sr-only"
                    >
                        Confirm Password
                    </label>
                    <input
                        id="inputConfirmPassword"
                        v-model="confirmPassword"
                        v-validate="{ required: true, 
                                      confirmPassword: true, 
                                      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{12,100}$/ }"
                        
                        class="form-control"
                        name="inputConfirmPassword"
                        autocomplete="new-password"
                        data-vv-as="confirm password"
                        type="password"
                        placeholder="Confirm Password"
                    >
                    <font-awesome-icon
                        :icon="['fal', 'lock-alt']"
                    />
                </div>
                <button
                    type="button"
                    class="btn-login"
                    @click="handleSubmit"
                >
                    Reset Password
                </button>
                <div
                    v-if="veeErrors.items.length > 0"
                    class="error"
                >
                    <p
                        v-for="item in veeErrors.items"
                        :key="item.key"
                    >
                        {{ item.msg }}
                    </p>
                </div>
            </div>
            <div
                v-if="error"
                class="error"
            >
                {{ error }}
            </div>
        </fieldset>
    </div>
</template>

<script>

export default {
    name: "ResetPassword",
    props: {
        jwt: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            password: null,
            confirmPassword: null,
            loading: false,
            showResetForm: false,
            showResetSuccess: false,
            error: null
        };
    },
    created() {
        this.$store.dispatch("auth/validateToken",
            {
                jwt: this.jwt
            }
        ).then(() => {
            this.showResetForm = true;
        }).catch(err => {
            this.error = err.message;
        });

        // Creating a validator rule to require the confirm password
        // field to match the password field
        this.$validator.extend("confirmPassword", {
            getMessage: (field) => {
                return `The ${field} field does not match the password field.`;
            },
            validate: (value) => {
                return value === this.password;
            }
        });
    },
    methods: {
        handleSubmit() {
            this.$validator.validate().then((valid) => {
                if (valid) {
                    this.$store.dispatch(
                        "auth/resetPassword",
                        {
                            password: this.password,
                            confirmPassword: this.confirmPassword,
                            jwt: this.jwt
                        }
                    ).then(() => {
                        this.showResetSuccess = true;
                        this.showResetForm = false;
                    }).catch(err => {
                        this.error = err.message;
                    });
                }
            });
        },
        goToLogin() {
            this.$router.push({name: "login"});
        }
    }
};

</script>
