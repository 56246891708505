/* eslint-disable camelcase */
import renewalService from "../../services/renewals";

export const types = {
    "SET_RENEWAL_RULES": "SET_RENEWAL_RULES",
    "SET_RENEWALS": "SET_RENEWALS",
    "SET_ERROR": "SET_ERROR",
    "SET_LOADING": "SET_LOADING",
    "SET_TEMPLATE_REVISIONS": "SET_TEMPLATE_REVISIONS",
    "SET_TEMPLATES": "SET_TEMPLATES",
    "SET_ERROR_TEMPLATE_REVISIONS": "SET_ERROR_TEMPLATE_REVISIONS",
    "SET_RENEWAL_TEST_RESULTS": "SET_RENEWAL_TEST_RESULTS",
    "SET_RENEWAL": "SET_RENEWAL"
};


const state = {
    renewalRuleOptions: [],
    renewals: [],
    renewal: {},
    renewalRules: [],
    renewalRule: {},
    error: false,
    loading: false,
    templateRevisions: [],
    templates: [],
    errorTemplates: [],
    renewalTestResults: []
};

const actions = {
    async getRenewals({ commit }, { brand }) {
        try {
            commit(types.SET_LOADING, true);
            commit(types.SET_ERROR, false);
            const result = await renewalService.getRenewals(brand);
            commit(types.SET_RENEWALS, result.data);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async getRenewal({ commit }, payload) {
        try {
            commit(types.SET_LOADING, true);
            commit(types.SET_ERROR, false);
            const result = await renewalService.getRenewal(payload);
            commit(types.SET_RENEWAL, result.data);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async createRenewal({ commit }, payload) {
        try {
            commit(types.SET_LOADING, true);
            commit(types.SET_ERROR, false);
            const result = await renewalService.createRenewal(payload);
            commit(types.SET_LOADING, false);
            return result.data;
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async updateRenewal({ commit }, payload) {
        try {
            commit(types.SET_LOADING, true);
            commit(types.SET_ERROR, false);
            await renewalService.patchRenewal(payload);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async deleteRenewal({ commit }, payload) {
        try {
            commit(types.SET_LOADING, true);
            commit(types.SET_ERROR, false);
            await renewalService.deleteRenewal(payload);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async setRenewalRules({ commit }, payload) {
        try {
            commit(types.SET_LOADING, true);
            commit(types.SET_ERROR, false);
            commit(types.SET_RENEWAL_RULES, []);
            const result = await renewalService.getRenewalRules(payload);
            commit(types.SET_RENEWAL_RULES, result.data);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async saveRenewalRule({ commit }, payload) {
        try {
            commit(types.SET_LOADING, true);
            commit(types.SET_ERROR, false);
            await renewalService.createRenewalRule(payload);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async updateRenewalRules({ commit }, payload) {
        try {
            commit(types.SET_LOADING, true);
            commit(types.SET_ERROR, false);
            payload.data.forEach(d => {
                d.template_id = d.template_id === "" ? null : d.template_id;
                d.error_template_id = d.error_template_id === "" ? null : d.error_template_id;
            });
            await renewalService.updateRenewalRules(payload);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async deleteRenewalRule({ commit }, payload) {
        try {
            commit(types.SET_LOADING, true);
            commit(types.SET_ERROR, false);
            await renewalService.deleteRenewalRule(payload);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
            throw error.message;
        }
    },
    async fetchTemplateRevisions({ commit, rootState }, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, false);
        try {
            const revisions = await renewalService.fetchRevisions(rootState.auth.selectedBrand.name_url, 
                payload.templateID);
            if(payload.type === "success") {
                commit(types.SET_TEMPLATE_REVISIONS, revisions.data.revisions);
            } else {
                commit(types.SET_ERROR_TEMPLATE_REVISIONS, revisions.data.revisions);
            }
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_ERROR, error.message);
        }
    },
    async fetchTemplates({ commit, rootState }) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, false);
        try {
            const templates = await renewalService.fetchTemplates(rootState.auth.selectedBrand.name_url);
            commit(types.SET_TEMPLATES, templates.data);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_ERROR, error.message);
        }
    },
    async testRenewals( {commit, rootState}, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, false);
        commit(types.SET_RENEWAL_TEST_RESULTS, []);
        try {
            payload.brandID = rootState.auth.selectedBrand.name_url;
            const result = await renewalService.testRenewal(payload);
            commit(types.SET_RENEWAL_TEST_RESULTS, result.data);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_ERROR, error.message);
            commit(types.SET_LOADING, false);
            throw error.message;
        }
    }
};

// mutations
const mutations = {
    [types.SET_RENEWAL_RULES]: (state, rules) => (state.renewalRules = rules),
    [types.SET_RENEWALS]: (state, renewals) => (state.renewals = renewals),
    [types.SET_ERROR]: (state, error) => (state.error = error),
    [types.SET_LOADING]: (state, loading) => (state.loading = loading),
    [types.SET_TEMPLATE_REVISIONS]: (state, revisions) => state.templateRevisions = revisions,
    [types.SET_TEMPLATES]: (state, templates) => state.templates = templates,
    [types.SET_ERROR_TEMPLATE_REVISIONS]: (state, errorTemplates) => state.errorTemplates = errorTemplates,
    [types.SET_RENEWAL_TEST_RESULTS]: (state, renewalTestResults) => state.renewalTestResults = renewalTestResults,
    [types.SET_RENEWAL]: (state, renewal) => (state.renewal = renewal)
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
