import * as conditionsService from "../../../../services/provider/conditions";

export const types = {
    "SET_CONDITIONS": "SET_CONDITIONS",
    "SET_CONDITION_TYPES": "SET_CONDITION_TYPES"
};

const state = {
    conditions: [],
    conditionTypes: []
};

const actions = {
    async getConditions({ commit }) {
        const { data } = await conditionsService.getConditions();
        commit(types.SET_CONDITIONS, data);
    },
    async getConditionTypes({ commit }) {
        const { data } = await conditionsService.getConditionTypes();
        commit(types.SET_CONDITION_TYPES, data);
    }
};

const mutations = {
    [types.SET_CONDITIONS]: (state, conditions) => (state.conditions = conditions),
    [types.SET_CONDITION_TYPES]: (state, types) => (state.conditionTypes = types)
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
