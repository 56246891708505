<template>
    <div class="observer" />
</template>

<script>
export default {
    props: {
        rootMargin: {
            type: String,
            default: "0px"
        },
        threshold: {
            type: Number,
            default: 0
        },
        done: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            observer: null
        };
    },
    watch: {
        done(value) {
            if (value) {
                this.observer.disconnect();
            }
        }
    },
    mounted() {
        const options = {
            rootMargin: this.rootMargin,
            threshold: this.threshold
        };
        this.observer = new IntersectionObserver(([entry]) => {
            if (entry && entry.isIntersecting) {
                this.$emit("loadMore");
            }
        }, options);
        this.observer.observe(this.$el);
    },
    destroyed() {
        this.observer.disconnect();
    }
};
</script>

<style scoped>
.observer {
    height: 1px;
}
</style>
