var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['VueCarousel', {'hasArrows': _vm.canForward || _vm.canBackward }]},[_c('div',{staticClass:"VueCarousel-wrapper"},[_c('div',{ref:"carousel",staticClass:"VueCarousel-inner",style:({
                'transform': `translate(${_vm.dragOffset}px, 0)`,
                'transition': _vm.dragging ? 'none' : '0.25s ease-in-out transform',
                'flex-basis': `${_vm.itemWidth}px`,
                'visibility': _vm.itemWidth ? 'visible' : 'hidden',
            })},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,style:({
                    'flex-shrink': 0,
                    'width': `${_vm.itemWidth}px`,
                    'ms-flex-preferred-size': `${_vm.itemWidth}px`,
                    'webkit-flex-basis': `${_vm.itemWidth}px`,
                })},[_vm._t("default",null,{"item":item})],2)}),0),(_vm.isBusy)?_vm._t("busy",function(){return [_c('div',{staticClass:"VueCarousel-busy",on:{"mousedown":function($event){$event.stopPropagation();},"touchstart":function($event){$event.stopPropagation();}}})]}):_vm._e()],2),(_vm.navigationEnabled)?_vm._t("navigation",function(){return [(_vm.canForward || _vm.canBackward)?_c('button',{staticClass:"VueCarousel-forward",attrs:{"disabled":!_vm.canForward,"type":"button"},on:{"click":function($event){return _vm.move('forward')}}},[_vm._v(" > ")]):_vm._e(),(_vm.canBackward || _vm.canForward)?_c('button',{staticClass:"VueCarousel-back",attrs:{"disabled":!_vm.canBackward,"type":"button"},on:{"click":function($event){return _vm.move('backward')}}},[_vm._v(" < ")]):_vm._e()]}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }