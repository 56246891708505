<template>
    <li class="base__item">
        <div v-if="typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number' ">
            <span
                v-if="item"
                class="item"
            >
                {{ item | Uppercase }}: <span class="value">{{ value }}</span>
            </span>
            <span
                v-else
                class="item"
            >
                {{ value }}
            </span>
        </div>
        <div v-if="Array.isArray(value) ">
            <div v-if="value.length > 0"> 
                <span
                    v-if="item"
                    class="title"
                >
                    {{ item | Uppercase }}:
                </span>
                <ul>
                    <base-item-display
                        v-for="(a, key) in value"
                        :key="key"
                        :value="a"
                    />
                </ul>
            </div>
            <div v-else>
                <li>
                    <span class="item">{{ item | Uppercase }}:</span>
                </li>
            </div>  
        </div>
        <div v-if="typeof value === 'object' && !Array.isArray(value)">
            <span
                v-if="item.length > 0"
                class="title"
            >
                {{ item | Uppercase }}:
            </span>
            <ul>
                <base-item-display
                    v-for="(a, b, key) in value"
                    :key="key"
                    :item="b"
                    :value="a"
                />
            </ul>
        </div>
    </li>
</template>

<script>
export default {
    components: {
        BaseItemDisplay: () => import ("../base/BaseItemDisplay")
    },
    filters: {
        Uppercase(value) {
            return value.split("_").map(x => x.charAt(0).toUpperCase() + x.substring(1)).join(" ");
        }
    },
    props: {
        item: {
            type: [String, Number],
            default: ""
        },
        value: {
            type: [String, Number, Object, Array, Boolean],
            default: ""
        }
    }
};
</script>
