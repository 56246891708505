import formsService from "../../services/forms";

export const types = {
    "SET_FORMS": "SET_FORMS",
    "SET_FORM": "SET_FORM",
    "SET_FORM_TYPES": "SET_FORM_TYPES",
    "SET_FORM_REVISIONS_COUNT": "SET_FORM_REVISIONS_COUNT",
    "SET_FORM_REVISIONS": "SET_FORM_REVISIONS",
    "SET_CUSTOM_COMPONENTS": "SET_CUSTOM_COMPONENTS"
};

const getters = {

};
// initial state
const state = {
    forms: [],
    formTypes: [],
    revisionCount: 0,
    revisions: [],
    customComponents: []
};
// actions
const actions = {
    async getAllForms({ commit, rootState }) {
        const forms = await formsService.getForms(rootState.auth.selectedBrand.name_url);
        commit(types.SET_FORMS, forms.data);
    },
    async postForm({ commit, rootState }, { name, comment, tags, type, formIn, subtitle }) {
        const form = await formsService.postForm(
            rootState.auth.selectedBrand.name_url,
            name,
            formIn,
            comment,
            tags,
            type,
            subtitle
        );
        commit(types.SET_FORM, { ...form.data, id: form.data.form_id });
        return form.data;
    },
    async getFormTypes({ commit, rootState }) {
        const formTypes = await formsService.getTypes(
            rootState.auth.selectedBrand.name_url
        );
        commit(types.SET_FORM_TYPES, formTypes.data);
        return formTypes.data;
    },
    async getFormRevisions({ commit, rootState }, { id, limit, offset }) {
        const revisions = await formsService.getFormRevisions(
            id,
            rootState.auth.selectedBrand.name_url,
            {
                offset,
                limit
            }
        );
        commit(types.SET_FORM_REVISIONS, revisions.data);
        commit(types.SET_FORM_REVISIONS_COUNT, revisions.count);
        return revisions;
    },
    async getCustomComponents({ commit, rootState }) {
        const customComponents = await formsService.getCustomComponents(
            rootState.auth.selectedBrand.name_url
        );
        commit(types.SET_CUSTOM_COMPONENTS, customComponents.data);
        return customComponents.data;
    },
    async changeFormName({ dispatch, rootState }, { id: formId, name }) {
        await formsService.patchFormName(
            rootState.auth.selectedBrand.name_url,
            formId,
            name
        );
        await dispatch("pages/getAllPages", null, {root: true});
        await dispatch("getAllForms");
    }
};

// mutations
const mutations = {
    [types.SET_FORM](state, form) {
        state.forms.push(form);
    },
    [types.SET_FORMS](state, forms) {
        state.forms = forms;
    },
    [types.SET_FORM_TYPES](state, val) {
        state.formTypes = val;
    },
    [types.SET_FORM_REVISIONS_COUNT](state, val) {
        state.revisionCount = val;
    },
    [types.SET_FORM_REVISIONS](state, val) {
        state.revisions = val;
    },
    [types.SET_CUSTOM_COMPONENTS](state, customComponents) {
        state.customComponents = customComponents;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
