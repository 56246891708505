import axios from "./axios";

export default {
    getPages({ brandUrl }) {
        return axios.get(`/brands/${brandUrl}/pages`);
    },
    postPage({ brandUrl, name, page={}, comment, tags }){
        return axios.post(`/brands/${brandUrl}/pages`,{ name, page, comment, tags });
    },
    getPageRevisions({ pageId, brandUrl, params }) {
        return axios.get(`/brands/${brandUrl}/pages/${pageId}/revisions`, {params});
    },
    getPageRevision({ pageId, revisionId, brandUrl, options }) {
        return axios.get(`brands/${brandUrl}/pages/${pageId}/revisions/${revisionId}`, options);
    },
    postPageRevision({ pageId, revisionId, brandUrl, revision, name }){
        return axios.post(`/brands/${brandUrl}/pages/${pageId}/revisions/${revisionId}`,{ revision, name });
    }
};
