<template>
    <span
        v-b-tooltip.hover
        :class="['base-badge', badgeClass(variant), badgeClass(size)]"
        :title="title"
    >
        <slot />
        <span
            v-if="numberCount"
            class="base-badge-append"
        >{{ numberCount }}</span>
    </span>
</template>

<script>
export default {
    props:{
        variant:{
            type:String,
            required:false,
            default: "dark"
        },
        title:{
            type:String,
            required:false,
            default: ""
        },
        numberCount:{
            type: [Number, String],
            required:false,
            default: null
        },
        size:{
            type:String,
            required:false,
            default: "medium"
        }
    },
    methods: {
        badgeClass(data) {
            return "base-badge-" + data;
        }
    }
};
</script>

<style scoped lang="sass">
    .base-badge 
        padding: 10px 15px
        position: relative
        display: inline-block
        margin: 2px 0
        border-radius: 20px
        font-size: 0.8rem
        &-append
            position: absolute
            top: -5px
            right: -10px
            border-radius: 50%
            color: white
            text-align: center
            font-size: 12px
            color: white
            line-height: 24px
            width: 24px
            height: 24px
        &-small
            padding: 2px 5px
            .base-badge-append
                right: -15px
                width: 20px
                height: 20px
                font-size: 10px
                line-height: 20px
        &-medium
            padding: 7px 12px
            .base-badge-append
                right: -12px
                width: 24px
                height: 24px
                font-size: 12px
                line-height: 26px
        &-large
            padding: 10px 15px
            .base-badge-append
                right: -15px
                width: 24px
                height: 24px
                line-height: 26px
        &-primary
            background: #c9dbf7
            color: #3e4c63
            .base-badge-append
                background: #707c8f
        &-secondary
            background: #999
            color: #FFFFFF
            .base-badge-append
                background: #666
        &-success
            background: #d2f3e7
            color: #019a5e
            .base-badge-append
                background: #019a5e
        &-danger
            background: #dc3545
            color: #ffffff
            .base-badge-append
                background: #78121c
        &-warning
            background: #f4e8a9
            color: #4c4626
            .base-badge-append
                background: #999061
        &-info
            background: #e4bbf5
            color: #563563
            .base-badge-append
                background: #735b7e
        &-light
            background: #e0e0e1
            color: #262626
            .base-badge-append
                background: #999
        &-dark
            background: #666
            color: #FFFFFF
            .base-badge-append
                background: #333
</style>