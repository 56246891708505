<template>
    <div class="chart">
        <div class="chart__menu">
            <div class="chart__menu--edit row">
                <b-input-group class="col-5">
                    <label
                        for="dashFromDate"
                        class="editLbl"
                    >From</label>

                    <div class="dateContainer">
                        <b-form-datepicker
                            id="dashFromDate"
                            v-model="from"
                            data-vv-as="From Date"
                            name="startdate"
                            aria-label="From Date"
                            class="startDate"
                            input-class="form-control form-control-md"
                            :max="disabledDates"
                        />
                    </div>
                </b-input-group>
                <b-input-group class="col-5">
                    <label
                        for="dashToDate"
                        class="editLbl"
                    >To</label>
                    <div class="dateContainer">
                        <b-form-datepicker
                            id="dashToDate"
                            v-model="to"
                            data-vv-as="To Date"
                            name="todate"
                            aria-label="To Date"
                            class="toDate"
                            input-class="form-control form-control-md"
                            :max="disabledDates"
                        />
                    </div>
                </b-input-group>
                <b-input-group class="col-2">
                    <b-button
                        class="update"
                        variant="primary"
                        :disabled="loading"
                        @click="submit"
                    >
                        Update
                    </b-button>
                </b-input-group>

                <div class="col-12 errorMsg">
                    <p
                        v-if="error"
                        class="error"
                    >
                        {{ error }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
    props: {
        selected: {
            type: Object,
            default: ( () =>  {
                return {};
            })
        }
    },
    data() {
        return {
            unit: "day",
            from: this.$moment().format("YYYY-MM-DD"),
            to: this.$moment().format("YYYY-MM-DD"),
            toTime: "23:59:59",
            fromTime: "00:00:00",
            disabledDates: new Date()
        };
    },
    computed: {
        ...mapState({
            error: state => state.dashboardMetrics.error,
            endpoints: state => state.dashboardMetrics.endpointsData,
            range: state => state.dashboardMetrics.customRange,
            loading: state => state.dashboardMetrics.loading
        })
    },
    mounted() {
        const range = 30;
        this.$store.dispatch("dashboardMetrics/setCustomRange", range);
        this.from = this.$moment().subtract(range, "days").format("YYYY-MM-DD");
    },
    methods: {
        ...mapActions("dashboardMetrics", ["runAll"]),
        async submit() {
            const range = moment(this.to).diff(this.from, "days");
            let self = this;
            self.from = moment(self.from).format("YYYY-MM-DD");
            self.to = moment(self.to).format("YYYY-MM-DD");
            const data = {
                identifier: self.identifier,
                endpointID: this.selected.id,
                unit: self.unit,
                from: self.from,
                to: self.to,
                fromTime: self.fromTime,
                toTime: self.toTime,
                range
            };
            // emit the range upto the parent so we can trigger the range watcher
            this.$emit("range", range);
            this.$store.dispatch("dashboardMetrics/setCustomRange", range);
            await this.runAll(data);
        }
    }

};
</script>

