import ValueExpression from "./ValueExpression";
import FormulaExpression from "./FormulaExpression";
import LookupExpression from "./LookupExpression";
import RequestExpression from "./RequestExpression";
import ResourceExpression from "./ResourceExpression";
import IfExpression from "./IfExpression";
import ForeachExpression from "./ForeachExpression";
import TableExpression from "./TableExpression";
import AggregateExpression from "./AggregateExpression";
import CountifExpression from "./CountifExpression";
import LookupTableExpression from "./LookupTableExpression";
import PickExpression from "./PickExpression";
import VeriskExpression from "./VeriskExpression";
import ApiExpression from "./ApiExpression";
import BlockExpression from "./BlockExpression";
import OutputGroupExpression from "./OutputGroupExpression";

/**
 * Expression Factory takes a VALID schema and
 * Produces individual expressions. This is needed to provide
 * the validation and reactivity to display the form. It replaces
 * the need to use VeeValidate (which would essentially create a
 * wrapper around the object)
 * TODO - We could introduce validation of scheme but
 * strictly this should not be needed as the schemas are validated
 * via the api.
 * TODO - we dont pass the properties in directly as we have a custom setter
 * which triggers validation. (Hence why we set them after )
 * If the expression is missing fields it will create a default
 */
export default class ExpressionFactory {
    constructor(expression){
        let exp;
        switch(expression.expressionType){
            //this is used for creating a new expression only
            //need to refactoir this as it duplicates the lookup code.
            case "lookuptable":
                exp = new LookupTableExpression(expression.id);
                exp.types = expression.types || [];
                exp.returns = expression.returns || {type:"string", default:"reject"};
                exp.aggregate = expression.aggregate || null;
                exp.tableName = expression.data;
                exp.headings = [];
                exp.mappedHeadings = [];
                exp.description = expression.description;
                exp.tags = expression.tags||[];
                exp.nullMatches = expression.nullMatches || [];
                return exp;
            case "lookup":
                if(
                    expression.returns &&
                    (
                        expression.returns.type === "object" ||
                        expression.returns.type === "array" &&
                        expression.returns.items.type === "object"
                    ) &&
                    typeof expression.data!=="string"
                ) {
                    exp = new LookupExpression(expression.id);
                    exp.returns = expression.returns || {type:"string", default:"reject"};
                    exp.aggregate = expression.aggregate || null;
                    exp.types = LookupExpression.objectTypesBuilder(
                        expression.types,
                        expression.returns,
                        expression.data
                    );
                    exp.columns = LookupExpression.objectColumnBuilder(
                        expression.headings,
                        expression.returns,
                        expression.data
                    );
                    exp.rows = LookupExpression.objectRowBuilder(
                        expression.data,
                        exp.types,
                        expression.returns,
                        exp.columns
                    );
                    exp.description = expression.description;
                    exp.tags = expression.tags||[];
                    exp.nullMatches = LookupExpression.nullMatchesBuilder(expression.headings, expression.nullMatches);
                //duplicated as schemes dont have a lookup table type
                } else if(typeof expression.data !== "string"){
                    exp = new LookupExpression(expression.id);
                    exp.types = expression.types || [];
                    exp.returns = expression.returns || {type:"string", default:"reject"};
                    exp.aggregate = expression.aggregate || null;
                    exp.columns = LookupExpression.columnBuilder(expression.headings);
                    exp.rows = LookupExpression.rowBuilder(expression.data,expression.types);
                    exp.description = expression.description;
                    exp.tags = expression.tags||[];
                    exp.nullMatches = LookupExpression.nullMatchesBuilder(expression.headings, expression.nullMatches);
                } else {
                    exp = new LookupTableExpression(expression.id);
                    exp.description = expression.description;
                    exp.types = LookupTableExpression.objectTypesBuilder(expression.types,expression.returns) || [];
                    exp.returns = expression.returns || {type:"string", default:"reject"};
                    exp.aggregate = expression.aggregate || null;
                    exp.tableName = expression.data;
                    // eslint-disable-next-line max-len
                    exp.headings = LookupTableExpression.objectHeadingsBuilder(expression.headings,expression.returns) || [];
                    exp.columns = expression.columns || [];
                    //mapped headings are the headings from table
                    //we are mapping to. These are only realy needed for
                    //the interface
                    exp.mappedHeadings = expression.mappedHeadings || [];
                    exp.mappedReturns = expression.mappedReturns || Array(exp.mappedHeadings.length);
                    exp.description = expression.description;
                    exp.tags = expression.tags||[];
                    exp.nullMatches = LookupExpression.nullMatchesBuilder(expression.headings, expression.nullMatches);
                }
                return exp;
            case "table":
                exp = new TableExpression(expression.id);
                exp.types = expression.types || [];
                //TODO this needs work as we currently assume we return an array of strings
                //we need to build this from the column types
                exp.returns = {type:"array", items:{type:"string"}};
                exp.output = expression.output || false;
                exp.columns = TableExpression.columnBuilder(expression.headings);
                exp.rows = LookupExpression.rowBuilder(expression.data, expression.types);
                exp.description = expression.description;
                exp.tags = expression.tags||[];
                exp.nullMatches = LookupExpression.nullMatchesBuilder(expression.headings, expression.nullMatches);
                return exp;
            case "value":
                exp = new ValueExpression(expression.id);
                exp.data = expression.data;
                exp.returns = expression.returns || {type:"string"};
                exp.description = expression.description;
                exp.tags = expression.tags||[];
                return exp;
            case "formula":
                exp = new FormulaExpression(expression.id);
                exp.returns = expression.returns || {type:"string"};
                exp.data = expression.data;
                exp.description = expression.description;
                exp.tags = expression.tags||[];
                return exp;
            case "request":
                exp = new RequestExpression(expression.id);
                expression.returns = expression.returns || {type:"string"};
                exp.returns = RequestExpression.returnBuilder(expression.returns);
                exp.optional = expression.returns.default === undefined ? false:true;
                exp.description = expression.description;
                exp.tags = expression.tags||[];
                return exp;
            case "resource":
                exp = new ResourceExpression(expression.id);
                exp.data = expression.data;
                exp.resourceType = expression.resourceType || "document";
                exp.returns = expression.returns || {type:"string"};
                exp.description = expression.description;
                exp.tags = expression.tags||[];
                return exp;
            case "aggregate":
                exp = new AggregateExpression(expression.id);
                exp.items = expression.items;
                exp.item = expression.item;
                exp.type = expression.type;
                exp.returns = expression.returns || {type:"number"};
                exp.description = expression.description;
                exp.tags = expression.tags || [];
                return exp;
            case "pick":
                exp = new PickExpression(expression.id);
                exp.items = expression.items;
                exp.item = expression.item;
                exp.type = expression.type;
                exp.returns = expression.returns || {type:"object", properties:[{type:"number"}]};
                exp.description = expression.description;
                exp.tags = expression.tags||[];
                return exp;
            case "countif":
                exp = new CountifExpression(expression.id);
                exp.items = expression.items;
                exp.item = expression.item;
                exp.condition = expression.condition;
                exp.returns = expression.returns || {type:"number"};
                exp.description = expression.description;
                exp.tags = expression.tags || [];
                return exp;
            case "if":
                exp = new IfExpression(expression.id);
                exp.condition = expression.condition;
                exp.returns = expression.returns || {type:"boolean"};
                exp.description = expression.description;
                exp.tags = expression.tags||[];
                if(expression.block && Array.isArray(expression.block)){
                    exp.block = expression.block.map(exp=>{
                        return new ExpressionFactory(exp);
                    });
                }else{
                    exp.block = [];
                }
                return exp;
            case "block":
                exp = new BlockExpression(expression.id);
                exp.returns = expression.returns || {type:"null"};
                exp.description = expression.description;
                exp.tags = expression.tags||[];
                if(expression.block && Array.isArray(expression.block)){
                    exp.block = expression.block.map(exp=>{
                        return new ExpressionFactory(exp);
                    });
                }else{
                    exp.block = [];
                }
                return exp;
            case "outputgroup":
                exp = new OutputGroupExpression(expression.id);
                exp.returns = expression.returns || { type: "object", properties:[] };
                exp.description = expression.description;
                exp.tags = expression.tags || [];
                if (expression.block && Array.isArray(expression.block)) {
                    exp.block = expression.block.map(exp => {
                        return new ExpressionFactory(exp);
                    });
                } else {
                    exp.block = [];
                }
                return exp;
            case "foreach":
                exp = new ForeachExpression(expression.id);
                exp.items = expression.items;
                exp.item = expression.item;
                exp.returns = expression.returns || {type:"array", items:{type:"string"}};
                exp.description = expression.description;
                exp.tags = expression.tags||[];
                if(expression.block && Array.isArray(expression.block)){
                    exp.block = expression.block.map(exp=>{
                        return new ExpressionFactory(exp);
                    });
                }else{
                    exp.block = [];
                }
                return exp;
            case "verisktwo":
                exp = new VeriskExpression(expression.id);
                exp.url = expression.url;
                exp.key = expression.key;
                exp.region = expression.region;
                exp.amt = expression.amt;
                exp.winterSports = expression.winterSports;
                exp.pregnancy= expression.pregnancy;
                exp.pregnancyComplications = expression.pregnancyComplications;
                exp.corona = expression.corona;
                exp.linkedCondition = expression.linkedCondition;
                exp.rescore = expression.rescore;
                exp.rescoreOnDifference = expression.rescoreOnDifference;
                exp.test = expression.test || false;
                exp.tags = expression.tags || [];
                exp.verisk = expression.verisk;
                return exp;
            case "api":
                exp = new ApiExpression(expression.id);
                exp.url = expression.url;
                exp.method = expression.method;
                if (expression.headers) {
                    expression.headers.forEach((el) => exp.addHeader(el));
                }
                if (expression.queries) {
                    expression.queries.forEach((el) => exp.addQuery(el));
                }
                if (expression.data) {
                    expression.data.forEach((el) => exp.addData(el));
                }
                exp.expressionType = expression.expressionType;
                exp.test = expression.test;
                exp.time = expression.time;
                exp.tags = expression.tags || [];
                exp.returns = expression.returns || { type: "string" };
                return exp;
            default:
                //eslint-disable-next-line
                throw new Error(`Expression Factory: Could not create an expression of type: ${expression.expressionType}`);
        }
    }
}
