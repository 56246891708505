import crmServices from "../../services/crm";
import endpointsService from "../../services/endpoints";
import paymentServices from "../../services/payments";
import customerServices from "../../services/customers";
import { formHasElementWithKey } from "./crmHelpers";
import moment from "moment";

function selectCountryAreaMappings(areas, countries, form) {
    if (form.triparea) {
        if (!Array.isArray(form.triparea)) {
            form.triparea = [form.triparea];
        }
        // eslint-disable-next-line camelcase
        form.chosen_area = form.triparea.map(trip => areas.find(
            (area) => area.area_code === trip || area.code === trip
        ));
    } else {
        // eslint-disable-next-line camelcase
        form.chosen_area = [];
    }

    if (form.isolist) {
        // if we get a string back from the aggregators convert it to an array. 
        if (typeof form.isolist === "string" && form.isolist.length > 0) {
            form.isolist = form.isolist.split(",");
        }
        if (!Array.isArray(form.isolist)) {
            form.isolist = [form.isolist];
        }
        // eslint-disable-next-line camelcase
        form.travel_destinations = form.isolist.map(iso => countries.find( area => area.iso_code === iso));
    } else {
        // eslint-disable-next-line camelcase
        form.travel_destinations = [];
    }
}

function setCustomerInformation(formData) {
    if(formData.travellers) {
        formData.travellers.forEach((traveller, index) => {
            if (typeof traveller.verisk === "object" && traveller.verisk.screened === true) {
                let veriskScreeningConditions = traveller.verisk.ScreeningHistory?.conditions?.Condition ?? [];
                if (!Array.isArray(veriskScreeningConditions)) {
                    veriskScreeningConditions = [veriskScreeningConditions];
                }
                let conditions = veriskScreeningConditions.map(condition => {
                    return {conditions: [{ name: condition?.name ?? "" }]};
                });
                traveller.declarationParts = conditions;
            } else {
                delete traveller.verisk;
                traveller.declarationParts = "";
            }
            // if we don't have dob in the form data create the object i.e it's sent from comparecover
            if (!formData.dob) {
                formData.dob = {};
            }
            const travellerNumber = (index + 1);

            // if we don't have either of the Days Months or Years for the DOB's,
            // create them by extracting them form the DOB
            if (!formData.dob["dd" + travellerNumber] ||
                !formData.dob["mm" + travellerNumber] ||
                !formData.dob["yyyy" + travellerNumber]
            ) {
                formData.dob["dd" + travellerNumber] = moment(traveller.dob).format("D");
                formData.dob["mm" + travellerNumber] = moment(traveller.dob).format("M");
                formData.dob["yyyy" + travellerNumber] = moment(traveller.dob).format("YYYY");
            }
        });
    }
    if (!formData.selectedTravellers) {
        formData.selectedTravellers = formData?.travellers?.length;
    }

    return formData;
}

export const types = {
    "SET_CALL_TYPE": "SET_CALL_TYPE",
    "SET_EXISTING_POLICY_REF": "SET_EXISTING_POLICY_REF",
    "SET_ERROR": "SET_ERROR",
    "SKIP_TAB_INDEX": "SKIP_TAB_INDEX",
    "SET_STATUS": "SET_STATUS",
    "SET_MESSAGE": "SET_MESSAGE",
    "SET_STEP": "SET_STEP",
    "SET_VERISK": "SET_VERISK",
    "SET_EDIT": "SET_EDIT",
    "SET_FORM_DATA": "SET_FORM_DATA",
    "SET_FORM_USER_DATA": "SET_FORM_USER_DATA",
    "SET_FORM_REQUESTER_ID": "SET_FORM_REQUESTER_ID",
    "SET_MEDICAL": "SET_MEDICAL",
    "SET_POLICY": "SET_POLICY",
    "SET_ADJUST_TYPE": "SET_ADJUST_TYPE",
    "CLEAR_STATE": "CLEAR_STATE", 
    "CLEAR_POLICY_STATE_DATA": "CLEAR_POLICY_STATE_DATA",
    "SET_ADDRESS": "SET_ADDRESS",
    "SET_JOURNEY": "SET_JOURNEY",
    "SET_ENDPOINT": "SET_ENDPOINT",
    "SET_SEARCH_POLICY": "SET_SEARCH_POLICY",
    "SET_EXISTING_QUOTE_REF": "SET_EXISTING_QUOTE_REF",
    "SET_QUOTE": "SET_QUOTE",
    "SET_TEST_INFO": "SET_TEST_INFO"
};

/* callTypes
nq = neq quotes
ep = existing policy
eq = existing quote */

const initialState = () => {
    return {
        callType: "",
        existingPolicyRef: "",
        error: "",
        journey: [],
        endpoint: {},
        status: "",
        message: "",
        step: 0,
        skipTabIndex: false,
        verisk: false,
        edit: false,
        form: {
            "trip_type": "ST",
            "start_date": "",
            "end_date": "",
            "travel_destinations": [],
            "chosen_area": [],
            firstname: "",
            surname: "",
            email: "",
            grouptype: "",
            travellers: [],
            medical: {},
            dob: {},
            selectedTravellers: 1,
            premedquestions: {
                doyouhave: "",
                "pre_screening_failed": "",
                "requires_screening": ""
            },
            wintersports: false,
            cruisecover: false,
            businesstrip: false,
            gadget: false,
            // eslint-disable-next-line camelcase
            gadget_level: "",
            triparea: [],
            "requester_id": "d2c",
            endpoint: "d2c",
            duration: null,
            testing: "false",
            multiTripDateChoice: "today"
        },
        policies: [],
        policy: {
            "policy_data": {
                customerData: [],
                contactInfo: {}
            }
        },
        adjustType: "",
        customerPolicySearch: [],
        quote: {},
        testInfo: {
            updatedDate: "",
            revisionId: ""
        }
    };
};

const policyData = () => {
    return {
        form: {
            "trip_type": "ST",
            "start_date": "",
            "end_date": "",
            "travel_destinations": [],
            "chosen_area": [],
            firstname: "",
            surname: "",
            email: "",
            grouptype: "",
            travellers: [],
            medical: {},
            dob: {},
            selectedTravellers: 1,
            premedquestions: {
                doyouhave: "",
                "pre_screening_failed": "",
                "requires_screening": ""
            },
            wintersports: false,
            cruisecover: false,
            businesstrip: false,
            gadget: false,
            // eslint-disable-next-line camelcase
            gadget_level: "",
            triparea: [],
            "requester_id": "d2c",
            endpoint: "d2c",
            duration: null,
            testing: "false"
        },
        policies: [],
        policy: {
            "policy_data": {
                customerData: [],
                contactInfo: {}
            }
        }
    };
};

const state = initialState();

const getters = {
    getAdjustType: state => state.adjustType,
    calculateStartDate: (callType, data = {}) => {
        if (callType === "rp") {
            const format = "YYYY-MM-DD";
            const formattedEndDate = moment(data.end_date, format);
            const currentDate = moment();

            return formattedEndDate.diff(currentDate, "days") < 0 ?
                currentDate.format(format) : formattedEndDate.add(1, "days").format(format);
        }
        return data.start_date;
    },
    calculateDiscoveryPayload: async (state, _, rootState) =>{
        // See what product type we are working with
        const brandType = rootState.auth.selectedBrand?.type;
        const data = state.callType === "eq" ? state?.quote?.data : state?.policy?.policy_data;
        const { discovery, autorenewal, requester_id: requesterId } = data;
        let brandData = {};

        switch(brandType) {
            case "motorbreakdown" : {
                // MBA OR CHE
                const dataKeyTest = /_type|_date|vehicle|driver|area/gi;
                const filteredData = Object.keys(data).filter(key => dataKeyTest.test(key)).reduce((acc, key) => {
                    acc[key] = data[key];
                    return acc;
                }, {});

                brandData = {
                    ...filteredData,
                    "start_date": getters.calculateStartDate(state.callType, data)
                };
                break;
            }
            case "default" :
            case "travel" : {
                // Travel
                // Only calling out to the API here if we need to. Feel free to move this higher up if needed elsewhere
                const endpointSettings = await paymentServices.fetchEndpointSettings({
                    provider: rootState.auth.provider,
                    brand: rootState.auth.selectedBrand.name_url,
                    endpoint: state.endpoint?.name_url
                });
                const countryMappings = endpointSettings.data.find(data => data.key === "country_mappings");

                const today = moment().format("YYYY-MM-DD");
                const optional = ["wintersports", "golf", "wedding", "cruisecover", "gadget", "hazardous"]
                    .reduce((agg, val) => {
                        if (data[val]) {
                            return { ...agg, [val]: true };
                        }
                        return { ...agg };
                    }, {});

                const verisk = [];
                if (data.travellers) {
                    for (const [i, t] of data.travellers.entries()) {
                        if (t.verisk) {
                            verisk.push({
                                declarationParts: [ ...t.declarationParts ],
                                "traveller_number": i + 1,
                                verisk: { ...t.verisk }
                            });
                        }
                    }
                }

                const startDate = getters.calculateStartDate(state.callType, data);
                
                const usingStStartDate =
                    formHasElementWithKey(state.journey[0].data.main, "name", "stStartDate");

                brandData = {
                    gadgetLevel: optional["gadget"] ? data["gadget_level"] : undefined,
                    tripType: data.trip_type,
                    "start_date": usingStStartDate ? undefined : startDate,
                    stStartDate: usingStStartDate ? startDate : undefined,
                    stEndDate: data.end_date,
                    amtStartDateType: startDate === today ? "today" : "other",
                    amtStartDate: usingStStartDate ? startDate : undefined,
                    bpStartDate: usingStStartDate ? startDate : undefined,
                    bpLengthOfCover: (startDate && data.end_date) &&
                        `${ ("00" + moment(data.end_date).add(1, "day")
                            .diff(moment(startDate), "months")).substr(-2) }`,
                    destination: {
                        // Need to map these back to the right places
                        area: Array.isArray(data.triparea) ? data.triparea[0] : "",
                        countries: data.isolist?.length ? data.isolist
                            .map((code) => countryMappings.value.find(mapping => mapping.iso_code === code))
                            .filter((country) => !!country) : []
                    },
                    firstName: data.firstname,
                    surname: data.surname,
                    emailAddress: data.email,
                    travellersInformation: {
                        selectedTravellers: data.travellers?.length,
                        travellers: data.travellers?.map((t, i) => ({
                            // eslint-disable-next-line camelcase
                            traveller_number: i + 1,
                            firstname: t.firstname || "",
                            surname: t.surname || "",
                            dob: t.dob,
                            age: moment(startDate).diff(moment(t.dob), "years"),
                            description: `Traveller ${t.traveller_number} - DOB: ${t.dob}`
                        })),
                        grouptype: data.grouptype
                    },
                    stOptional: { ...optional },
                    amtOptional: { ...optional },
                    bpOptional: { ...optional },
                    veriskInformation: {
                        travellers: verisk
                    },
                    ...verisk.length ? {
                        preExistingMedical: "yes",
                        preScreeningFailed: "no",
                        requiresScreening: "yes"
                    } : {
                        preExistingMedical: "no",
                        preScreeningFailed: undefined,
                        requiresScreening: undefined
                    }
                };
                break;
            }
        }
        return { ...discovery, ...brandData, autorenewal, "requester_id": requesterId };
    }
};

const actions = {
    async populateData({state, dispatch, getters}) {
        return Promise.all([
            dispatch("data/setFormData", {                
                payload: await getters.calculateDiscoveryPayload,
                page: "discovery"
            }, {root: true}),
            dispatch("data/setFormData", {
                payload: state?.policy?.policy_data?.details,
                page: "details"
            }, {root: true})
        ]);
    },
    setCallType({commit}, type ) {
        commit("SET_CALL_TYPE", type);
    },
    setSkipTabIndex({commit}, value) {
        commit("SKIP_TAB_INDEX", value);
    },
    setStep({commit}, step) {
        commit("SET_STEP", step);
    },
    setVerisk({commit}, value) {
        commit("SET_VERISK", value);
    },
    setExistingPolicyNumber({commit}, policyRef) {
        commit("SET_EXISTING_POLICY_REF", policyRef);
    },
    setExistingQuoteNumber({commit},quoteRef) {
        commit("SET_EXISTING_QUOTE_REF", quoteRef);
    },
    setAdjustType({commit}, adjustType) {
        commit("SET_ADJUST_TYPE", adjustType);
    },
    async checkAdjustment({commit, rootState}, payload) {
        commit("SET_ERROR", false);
        commit("SET_MESSAGE", null);
        commit("SET_STATUS", null);
        try {
            payload.provider = rootState.auth.provider;
            payload.brand = rootState.auth.selectedBrand.name_url;
            // eslint-disable-next-line max-len
            if (rootState.crm.adjustType === "remove_traveller" || rootState.crm.adjustType === "change_in_health_remove") {
                payload.adjustReason = true;
            }
            let result = await crmServices.calculateAdjustment(payload);
            commit("SET_STATUS", result.data.status);
            commit("SET_MESSAGE", result.data.message);
        } catch (error) {
            commit("SET_ERROR", error.message);
        }
    },
    setEditCustomerInfo({commit}, value) {
        commit("SET_EDIT", value);
    },
    async getPolicy({ commit, state }, payload) {
        try {
            commit(types.SET_ERROR, false);

            payload.endpoint = state.endpoint?.name_url;

            const endpointSettings = await paymentServices.fetchEndpointSettings(payload);
            const countryMappings = endpointSettings.data.find(data => data.key === "country_mappings");
            const areaMappings = endpointSettings.data.find(data => data.key === "area_mappings");

            const { data } = await crmServices.fetchCustomerData(payload);
            commit(types.SET_POLICY, data );

            const formData = setCustomerInformation(data.policy_data);

            selectCountryAreaMappings(
                areaMappings ? areaMappings.value : [],
                countryMappings ? countryMappings.value : [],
                formData
            );

            if (formData?.premedquestions?.requires_screening === true) {
                commit("SET_VERISK", true);
            }

            commit(types.SET_FORM_DATA, formData);
        } catch (error) {
            if (error.response) {
                commit(types.SET_ERROR, "Cannot find this customer policy");
            }
            commit(types.SET_ERROR, error.message);
        }
    },
    async setQuoteData({commit, rootState}) {
        try {
            const endpointSettings = await paymentServices.fetchEndpointSettings({
                provider: rootState.auth.provider,
                brand: rootState.auth.selectedBrand.name_url,
                endpoint: state.endpoint?.name_url
            });
            
            const formData = setCustomerInformation(rootState.customers.quote.request_mapped);
            const countryMappings = endpointSettings.data.find(data => data.key === "country_mappings");
            const areaMappings = endpointSettings.data.find(data => data.key === "area_mappings");

            if (formData?.premedquestions?.requires_screening === true) {
                commit("SET_VERISK", true);
            }
            selectCountryAreaMappings(
                areaMappings ? areaMappings.value : [],
                countryMappings ? countryMappings.value : [],
                formData
            );
            commit(types.SET_FORM_DATA, formData);
        } catch (error) {
            commit(types.SET_ERROR, error.message);
        }
    },
    async addCallNote({ commit, rootState }, payload) {
        try {
            const createdOn = payload.get("createdOn");
            const noteSubject = payload.get("noteSubject");
            const manualNoteSubject = payload.get("manualNoteSubject");
            const noteDetails = payload.get("noteDetails");
            const callReference = payload.get("callReference");
            const quoteNum = payload.get("quoteNum");
            const policyNum = payload.get("policyNum");
            const previousPolicyNum = payload.get("previousPolicyNum");
            await crmServices.postCallNote({
                brand: rootState.auth.selectedBrand.name_url,
                createdOn,
                noteSubject,
                manualNoteSubject,
                noteDetails,
                callReference,
                quoteNum,
                policyNum,
                previousPolicyNum
            }
            );
        } catch (err) {
            commit(types.SET_ERROR, "There was a problem adding a note for this call, please try again");
            throw new Error(err);
        }
    },
    clearCrmState({commit}) {
        commit(types.CLEAR_STATE);
    },
    clearPolicyStateData({ commit }) {
        commit(types.CLEAR_POLICY_STATE_DATA);
    },
    clearError({ commit }) {
        commit(types.SET_ERROR, null);
    },
    setAddress({commit}, payload) {
        commit(types.SET_ADDRESS, payload);
    },
    async getCRMEndpoint({ commit, rootState, dispatch }) {
        commit(types.SET_ERROR, null);
        try {
            const result = await endpointsService.getCrmEndpoint(
                rootState.auth.selectedBrand.name_url
            );

            await dispatch("setEndpoint", result?.data);
        } catch (err) {
            commit(types.SET_ERROR, err.message);
        }
    },
    async getJourney({ commit, rootState, dispatch }, { endpointId }) {
        commit(types.SET_ERROR, null);
        try {
            const crmJourney = await endpointsService.getEndpointCrmJourney(
                rootState.auth.selectedBrand.name_url,
                endpointId
            );
            const crmJourneyData = crmJourney?.data || [];

            await dispatch("setJourney", crmJourneyData);
        } catch (err) {
            commit(types.SET_ERROR, err.message);

            throw err;
        }
    },
    setJourney({ commit }, payload) {
        commit(types.SET_JOURNEY, payload);
    },
    setEndpoint({ commit }, payload) {
        commit(types.SET_ENDPOINT, payload);
    },
    setFormUserData({commit}, payload) {
        commit(types.SET_FORM_USER_DATA, payload);
    },
    setFormRequesterId({commit}, payload) {
        commit(types.SET_FORM_REQUESTER_ID, payload);
    },
    async searchPoliciesByCustomer({commit}, payload) {
        try {
            commit(types.SET_ERROR, "");
            commit(types.SET_SEARCH_POLICY, []);
            const searchResults = await crmServices.getPoliciesByCustomerDetails(payload);
            await Promise.all([searchResults]);
            commit(types.SET_SEARCH_POLICY, searchResults.data);

        } catch (err) {
            commit(types.SET_ERROR, "There was a problem finding this customer's details");
            throw new Error(err);
        }
    },
    clearSearchedPolicies({commit}) {
        commit(types.SET_SEARCH_POLICY, []);
    },
    async searchForQuote({ commit }, payload) {
        try {
            commit(types.SET_ERROR, false);
            commit(types.SET_QUOTE, {});
            const result = await customerServices.searchForAQuote(payload);
            result.data.data = setCustomerInformation(result.data.data);
            commit(types.SET_QUOTE, result.data);
        } catch (err) {
            commit(types.SET_QUOTE, []);
            commit(types.SET_ERROR, "Quote Not Found");
            throw new Error(err);
        }
    },
    async setTestInfo({ commit }, testInfo) { 
        commit(types.SET_TEST_INFO, testInfo);
        
    }
};

const mutations = {
    [types.SET_CALL_TYPE]: (state, quotes) => (state.callType = quotes),
    [types.SET_EXISTING_POLICY_REF]: (state, existingPolicyRef) => (state.existingPolicyRef = existingPolicyRef),
    [types.SET_EXISTING_QUOTE_REF]: (state, existingQuoteRef) => (state.existingQuoteRef = existingQuoteRef),
    [types.SET_ERROR]: (state, error) => (state.error = error),
    [types.SET_STATUS]: (state, status) => (state.status = status),
    [types.SET_MESSAGE]: (state, message) => (state.message = message),
    [types.SET_STEP]: (state, step) => (state.step = step),
    [types.SKIP_TAB_INDEX]: (state, value) => (state.skipTabIndex = value),
    [types.SET_VERISK]: (state, verisk) => (state.verisk = verisk),
    [types.SET_EDIT]: (state, edit) => (state.edit = edit),
    [types.SET_POLICY]: (state, policy) => (state.policy = policy),
    [types.SET_FORM_DATA]: (state, form) => (state.form = form),
    [types.SET_FORM_USER_DATA]: (state, form) => {
        state.form.firstname = form.firstname;
        state.form.surname = form.surname;
    },
    [types.SET_FORM_REQUESTER_ID]: (state, requesterId) => {
        // eslint-disable-next-line camelcase
        state.form.requester_id = requesterId;
    },
    [types.SET_MEDICAL]: (state, medical) => (state.form.premedquestions = medical),
    [types.SET_ADJUST_TYPE]: (state, adjustType) => (state.adjustType = adjustType),
    [types.CLEAR_STATE]: (state) => (Object.assign(state, initialState())),
    [types.CLEAR_POLICY_STATE_DATA]: (state) => (Object.assign(state, policyData())),
    // eslint-disable-next-line camelcase
    [types.SET_ADDRESS]: (state, address) => (state.policy.policy_data.contact.contact_address = address),
    [types.SET_JOURNEY]: (state, value) => (state.journey = value),
    [types.SET_ENDPOINT]: (state, value) => (state.endpoint = value),
    [types.SET_SEARCH_POLICY]: (state, searchResults) => (state.customerPolicySearch = searchResults),
    [types.SET_QUOTE]: (state, quote) => (state.quote = quote),
    [types.SET_TEST_INFO]: (state, testInfo) => (state.testInfo = testInfo)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
