<template>
    <b-popover
        :target="target"
        triggers="focus"
    >
        <div class="p-1">
            <div class="mb-1">
                Password must contain:
            </div>
            <UserPasswordValidationItem
                v-for="(item, index) in regexItems"
                :key="index"
                :value="value"
                :regex="item.regex"
                :label="item.label"
                :is-invalid="isInvalid"
            />
        </div>
    </b-popover>
</template>

<script>
import UserPasswordValidationItem from "./UserPasswordValidationItem.vue";

export default {
    name: "UserPasswordValidationPopover",
    components: {
        UserPasswordValidationItem
    },
    props: {
        value: {
            type: String,
            required: true
        },
        target: {
            type: String,
            required: true
        },
        isInvalid: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            regexItems: [
                {
                    regex: /^.{12,100}$/,
                    label: "Between 12 and 100 characters"
                },
                {
                    regex: /[a-z]+/,
                    label: "At least one lowercase character"
                },
                {
                    regex: /[A-Z]+/,
                    label: "At least one uppercase character"
                },
                {
                    regex: /\d+/,
                    label: "At least one number"
                },
                {
                    regex: /[#$^+=!*()@%&]+/,
                    label: "At least one special character"
                }
            ]
        };
    }
};
</script>
