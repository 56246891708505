<template>
    <div class="input-json">
        <div
            class="input-json__wrapper"
            :class="validClass"
        >
            <textarea
                ref="textarea"
            />
        </div>
        <div class="input-json__cursor-pos">
            Cursor Position: {{ curPos }} Line: {{ line }}<div />
        </div>
    </div>
</template>

<script>
import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/addon/hint/javascript-hint.js";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/edit/matchbrackets.js";

export default {
    props:{
        value:{
            type:String,
            required:true
        },
        state:{
            type:[Boolean,null],
            default:null
        },
        disabled:{
            type:[Boolean],
            default:false
        }
    },
    data(){
        return {
            instance:undefined,
            curPos:0,
            line:1
        };
    },
    computed:{
        validClass(){
            if(this.state===true){
                return {
                    ["input-json__wrapper--is-valid"]:true
                };
            }
            if(this.state===false){
                return  {
                    ["input-json__wrapper--is-invalid"]:true
                };
            }
            return {};
        }
    },
    beforeDestroy() {
        this.destroy();
    },
    mounted(){
        this.instance = CodeMirror.fromTextArea(this.$refs.textarea,
            {
                tabSize: 4,
                styleActiveLine: true,
                foldGutter: true,
                styleSelectedText: true,
                theme: "default",
                matchBrackets: true,
                lineNumbers: true,
                readOnly:this.disabled?"nocursor":false,
                mode: {name: "javascript", json: true}
            });


        this.instance.setValue(this.value);


        this.instance.on("change", cm => {
            if (this.$emit) {
                this.$emit("input", cm.getValue());
            }
        });

        this.instance.on("cursorActivity", cm=>{
            // console.log(cm.doc.getCursor());
            this.curPos = cm.doc.getCursor().ch;
            this.line = cm.doc.getCursor().line+1;
        });

        this.refresh();

    },
    methods:{
        destroy(){
            const element = this.instance.doc.cm.getWrapperElement();
            element && element.remove && element.remove();
        },
        refresh() {
            setTimeout(()=>{
                this.instance.refresh();
            }, 0);
        }
    }
};
</script>
<style>
.CodeMirror {
    height: auto;
    font-family: inherit;
    border:none;

}
.input-json{
    width:100%;
    min-height: 100px;
}

.input-json__cursor-pos___container{
    display:flex;
    justify-content: space-between;
}

.input-json__cursor-pos{
    color:#6c757d;
    font-size: 80%;
    font-weight: 400;
    display: block;
    margin-top: 0.25rem;
    width:100%;
}

.input-json__wrapper{
    border: 1px solid #ced4da;
    padding:0.5rem;
    background:white;
}

.input-json__wrapper--is-valid{
    border-color: #28a745;
}

.input-json__wrapper--is-invalid{
    border-color: #dc3545;
}


</style>