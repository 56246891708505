import axios from "../../services/axios";
import policiesService from "../../services/policies";

export const types = {
    "GET_DATA": "GET_DATA",
    "SET_THEME": "SET_THEME",
    "SET_IMAGES": "SET_IMAGES",
    "SET_ERROR": "SET_ERROR",
    "GET_POLICY_TEMPLATES": "GET_POLICY_TEMPLATES"
};

const state = {
    data: {},
    images: {},
    policyTemplates: {},
    theme: []
};

const getters = {

};

const actions = {
    async getData({commit}, brand) {
        let result = await axios.get(`/brands/${brand}/editor/`);
        commit(types.GET_DATA, result.data);
        commit(types.SET_THEME, result.data.theme);
    },
    async saveDesignChanges({commit}, payload) {
        try {
            await axios.post(`/brands/${payload.brand}/editor`, payload.form);
        } catch (error) {
            commit(types.SET_ERROR, "Issue saving");
            throw error;
        }
    },
    async getPolicyRevisions({commit}, payload) {
        try {
            let policyTemplates = await policiesService.getPolicyRevisions(payload);
            commit(types.GET_POLICY_TEMPLATES, policyTemplates.data);
        } catch (error) {
            commit(types.SET_ERROR, "Issue loading");
            throw error;
        }
    },
    storeImages({commit}, payload) {
        try {
            commit(types.SET_IMAGES, payload);
        } catch (error) {
            commit(types.SET_ERROR, "Issue saving");
        }
    },
    updateTheme({commit}, theme) {
        commit(types.SET_THEME, theme);
    },
    postPolicyTemplate({ rootState }, template) {
        policiesService.postPolicyTemplate(rootState.auth.selectedBrand.name_url, template);
    }
};

const mutations = {
    [types.GET_DATA]: (state, data) => (state.data = data),
    [types.SET_IMAGES]: (state, images) => (state.images = images),
    [types.GET_POLICY_TEMPLATES]: (state, policyTemplates) => (state.policyTemplates = policyTemplates),
    [types.SET_THEME]: (state, theme) => (state.theme = theme)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
