import axios from "../axios";

export const getProviderRoles = () => axios.get("provider/roles");

export const getProviderRole = (roleId) => axios.get(`provider/roles/${roleId}`);

export const getProviderRolePermissions = (roleId) => axios.get(`provider/roles/${roleId}/permissions`);

export const getProviderRoleUsers = (roleId) => axios.get(`provider/roles/${roleId}/users`);

// provider
export const getBrandRoles = () => axios.get("provider/brands/roles");

export const createBrandRole = ({ name, description, permissionIds }) =>
    axios.post("provider/brands/roles", { name, description, permissionIds });

export const getBrandRole = (roleId) => axios.get(`provider/brands/roles/${roleId}`);

export const getBrandRolePermissions = (roleId) => axios.get(`provider/brands/roles/${roleId}/permissions`);

export const getBrandRoleUsers = (roleId) => axios.get(`provider/brands/roles/${roleId}/users`);

export const updateBrandRole = ({ id, name, description, permissionIds }) =>
    axios.patch(`provider/brands/roles/${id}`, { name, description, permissionIds });

export const deleteBrandRole = (roleId) => axios.delete(`provider/brands/roles/${roleId}`);
