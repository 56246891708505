export const types = {
    "TOGGLE_SIDEBAR": "TOGGLE_SIDEBAR"
};

const state = () => ({
    isCollapsedSideBar: false
});

const actions = {
    toggleSideBar({ commit }, value) {
        commit(types.TOGGLE_SIDEBAR, value);
    }
};


const mutations = {
    [types.TOGGLE_SIDEBAR](state, value) {
        state.isCollapsedSideBar = value;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
