<template>
    <div :class="classes">
        <font-awesome-icon
            class="mr-2"
            :icon="icon"
        />
        <div>{{ label }}</div>
    </div>
</template>

<script>
export default {
    name: "UserPasswordValidationItem",
    props: {
        value: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        regex: {
            type: RegExp,
            required: true
        },
        isInvalid: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isRegexPassed() {
            return this.regex.test(this.value);
        },
        classes() {
            const baseTextColor = this.isInvalid ? "text-danger" : "text-muted";
            return [ this.isRegexPassed ? "text-success" : baseTextColor, "d-flex align-items-center"];
        },
        icon() {
            return this.isInvalid && !this.isRegexPassed ? ["fal", "times-circle"] : ["fal", "check-circle"];
        }
    }
};
</script>

