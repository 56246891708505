import schemeService from "../../../services/scheme";

const types = {
    "SET_BATCH_TESTS": "SET_BATCH_TESTS"
};

const state = {
    batchTests: []
};

const actions = {
    uploadTestData({ rootState }, data) {
        return schemeService.uploadTestData(
            data,
            rootState.scheme.current.schemeId,
            rootState.auth.selectedBrand.name_url,
            rootState.scheme.current.revisionId
        );
    },
    async uploadBatchTestData({ rootState, dispatch }, { data, engine }) {
        await schemeService.uploadBatchTestData(
            data,
            engine,
            rootState.scheme.current.schemeId,
            rootState.auth.selectedBrand.name_url,
            rootState.scheme.current.revisionId
        );

        return dispatch("getBatchTests");
    },
    async getBatchTests({ rootState, commit, state }) {
        const res = await schemeService.getBatchTests(
            rootState.scheme.current.schemeId,
            rootState.auth.selectedBrand.name_url,
            rootState.scheme.current.revisionId
        );

        commit(types.SET_BATCH_TESTS, res);
        return state.batchTests;
    },
    getABatchTest({ rootState }, id) {
        return schemeService.getABatchTest(
            id,
            rootState.scheme.current.schemeId,
            rootState.auth.selectedBrand.name_url,
            rootState.scheme.current.revisionId
        );
    },
    async deleteABatchTest({ dispatch, rootState }, id) {
        await schemeService.deleteABatchTest(
            id,
            rootState.scheme.current.schemeId,
            rootState.auth.selectedBrand.name_url,
            rootState.scheme.current.revisionId
        );

        return dispatch("getBatchTests");
    },
    historicTest({ rootState }, data) {
        return schemeService.historicTest(
            data,
            rootState.scheme.current.schemeId,
            rootState.auth.selectedBrand.name_url,
            rootState.scheme.current.revisionId
        );
    }
};

const mutations = {
    [types.SET_BATCH_TESTS](state, data) {
        state.batchTests = data;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
