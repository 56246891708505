import metricsService from "../../services/dashboard-metrics";
import moment from "moment";
import Vue from "vue";
export const types = {
    SETUP: "SETUP",
    SET_LOADING: "SET_LOADING",
    SET_ERROR: "SET_ERROR",
    SET_QUOTES_CHART_DATA: "SET_QUOTES_CHART_DATA",
    SET_ERRORS_CHART_DATA: "SET_ERRORS_CHART_DATA",
    SET_REQUESTS_CHART_DATA: "SET_REQUESTS_CHART_DATA",
    SET_CONVERSION_CHART_DATA: "SET_CONVERSION_CHART_DATA",
    SET_REJECTS_CHART_DATA: "SET_REJECTS_CHART_DATA",
    SET_ENDPOINTS: "SET_ENDPOINTS",
    SET_POLICIES_SOLD: "SET_POLICIES_SOLD",
    SET_CANCELLATIONS: "SET_CANCELLATIONS",
    SET_INCOME: "SET_INCOME",
    SET_QUOTES_PERCENTAGE_DATA: "SET_QUOTES_PERCENTAGE_DATA",
    SET_ERRORS_PERCENTAGE_DATA: "SET_ERRORS_PERCENTAGE_DATA",
    SET_POLICIES_PERCENTAGE_DATA: "SET_POLICIES_PERCENTAGE_DATA",
    SET_REQUESTS_PERCENTAGE_DATA: "SET_REQUESTS_PERCENTAGE_DATA",
    SET_REJECTS_PERCENTAGE_DATA: "SET_REJECTS_PERCENTAGE_DATA",
    SET_CANCELLATIONS_PERCENTAGE_DATA: "SET_CANCELLATIONS_PERCENTAGE_DATA",
    SET_CONVERSIONS_PERCENTAGE_DATA: "SET_CONVERSIONS_PERCENTAGE_DATA",
    SET_INCOME_PERCENTAGE_DATA: "SET_INCOME_PERCENTAGE_DATA",
    SET_CUSTOM_RANGE: "SET_CUSTOM_RANGE",
    SET_CHART_DATA_ALL: "SET_CHART_DATA_ALL",
    SET_QUOTES_LOADING: "SET_QUOTES_LOADING",
    SET_CONVERSIONS_LOADING: "SET_CONVERSIONS_LOADING",
    SET_REQUESTS_LOADING: "SET_REQUESTS_LOADING",
    SET_ERRORS_LOADING: "SET_ERRORS_LOADING",
    SET_REJECTS_LOADING: "SET_REJECTS_LOADING",
    SET_CANCELLATIONS_LOADING: "SET_CANCELLATIONS_LOADING",
    SET_INCOME_LOADING: "SET_INCOME_LOADING"
    // SET_POLICIES_LOADING: "SET_POLICIES_LOADING"
};

const getters = {};

const metricTemplate = function() {
    return {
        quotesChartData: 0,
        errorsChartData: 0,
        requestsChartData: 0,
        conversionChartData: 0,
        rejectsChartData: 0,
        // policiesSold: 0,
        cancellations: 0,
        income: 0,
        quotesPercentageData: 0,
        errorsPercentageData: 0,
        // policiesPercentageData: 0,
        requestsPercentageData: 0,
        rejectsPercentageData: 0,
        cancellationsPercentageData: 0,
        conversionsPercentageData: 0,
        incomePercentageData: 0
    };
};

const state = {
    current: {},
    endpointsData: null,
    loading: false,
    error: false,
    customRange: 30,
    chartData: [0, 0, 0, 0, 0, 0],
    loadingQuotes: false,
    loadingConversions: false,
    loadingRequests: false,
    loadingErrors: false,
    loadingRejects: false,
    loadingCancellations: false,
    loadingIncome: false,
    loadingPolicies: false
};

const mutations = {
    [types.SET_LOADING](state, loading) {
        state.loading = loading;
    },
    [types.SET_ERROR](state, error) {
        state.error = error;
    },
    [types.SET_QUOTES_CHART_DATA](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }

        state.current[data.endpointID].quotesChartData = data.quotesChartData;
    },
    [types.SET_REQUESTS_CHART_DATA](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].requestsChartData = data.requestsChartData;
    },
    [types.SET_ERRORS_CHART_DATA](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].errorsChartData = data.errorsChartData;
    },
    [types.SET_CONVERSION_CHART_DATA](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].conversionChartData = data.conversionChartData;
    },
    [types.SET_REJECTS_CHART_DATA](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].rejectsChartData = data.rejectsChartData;
    },
    [types.SET_ENDPOINTS](state, endpointsData) {
        state.endpointsData = endpointsData;
    },
    // [types.SET_POLICIES_SOLD](state, data) {
    //     if (!state.current[data.endpointID]) {
    //         Vue.set(state.current, data.endpointID, metricTemplate());
    //     }
    //     state.current[data.endpointID].policiesSold = data.policiesSold;
    // },
    [types.SET_CANCELLATIONS](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].cancellations = data.cancellations;
    },
    [types.SET_INCOME](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].income = data.income;
    },
    [types.SET_QUOTES_PERCENTAGE_DATA](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].quotesPercentageData = data.quotesPercentageData;
    },
    [types.SET_ERRORS_PERCENTAGE_DATA](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].errorsPercentageData = data.errorsPercentageData;
    },
    // [types.SET_POLICIES_PERCENTAGE_DATA](state, data) {
    //     if (!state.current[data.endpointID]) {
    //         Vue.set(state.current, data.endpointID, metricTemplate());
    //     }
    //     state.current[data.endpointID].policiesPercentageData = data.policiesPercentageData;
    // },
    [types.SET_REQUESTS_PERCENTAGE_DATA](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].requestsPercentageData = data.requestsPercentageData;
    },
    [types.SET_REJECTS_PERCENTAGE_DATA](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].rejectsPercentageData = data.rejectsPercentageData;
    },
    [types.SET_CANCELLATIONS_PERCENTAGE_DATA](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].cancellationsPercentageData = data.cancellationsPercentageData;
    },
    [types.SET_CONVERSIONS_PERCENTAGE_DATA](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].conversionsPercentageData = data.conversionsPercentageData;
    },
    [types.SET_INCOME_PERCENTAGE_DATA](state, data) {
        if (!state.current[data.endpointID]) {
            Vue.set(state.current, data.endpointID, metricTemplate());
        }
        state.current[data.endpointID].incomePercentageData = data.incomePercentageData;
    },
    [types.SET_CUSTOM_RANGE](state, customRange) {
        state.customRange = customRange;
    },
    [types.SET_CHART_DATA_ALL](state, allChartData) {
        state.chartData = allChartData;
    },
    [types.SET_QUOTES_LOADING]: ((state, loading) => state.loadingQuotes = loading),
    [types.SET_CONVERSIONS_LOADING]: ((state, loading) => state.loadingConversions = loading),
    [types.SET_REQUESTS_LOADING]: ((state, loading) => state.loadingRequests = loading),
    [types.SET_ERRORS_LOADING]: ((state, loading) => state.loadingErrors = loading),
    [types.SET_REJECTS_LOADING]: ((state, loading) => state.loadingRejects = loading),
    [types.SET_CANCELLATIONS_LOADING]: ((state, loading) => state.loadingCancellations = loading),
    [types.SET_INCOME_LOADING]: ((state, loading) => state.loadingIncome = loading)
    // [types.SET_POLICIES_LOADING]: ((state, loading) => state.loadingPolicies = loading)
};

// SET_QUOTES_LOADING: "SET_QUOTES_LOADING",
//     SET_CONVERSIONS_LOADING: "SET_CONVERSIONS_LOADING",
//     SET_REQUESTS_LOADING: "SET_REQUESTS_LOADING",
//     SET_ERRORS_LOADING: "SET_ERRORS_LOADING",
//     SET_REJECTS_LOADING: "SET_REJECTS_LOADING",
//     SET_CANCELLATIONS_LOADING: "SET_CANCELLATIONS_LOADING"

const actions = {
    async runAll({ commit, rootState }, payload) {
        commit(types.SET_LOADING, true);
        commit(types.SET_ERROR, false);
        try {
            const chartData = await Promise.all([
                actions.getQuotesMetric({ commit, rootState }, payload),
                actions.getRequestsMetric({ commit, rootState }, payload),
                actions.getErrorsMetric({ commit, rootState }, payload),
                actions.getConversionMetric({ commit, rootState }, payload),
                actions.getRejectsMetric({ commit, rootState }, payload),
                actions.getCancellations({ commit, rootState }, payload),
                actions.getIncome({ commit, rootState }, payload)
            ]);
            const filteredData = chartData.filter(d => {
                return typeof d !== "undefined";
            });
            commit(types.SET_CHART_DATA_ALL, filteredData);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error.message);
        }

    },
    async getQuotesMetric({ commit, rootState }, payload) {
        try {
            commit(types.SET_QUOTES_LOADING, true);
            const quotesMetricRequest = await metricsService.getQuotesMetric(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                payload.from + "T" + payload.fromTime,
                payload.to + "T" + payload.toTime,
                payload.requester
            );

            const percentageToDate = moment(payload.from).subtract(1, "days").format("YYYY-MM-DD");
            const percentageFromDate = moment(percentageToDate).subtract(payload.range, "days").format("YYYY-MM-DD");
            
            const quotesPercentageRequest = await metricsService.getQuotesMetric(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                percentageFromDate + "T" + payload.fromTime,
                percentageToDate + "T" + payload.toTime,
                payload.requester
            );
            const quotesMetricData = parseInt(quotesMetricRequest.data[0].count);
            const percentageData = parseInt(quotesPercentageRequest.data[0].count);

            commit(types.SET_QUOTES_PERCENTAGE_DATA, {
                quotesPercentageData: percentageData,
                endpointID: payload.endpointID
            });
            commit(types.SET_QUOTES_CHART_DATA, {
                quotesChartData: quotesMetricData,
                endpointID: payload.endpointID
            });
            commit(types.SET_QUOTES_LOADING, false);
            return { label: "Quotes", data: quotesMetricData };
        } catch (err) {
            commit(types.SET_QUOTES_LOADING, false);
            if (err.response) {
                commit(types.SET_ERROR, err.response.data.message);
                throw new Error(err.message);
            } else {
                commit(types.SET_ERROR, "We have ran into an issue, please try again");
                throw new Error("We have ran into an issue, please try again");
            }
        }
    },
    async getRequestsMetric({ commit, rootState }, payload) {
        try {
            commit(types.SET_REQUESTS_LOADING, true);
            const requestsMetricRequest = await metricsService.getJobsMetric(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                payload.from + "T" + payload.fromTime,
                payload.to + "T" + payload.toTime,
                payload.requester
            );

            const percentageToDate = moment(payload.from).subtract(1, "days").format("YYYY-MM-DD");
            const percentageFromDate = moment(percentageToDate).subtract(payload.range, "days").format("YYYY-MM-DD");
            
            const requestsPercentageRequest = await metricsService.getJobsMetric(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                percentageFromDate + "T" + payload.fromTime,
                percentageToDate + "T" + payload.toTime,
                payload.requester
            );
            const percentageData = parseInt(requestsPercentageRequest.data[0].count);
            const requestsMetricData = parseInt(requestsMetricRequest.data[0].count);

            commit(types.SET_REQUESTS_PERCENTAGE_DATA, {
                requestsPercentageData: percentageData,
                endpointID: payload.endpointID
            });
            commit(types.SET_REQUESTS_CHART_DATA, {
                requestsChartData: requestsMetricData,
                endpointID: payload.endpointID
            });
            commit(types.SET_REQUESTS_LOADING, false);
            return { label: "Requests", data: requestsMetricData };
        } catch (err) {
            commit(types.SET_REQUESTS_LOADING, false);
            if (err.response) {
                commit(types.SET_ERROR, err.response.data.message);
                throw new Error(err.message);
            } else {
                commit(types.SET_ERROR, "We have ran into an issue, please try again");
                throw new Error("We have ran into an issue, please try again");
            }
        }
    },
    async getErrorsMetric({ commit, rootState }, payload) {
        try {
            commit(types.SET_ERRORS_LOADING, true);
            const errorsMetricRequest = await metricsService.getErrorsMetric(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                payload.from + "T" + payload.fromTime,
                payload.to + "T" + payload.toTime,
                payload.requester
            );

            const percentageToDate = moment(payload.from).subtract(1, "days").format("YYYY-MM-DD");
            const percentageFromDate = moment(percentageToDate).subtract(payload.range, "days").format("YYYY-MM-DD");
            
            const errorsPercentageRequest = await metricsService.getErrorsMetric(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                percentageFromDate + "T" + payload.fromTime,
                percentageToDate + "T" + payload.toTime,
                payload.requester
            );
            const errorsMetricData = parseInt(errorsMetricRequest.data[0].count);
            const percentageData = parseInt(errorsPercentageRequest.data[0].count);

            commit(types.SET_ERRORS_PERCENTAGE_DATA, {
                errorsPercentageData: percentageData,
                endpointID: payload.endpointID
            });
            commit(types.SET_ERRORS_CHART_DATA, {
                errorsChartData: errorsMetricData,
                endpointID: payload.endpointID
            });
            commit(types.SET_ERRORS_LOADING, false);
            return { label: "Errors", data: errorsMetricData };
        } catch (err) {
            commit(types.SET_ERRORS_LOADING, false);
            if (err.response) {
                commit(types.SET_ERROR, err.response.data.message);
                throw new Error(err.message);
            } else {
                commit(types.SET_ERROR, "We have ran into an issue, please try again");
                throw new Error("We have ran into an issue, please try again");
            }
        }
    },
    async getConversionMetric({ commit, rootState }, payload) {
        try {
            commit(types.SET_CONVERSIONS_LOADING, true);
            const conversionsMetricRequest = await metricsService.getConversionsMetric(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                payload.from + "T" + payload.fromTime,
                payload.to + "T" + payload.toTime,
                payload.requester
            );

            const percentageToDate = moment(payload.from).subtract(1, "days").format("YYYY-MM-DD");
            const percentageFromDate = moment(percentageToDate).subtract(payload.range, "days").format("YYYY-MM-DD");
            
            const conversionsPercentageRequest = await metricsService.getConversionsMetric(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                percentageFromDate + "T" + payload.fromTime,
                percentageToDate + "T" + payload.toTime,
                payload.requester
            );

            const percentageData = parseInt(conversionsPercentageRequest.data[0].count);
            const conversionsMetricData = parseInt(conversionsMetricRequest.data[0].count);

            commit(types.SET_CONVERSIONS_PERCENTAGE_DATA, {
                conversionsPercentageData: percentageData,
                endpointID: payload.endpointID
            });
            commit(types.SET_CONVERSION_CHART_DATA, {
                conversionChartData: conversionsMetricData,
                endpointID: payload.endpointID
            });
            commit(types.SET_CONVERSIONS_LOADING, false);
            return { label: "Conversions", data: conversionsMetricData };
        } catch (err) {
            commit(types.SET_CONVERSIONS_LOADING, false);
            if (err.response) {
                commit(types.SET_ERROR, err.response.data.message);
                throw new Error(err.message);
            } else {
                commit(types.SET_ERROR, "We have ran into an issue, please try again");
                throw new Error("We have ran into an issue, please try again");
            }
        }
    },
    async getRejectsMetric({ commit, rootState }, payload) {
        try {
            commit(types.SET_REJECTS_LOADING, true);

            const rejectsMetricRequest = await metricsService.getRejectsMetric(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                payload.from + "T" + payload.fromTime,
                payload.to + "T" + payload.toTime,
                payload.requester
            );

            const percentageToDate = moment(payload.from).subtract(1, "days").format("YYYY-MM-DD");
            const percentageFromDate = moment(percentageToDate).subtract(payload.range, "days").format("YYYY-MM-DD");
            
            const rejectsPercentageRequest = await metricsService.getRejectsMetric(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                percentageFromDate + "T" + payload.fromTime,
                percentageToDate + "T" + payload.toTime,
                payload.requester
            );
            const percentageData = parseInt(rejectsPercentageRequest.data[0].count);
            const rejectsMetricData = parseInt(rejectsMetricRequest.data[0].count);
            commit(types.SET_REJECTS_PERCENTAGE_DATA, {
                rejectsPercentageData: percentageData,
                endpointID: payload.endpointID
            });
            commit(types.SET_REJECTS_CHART_DATA, {
                rejectsChartData: rejectsMetricData,
                endpointID: payload.endpointID
            });
            commit(types.SET_REJECTS_LOADING, false);
            return { label: "Rejects", data: rejectsMetricData };
        } catch (err) {
            commit(types.SET_REJECTS_LOADING, false);
            if (err.response) {
                commit(types.SET_ERROR, err.response.data.message);
                throw new Error(err.message);
            } else {
                commit(types.SET_ERROR, "We have ran into an issue, please try again");
                throw new Error("We have ran into an issue, please try again");
            }
        }
    },
    async getEndpoints({ commit, rootState }) {
        try {
            const endpointsRequest = await metricsService.getEndpoints(rootState.auth.selectedBrand.name_url);
            const endpointsData = endpointsRequest.data;
            commit(types.SET_ENDPOINTS, endpointsData);
            commit(types.SET_LOADING, false);
            return endpointsData;
        } catch (err) {
            commit(types.SET_LOADING, false);
            if (err.response) {
                commit(types.SET_ERROR, err.response.data.message);
                throw new Error(err.message);
            }
        }
    },
    async getCancellations({ commit, rootState }, payload) {
        try {
            commit(types.SET_CANCELLATIONS_LOADING, true);
            const cancellationsDataRequest = await metricsService.getCancellationsSummary(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                payload.from + "T" + payload.fromTime,
                payload.to + "T" + payload.toTime,
                payload.requester
            );

            const percentageToDate = moment(payload.from).subtract(1, "days").format("YYYY-MM-DD");
            const percentageFromDate = moment(percentageToDate).subtract(payload.range, "days").format("YYYY-MM-DD");
            
            const cancellationsPercentageRequest = await metricsService.getCancellationsSummary(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                percentageFromDate + "T" + payload.fromTime,
                percentageToDate + "T" + payload.toTime,
                payload.requester
            );
            const percentageData = parseInt(cancellationsPercentageRequest.data[0].count);
            const cancellations = parseInt(cancellationsDataRequest.data[0].count);
            commit(types.SET_CANCELLATIONS, {
                cancellations: cancellations,
                endpointID: payload.endpointID
            });
            commit(types.SET_CANCELLATIONS_PERCENTAGE_DATA, {
                cancellationsPercentageData: percentageData,
                endpointID: payload.endpointID
            });
            commit(types.SET_CANCELLATIONS_LOADING, false);
            return { label: "Cancellations", data: cancellations };
        } catch (err) {
            commit(types.SET_CANCELLATIONS_LOADING, false);
            if (err.response) {
                commit(types.SET_ERROR, err.response.data.message);
                throw new Error(err.message);
            } else {
                commit(types.SET_ERROR, "We have ran into an issue, please try again");
                throw new Error("We have ran into an issue, please try again");
            }
        }
    },
    async getIncome({ commit, rootState }, payload) {
        try {
            commit(types.SET_INCOME_LOADING, true);
            const incomeRequest = await metricsService.getIncomeSummary(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                payload.from + "T" + payload.fromTime,
                payload.to + "T" + payload.toTime,
                payload.requester
            );

            const percentageToDate = moment(payload.from).subtract(1, "days").format("YYYY-MM-DD");
            const percentageFromDate = moment(percentageToDate).subtract(payload.range, "days").format("YYYY-MM-DD");
            
            const incomePercentageRequest = await metricsService.getIncomeSummary(
                rootState.auth.selectedBrand.name_url,
                payload.endpointID,
                payload.unit,
                percentageFromDate + "T" + payload.fromTime,
                percentageToDate + "T" + payload.toTime,
                payload.requester
            );
            const percentageData = incomePercentageRequest.data;
            const percentage = percentageData.map(policy => {
                if (policy.total === null) {
                    policy.total = 0;
                } return parseInt(policy.total);
            }).reduce((a, c) => a + c);
            commit(types.SET_INCOME_PERCENTAGE_DATA, {
                incomePercentageData: percentage,
                endpointID: payload.endpointID
            });

            const income = incomeRequest.data;
            const incomeTotal = income.map(i => {
                if (i.total === null) {
                    i.total = 0;
                } return parseInt(i.total);
            }).reduce((a, c) => a + c);
            commit(types.SET_INCOME, {
                income: incomeTotal,
                endpointID: payload.endpointID
            });
            commit(types.SET_INCOME_LOADING, false);
        } catch (err) {
            commit(types.SET_INCOME_LOADING, false);
            if (err.response) {
                commit(types.SET_ERROR, err.response.data.message);
                throw new Error(err.message);
            } else {
                commit(types.SET_ERROR, "We have ran into an issue, please try again");
                throw new Error("We have ran into an issue, please try again");
            }
        }
    },
    setCustomRange({ commit }, range) {
        commit(types.SET_CUSTOM_RANGE, range);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
