import axios from "./axios";

export default {
    async getForm(formId, revisionId, brandURL, options) {
        let response = await axios.get(`brands/${brandURL}/forms/${formId}/revisions/${revisionId}`, options);
        return {
            formId: response.data.form_id,
            expressions: response.data.form,
            name: response.data.name + " " + (response.data.comment || ""),
            revisionId: response.data.id,
            interfaces: response.data.interfaces
        };
    },
    getForms(brandURL) {
        return axios.get(`/brands/${brandURL}/forms`);
    },
    async getFormRevisions(formId,brandURL,params) {
        const res = await axios.get(`/brands/${brandURL}/forms/${formId}/revisions`, {params});
        return res.data;

    },
    async getFormRevision(formId,revisionId,brandURL) {
        return axios.get(`/brands/${brandURL}/forms/${formId}/revisions/${revisionId}`);
    },
    postFormRevision(formId,revisionId,brandURL,{ revision, name, subtitle }){
        return axios.post(`/brands/${brandURL}/forms/${formId}/revisions/${revisionId}`,{ revision, name, subtitle });
    },
    postForm(brandURL,name,form,comment, tags, type, subtitle){
        return axios.post(`/brands/${brandURL}/forms`,{name,form,comment, tags, type, subtitle});
    },
    getTypes(brandURL) {
        return axios.get(`brands/${brandURL}/forms/types`);
    },
    async getCustomComponents(brandURL) {
        // TODO Get custom components from BD
        return await axios.get(`brands/${brandURL}/forms/customComponents`);
    },
    async patchFormName(brandURL, formId, name) {
        return axios.patch(`brands/${brandURL}/forms/${formId}`, { name });
    }
};
