const Token = require("./Token");
const TokenType = require("./TokenType");
/**
 * Lexer
 * @class
 * @classdesc Creates a lexer for a given input
 */
class Lexer {
    /**
   * Represents a lexer
   * @constructor
   * @param {string} input - The input to lex
   */
    constructor(input) {
        this.input = input;
        this.position = 0;
        this.readPosition = 0;
        this.ch = undefined;
        // custom keywords
        this.keywords = new Map([
            ["if", TokenType.IF],
            ["not", TokenType.NOT],
            ["and", TokenType.AND],
            ["or", TokenType.OR],
            ["true", TokenType.TRUE],
            ["false", TokenType.FALSE]
        ]);
        this.readChar();
    }

    /** Resets the position/read position
     * @param {Number} pos - the position to set
     */
    resetPosition(pos) {
        this.position = pos;
        this.readPosition = pos + 1;
        this.ch = this.input[this.position];
    }

    /** Look up an identifier to see if it is reserved
     * @param ident - the identifier to lookup
     */
    lookupIdent(ident) {
        const tok = this.keywords.get(ident);
        if (tok !== undefined) {
            return tok;
        }
        return TokenType.IDENT;
    }

    /**
   * Reads the next character in the input string
   * @return {string} - The next character
   */
    readChar() {
        if (this.readPosition >= this.input.length) {
            this.ch = undefined;
        } else {
            this.ch = this.input[this.readPosition];
        }
        this.position = this.readPosition;
        this.readPosition += 1;
    }
    /**
   * Peeks at the next character in the input string
   * @return {string} - The peeked character
   */
    peekChar() {
        if (this.readPosition >= this.input.length) {
            return undefined;
        } else {
            return this.input[this.readPosition];
        }
    }

    /**
   * Skips white space in the input string
   * @return {string} - The peeked character
   */
    skipWhiteSpace() {
        while (this.ch === " ") {
            this.readChar();
        }
    }

    /**
   * Reads the identifier from the input string
   * @return {string} - The identifier name
   */
    readIdentifier() {
        const position = this.position;
        while (this.isIndentifierCharacter(this.ch)) {
            this.readChar();
            let pos = this.position; // remember in case we need to go back
            if (this.ch === "." && this.isLetter(this.peekChar())) {
                this.readChar();
            } else if (this.ch === "[" && (this.isNumber(this.peekChar()) || this.peekChar() === "]")) {
                this.readChar();
                if (!this.isNumber(this.ch)) {
                    this.resetPosition(pos);
                    return this.input.slice(position, this.position);
                }
                while (this.isNumber(this.ch)) {
                    this.readChar();
                }
                if (this.ch !== "]") {
                    this.resetPosition(pos);
                    return this.input.slice(position, this.position);
                }
                this.readChar();

                if (this.ch === "." && this.isIndentifierCharacter(this.peekChar())) {
                    this.readChar();
                }
            }
        }
        return this.input.slice(position, this.position);
    }

    /**
   * Reads the date from the input string
   * @return {string} - The date
   */
    readDate() {
        let d = "";
        while (this.ch !== "#" && this.ch !== undefined) {
            d += this.ch;
            this.readChar();
        }

        if (/^\d{4}-\d{2}-\d{2}$/.test(d)) {
            return d;
        }

        return null;
    }

    /**
   * Reads a string from the input string
   * @return {string} - The identifier name
   */
    readString() {
        const position = this.position;
        while (this.ch !== "\"") {
            if (this.ch === undefined) {
                // we have reached the end before another quote
                return null;
            }
            this.readChar();
        }
        return this.input.slice(position, this.position);
    }

    /**
   * Checks to see if the passed in character is valid Identifier character e.g h12-abc is fine.
   * @return {bool} - Is the passed in character valid
   */
    isIndentifierCharacter(ch) {
        return (ch === "_" ||
            ch === "-" ||
            ch === "$" ||
            this.isLetter(ch) ||
            this.isNumber(ch)
        );
    }

    /**
   * Checks to see if the passed in character is valid character
   * @return {bool} - Is the passed in character valid
   */
    isLetter(ch) {
        // TODO - may need to also allow . for names such as travellers.age
        // TODO - may need to allow travellers[0].age
        return (
            (ch >= "a" && ch <= "z") ||
            (ch >= "A" && ch <= "Z")
        );
    }

    /**
   * Checks to see if the passed in character is valid number
   * @return {bool} - Is the passed in character a number
   */
    isNumber(ch) {
        return ch >= "0" && ch <= "9";
    }

    /**
   * Reads a number from the input string
   * @return {Number} - Return the number
   */
    readNumber() {
        let decimal = false;
        let position = this.position;
        while (this.isNumber(this.ch)) {
            this.readChar();
            if (this.ch === "." && !decimal && this.isNumber(this.peekChar())) {
                decimal = true;
                this.readChar();
            }
        }
        return this.input.slice(position, this.position);
    }

    /**
   * Returns the next token from the lexed string
   * @return {Token} - The next token
   */
    nextToken() {
        let token;
        let pos;

        this.skipWhiteSpace();
        switch (this.ch) {
            case "=":
                token = new Token(TokenType.EQ, this.ch, this.position);
                break;
            case "(":
                token = new Token(TokenType.LPAREN, this.ch, this.position);
                break;
            case ")":
                token = new Token(TokenType.RPAREN, this.ch, this.position);
                break;
            case ",":
                token = new Token(TokenType.COMMA, this.ch, this.position);
                break;
            case "+":
                token = new Token(TokenType.ADD, this.ch, this.position);
                break;
            case "-":
                token = new Token(TokenType.MINUS, this.ch, this.position);
                break;
            case "/":
                token = new Token(TokenType.DIVIDE, this.ch, this.position);
                break;
            case "*":
                token = new Token(TokenType.MULTIPLY, this.ch, this.position);
                break;
            case "[":
                token = new Token(TokenType.LSQR, this.ch, this.position);
                break;
            case "]":
                token = new Token(TokenType.RSQR, this.ch, this.position);
                break;
            case ">":
                if (this.peekChar() === "=") {
                    let ch = this.ch;
                    this.readChar();
                    let literal = ch + this.ch;
                    token = new Token(TokenType.GTE, literal);
                } else {
                    token = new Token(TokenType.GT, this.ch);
                }
                break;
            case "<":
                if (this.peekChar() === "=") {
                    let ch = this.ch;
                    this.readChar();
                    let literal = ch + this.ch;
                    token = new Token(TokenType.LTE, literal, this.position);
                } else if (this.peekChar() === ">") {
                    let ch = this.ch;
                    this.readChar();
                    let literal = ch + this.ch;
                    token = new Token(TokenType.NEQ, literal, this.position);
                } else {
                    token = new Token(TokenType.LT, this.ch, this.position);
                }
                break;
            case "#":
                pos = this.position;
                this.readChar(); // skip first "
                // eslint-disable-next-line
                const date = this.readDate();
                if (date === null) {
                    token = new Token(TokenType.HASH, "#", this.position);
                    this.resetPosition(pos);
                    break;
                }
                token = new Token(TokenType.DATE, date, this.position);
                break;
            case "\"":
                pos = this.position;
                this.readChar(); // skip first "
                // eslint-disable-next-line
                const str = this.readString();
                if (str === null) {
                    token = new Token(TokenType.QUOTES, "\"", this.position);
                    this.resetPosition(pos);
                    break;
                }
                token = new Token(TokenType.STRING, str, this.position);
                break;
            case undefined:
                token = new Token(TokenType.EOF, "", this.position);
                break;
            default:
                if (this.isLetter(this.ch)) {
                    // return so as not to advance the read char below.
                    const ident = this.readIdentifier();
                    const type = this.lookupIdent(ident);
                    return new Token(type, ident, this.position);
                } else if (this.isNumber(this.ch)) {
                    // return so as not to advance the read char below.
                    return new Token(TokenType.NUMBER, this.readNumber(), this.position);
                } else {
                    token = new Token(TokenType.ILLEGAL, this.ch, this.position);
                }
        }
        this.readChar();
        return token;
    }
}

module.exports = Lexer;
