<template>
    <div
        class="navigation-wrapper"
        :class="{collapsed: isCollapsedSideBar}"
    >
        <div
            class="navigation"
            role="listbox"
        >
            <SidebarNavigationItem
                v-for="(route, index) in filteredRoutes"
                :key="route.name + index"
                :to="route.to"
                :name="route.name"
                :icon="route.icon"
                :is-collapsed="isCollapsedSideBar"
                class="navigation-item"
            />
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import SidebarNavigationItem from "./SidebarNavigationItem";

const TEST_MODE_ROUTES = ["CRM", "Customers", "Test Mode"];

export default {
    name: "SidebarNavigation",
    components: {
        SidebarNavigationItem
    },
    props: {
        routes: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapState({
            provider: (state) => state.auth.provider,
            selectedBrand: (state) => state.auth.selectedBrand,
            isCollapsedSideBar: (state) => state.application.isCollapsedSideBar
        }),
        testMode() {
            return this.$store.state.testMode.testing;
        },
        filteredRoutes() {
            return this.routes.filter(route => 
                route.show && (!this.testMode 
                    || TEST_MODE_ROUTES.includes(route.name)));
        }
    },
    watch: {
        "$route": {
            handler() {
                // Hide all tooltips in the sidebar after changing a route
                this.$root.$emit("bv::hide::tooltip");
            }
        }
    }
};
</script>

<style lang="sass" scoped>
.navigation-wrapper
    height: 100%
    overflow-y: auto
    overflow-x: hidden
    margin: 5px 0
    &::-webkit-scrollbar
        display: none
        width: 12px
        height: 12px
    &::-webkit-scrollbar-track
        background: darken($headerBg, 10%)
    &::-webkit-scrollbar-thumb
        min-height: 20px
        background: lighten($headerBg, 10%)
        transition: all 0.5s ease
        border: 4px solid rgba(0, 0, 0, 0)
        background-clip: padding-box
        border-radius: 9999px
    &::-webkit-scrollbar-thumb:hover
        background: lighten($headerBg, 20%)
        transition: all 0.5s ease
        border: 4px solid rgba(0, 0, 0, 0)
        background-clip: padding-box
        border-radius: 9999px
    &:hover:not(.collapsed)
        &::-webkit-scrollbar
            display: block
    &.collapsed
        &::-webkit-scrollbar
            display: none
    .navigation
        display: flex
        flex-flow: column
        gap: 3px
        width: 100%
</style>
