import {isNotEmpty,isValidIdentifier,isPropertyArrayOfStrings, isExpressionDuplicate} from "./ExpressionValidators";
import randomKey from "random-key";


//TODO implement save - ready for the DB

export default class Expression {
    constructor(id,properties){
        //to save running validation everytime
        //validate is called, we keep track of the expression
        // if we have alreadt run validation and we have
        //not edited, we do not need to revalidate.
        this.properties = {
            id:{
                value: id,
                isValid:null,
                errors:[],
                validators:[
                    isNotEmpty(),
                    isValidIdentifier(),
                    isExpressionDuplicate()
                ]
            },
            _expId:{
                value: randomKey.generate(5),
                isValid:null,
                errors:[],
                validators:[]
            },
            description:{
                value: "",
                isValid:null,
                errors:[],
                validators:[]
            },
            returns:{  //value holds the type opject
                value: null,
                isValid:null,
                validators:[],
                get type(){
                    return this.value.type ? this.value.type : null;
                }  //getter to stop having to go into values object e.g returns{type:number}
            },
            tags:{
                value: "",
                isValid:null,
                errors:[],
                validators:[isPropertyArrayOfStrings()]
            },
            ...properties
        };
        this.createGettersAndSetters();
        this.validateProperties();
    }
    createGettersAndSetters(){
        Object.keys(this.properties).forEach((key)=>{
            Object.defineProperty(this, key, {
                get: function() {
                    return this.properties[key].value;
                },
                set: function(val) {
                    this.properties[key].value = val;
                    this.properties[key].dirty = true;
                    this.validateProperties();
                },
                configurable: true
            });
        });
    }
    isValid(property=null){
        if(property){
            return this.properties[property].isValid;
        }else{
            return Object.keys(this.properties).every(key=>this.properties[key].isValid);
        }
    }
    validateProperties(){
        Object.keys(this.properties).forEach(key=>this.validateProperty(key));
    }
    //TO allow us to auto validate the table, we could pass the whole object to validator
    //as we need access to count the cells
    validateProperty(property){
        let p = this.properties[property];
        p.errors = [];
        for (let validatorIndex = 0; validatorIndex < p.validators.length; validatorIndex++) {
            const validator = p.validators[validatorIndex];
            let valid = validator.validate(p.value,this);
            if(!valid){
                p.errors.push(validator.message(property, this));
                p.isValid = false;
                return;
            }

        }
        p.isValid = true;
    }

    allErrors(property=null){
        if (property===null){
            let errors =  Object.keys(this.properties).map((key)=>this.properties[key].errors).flat();
            return errors;
        }
        return this.properties[property].errors;
    }
    firstError(field=null){
        return this.allErrors(field)[0];
    }

    /**
     * @function clean
     * @description passes this expression to cleanExpression
     * @returns {object} cleanded expression
     */
    clean() {
        return this.constructor.cleanExpression(this);
    }

    /**
     * @function cleanExpression
     * @description abstract-ish method to force child classes to implement a cleanExpression function
     * @throws
     */
    static cleanExpression(exp) {
        throw new Error(`cleanExpression is not implemented for: ${exp.expressionType}`);
    }
    // adds has moved as a method across all expressions and sets
    // a default. The has moved method triggers a "reload" of the state
    // this is necessary to allow instant validation feedback
    // for the isExpressionInScope validator.
    hasMoved() {
        // eslint-disable-next-line no-self-assign
        this.validateProperty("id");
    }
    static getPropertyIdentifiers(expression, identifier = "") {
        let identifiers = [];
        if (expression.id) {
            if(identifier===""){
                identifier = expression.id;
            }else{
                identifier = identifier + "." + expression.id;
            }
            identifiers.push(identifier);
        }
        if (expression.element) {
            identifier = identifier + "[]";
            identifiers.push(identifier);
            return [...identifiers, ...this.getPropertyIdentifiers(expression.element, identifier)];
        } else if (expression.returns) {
            return [...identifiers, ...this.getPropertyIdentifiers(expression.returns, identifier)];
        } else if (expression.properties) {
            for (let index = 0; index < expression.properties.length; index++) {
                identifiers = [
                    ...identifiers,
                    ...this.getPropertyIdentifiers(expression.properties[index], identifier)];
            }
            return identifiers;
        } else {
            return identifiers;
        }
    }

}
