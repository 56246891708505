<template>
    <div>
        <p>
            <small>
                Please setup your two factor authentication.
                You will need an Authenticator on your mobile device. Enter your code to confirm setup.
            </small>
        </p>
        <div class="clear" />
        <img
            :src="image"
            class="qr-code"
        >
        <div class="form-group">
            <label
                for="inputCode"
                class="sr-only"
            >Password</label>
            <input
                id="inputCode"
                v-model="code"
                class="form-control"
                data-lpignore="true"
                placeholder="code"
                required
                @keyup.enter="handleCode"
            >
            <font-awesome-icon
                :icon="['fal', 'lock-alt']"
            />
        </div>
        <button
            class="btn-login"
            type="button"
            @click="handleCode"
        >
            Continue
        </button>
        <div
            v-if="error"
            class="error"
        >
            {{ error }}
        </div>
        <router-link
            to="forgotPassword"
            class="resetPassword"
            @click.native="$emit('forgot')"
        >
            Forgotten Password
        </router-link>
    </div>
</template>

<script>
import store from "../../../store/index";

async function getTwoFactorSetup(to,from,next){
    try{
        await store.dispatch("auth/loginSecondFactorSetup");
        return next();
    }catch{
        return next({name:"error"});
    }
}

export default {
    beforeRouteEnter(to,from,next){
        getTwoFactorSetup(to,from,next);
    },
    beforeRouteUpdate(to,from,next){
        getTwoFactorSetup(to,from,next);
    },
    data(){
        return {
            error: null,
            code: null,
            loading: true
        };
    },
    computed:{
        secret(){
            return this.$store.state.auth.twoFactorSecret;
        },
        image(){
            return this.$store.state.auth.twoFactorImage;
        }
    },
    async created() {
        if (!this.image) {
            try {
                await store.dispatch("auth/loginSecondFactorSetup");
            } catch (err) {
                this.error = err.message;
            }
        }
        this.loading = false;
    },
    methods:{
        handleCode(e){
            e.preventDefault();
            this.loading = true;
            this.$store.dispatch("auth/loginSecondFactor",{code:this.code}).then(()=>{
                this.loading = false;
                this.$emit("loggedIn");
            }).catch((err)=>{
                this.loading = false;
                this.error = err.message;
            });
        }

    }
};
</script>
