import jwt from "jsonwebtoken";
import axios from "./axios";
import store from "../store";
import * as Sentry from "@sentry/browser";

/**
 * Creates a JWT token for the given credentials
 *
 * @param {Object} payload The payload to include in the tokem
 * @param {String} secret The secret used to encrypt the token
 * @param {Number} expiresIn How many hours until the token should expire
 */
function createJWT(payload, secret, expiresIn = null) {
    return jwt.sign(
        payload,
        secret,
        expiresIn && { expiresIn: expiresIn + "h" }
    );
}

export default {
    get baseURL() {
        return store.state.env.vars.apiUrl;
    },
    getDocuments(brandId) {
        return axios.get(`brands/${brandId}/documents`);
    },
    sendDocuments(payload) {
        const brandId = payload.get("brandId");
        payload.delete("brandId");
        return axios.post(`brands/${brandId}/documents`, payload);
    },
    authenticateCustomer({token, postcode, provider, brand}) {
        return axios.post(
            `customer-documents/auth/${provider}/${brand}/${token}?postcode=${postcode}`
        );
    },
    getDocument({ token, postcode, provider, brand , format}) {
        return axios.get(
            `customer-documents/${provider}/${brand}/${token}/generate?postcode=${postcode}&format=${format}`
        );
    },
    requestPrintSend({token, postcode, provider, brand}) {
        return axios.patch(`customer-documents/${provider}/${brand}/${token}?type=printsend`, { postcode });
    },
    reissue(policyId, brandId, data) {
        return axios.post(`brands/${brandId}/policies/${policyId}/senddocs`, 
            data
        );
    },
    setPrintSent(policyID, brandId) {
        return axios.patch(`brands/${brandId}/brand-documents/policy?type=setPrintSent`, { policyID: policyID });
    },
    downloadPolicyDocument({ policyId, postcode, provider, brand, format }) {
        try {
            const documentSecretKey = store.state.env.vars.documentSecretKey;            
            const documentKey =  provider + brand + documentSecretKey;
            const secret = createJWT({
                policyReference: policyId,
                postcode,
                format
            }, documentKey);
            // eslint-disable-next-line max-len
            window.open(`${this.baseURL}/customer-documents/${provider}/${brand}/${secret}/generate`);                             
            
        } catch (err) {
            Sentry.captureException(new Error("Unable to send policy documents", { cause: err }));
        }    
    },
    downloadAdditionalDocuments(url) {
        window.open(`${this.baseURL}/files/${url}`);
    },
    downloadMedicalDocument({ policyId, postcode, provider, brand, traveller }) {
        try {                
            const documentSecretKey = store.state.env.vars.documentSecretKey;            
            const documentKey =  provider + brand + documentSecretKey;            
            const secret = createJWT({
                policyReference: policyId,
                postcode
            }, documentKey);
            // eslint-disable-next-line max-len
            window.open(`${this.baseURL}/customer-documents/${provider}/${brand}/${secret}/medical?traveller=${traveller}`);
            
        } catch (err) {
            Sentry.captureException(new Error("Unable to send policy documents", { cause: err }));
        }    
    }
};
