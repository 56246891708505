import * as rolesService from "../../../../services/provider/roles";
import * as permissionsService from "../../../../services/provider/permissions";

const populateGrantedField = (allPermissions, rolePermissions) => {
    const rolePermissionsMap = new Map();

    rolePermissions.forEach(permission => rolePermissionsMap.set(permission.pid, true));

    return allPermissions.map(permission => {
        permission.granted = rolePermissionsMap.get(permission.id) || false;
        return permission;
    });
};

export const types = {
    "SET_ROLE": "SET_ROLE",
    "SET_PERMISSIONS": "SET_PERMISSIONS",
    "SET_ROLE_USERS": "SET_ROLE_USERS",
    "UPDATE_ROLE_FIELD": "UPDATE_ROLE_FIELD",
    "UPDATE_PERMISSIONS": "UPDATE_PERMISSIONS",
    "RESET_FORM_STATE": "RESET_FORM_STATE"
};

const state = {
    role: null,
    permissions: [],
    roleUsers: [],
    isFormDirty: false
};

const actions = {
    async getProviderRoleDetails({ commit, rootState }, roleId) {
        const role = await rolesService.getProviderRole(roleId);
        const { data } = await rolesService.getProviderRolePermissions(roleId);

        const allProviderPermissions = rootState.provider.permissions.providerPermissions;
        const rolePermissions = populateGrantedField(allProviderPermissions, data);

        commit(types.SET_ROLE, role.data);
        commit(types.SET_PERMISSIONS, rolePermissions);
    },
    async getProviderRoleUsers({ commit }, roleId) {
        const { data } = await rolesService.getProviderRoleUsers(roleId);
        commit(types.SET_ROLE_USERS, data);
    },
    async getBrandRoleDetails({ commit, rootState }, roleId) {
        const role = await rolesService.getBrandRole(roleId);
        const { data } = await rolesService.getBrandRolePermissions(roleId);

        const allBrandPermissions = rootState.provider.permissions.brandPermissions;
        const rolePermissions = populateGrantedField(allBrandPermissions, data);

        commit(types.SET_ROLE, role.data);
        commit(types.SET_PERMISSIONS, rolePermissions);
    },
    async getBrandRoleUsers({ commit }, roleId) {
        const { data } = await rolesService.getBrandRoleUsers(roleId);
        commit(types.SET_ROLE_USERS, data);
    },
    async prepareBrandRoleData({ commit }) {
        const { data } = await permissionsService.getBrandPermissions();
        data.forEach(permission => permission.granted = false);

        commit(types.SET_ROLE, {
            name: "",
            description: ""
        });
        commit(types.SET_PERMISSIONS, data);
    },
    updateRoleField({ commit }, payload) {
        commit(types.UPDATE_ROLE_FIELD, payload);
        commit(types.RESET_FORM_STATE, true);
    },
    updatePermissions({ commit }, payload) {
        commit(types.UPDATE_PERMISSIONS, payload);
        commit(types.RESET_FORM_STATE, true);
    },
    resetFormState({ commit }, value) {
        commit(types.RESET_FORM_STATE, value);
    }
};

const mutations = {
    [types.SET_ROLE]: (state, role) => (state.role = role),
    [types.SET_ROLE_USERS]: (state, users) => (state.roleUsers = users),
    [types.SET_PERMISSIONS]: (state, permissions) => (state.permissions = permissions),
    [types.RESET_FORM_STATE]: (state, value) => (state.isFormDirty = value),
    [types.UPDATE_ROLE_FIELD](state, { field, value }) {
        state.role[field] = value;
    },
    [types.UPDATE_PERMISSIONS](state, permissions) {
        state.permissions = permissions;
    }
};

const getters = {
    groupedRoleUsers: (state) => {
        return state.roleUsers.reduce((acc, curr) => {
            const categoryIndex = acc.findIndex((item) => item.id === curr.brand_id);

            if (categoryIndex === -1) {
                acc.push({
                    id: curr.brand_id,
                    title: curr.brand_name,
                    users: [curr]
                });
            } else {
                acc[categoryIndex].users.push(curr);
            }

            return acc;
        }, []);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
