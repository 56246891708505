import { render, staticRenderFns } from "./BaseConfirmDelete.vue?vue&type=template&id=2f8e03f6&"
import script from "./BaseConfirmDelete.vue?vue&type=script&lang=js&"
export * from "./BaseConfirmDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports