import axios from "./axios";
import vanillaAxios from "axios";

export default {
    async getQuoteData(payload) {
        const type = payload.type ? payload.type : "new";
        let result = await axios.get(
            `/payments/${payload.provider}/${payload.quoteNumber}?qr=${payload.quoteReference}&type=${type}`
        );
        let data = result.data.data;
        data["quote_number"] = result.data["quote_number"];
        data.productInfo = {1: "2"};
        data.documents = {};
        data.contactInfo = {};
        data["client_key"] = result.data["client_key"];
        for (let d in data) {

            if (/policy_wording|ipid|tob/.test(d)) {
                data.documents[d] = data[d];
            }

            if (/firstname|surname/.test(d)) {
                data.travellers[0][d] = data[d];
            }

            if (/email/.test(d)) {
                data.contactInfo[d] = data[d];
            }
        }
        return data;    
    },
    async findAddress(postcode) {
        let res = await vanillaAxios(`
        https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?key=PG34-DY99-CE29-PJ84&text=${postcode}
        `);
        if (!res.data.Items[0].Id) {
            throw "Cannot find address info, please try entering address manually";
        }

        /* eslint-disable */
        let addresses = await vanillaAxios(`
        https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?key=PG34-DY99-CE29-PJ84&container=
        ${res.data.Items[0].Id}
        `);
        if (addresses.data.Items[0].Error) {
            throw "Cannot find address info, please try entering address manually";
        }
        /* eslint-enable */
        return addresses.data;
    },
    async getSelectedAddress(payload) {
        /* eslint-disable-next-line */
        let res = await vanillaAxios(`https://api.addressy.com/Capture/Interactive/Retrieve/v1.00/json3.ws?Key=PG34-DY99-CE29-PJ84&Id=${payload.id}`);
        return res.data;
    },
    async processPayment(payload) {
        let result = await axios.post("/payments/", payload);
        return result.data;
    },
    async process3DSecurePayment(payload) {
        let result = await axios.post("/payments/secure", payload);
        return result.data;
    },
    poll3DSecurePayment(payload) {
        return axios.get(`/payments/polling?quote_number=${payload.quoteNum}&provider=${payload.provider}`);
    },
    async fetchEndpointSettings(payload) {
        return axios.get(`/payments/${payload.provider}/${payload.brand}/${payload.endpoint}/settings`);
    }
};
