import axios from "./axios";

export default {
    getMyComponents(brandURL) {
        return axios.get(`brands/${brandURL}/components`);
    },
    async saveMyComponent(brandURL, payload) {
        return await axios.post(`brands/${brandURL}/components`, payload);
    },
    async removeMyComponent(brandURL, id) {
        return await axios.delete(`brands/${brandURL}/components/${id}`);
    }
};
