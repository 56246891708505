import axios from "../axios";

const getBrands = () => axios.get("provider/brands");

export const getUsers = () => axios.get("provider/users");

export const getUserById = (userId) => axios.get(`provider/users/${userId}`);

export const createUser = (payload) => axios.post("provider/users", payload);

export const getUserBrandAccess = (userId) => axios.get(`provider/users/${userId}/brand-access`);

const getRoles = () => axios.get("providers/roles");

const getAllPermissions = () => axios.get("providers/permissions");

const createRole = ({ name, description, permissions }) =>
    axios.post("providers/roles", { name, description, permissions });

export const updateUserBrandAccess = ({ userId, brands }) =>
    axios.put(`provider/users/${userId}/brand-access`, { brands });

export const addUserBrandAccess = ({ userId, brands }) =>
    axios.post(`provider/users/${userId}/brand-access`, { brands });

export const getUserProviderAccess = (userId) => axios.get(`provider/users/${userId}/provider-access`);

export const updateUserProviderAccess = ({ userId, roles }) =>
    axios.put(`provider/users/${userId}/provider-access`, { roles });

export const addUserProviderAccess = ({ userId, roles }) =>
    axios.post(`provider/users/${userId}/provider-access`, { roles });

const saveNewRole = ({ name, description, permissions }) =>
    axios.post("providers/roles", { name, description, permissions });

const getRolePermissions = (roleId) => axios.get(`providers/roles/${roleId}/permissions`);

const getRoleUsers = (roleId) => axios.get(`providers/roles/${roleId}/users`);

export const updateUserLockedStatus = ({ userId, locked }) =>
    axios.patch(`provider/users/${userId}/locked`, { locked });

export const updateUser = ({ userId, ...payload }) =>
    axios.patch(`provider/users/${userId}`, payload);

const getRoleDetails = (roleId) => axios.get(`providers/roles/${roleId}`);

const updateRole = ({ roleId, name, description, permissions}) =>
    axios.patch(`providers/roles/${roleId}`, { name, description, permissions });

const getUserAccess = (userId) => axios.get(`provider/users/${userId}/access`);

export const getUserConditions = (userId) => axios.get(`provider/users/${userId}/conditions`);

export const addUserConditions = ({ userId, conditions }) =>
    axios.post(`provider/users/${userId}/conditions`, { conditions });

export const updateUserConditions = ({ userId, conditions }) =>
    axios.put(`provider/users/${userId}/conditions`, { conditions });

export default {
    getUsers,
    getUserById,
    getUserAccess,
    getRoleDetails,
    updateRole,
    saveNewRole,
    getBrands,
    getRoles,
    getAllPermissions,
    createRole,
    getRolePermissions,
    getRoleUsers

};
