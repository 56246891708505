<template>
    <div
        ref="tags"
        :class="[$style.wrapper, {[$style.disabled]: disabled}, {[$style.isInvalid]: isInvalid}]"
        @click="showDropdown"
    >
        <div :class="$style.tagsWrapper">
            <slot
                name="default"
                v-bind="scope"
            >
                <slot
                    name="selection"
                    v-bind="scope"
                >
                    <div>{{ tags.join(', ') }}</div>
                </slot>
                <div :class="$style.dropdownIcon">
                    <font-awesome-icon
                        size="lg"
                        :icon="isDropdownShow ? ['fal', 'angle-up'] : ['fal', 'angle-down']"
                    />
                </div>
                <div
                    v-if="isDropdownShow"
                    ref="dropdown"
                    v-outside-click="{
                        handler: hideDropdown,
                        include: includeNodesForOutsideClick,
                        rootEl: 'body'
                    }"
                    :class="[$style.dropdown]"
                    :style="dropdownStyles"
                >
                    <slot
                        name="prepend-dropdown-items"
                    />
                    <div
                        v-if="items.length"
                        ref="options"
                    >
                        <div
                            v-for="(option, index) in items"
                            :key="index"
                            :title="objectItems ? option[labelField] : option"
                            :class="[
                                $style.dropdownListItem,
                                {[$style.active]: isItemActive(option)},
                            ]"
                            @click.stop="selectItem(option)"
                        >
                            <slot
                                name="dropdown-item"
                                v-bind="{ item: option, isActive: isItemActive(option) }"
                            >
                                <div :class="$style.dropdownListTitle">
                                    <span v-if="objectItems">{{ option[labelField] }}</span>
                                    <span v-else>{{ option }}</span>
                                </div>
                                <div
                                    v-if="isItemActive(option)"
                                    :class="$style.dropdownListIcon"
                                >
                                    <font-awesome-icon
                                        :icon="['fal', 'trash']"
                                    />
                                </div>
                            </slot>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
    name: "BaseMultiselect",
    inheritAttrs: false,
    model: {
        prop: "value",
        event: "input"
    },
    props: {
        value: {
            type: Array,
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        valueField: {
            type: String,
            default: "value"
        },
        labelField: {
            type: String,
            default: "label"
        },
        objectItems: {
            type: Boolean,
            default: false
        },
        showAutocomplete: {
            type: Boolean,
            default: false
        },
        showError: {
            type: Boolean,
            default: false
        },
        closeOnSelect: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "Enter a new value"
        },
        height: {
            type: [String, Number],
            default: ""
        },
        width: {
            type: [String, Number],
            default: ""
        },
        maxHeight: {
            type: [String, Number],
            default: ""
        },
        maxWidth: {
            type: [String, Number],
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isInvalid: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tags: [...this.value],
            isDropdownShow: false
        };
    },
    computed: {
        dropdownStyles() {
            return {
                width: `${this.width}px`,
                height: `${this.height}px`,
                maxWidth: `${this.maxWidth}px`,
                maxHeight: `${this.maxHeight}px`
            };
        },
        scope() {
            const { tags, removeItem, addItem } = this;
            return {
                tags,
                removeItem,
                addItem
            };
        }
    },
    watch: {
        isDropdownShow(value) {
            if (value) {
                this.$nextTick(() => {
                    this.popper = createPopper(this.$el, this.$refs.dropdown, {
                        placement: "bottom-start",
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, 2]
                                }
                            }
                        ]
                    });
                });
            } else {
                this.popper.destroy();
                this.popper = null;
            }

            this.$emit("update:dropdown", value);
        },
        tags() {
            this.popper?.update();
        },
        value(newValues) {
            this.tags = [...newValues];
        }
    },
    methods: {
        filterItem(item, field) {
            const filteredItem = this.objectItems ? item?.[field] : item;
            return filteredItem?.toString().toLowerCase();
        },
        showDropdown() {
            if (this.items && this.items.length && !this.isDropdownShow) {
                this.isDropdownShow = true;
            }
        },
        hideDropdown() {
            this.isDropdownShow = false;
        },
        includeNodesForOutsideClick() {
            return [this.$refs.tags];
        },
        selectItem(item, isClose) {
            if (this.isItemActive(item)) {
                this.removeItem(item);
            } else {
                this.addItem(item);
            }

            if (this.closeOnSelect || isClose) {
                this.hideDropdown();
            }
        },
        isItemActive(item) {
            const isActive = this.objectItems 
                ? this.tags.some(tag => {
                    return this.filterItem(tag, this.valueField) === this.filterItem(item, this.valueField);
                })
                : this.tags.some(tag => tag === item);
                
            return isActive;
        },
        addItem(item)  {
            if (item && !this.objectItems && !this.tags.includes(item.trim())) {
                const trimItem = typeof item === "string" ? item.trim() : item;
                this.tags.push(trimItem);
            } else if (this.objectItems) {
                this.tags.push(item);
            }
            this.emitActions(this.tags);
        },
        removeItem(item) {
            const tagIndex = this.tags.findIndex(tag => {
                if (this.objectItems) {
                    return tag[this.valueField] === item[this.valueField];
                } else {
                    return tag === item;
                }
            });
            this.tags.splice(tagIndex, 1);
            this.emitActions(this.tags);
        },
        emitActions(value) {
            this.$emit("input", value);
            this.$emit("change", value);
        }
    }
};
</script>

<style lang="sass" module>
.wrapper
    position: relative
    display: flex
    flex-flow: row wrap
    gap: 10px
    height: auto
    padding: 10px
    background: #ffffff
    border: 1px solid #ced4da
    &.disabled
        background-color: #f6f6f6
        opacity: 0.6
        pointer-events: none
.tagsWrapper
    display: flex
    align-items: center
    gap: 10px
    width: 100%
.dropdown
    position: absolute
    top: calc(100% + 2px)
    left: 0
    width: 100%
    min-width: 150px
    max-width: 100%
    overflow-y: auto
    box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.3)
    background-color: #ffffff
    overscroll-behavior: contain
    z-index: 11
    &::-webkit-scrollbar
        width: 15px
    &::-webkit-scrollbar-track
        background: #ffffff
    &::-webkit-scrollbar-thumb
        min-height: 40px
        background: #cccccc
        transition: all 0.5s ease
        border: 4px solid rgba(0, 0, 0, 0)
        background-clip: padding-box
        border-radius: 9999px
    &::-webkit-scrollbar-thumb:hover
        background: #7a7a7a
        transition: all 0.5s ease
        border: 4px solid rgba(0, 0, 0, 0)
        background-clip: padding-box
        border-radius: 9999px
    &.grouped
        .dropdownListItem
            padding-left: 2em
.dropdownListItem
    display: flex
    align-items: center
    padding: 6px 15px
    cursor: pointer
    &:hover
        background-color: #e8e0f3
    &:hover
        .dropdownListIcon
            opacity: 1
    &.highlighted
        background-color: #e3e3e3
        color: #000000
.dropdownListTitle
    user-select: none
    white-space: nowrap
    word-wrap: break-word
    overflow: hidden
    text-overflow: ellipsis
.dropdownListItemTitle
    font-weight: bold
    padding: 0.5em 1em
.dropdownListIcon
    margin-left: auto
    opacity: 0
.warningBound
    width: 100%
.dropdownIcon
    position: absolute
    right: 0.25rem
    top: 50%
    transform: translateY(-50%)
    width: 1.5rem
    text-align: center
    pointer-events: none
.active
    background: #e8e0f3
    color: darken($base, 35%)  
.isInvalid
    border-color: #dc3545 
</style>
