import {
    isNotEmpty,
    isBoolean,
    isNumeric,
    isValidIdentifier,
    isExpressionIdPresent,
    isExpressionInScope,
    isExpressionReturning,
    orValidate
} from "./ExpressionValidators";
import Expression from "./Expression";

/**
 * @class VeriskExpression
 * @extends Expression
 * @property {String} url - the url of the verisk service
 * @property {String} key - the auth key for accessing the verisk service
 * @property {String} region - the name of the expression containing the region
 * @property {Boolean} amt - is this an amt quote
 * @property {Boolean} winterSport - are we screening verisk with winter sports
 * @property {Boolean} pregnancy - are we screening verisk with pregnancy
 * @property {Boolean} pregnancyComplications - are we screening verisk with pregnancy complications
 * @property {Boolean} corona - are we screening verisk with COVID-19
 * @property {Number} linkedConditions - what level of linked conditions are we using for screening
 * @property {Boolean} rescore - always rescore
 * @property {Boolean} rescoreOnDifference - rescore if the verisk settings are different
 * @property {Boolean} test - is this a test
 * @property {String} expressionType="verisktwo" - expression type
 * @property {String} verisk - the name of the expression containing the verisk data
 *
 * @returns {VeriskExpression}
 */
export default class VeriskExpression extends Expression {
    constructor(id) {
        const properties = {
            url: {
                value: "",
                isValid: null,
                errors: [],
                validators: [ isNotEmpty() ]
            },
            key: {
                value: "",
                isValid: null,
                errors: [],
                validators: [ isNotEmpty() ]
            },
            region: {
                value: "",
                isValid: null,
                errors: [],
                validators: [
                    isNotEmpty(),
                    isValidIdentifier(),
                    isExpressionIdPresent(),
                    isExpressionInScope()
                ]
            },
            amt: {
                value: "",
                isValid: null,
                errors: [],
                validators: [
                    isNotEmpty(),
                    orValidate(
                        [
                            isBoolean()
                        ],
                        [
                            isValidIdentifier(),
                            isExpressionIdPresent(),
                            isExpressionInScope(),
                            isExpressionReturning("boolean")
                        ],
                        "AMT must be yes or no, or an expression id which returns yes or no"
                    )
                ]
            },
            winterSports: {
                value: "",
                isValid: null,
                errors: [],
                validators: [
                    isNotEmpty(),
                    orValidate(
                        [
                            isBoolean()
                        ],
                        [
                            isValidIdentifier(),
                            isExpressionIdPresent(),
                            isExpressionInScope(),
                            isExpressionReturning("boolean")
                        ],
                        "Winter Sports must be yes or no, or an expression id which returns yes or no"
                    )
                ]
            },
            pregnancy: {
                value: "",
                isValid: null,
                errors: [],
                validators: [
                    isNotEmpty(),
                    isBoolean()
                ]
            },
            pregnancyComplications: {
                value: "",
                isValid: null,
                errors: [],
                validators: [
                    isNotEmpty(),
                    isBoolean()
                ]
            },
            corona: {
                value: "",
                isValid: null,
                errors: [],
                validators: [
                    isNotEmpty(),
                    isBoolean()
                ]
            },
            linkedCondition: {
                value: "",
                isValid: null,
                errors: [],
                validators: [
                    isNotEmpty(),
                    isNumeric()
                ]
            },
            rescore: {
                value: "",
                isValid: null,
                errors: [],
                validators: [
                    isNotEmpty(),
                    isBoolean()
                ]
            },
            rescoreOnDifference: {
                value: "",
                isValid: null,
                errors: [],
                validators: [
                    isNotEmpty(),
                    isBoolean()
                ]
            },
            test: {
                value: "",
                isValid: null,
                errors: [],
                validators: [
                    isNotEmpty(),
                    isBoolean()
                ]
            },
            expressionType: {
                value: "verisktwo",
                isValid: true,
                errors: [],
                validators: []
            },
            verisk: {
                value: "",
                isValid: true,
                errors: [],
                validators: [
                    isNotEmpty()
                ]
            },
            returns: {
                type: "object",
                isValid: true,
                validators: [],
                errors: [],
                value: {
                    type: "object",
                    properties: [
                        {
                            id: "linked_conditions",
                            type: "array",
                            items: {
                                type: "object",
                                properties: [
                                    {
                                        id: "@_Type",
                                        type: "string"
                                    },
                                    {
                                        id: "@_ICD",
                                        type: "number"
                                    },
                                    {
                                        id: "@_name",
                                        type: "string"
                                    }
                                ]
                            }
                        },
                        {
                            id: "medical_risk",
                            type: "number"
                        },
                        {
                            id: "screened",
                            type: "boolean"
                        },
                        {
                            id: "conditions",
                            type: "array",
                            items: {
                                type: "object",
                                properties: [
                                    {
                                        id: "ICD9",
                                        type: "number"
                                    },
                                    {
                                        id: "ICD",
                                        type: "string"
                                    },
                                    {
                                        id: "name",
                                        type: "string"
                                    },
                                    {
                                        id: "exclusion_type",
                                        type: "string"
                                    }
                                ]
                            }
                        },
                        {
                            id: "exclusions",
                            type: "boolean"
                        },
                        {
                            id: "data",
                            type: "string"
                        }
                    ]
                }
            }
        };
        super(id, properties);
    }

    static cleanExpression(exp) {
        return {
            id: exp.id,
            url: exp.url,
            key: exp.key,
            region: exp.region,
            amt: exp.amt,
            winterSports: exp.winterSports,
            pregnancy: exp.pregnancy,
            pregnancyComplications: exp.pregnancyComplications,
            corona: exp.corona,
            linkedCondition: exp.linkedCondition,
            rescore: exp.rescore,
            rescoreOnDifference: exp.rescoreOnDifference,
            test: exp.test,
            expressionType: exp.expressionType,
            returns: exp.returns,
            verisk: exp.verisk
        };
    }
}
