import axios from "./axios";

/**
 * Posts an auditable event
 * @param {String} brandURL
 * @param {Object} payload
 */
const postAuditableEvent = async function(brandURL, payload) {
    return await axios.post(`/brands/${brandURL}/audits`, payload);
};

export default {
    postAuditableEvent
};
