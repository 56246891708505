import pagesService from "../../services/pages";

export const types = {
    SET_PAGES: "SET_PAGES",
    SET_PAGE: "SET_PAGE",
    SET_PAGE_REVISIONS_COUNT: "SET_PAGE_REVISIONS_COUNT",
    SET_PAGE_REVISIONS: "SET_PAGE_REVISIONS"
};

const state = {
    pages: [],
    revisionCount: 0,
    revisions: []
};

const actions = {
    async getAllPages({ commit, rootState }) {
        const pages = await pagesService.getPages({ brandUrl: rootState.auth.selectedBrand.name_url });
        commit(types.SET_PAGES, pages.data);
    },
    async postPage({ commit, rootState }, { name, comment, tags, pageIn = {} }) {
        const page = await pagesService.postPage({
            brandUrl: rootState.auth.selectedBrand.name_url,
            name,
            page: pageIn,
            comment,
            tags
        });
        if (page.data === "") {
            return null;
        }
        commit(types.SET_PAGE, { ...page.data, id: page.data.page_id });
        return page.data;
    },
    async getPageRevisions({ commit, rootState }, { id, limit, offset }) {
        const revisions = await pagesService.getPageRevisions({
            pageId: id,
            brandUrl: rootState.auth.selectedBrand.name_url,
            params: {
                offset,
                limit
            }
        });
        commit(types.SET_PAGE_REVISIONS, revisions.data.data);
        commit(types.SET_PAGE_REVISIONS_COUNT, revisions.data.count);
        return revisions.data;
    }
};

const mutations = {
    [types.SET_PAGE](state, page) {
        state.pages.push(page);
    },
    [types.SET_PAGES](state, pages) {
        state.pages = pages;
    },
    [types.SET_PAGE_REVISIONS_COUNT](state, val) {
        state.revisionCount = val;
    },
    [types.SET_PAGE_REVISIONS](state, val) {
        state.revisions = val;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
