import Expression from "./Expression";
import {
    isNotEmpty,
    isBoolean,
    isIn,
    isExpressionIdPresent
} from "./ExpressionValidators";

/**
 * @function keyValuValidator
 * @description returns a keyValue Validator
 * @return {KeyValueValidator}
 */
function keyValueValidator() {
    /**
     * @class KeyValueValidator
     * @inner
     * @description validator to use on the value object when it contains a key
     * value object, this passes the responisibility for validation on to the key
     * value objects
     */
    return {
        /**
         * @function validate
         * @description reduces over the values calling validate on all values
         * @memberof KeyValueValidator
         * @param {object} v - object with a validate function
         * @returns {Boolean} - is every object valid
         */
        validate(v) {
            return v.reduce((acc, el) => el.validate() && acc, true);
        },
        /**
         * @function message
         * @description message to be displayed if invalid, this is only
         * included to conform to validator interface, errors on child
         * values should be used
         * @memberof KeyValueValidator
         * @returns {String} - error message
         */
        message() {
            return "This property is invalid";
        }
    };
}

/**
 * @class Value
 * @description a value object
 * @property {Array<object>} validators - array of validator objects
 * @property {Boolean} dirty - has the object been changed
 * @property {?Boolean} isValid - is the value valid
 * @property {Array<String>} errors - array of error messages
 * @property {*} value - value of the object
 * @param {object} [options={}] - options object
 * @param {*} [options.value=""] - the value of the object
 * @param {Array<object>} [options.validators=[]] - array of validator objects
 * @returns {Value} - value object
 */
class Value {
    constructor(options = {}) {
        this.validators = [];
        this.dirty = false;

        /**
         * @property {*} _value - the value of the object
         * @private
         */
        this._value = "";

        /**
         * @property {?Boolean} _isValid - is the value valid
         * @private
         */
        this._isValid = null;

        /**
         * @property {Array<String>} _errors - array of errors
         * @private
         */
        this._errors = [];

        this._value = options.value || this._value;
        this.validators = options.validators || this.validators;
    }

    /**
     * @function validate
     * @description runs validators against the value
     * @memberof Value
     * @instance
     * @returns {void} - no return value
     */
    validate() {
        this.validators.forEach((f) => {
            if (!f.validate(this.value, this)) {
                this.errors.push(f.message());
            }
        });
    }

    /*
     * getter for is valid, either returns _isValid or if value contains an
     * array of objects with isValid methods returns true if every isValid is
     * true
    */
    get isValid() {
        if (
            Array.isArray(this.value) &&
            this.value[0] &&
            typeof this.value[0].isValid !== "undefined"
        ) {
            return this.value.every((el) => el.isValid);
        } else {
            return this._isValid;
        }
    }

    // sets the isValid on this instance, does not alter child objects
    set isValid(val) {
        this._isValid = val;
    }

    /*
     * gets the errors, if value contains an errors array returns a
     * flat array of all child errors
     */
    get errors() {
        if (
            Array.isArray(this.value) &&
            this.value[0] &&
            typeof this.value[0].errors !== "undefined"
        ) {
            return this.value.reduce((acc, el) => [...acc, ...el.errors], []);
        } else {
            return this._errors;
        }
    }

    /*
     * sets errors on this object instance, does not alter child objects
     */
    set errors(val) {
        this._errors = val;
    }

    /*
     * gets the value
     */
    get value() {
        return this._value;
    }

    /*
     * sets the value of this instance, does not alter child objects, sets the
     * object to dirty and validates
     */
    set value(val) {
        this._value = val;
        this.dirty = true;
        this.validate();
    }

    /**
     * @function clean
     * @description returns the clean value of this instance, if value contains
     * an array of objects with clean functions it returns an array of cleaned children
     * @memberof Value
     * @instance
     * @returns {*} - clean value of current object
     */
    clean() {
        if (
            Array.isArray(this.value) &&
            this.value[0] &&
            typeof this.value[0].clean === "function"
        ) {
            return this.value.map((el) => el.clean());
        } else {
            return this.value;
        }
    }
}

/**
 * @class KeyValue
 * @description used for headers, queries, and data, has 3 properties name,
 * value, and dynamic
 * @property {Boolean} dirty - has the object been changed
 * @property {Array<String>} errors - array of errors
 * @property {?Boolean} isNameValid - is the value valid
 * @property {?Boolean} isDynamicValid - is the value valid
 * @property {?Boolean} isValueValid - is the value valid
 * @property {Array<object>} nameValidators - array of validator objects
 * for the name property
 * @property {Array<object>} dynamicValidators - array of validator objects
 * for the dynamic property
 * @property {Array<object>} valueValidators - array of validator objects
 * for the value property
 * @property {?Boolean} isValid - is the object as a whole valid
 * @property {String} name - the name of the key value pair
 * @property {Boolean} dynamic - is the value dynamic
 * @property {String} value - the value of the key value pair
 *
 * @param {object} [options={}] - options object
 * @param {String} [options.name] - the name of the property
 * @param {Boolean} [options.dynamic] - is the value pulled out of previous data
 * @param {*} [options.value] - the value of the pair
 * @returns {KeyValue} - key value pair object
 */
class KeyValue {
    constructor(options = {}) {
        this.dirty = false;
        this.errors = [];
        this.isNameValid = null;
        this.isDynamicValid = null;
        this.isValueValid = null;
        this.nameValidators = [ isNotEmpty("Value") ];
        this.dynamicValidators = [ isBoolean() ];
        this.valueValidators = [ isNotEmpty("Name") ];

        /**
         * @property {String} _name - the name value
         * @private
         */
        this._name = "";

        /**
         * @property {*} _value - the value value
         * @private
         */
        this._value = "";

        /**
         * @property {Boolean} _dynamic - the dynamic value
         * @private
         */
        this._dynamic = false;

        this._name = options.name || this._name;
        this._value = options.value || this._value;
        this._dynamic = options.dynamic || this._dynamic;
    }

    /**
     * @function validate
     * @description validates the key value pair
     * @memberof KeyValue
     * @instance
     * @returns {Boolean} - is the key value pair valid
     */
    validate() {
        // We have 3 values to validate the name, dynamic, and value
        const errors = [];

        // validate the name
        this.isNameValid = this.nameValidators.every((el) => el.validate(this.name));
        if (!this.isNameValid) {
            // push the error message onto the errors
            errors.push(...this.nameValidators.map((el) => el.message()));
        }

        // validate the dynamic, this propably isn't needed because the ui only allows true false values
        this.isDynamicValid = this.dynamicValidators.every((el) => el.validate(this.dynamic));
        if (!this.isDynamicValid) {
            // push the error message onto the errors
            errors.push(...this.dynamicValidators.map((el) => el.message()));
        }

        // validate the value, this validator is ran if dynamic or not
        this.isValueValid = this.valueValidators.every((el) => el.validate(this.value));
        if (this.dynamic) {
            // if its dynamic we have additional validators to run
            let isIdPresent = isExpressionIdPresent().validate(this.value);
            if (!isIdPresent) {
            // push the error message onto the errors
                errors.push(isExpressionIdPresent().message());
            }
            this.isValueValid = this.isValueValid && isIdPresent;
        }
        if (!this.isValueValid) {
            // push the error message onto the errors
            errors.push(...this.valueValidators.map((el) => el.message()));
        }

        this.errors = errors;

        return this.isValid;
    }

    /*
     * is name, dynamic and value valid
     */
    get isValid() {
        return this.isNameValid && this.isDynamicValid && this.isValueValid;
    }

    /*
     * the name of the key pair
     */
    get name() {
        return this._name;
    }

    /*
     * the name of the key pair
     * sets the object to dirty and validates it
     */
    set name(val) {
        this.dirty = true;
        this._name = val;
        this.validate();
    }

    /*
     * the value of the obejct
     */
    get value() {
        return this._value;
    }

    /*
     * value of the object
     * sets the object to dirty and validates it
     */
    set value(val) {
        this.dirty = true;
        this._value = val;
        this.validate();
    }

    /*
     * is the value dynamic
     */
    get dynamic() {
        return this._dynamic;
    }

    /*
     * is the value dynamic
     * sets the object to dirty and validates it
     */
    set dynamic(val) {
        this.dirty = true;
        this._dynamic = val;
        this.validate();
    }

    /**
     * @function clean
     * @description returns a cleaned version of the object
     * @memberof KeyValue
     * @instance
     * @returns {object} - cleaned object
     */
    clean() {
        return {
            value: this.value,
            name: this.name,
            dynamic: this.dynamic
        };
    }

}

/**
 * @class ApiExpression
 * @extends Expression
 * @property {String} url - the url for the engine to hit
 * @property {String} method - the method to use when hitting the engine
 * @property {Array<KeyValue>} queries - query params for api request
 * @property {Array<KeyValue>} headers - header for api request
 * @property {Array<KeyValue>} data - body for the api request
 * @property {String} expressionType="api" - the expression type
 * @property {Boolean} test - is this a test
 * @property {String} time - the time for the request
 *
 * @param {String} id - id of the expression
 * @returns {ApiExpression}
 */
export default class ApiExpression extends Expression {
    constructor(id) {
        const possibleMethods = ["POST", "GET"];
        const properties = {
            url: new Value({
                validators: [ isNotEmpty() ]
            }),
            method: new Value({
                validators: [
                    isNotEmpty(),
                    isIn(possibleMethods)
                ]
            }),
            queries: new Value({
                value: [],
                validators: [ keyValueValidator() ]
            }),
            headers: new Value({
                value: [],
                validators: [ keyValueValidator() ]
            }),
            data: new Value({
                value: [],
                validators: [ keyValueValidator() ]
            }),
            expressionType: new Value({
                value: "api"
            }),
            test: new Value({
                validators: [
                    isNotEmpty(),
                    isBoolean()
                ]
            }),
            time: new Value()
        };
        super(id, properties);
        this.possibleMethods = possibleMethods;
    }

    /**
     * @function addQuery
     * @description adds a new query to the object
     * @memberof ApiExpression
     * @instance
     * @param {object} [query={}] - the query data
     * @param {String} [query.name] - the name of the value
     * @param {Boolean} [query.dynamic] - the the value dynamic
     * @param {*} [query.value] - the value of the query
     */
    addQuery(query = {}) {
        this.properties.queries._value.push(new KeyValue(query));
    }

    /**
     * @function addHeader
     * @description adds a new header to the object
     * @memberof ApiExpression
     * @instance
     * @param {object} [header={}] - the header data
     * @param {String} [header.name] - the name of the value
     * @param {Boolean} [header.dynamic] - the the value dynamic
     * @param {*} [header.value] - the value of the header
     */
    addHeader(header = {}) {
        this.properties.headers._value.push(new KeyValue(header));
    }

    /**
     * @function addData
     * @description adds a new data to the object
     * @memberof ApiExpression
     * @instance
     * @param {object} [data={}] - the data data
     * @param {String} [data.name] - the name of the value
     * @param {Boolean} [data.dynamic] - the the value dynamic
     * @param {*} [data.value] - the value of the data
     */
    addData(data = {}) {
        this.properties.data._value.push(new KeyValue(data));
    }

    /**
     * @function cleanExpression
     * @description returns a cleaned version of the expression
     * @memberof ApiExpression
     * @static
     * @param {ApiExpression} exp - the expression to clean
     * @returns {object} - a cleaned version of the expression
     */
    static cleanExpression(exp) {
        return {
            id: exp.id,
            url: exp.url,
            method: exp.method,
            headers: exp.properties.headers.clean(),
            data: exp.method === "GET" ? [] : exp.properties.data.clean(),
            expressionType: exp.expressionType,
            test: exp.test,
            time: exp.time,
            returns: exp.returns,
            queries: exp.properties.queries.clean()
        };
    }
}
