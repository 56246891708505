<template>
    <div
        class="base-input__tag"
    >
        <font-awesome-icon
            :icon="['fal', 'tag']"
        />
        <span class="base-input__tag--name">
            {{ tag }}
        </span>
        <span
            v-if="showDelete"
            class="base-input__tag--delete"
            @click="$emit('delete')"
        >
            <font-awesome-icon
                :icon="['fal', 'times-circle']"
            />
        </span>
    </div>
</template>

<script>
export default {
    props:{
        tag:{
            type:String,
            required:true
        },
        showDelete:{
            type:Boolean,
            required:false,
            default:true
        }
    }
};
</script>

<style scoped lang="sass">
    .base-input__tag
        padding: 3px 3px 3px 3px
        position:relative
        border: 1px solid $secondary
        background: lighten($secondary, 25%)
        position: relative
        color: darken($secondary, 45%)
        margin: 5px 5px 5px 0
        border-radius: 3px
        display: flex
        justify-content: space-between
        align-items: center
        svg
          margin-left: 5px
          margin-right: 5px
        &--name
          padding-right: 5px
          font-size: 0.85rem
        &--delete
          position: absolute
          right: 0
          top: 0
          cursor: pointer
          bottom: 0
          border-radius: 3px
          border-top-left-radius: 0
          border-bottom-left-radius: 0
          background: $red
          color: #fff
          v-align: middle
          padding: 3px 5px
          width: 25px
          text-align: right
          &:hover, &:focus, &:active
            background: darken($primary, 15%)
          svg
            margin: 0
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
    .form-group
        .base-input__tag
            &--name
                padding-right: 35px
</style>