import axios from "axios";
import store from "../store";
import NProgress from "nprogress";

NProgress.configure({ easing: "ease", trickle: false, showSpinner: false });

//This is set in DEV only
const customAxios = axios.create({
    //eslint-disable-next-line
    baseURL:process.env.VUE_APP_API_URL,
    onDownloadProgress(pe) {
        if(pe.lengthComputable) {
            const progress = pe.total / pe.loaded;
            NProgress.set(progress);
        }
    }
});

let authHeader = function() {
    // return authorization header with jwt token
    // if we are overriding
    let jwt = sessionStorage.getItem("jwt");
    if (jwt) {
        return { "authentication": "bearer " + jwt };
    } else {
        return {};
    }
};

customAxios.interceptors.response.use(
    function(response) {
        NProgress.done();
        return response;
    },
    function(error) {
        //TODO unpack axios errors so that they behave like normal
        //JS Errors
        if(
            error.response &&
            error.config.url !== "auth/login" &&
            error.response.status === 401
        ){
            store.dispatch("auth/logout");
        }

        try{
            if (error.response && error.response.data.errors) {
                error.message = error.response.data.errors
                    // error lines should end with a full stop
                    .map(({ message }) => message[message.length - 1] === "." ? message : `${message}.`)
                    .join("\n");
                error.details = error.response.data.errors;
            }
        } catch (err) {
            // do nothing
        }
        return Promise.reject(error);
    }
);

customAxios.interceptors.request.use(
    function(config) {
        const testing = store.state.testMode?.testing ?? false; 
        //adds the auth header if available
        //adds the baseURL is not set
        if(!config.baseURL){
            //use main axios instance so as not to get stuck recursively
            return axios.get("/app/config").then((res)=>{
                return store.dispatch("env/setEnvironmentVariables",res.data).then(()=>{
                    //eslint-disable-next-line
                    customAxios.defaults.baseURL = res.data.apiUrl;
                    return {
                        ...config,
                        baseURL:res.data.apiUrl,
                        headers:{...authHeader(),...config.headers, testing}
                    };
                });
            }).catch((err)=>{
                //eslint-disable-next-line
                throw err;
            });
        }
        NProgress.start();
        return Promise.resolve({
            ...config,
            headers:{...authHeader(),...config.headers, testing}
        });
    },
    function(error) {
        return Promise.reject(error);
    }
);

export default customAxios;
