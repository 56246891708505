import axios from "./axios";

const fetchRetentionRules = (brand) => {
    return axios.get(`brands/${brand}/retention-rules`);
};
const fetchRuleById = (brand, id) => {
    return axios.get(`brands/${brand}/retention-rules/${id}`);
};
const updateRetentionRule = (brand, payload) => {
    return axios.patch(`brands/${brand}/retention-rules/${payload.id}`, {
        period: payload.period
    });
};
const postRetentionRule = (brand, payload) => {
    return axios.post(`brands/${brand}/retention-rules`, {
        period: payload.period,
        retentionID: payload.retentionID
    });
};

const getWebhooks = (brand) => {
    return axios.get(`/brands/${brand}/webhooks`);
};

const getWebhookTypes = (brand) => {
    return axios.get(`/brands/${brand}/webhooks/types`);
};

const createWebhook = (brand, payload) => {
    return axios.post(`/brands/${brand}/webhooks`, {
        webhookTypeId: payload.webhook_type_id,
        url: payload.url,
        contentType: payload.content_type,
        secret: payload.secret,
        signature: payload.signature,
        status: payload.status
    });
};

const updateWebhook = (brand, payload) => {
    return axios.patch(`/brands/${brand}/webhooks/${payload.id}`, {
        webhookTypeId: payload.webhook_type_id,
        url: payload.url,
        contentType: payload.content_type,
        secret: payload.secret,
        signature: payload.signature,
        status: payload.status
    });
};

const deleteWebhook = (brand, webhookId) => {
    return axios.delete(`/brands/${brand}/webhooks/${webhookId}`);
};

const getWebhooksServiceErrors = async brand => {
    return axios.get(`/brands/${brand}/webhooks/service-errors`);
};

const getPolicyNumberingMethod = brand => {
    return axios.get(`/brands/${brand}/custom-policy-numbers/`);
};

const setPolicyNumberingMethod = (brand, payload) => {
    return axios.patch(`/brands/${brand}/custom-policy-numbers/set-method/`, payload);
};

const getLastPolicyNumber = brand => {
    return axios.get(`/brands/${brand}/custom-policy-numbers/last-policy-number/`);
};

const addPolicyNumbers = (brand, payload) => {
    return axios.post(`/brands/${brand}/custom-policy-numbers/`, payload);
};

const setPolicyNumberLowTidemark = (brand, payload) => {
    return axios.patch(`/brands/${brand}/custom-policy-numbers/low-tidemark/`, payload);
};

export default {
    fetchRetentionRules,
    fetchRuleById,
    updateRetentionRule,
    postRetentionRule,
    getWebhooks,
    getWebhookTypes,
    createWebhook,
    updateWebhook,
    deleteWebhook,
    getWebhooksServiceErrors,
    getPolicyNumberingMethod,
    setPolicyNumberingMethod,
    getLastPolicyNumber,
    addPolicyNumbers,
    setPolicyNumberLowTidemark
};
