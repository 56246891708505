class AST {
    constructor() {
        this.root = undefined;
    }
}

/**
 * Expression
 * @class
 * @classdesc Creates an Expression e.g a + 10
 */
class ExpressionStatement {
    constructor(token, expression) {
        this.token = token;
        this.expression = expression;
    }
    tokenLiteral() {
        return this.token.tokenLiteral;
    }
    toString() {
        if (this.expression) {
            return this.expression.toString();
        }
        return "";
    }
}

/**
 * Identifier
 * @class
 * @classdesc Creates an Identifier **Node**
 */
class Identifier {
    constructor(token, value) {
        this.token = token;
        this.value = value;
    }
    tokenLiteral() {
        return this.token.literal;
    }
    toString() {
        return this.tokenLiteral();
    }
}

class NumberLiteral {
    constructor(token, value) {
        this.token = token;
        this.value = value;
    }
    tokenLiteral() {
        return this.token.literal;
    }
    toString() {
        return this.tokenLiteral();
    }
}

class StringLiteral {
    constructor(token, value) {
        this.token = token;
        this.value = value;
    }
    tokenLiteral() {
        return this.token.literal;
    }
    toString() {
        return "\"" + this.tokenLiteral() + "\"";
    }
}

class DateLiteral {
    constructor(token, value) {
        this.token = token;
        this.value = value;
    }
    tokenLiteral() {
        return this.token.literal;
    }
    toString() {
        return "#" + this.tokenLiteral() + "#";
    }
}

class BooleanLiteral {
    constructor(token, value) {
        this.token = token;
        this.value = value;
    }
    tokenLiteral() {
        return this.token.literal;
    }
    toString() {
        return this.tokenLiteral();
    }
}

class PrefixExpression {
    constructor(token, operator, right) {
        this.token = token;
        this.operator = operator;
        this.right = right;
    }
    tokenLiteral() {
        return this.token.literal;
    }
    toString() {
        return "(" + this.operator + this.right.toString() + ")";
    }
}

class InfixExpression {
    constructor(token, left, operator, right) {
        this.token = token;
        this.operator = operator;
        this.right = right;
        this.left = left;
    }
    tokenLiteral() {
        return this.token.literal;
    }
    toString() {
        // console.log(this.left);
        return "(" + this.left.toString() + " " + this.operator + " " + this.right.toString() + ")";
    }
}

class IFExpression {
    constructor(token, condition, trueExp, falseExp) {
        this.token = token;
        this.condition = condition;
        this.trueExp = trueExp;
        this.falseExp = falseExp;
    }
    tokenLiteral() {
        return this.token.literal;
    }
    toString() {
        // console.log(this.left);
        return "if(" + this.condition.toString() + ", " +
            this.trueExp.toString() + ", " +
            this.falseExp.toString() + ")";
    }
}

class AndExpression {
    constructor(token, param1, param2) {
        this.token = token;
        this.param1 = param1;
        this.param2 = param2;
    }
    tokenLiteral() {
        return this.token.literal;
    }
    toString() {
        // console.log(this.left);
        return "and(" + this.param1.toString() + "," + this.param2.toString() + ")";
    }
}

class OrExpression {
    constructor(token, param1, param2) {
        this.token = token;
        this.param1 = param1;
        this.param2 = param2;
    }
    tokenLiteral() {
        return this.token.literal;
    }
    toString() {
        // console.log(this.left);
        return "or(" + this.param1.toString() + "," + this.param2.toString() + ")";
    }
}

class NotExpression {
    constructor(token, param1) {
        this.token = token;
        this.param1 = param1;
    }
    tokenLiteral() {
        return this.token.literal;
    }
    toString() {
        // console.log(this.left);
        return "not(" + this.param1.toString() + ")";
    }
}

class CallExpression {
    constructor(token, func = undefined, args = []) {
        this.token = token;
        this.func = func;
        this.args = args;
    }
    tokenLiteral() {
        return this.token.literal;
    }
    toString() {
        let args = this.args.map(arg => arg.toString());
        return this.func.toString() + "(" + args.join(", ") + ")";
    }
}

module.exports = {
    AST,
    ExpressionStatement,
    Identifier,
    NumberLiteral,
    StringLiteral,
    BooleanLiteral,
    DateLiteral,
    PrefixExpression,
    InfixExpression,
    CallExpression,
    IFExpression,
    AndExpression,
    OrExpression,
    NotExpression
};
