<template>
    <div class="base-header">
        <div class="base-header__wrapper">
            <div class="base-header__title">
                <span>{{ title }}</span>
            </div>
            <div
                v-for="(item, index) in subtitles"
                :key="index"
                :title="item"
                class="base-header__subtitle-item"
            >
                <span class="base-header__subtitle-item__title">
                    {{ item }}
                </span>
            </div>
            <div class="base-header__actions">
                <slot name="actions" />
            </div>
        </div>
        <slot name="breadcrumbs">
            <BaseBreadcrumbs
                v-if="breadcrumbs.length"
                :items="breadcrumbs"
            />
        </slot>
    </div>
</template>

<script>
import BaseBreadcrumbs from "./BaseBreadcrumbs.vue";

export default {
    name: "BaseHeader",
    components: {
        BaseBreadcrumbs
    },
    props: {
        title: {
            type: String,
            default: ""
        },
        items: {
            type: Array,
            default: () => []
        },
        breadcrumbs: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        subtitles() {
            return this.items.filter(item => !!item);
        }
    }
};
</script>

<style lang="sass" scoped>
.base-header
    position: relative
    background: #ffffff
    padding: 15px 0
    margin-bottom: 1rem
    &__wrapper
        display: flex
    &__title
        font-family: Gilroy-Bold, sans-serif
        font-size: 1.5rem
        font-weight: 700
        line-height: 46px
    &__subtitle-item,
    &__subtitle-name
        position: relative
        margin-left: 24px
        display: flex
        &:before
            content: ""
            position: absolute
            left: -16px
            top: 50%
            transform: translateY(-50%)
            width: 10px
            height: 1px
            background-color: gray
        &__title
            display: inline-block
            max-width: 700px
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
            font-size: 1.3rem
            color: #747373
    &__actions
        margin-left: auto
        display: flex
        gap: 10px
</style>
