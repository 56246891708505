import axios from "./axios";

//import axios from "axios";

export default {
    async getSchemes(brandURL, archived) {
        return axios.get(`/brands/${brandURL}/schemes`, {
            params: {
                archived
            }
        });
    },
    async getSchemeRevisions(schemeId,brandURL,params) {
        return axios.get(`/brands/${brandURL}/schemes/${schemeId}/revisions`, {params});

    },
    async postScheme(brandURL,name,scheme=[],comment, tags){
        return axios.post(`/brands/${brandURL}/schemes`,{name,scheme,comment, tags});
    },
    async patchSchemeArchive(brandURL, schemeID, payload) {
        return axios.patch(`brands/${brandURL}/schemes/${schemeID}/archive`, payload);
    }
};
