import {
    isFormulaValid,
    isNotEmpty,
    isExpressionIdPresent,
    isExpressionIdUnique,
    isValidIdentifier,
    isExpressionInScope
} from "./ExpressionValidators";
import Expression from "./Expression";

export default class CountifExpression extends Expression {
    constructor(id){
        const properties = {
            condition:{
                value: null,
                isValid:null,
                dirty:false,
                errors:[],
                validators:[
                    isNotEmpty(),
                    isFormulaValid()
                ]
            },
            expressionType:{
                value: "countif",
                isValid:null,
                validators:[]
            },
            items:{
                value: null,
                isValid:null,
                validators:[
                    isNotEmpty(),
                    isExpressionIdPresent(),
                    isExpressionInScope()
                ]
            },
            item:{
                value: null,
                isValid:null,
                validators:[
                    isNotEmpty(),
                    isValidIdentifier(),
                    isExpressionIdUnique()
                ]
            }

        };
        super(id,properties);
    }

    static cleanExpression(exp) {
        return {
            id:exp.id,
            returns:exp.returns,
            expressionType:exp.expressionType,
            items:exp.items,
            item:exp.item,
            condition:exp.condition,
            description: exp.description,
            tags: exp.tags

        };
    }
    // function which triggers a "reload" of the state
    // this is necessary to allow instant validation feedback
    // for the isExpressionInScope validator.
    hasMoved() {
        // eslint-disable-next-line no-self-assign
        this.items = this.items;
    }
}

