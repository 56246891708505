import VuexPersistence from "vuex-persist";

export default new VuexPersistence({
    storage: window.sessionStorage,
    reducer: state => ({
        data: {
            results: state.data.results,
            form: state.data.form
        },
        auth: {
            userpilotUser: state.auth.userpilotUser
        },
        testMode: {
            testing: state.testMode.testing
        }
    })
});