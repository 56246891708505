<template>
    <b-breadcrumb class="breadcrumbs">
        <b-breadcrumb-item
            v-for="({active, title, to}, index) in breadcrumbs"
            :key="index"
            :to="to"
            :active="active"
            :title="title"
            class="breadcrumbs-item"
        >
            <font-awesome-icon
                v-if="to && to.name === 'dashboard'"
                size="sm"
                :icon="['fal', 'tachometer-alt-slowest']"
            />
            <span v-else>{{ title }}</span>
        </b-breadcrumb-item>
    </b-breadcrumb>
</template>

<script>
export default {
    name: "BaseBreadcrumbs",
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        breadcrumbs() {
            const breadcrumbItems = this.items;
            breadcrumbItems.forEach(el => el.active = false);
            breadcrumbItems.at(breadcrumbItems.length - 1).active = true;
            return breadcrumbItems;
        }
    }
};
</script>

<style lang="sass" scoped>
.breadcrumbs
    background-color: $white
    z-index: 2
    margin-bottom: 0
    padding: 0.5rem 0
.breadcrumbs-item
    max-width: 450px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    font-size: 0.8rem
    a
        color: $linkColor
</style>
