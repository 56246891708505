import axios from "./axios";

const fetchTemplates =  (brandID) => {
    return axios.get(`brands/${brandID}/templates`);
};

const fetchEmailTemplates =  (brandID) => {
    return axios.get(`brands/${brandID}/email-templates`);
};

const createTemplate = (brandID, payload) => {
    return axios.post(`brands/${brandID}/templates`,
        payload);
};

const createEmailTemplate = (brandID, payload) => {
    return axios.post(`brands/${brandID}/email-templates`,
        payload);
};

const fetchRevisions = (brandID, templateID) => {
    return axios.get(`brands/${brandID}/templates/${templateID}`);
};

const postRevision = (brandID, templateID, template, comment, tags, subject) => {
    return axios.post(`brands/${brandID}/templates/${templateID}/revision`, {
        template,
        comment,
        tags,
        subject
    });
};

const previewTemplate = (brandID, templateID, revisionID, template, subject, policyNumber) => {
    // eslint-disable-next-line
    return axios.post(`brands/${brandID}/templates/${templateID}/revision/${revisionID}/preview${policyNumber ? "?policy=" + policyNumber : "?"}`, {
        template,
        subject
    });
};

const getPolicyTemplates = (brandID) => {
    return axios.get(`brands/${brandID}/templates/policy-templates/`);
};

const savePolicyTemplates = ({brandID, data}) => {
    return axios.post(`brands/${brandID}/templates/policy-templates/`, data);
};

export default {
    fetchTemplates,
    createTemplate,
    fetchRevisions,
    postRevision,
    previewTemplate,
    savePolicyTemplates,
    getPolicyTemplates,
    fetchEmailTemplates,
    createEmailTemplate
};