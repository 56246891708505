import axios from "../../services/axios";
import moment from "moment";



export const types = {
    "SET_AUDITS": "SET_AUDITS",
    "SET_AUDIT_COUNT": "SET_AUDIT_COUNT",
    "SET_LOADING": "SET_LOADING",
    "SET_ERROR": "SET_ERROR",
    "SET_DATES": "SET_DATES",
    "SET_HEADERS": "SET_HEADERS",
    "SET_MESSAGE": "SET_MESSAGE"
};


const state = {
    auditData: [],
    auditCount: 0,
    loading: false,
    error: false,
    dates: {},
    fields: [],
    message: ""
};

const getters = {

};
// actions
const actions = {
    async getAuditData({ commit, rootState }, payload) {
        commit(types.SET_LOADING, true);
        try {
            
            commit(types.SET_ERROR, false);
                
            commit(types.SET_MESSAGE, "");
            let { data } = await axios.get(`brands/${rootState.auth.selectedBrand.name_url}/audits`, payload);
                
            commit(types.SET_AUDIT_COUNT, Number(data.count));
            if (data.data.length <= 0) {
                commit(types.SET_AUDITS, []);
                commit(types.SET_LOADING, false);
                return commit(types.SET_MESSAGE, "No results found, please try changing you search");
            }
            data.data.forEach(audit => {
                /* eslint-disable-next-line */
                            audit.created_at = moment(audit.created_at).format(
                    "YYYY-MM-DD H:mm:ss"
                );
                /* eslint-disable-next-line */
                            audit.modified_at = moment(audit.modified_at).format(
                    "YYYY-MM-DD H:mm:ss"
                );
                // make the json look pretty
                if (audit.event_data.length > 0 ) {
                    let niceJson = JSON.parse(audit.event_data);
                    /* eslint-disable-next-line */
                    audit.event_data = JSON.stringify(niceJson, null, 4)
                }
            });
            let dates = {
                endDate: moment(data.data[0].created_at).format("YYYY-MM-DD 23:59:59"),
                startDate: moment(data.data[data.data.length - 1].created_at).format(
                    "YYYY-MM-DD 00:00:00"
                )
            };
            commit(types.SET_DATES, dates);
            // create the table headers
            let headers = [];
            Object.keys(data.data[0]).forEach(header => {
                if (header !== "event_data" && header !== "modified_at") {
                    headers.push({ key: header, sortable: true });
                }
            });
            headers.push("event_data");
            commit(types.SET_HEADERS, headers);
            commit(types.SET_AUDITS, data.data);
            commit(types.SET_LOADING, false);
        } catch (error) {
            commit(types.SET_LOADING, false);
            commit(types.SET_ERROR, error);
        }
    }
};


// mutations
const mutations = {
    [types.SET_LOADING]: (state, loading) => (state.loading = loading),
    [types.SET_DATES]: (state, dates) => (state.dates = dates),
    [types.SET_HEADERS]: (state, headers) => (state.fields = headers),
    [types.SET_AUDITS]: (state, audits) => (state.auditData = audits),
    [types.SET_ERROR]: (state, error) => (state.error = error),
    [types.SET_MESSAGE]: (state, message) => (state.message = message),
    [types.SET_AUDIT_COUNT]: (state, val) => state.auditCount = val
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
