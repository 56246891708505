/** @module */

let moment = require("moment");
let types = require("./types");
import {Validator} from "vee-validate";
import PhoneNumber from "awesome-phonenumber";

// CONSTANTS
const numberRange = /^\s*((-?[0-9]+(?:[.]{1}[0-9]+)?){1}\s*-\s*(-?[0-9]+(?:[.]{1}[0-9]+)?){1})\s*$/; // matches x-y
const numberGTLT = /^\s*((>{1}\s*=?|<{1}\s*=?){1}\s*(-?[0-9]+(?:[.]{1}[0-9]+)?){1})\s*$/; // matches >= <=
const number = /^\s*((-?[0-9]+(?:[.]{1}[0-9]+)?){1})\s*$/; // 11.2, 10
const integer = /^\s*-?[0-9]*\s*$/; // 10;
// eslint-disable-next-line max-len
const dateRange = /^\s*(\d\d\d\d-\d\d-\d\d)(?:\s*-\s*)(\d\d\d\d-\d\d-\d\d)\s*$/; // 12/12/06 - 11/01/07
// eslint-disable-next-line max-len
const date = /^\d\d\d\d?-\d\d?-\d\d$/; // 12/12/2008 or 12-12-2008 or 2008/12/12 or 2008-12-12
const dateGTLT = /^((?:>|<)=?)\s*(\d\d\d\d-\d\d-\d\d)$/;
const text = /^\s*([^#\s,]+)\s*$/; // /^\s*([\w^#]+)\s*$/; //hello    /^(\w+\s?)+$/

/**
 * Checks to see if an object is empty
 * @param {Object} item
 */
export function isEmpty(item) {
    if (item === null || item === undefined) {
        return true;
    }
    if (typeof item === "string") {
        return item === "";
    }

    if (typeof item === "object") {
        return Object.keys(item).length === 0;
    }

    if (Array.isArray(item)) {
        return item.length === 0;
    }
}

/**
 * Checks to see if an item is a valid identifier (json spec)
 * e.g my-var
 * @param {string} iden the item to validate
 */
export function isIdentifer(iden){
    return /^[$A-Z_][0-9A-Z_$-]*$/i.test(iden);
}

/**
 * Returns true if the string represents an integer e.g 7
 * @param {String} n the number to validate
 * @returns {boolean}
 */
export function isInteger(n) {
    return integer.exec(n) ? parseInt(n) : null;
}

/**
 * Returns true if the string represents a decimal e.g 1.2
 * @param {String} n the number to validate
 * @returns {boolean}
 */
export function isDecimal(n) {
    return number.exec(n) && !integer.exec(n) ? parseFloat(n) : null;
}

/**
 * Returns true if the string is a number;
 * @param {String} n the number to validate
 * @returns {Number} the number or null for no match
 */
export function isNumber(n) {
    return number.exec(n) ? Number(n) : null;
}

/**
 * Returns true if the string is a number range e.g 11 - 16;
 * @param {String} n the number to validate
 * @returns {Number[]} the starting and ending number of the range or null if no match is found
 */
export function isNumberRange(n) {
    // 11 - 18
    let match = numberRange.exec(n);
    if (match) {
        let startNumber = Number(match[2]);
        let endNumber = Number(match[3]);
        let isValid = startNumber < endNumber;
        return isValid ? [startNumber, endNumber] : null;
    }
    return null;
}

/**
 * Returns true if the string is a number range e.g >=10
 * @param {String} n the number to validate
 * @returns {object[]} the number and operator [num,op]
 */
export function isNumberGTLT(n) {
    let match = numberGTLT.exec(n);
    if (match) {
        let num = Number(match[3]);
        let op = match[2];
        if ([">", "<", ">=", "<="].includes(op.trim())) {

            return [num, op];
        }
    }
    return null;
}

/**
 * Returns true if the string is a valid lookup string (no special characters)
 * @param {String} t the text to validate
 * @returns {string} the validated string or null for no match
 */
export function isText(t) {
    return text.exec(t) ? String(t) : null;
}

/**
 * Returns true if the string is a valid lookup string (no special characters)
 * @param {String} t the text to validate
 * @returns {string} the validated string or null for no match
 */
export function isString(t) {
    if (isEmpty(t)) {
        return null;
    }
    return t.toString();
}

/**
 * Returns true if the string is a valid lookup boolean (yes,no,true,false)
 * @param {String} b the boolean to validate
 * @returns {boolean} the boolean value or null for no match
 */
export function isBoolean(b) {
    if (isEmpty(b)) {
        return null;
    }
    if (
        b.toString().toLowerCase() === "true" ||
        b.toString().toLowerCase() === "yes" ||
        b.toString().toLowerCase() === "false" ||
        b.toString().toLowerCase() === "no"
    ) {
        return b.toString().toLowerCase() === "true" || b === true || b.toString().toLowerCase() === "yes";
    } else {
        return null;
    }
}

/**
 * Returns the array if it is valid
 * @param {Object[]} a the array to validate
 * @returns {Object[]} the array or null if not an array
 */
export function isArray(a) {
    return Array.isArray(a) ? a : null;
}

/**
 * Returns the array if it is valid
 * @param {Object} o the object to validate
 * @returns {Object} the object or null if not an object
 */
export function isObject(o) {
    return typeof o === "object" ? o : null;
}

/**
 * Parses a date using moment. The string must be in the format DD/MM/YYYY
 * @param {String} d
 * @returns {Object} the moment object or null if not valid
 */
export function parseDate(d) {
    return moment(d, "YYYY-MM-DD", true).isValid() ? moment(d, "YYYY-MM-DD") : null;
}

export function parseDateISO(d) {
    return moment(d, moment.ISO_8601, true).isValid() ? moment(d, moment.ISO_8601) : null;
}

/**
 * Returns true if the string is a valid date in the format DD/MM/YYYY
 * DO NOT USE IN LOOKUPS
 * @param {Object} d the date like object to validate
 * @param {Object} f the format to return the date in
 * @returns {Object} a string based version of the date
 */
export function isDate(d, f = "DD/MM/YYYY") {
    let dv = parseDate(d) || parseDateISO(d);
    if (dv !== null) {
        return dv.format(f);
    }
    return null;
}

/**
 * Returns true if the string is a valid lookup date in the format DD/MM/YYYY
 * @param {String} d the date to validate
 * @returns {Object} a parsed moment date or null if not a single date
 */
export function isDateSingle(d) {
    // TODO fix  || dateISO.exec(d);date = parseDateISO(d);
    let match = date.exec(d);
    if (match) {
        let date = parseDate(d);
        if (date) {
            return date;
        }
    }
    return null;
}

/**
 * Returns true if the string is a valid range lookup date in the format DD/MM/YYYY
 * @param {String} d the date to validate
 * @returns {Object[]} an array of moment dates (start/end) or null if not a date range
 */
export function isDateRange(d) {
    let match = dateRange.exec(d);
    if (match) {
        let startDate = parseDate(match[1]);
        let endDate = parseDate(match[2]);
        let isValid = startDate && endDate && startDate.isSameOrBefore(endDate);
        return isValid ? [startDate, endDate] : null;
    }
    return null;
}

/**
 * Returns true if the string is a valid date in the format > DD/MM/YYYY
 * @param {String} d the date to validate
 * @returns {boolean}
 */
export function isDateGTLT(d) {
    let match = dateGTLT.exec(d);
    if (match) {
        let date = parseDate(match[2]);
        if(date === null) {
            return null;
        }
        let op = match[1];
        if ([">", "<", ">=", "<="].includes(op)) {
            return [date, op];
        }
    }
    return null;
}



/**
 * Checks that the type matches the provided data - if the data is held as text it will parse it
 * and output the correct format - mainly used inside lookups or when validating incoming types
 * e.g JSON age:10 or age:"10"
 * @param {String} type e.g number,string,date,bool
 * @param {*} data
 */
export function isValidType(type, data) {
    // Add a check for special keyword PASS,REJECT
    if (data === types.REJECT || data === types.PASS) {
        return data;
    }
    switch (type) {
        case types.NUMBER:
            return isNumber(data);
        case types.STRING:
            return isString(data);
        case types.DATE:
            return isDateSingle(data);
        case types.BOOLEAN:
            return isBoolean(data);
        case types.TEXT:
            return isText(data);
        case types.ARRAY:
            return isArray(data);
        case types.OBJECT:
            return isObject(data);
        default:
            return null;
    }
}

Validator.extend("savename", {
    getMessage: function(field) {
        return (
            "The " +
                field +
                " should not contain any numbers or " +
                "punctuation apart from and apostrophe (') or a hyphen (-)."
        );
    },
    validate: function(value) {
        return /^[a-zA-Z'` -]*$/.test(value);
    }
});

/**
 * Checks to see if a phone number is valid
 */
Validator.extend("isphonenumber", {
    getMessage: function() {
        return "The phone number format is incorrect";
    },
    validate(item) {
        return new PhoneNumber(item).isValid();
    }
});

Validator.extend("reportFilter",
    (item, submitting) => {
        if (submitting[0] === "no") {
            return true;
        }
        return item.column !== null &&
            item.column2 !== null &&
            item.operator !== "Please Select" &&
            ((item.column2 === "Custom" && String(item.custom).trim() !== "") || item.column2 !== "Custom");
    }
);

Validator.extend("columnHeadings", {
    getMessage: () => "This field cannot contain special characters. Only '< > = - _ + * / .' are allowed.",
    // eslint-disable-next-line no-useless-escape
    validate: value => /^[\w|\*\>\<\=\.\+\/|\-]+$/.test(value)
});



