var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"tags",class:[_vm.$style.wrapper, {[_vm.$style.disabled]: _vm.disabled}, {[_vm.$style.isInvalid]: _vm.isInvalid}],on:{"click":_vm.showDropdown}},[_c('div',{class:_vm.$style.tagsWrapper},[_vm._t("default",function(){return [_vm._t("selection",function(){return [_c('div',[_vm._v(_vm._s(_vm.tags.join(', ')))])]},null,_vm.scope),_c('div',{class:_vm.$style.dropdownIcon},[_c('font-awesome-icon',{attrs:{"size":"lg","icon":_vm.isDropdownShow ? ['fal', 'angle-up'] : ['fal', 'angle-down']}})],1),(_vm.isDropdownShow)?_c('div',{directives:[{name:"outside-click",rawName:"v-outside-click",value:({
                    handler: _vm.hideDropdown,
                    include: _vm.includeNodesForOutsideClick,
                    rootEl: 'body'
                }),expression:"{\n                    handler: hideDropdown,\n                    include: includeNodesForOutsideClick,\n                    rootEl: 'body'\n                }"}],ref:"dropdown",class:[_vm.$style.dropdown],style:(_vm.dropdownStyles)},[_vm._t("prepend-dropdown-items"),(_vm.items.length)?_c('div',{ref:"options"},_vm._l((_vm.items),function(option,index){return _c('div',{key:index,class:[
                            _vm.$style.dropdownListItem,
                            {[_vm.$style.active]: _vm.isItemActive(option)},
                        ],attrs:{"title":_vm.objectItems ? option[_vm.labelField] : option},on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(option)}}},[_vm._t("dropdown-item",function(){return [_c('div',{class:_vm.$style.dropdownListTitle},[(_vm.objectItems)?_c('span',[_vm._v(_vm._s(option[_vm.labelField]))]):_c('span',[_vm._v(_vm._s(option))])]),(_vm.isItemActive(option))?_c('div',{class:_vm.$style.dropdownListIcon},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}})],1):_vm._e()]},null,{ item: option, isActive: _vm.isItemActive(option) })],2)}),0):_vm._e()],2):_vm._e()]},null,_vm.scope)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }