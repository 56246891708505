import componentsService from "../../services/components";

export const types = {
    "SET_MY_COMPONENTS": "SET_MY_COMPONENTS",
    "ADD_MY_COMPONENT": "ADD_MY_COMPONENT"
};

const state = {
    myComponents: []
};

const actions = {
    async saveMyComponent({commit, rootState}, payload) {
        await componentsService.saveMyComponent(rootState.auth.selectedBrand.name_url, payload);
        commit(types.ADD_MY_COMPONENT, payload);
    },
    async getMyComponents({ commit }, brandURL) {
        const components = await componentsService.getMyComponents(brandURL);
        commit(types.SET_MY_COMPONENTS, components.data);
    },
    async removeMyComponent({ commit, rootState, state }, payload) {
        const myComponentId = state.myComponents.find(component => component.component === payload).id;
        await componentsService.removeMyComponent(rootState.auth.selectedBrand.name_url, myComponentId);
        const components = await componentsService.getMyComponents(rootState.auth.selectedBrand.name_url);
        commit(types.SET_MY_COMPONENTS, components.data);
    }
};

const mutations = {
    [types.SET_MY_COMPONENTS](state, myComponents) {
        state.myComponents = [ ...myComponents ];
    },
    [types.ADD_MY_COMPONENT](state, myComponent) {
        state.myComponents = [ ...state.myComponents, myComponent ];
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
